import React, {useState, useCallback} from 'react';

import Chart, {Props}  from "react-apexcharts";


const Grafico =({style, data, colorPrimaria, colorSecundaria, labels, onClick})=> { 

  
    const props: Props={}
    
 
  

    
    props.options = {
        chart: {
            events:{
              dataPointMouseEnter: function(e) {
                if (onClick) {
                  try{
                    e.path[0].style.cursor = "pointer";
                  }
                  catch{

                  }
                  
                }
              },
              dataPointSelection: async function(e, chart, option){
                    if (onClick) {
                     return await onClick(option)
                    }
                  
                }
            }
        },
        plotOptions: {
          radialBar: {
            startAngle: -135,
            endAngle: 225,
            hollow: {
              margin: 0,
              size: "65%",
              background: "#fff",
              image: undefined,
              imageOffsetX: 0,
              imageOffsetY: 0,
              position: "front",
              dropShadow: {
                enabled: true,
                top: 3,
                left: 0,
                blur: 4,
                opacity: 0.24
              },
              
            },
            track: {
              background: "#fff",
              strokeWidth: "90%",

              margin: 0, // margin is in pixels
              dropShadow: {
                enabled: true,
                top: -3,
                left: 0,
                blur: 4,
                opacity: 0.35
              },

            },
    
            dataLabels: {
              showOn: "always",
              name: {
                offsetY: 20,
                show: true,
                color: "#888",
                fontSize: "14px",
                // fontWeight: '10px'
              },
              value: {
                formatter: function (val) {
                  return "".concat(val, "%");
                },
                color: "#888",
                fontSize: "20px",
                show: true,
                offsetY:-20

              }
            }
          }
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            type: "horizontal",
            shadeIntensity: 0.5,
            // gradientToColors: [ "#ABE5A1", "#5A0"],

            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100],
            colorStops:[ 
                [
                
                  {
                    offset: 0,
                    color: colorPrimaria,
                    opacity: 1
                  },
                 
                  {
                    offset: 100,
                    color:colorSecundaria,
                    opacity: 1
                  }
                ],
               
              ]
           
              
          }
        },
        stroke: {
          lineCap: "round",
         
        },
        labels: labels,
  
      }
    ;

    return (
<React.Fragment>

       
        <div   style={style}>
         

            <Chart 
              options={props.options}
              series={data}
              type="radialBar"
              
            //   width="100%" height="100%"
            />


        </div>

    

</React.Fragment>
        
    
      );

}

export default Grafico;

