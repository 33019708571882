import React, { useState } from "react";

import { useDadosGlobal } from "../../context";

import { Formik } from "formik";
import Input from "../../layout/tela/campos/inputUI";
import { Carregando } from "../../loading";

import * as Padrao from "../../../style/config";
import * as Tema from "./style";

import schema from "../../../schemas/recuperarSenha";
import * as utils from "../../../utils";
import * as api from "../../../API/consulta";

function RecuperarSenha({ tipo, onAbrir }) {
  const { usuario } = useDadosGlobal();
  const [propsAlerta, setPropsAlerta] = useState(utils.propsAlerta);

  const finalizar = async () => {
    const tAlerta = propsAlerta.tipoAnimacao;
    setPropsAlerta(utils.semAlerta);

    if (tAlerta == utils.animacoes.sucesso) onAbrir(false);
  };

  const recuperar = async ({ values, errors }) => {
    try {
      if (Object.entries(errors).length > 0) return;
      setPropsAlerta(utils.alertaCarregando({}));

      const ret = await api.recuperarSenha({ tipo, codigo: values?.codigo });

      setPropsAlerta(utils.alertaSucesso({ titulo: ret.mensagem }));
    } catch (e) {
      setPropsAlerta(
        utils.montaAlerta({
          titulo: e.message,
          origem: e.origem,
          tAnimacao: e.tipoAlerta,
        })
      );
    }
  };

  return (
    <Tema.SenhaModal>
      <Formik
        validationSchema={schema}
        onSubmit={() => {}}
        validateOnMount
        initialValues={{}}
      >
        {({ values, validateForm, errors, handleChange, setFieldValue }) => (
          <React.Fragment>
            <Tema.SenhaCabecalho>
              <Tema.SenhaTitulo>
                Recuperar Senha {tipo == 1 ? "Condutor" : "Gestor"}
              </Tema.SenhaTitulo>
            </Tema.SenhaCabecalho>
            <Tema.SenhaConteudo>
              <div
                style={{ display: "flex", flexDirection: "column", gap: 15 }}
              >
                <span style={{ margin: 10 }}>
                  {tipo == 1 ? "Condutor" : "Gestor"}
                </span>
                <Tema.SenhaDivInput>
                  <Input
                    labelAtivo={true}
                    onChange={handleChange}
                    name={"codigo"}
                    label={
                      tipo == 1
                        ? "4 últimos dígitos do Chassi"
                        : "Informe seu Código"
                    }
                    maxLength={tipo == 1 ?4 :null}
                    type={tipo == 1 ? "text" : "text"}
                    placeHolder={""}
                  />
                </Tema.SenhaDivInput>
              </div>
            </Tema.SenhaConteudo>
            <Tema.SenhaRodape>
              <Padrao.Botao
                size={"small"}
                onClick={() => {
                  recuperar({ values, errors });
                }}
                principal={true}
              >
                Recuperar Senha
              </Padrao.Botao>
            </Tema.SenhaRodape>
          </React.Fragment>
        )}
      </Formik>
      <Carregando
        tipo={propsAlerta.tipoAnimacao}
        exibir={propsAlerta.exibir}
        userClose={propsAlerta.usuarioFechar}
        loop={propsAlerta.loop}
        titulo={propsAlerta.titulo}
        origem={propsAlerta.origem}
        setExibir={() => {
          setPropsAlerta(utils.semAlerta);
        }}
        onFinish={() => {
          finalizar();
        }}
        // styleFundo={{width:'90%', height:'90%'}}
        // styleAnimacao={{width:'70%'}}
      />
    </Tema.SenhaModal>
  );
}

export default RecuperarSenha;
