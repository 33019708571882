
import { makeStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import Drawer from '@material-ui/core/Drawer';

const useStyles = makeStyles((theme) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }));

const ModalHub =({abrir, onFechar, children, fecharClicarFora})=>{
    const classes = useStyles();
    return(
      // <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
      //       {list(anchor)}
      //     </Drawer>
        <Drawer
        anchor={'top'}
        className={classes.modal}
  open={abrir}
  onClose={() =>{onFechar()}}
  closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        disableBackdropClick={!fecharClicarFora}
        disableEscapeKeyDown={true}
>

  {children}
</Drawer>
    )
};

export default ModalHub