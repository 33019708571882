import React from 'react';

import Login from '../components/pages/login';
// import RoutePrivada from './routes.privada';
import RoutePublica from './routes.publica';
import {useDadosGlobal} from '../components/context';
import Layout from '../components/layout';
import { Redirect, Route, Switch, BrowserRouter } from 'react-router-dom';
import MinhaIndicacao from "../components/pages/indicacao/minhaIndicacao";
import IndicacaoFinalizacao from "../components/pages/indicacao/Finalizacao";

const RotaInterna= ({logado, isPrivado, ...rest}) =>{
    if (isPrivado && !logado){
        return <Redirect to={{pathname:"/"}} />
    }
    return <Route {...rest} />

    }

const Rotas =()=>{
    const {usuarioState} = useDadosGlobal();

    const { usuario } = usuarioState;
    const logado = !!usuario;
    return (
        <BrowserRouter>
        <Switch>
            <RotaInterna path="/" isPrivado ={false} exact component={Login} logado={logado} />
            <RotaInterna path="/minhaindicacao/:ait" isPrivado ={false} logado={logado} component={MinhaIndicacao} />
            <RotaInterna  exact isPrivado ={true} component={Layout} logado={logado} />

            {/* <RotaInterna path="/indicacao" component={Indicacao} />*/}
            <RotaInterna path="/notificacaoFinalizacao" component={IndicacaoFinalizacao} /> 


        </Switch>
        </BrowserRouter>
        )
};

export default Rotas;