import styled from "styled-components";

const fontePadrao = { primaria: "Montserrat", secundaria: "Lato" };

export const Tema = {
  CorFundoApp: "#fdc629",
  CorFundoTela: "#e2e2e2",
  CorSecundaria: "#52658c",
  CorTerciaria: "#52658c",
  CorAdicionar: "#67b652",
  CorFont: "#575756",
  Font: fontePadrao.primaria,
  FontSecundaria: fontePadrao.secundaria,
  CorFundoCampos: "rgba(130, 130, 130, 0.10)",
  corSucesso: "green",
  corErro: "#9F1600",
  corAviso: "#F0952A",
  fundoCarregando: {
    backgroundColor: "#F1F1F1",
    top: "25%",
    marginLeft: "5.5%",
    marginRight: "5.5%",
    height: "90%",
  },
  fundoProcessando: {
    backgroundColor: "#FFFF",
    top: "9%",
    marginLeft: "5.5%",
    marginRight: "5.5%",
    height: "82%",
  },
};

const TextoPadrao = styled.span`
  font-family: ${Tema.Font};
  color: ${Tema.CorFont};
`;

export const Tela = styled.div`
  background: #e2e2e2;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 4%;
  padding-right: 4%;

  @media (max-width: 1010px) {
    overflow: auto;
    padding-left: 3px;
    padding-right: 3px;
  }

  *:focus,
  select:focus {
    outline-style: none !important;
    border-style: solid !important;
    border-color: #fdc629 !important;
    border-width: 1px !important;
  }
`;

export const Celula = styled.div`
  max-width: 810px;
`;

export const Corpo = styled.div`
  background: transparent;
  display: grid;
  grid-template-columns: 335px 1fr;
  grid-gap: 20px;
  padding: 10px;
  align-self: center;
  height: 100%;
  width: 80%;
  max-width: 1145px;
  @media (max-width: 1343px) {
    grid-template-columns: 1fr;
    ${Celula}:nth-child(3) {
      grid-row: 1 / 2;
      grid-column: 1 / 1;
      justify-self: center !important;
      min-width: 335px;
      max-width: 335px;
    }
    ${Celula}:nth-child(5) {
      grid-row: 2 / 3;
      grid-column: 1 / 1;
      justify-self: center !important;
      min-width: 335px;
      max-width: 335px;
    }
    ${Celula}:nth-child(2) {
      grid-row: 3 / 4;
      grid-column: 1 / 1;
      justify-self: center !important;
      min-width: 726px;
      max-width: 726px;
    }
    ${Celula}:nth-child(4) {
      grid-row: 4 / 5;
      grid-column: 1 / 1;
      justify-self: center !important;
      min-width: 726px;
      max-width: 726px;
    }
    ${Celula}:nth-child(6) {
      grid-row: 5 / 6;
      grid-column: 1 / 1;
      justify-self: center !important;
      min-width: 726px;
      max-width: 726px;
    }
    ${Celula}:nth-child(7) {
      display: none;
    }
    ${Celula}:nth-child(8) {
      grid-row: 6 / 7;
      grid-column: 1 / 1;
      justify-self: center !important;
      min-width: 726px;
      max-width: 726px;
    }
    ${Celula}:nth-child(1) {
      grid-row: 7 / 8;
      grid-column: 1 / 1;
    }
    ${Celula}:nth-child(1) {
      grid-row: 8 / 7;
      grid-column: 1 / 1;
      min-width: 335px;
    }
  }
  @media (max-width: 726px) {
    /*min-width:335px;*/
    /* ${Celula}:nth-child(4) {min-width:335px !important; }
 ${Celula}:nth-child(6) {min-width:335px !important; }
 ${Celula}:nth-child(8) {min-width:335px !important; } */
  }
`;

export const Coluna = styled.div`
  background: #ffffff;
  border-radius: 20px;
  padding: 20px;
  font-family: "Lato";
  color: #575756;
  min-height: 550px;
  justify-self: normal;
`;

export const CarregandoFundo = styled.div`
  background-color: transparent;
  /* background-Color: rgb(255,255,255, 0.9); */

  margin: 2%;
  align-items: center;
  justify-content: center;
  width: 60%;
  height: 80%;
  outline: none;
  border-radius: 20px;
`;
export const TituloAnimacao = styled(TextoPadrao)`
  color: #fff;
  ${(props) => props.corTexto && `color: ${props.corTexto};`}
  text-align: center;
  font-size: 1.8em;
  text-transform: uppercase;
  font-weight: bold;
`;
export const TextoAnimacaoFundo = styled.div`
  width: 100%;
  text-align: center;
`;
export const DivFechar = styled(TextoAnimacaoFundo)`
  display: flex;
  margin-right: -2%;
  text-align: left;
  align-items: flex-end;
  justify-content: flex-end;
`;
export const TextoAnimacao = styled(TextoPadrao)`
  color: ${Tema.CorSecundaria};
  text-align: center;
  font-size: 1.2em;
  text-transform: uppercase;
`;

export const AnimacaoFundo = styled.div`
  width: 45%;
`;

export const DivSpinner = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 40px;
`;

export const BtnHamburger = styled.button`
  padding: 0px 0px;
  display: ${(props) => (props.isMenuVisible ? `inline-block;` : `none`)};
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
  outline: none !important;
  transition: all 300ms;
`;
