import React from "react";

import * as Padrao from "../../../style/config";
import * as Tema from "../../layout/tela/style";
import termsSVG from "../../../image/terms.svg";

import * as api from "../../../API/consulta";

import Lottie from "react-lottie";
import animationData from "../../../assets/animated/assinaturaTermo.json";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const abrirlink = (url) => window.open(url, "_blank");

const DownloadTermo = ({ dadosNot, data, onChange, erros }) => {
  return (
    <React.Fragment>
      <Tema.CardForm>
        {/* <Tema.FormTitulo>Dados da Notificação </Tema.FormTitulo> */}
        <Tema.CardFormTermo>
          <div
            style={{ textAlign: "center", width: "48%", alignSelf: "center" }}
          >
            <h1 style={{ textTransform: "uppercase", fontSize: "medium" }}>
              Faça o Download do seu Termo
            </h1>
            <div>
              <Lottie options={defaultOptions} height={"100%"} width={"100%"} />
            </div>
          </div>

          <div style={{ margin: "auto auto" }}>
            <h3>Clique no botão abaixo para fazer o download do seu termo</h3>
            <p>
              Não esqueça de verificar todos os dados e assinar para nos enviar
            </p>
            <div
              style={{
                display: "flex",
                flexFlow: "row-reverse",
                marginTop: "20px",
              }}
            >
              <Padrao.Botao
                size="small"
                principal={true}
                onClick={async () => {
                  const ret = await api.gerarPdfTermo({
                    locatario: dadosNot?.nomeLocatariodesc,
                    dataInfracao: dadosNot?.DataInfracao,
                    horaInfracao: dadosNot?.HoraInfracao,
                    nomeCondutor: data?.DadosCondutor?.nomeCondutor,
                    email: data?.DadosCondutor?.emailCondutor,
                    vencimentoCnh: data?.DadosCondutor?.dtVencimentoCNH,
                    marca: dadosNot?.Marca,
                    modelo: dadosNot?.Modelo,
                    placa: dadosNot?.Placa,
                    numeroRegistroInterno: dadosNot?.CodFlowExecute,
                    numeroCnh: data?.DadosCondutor?.numeroCnh,
                    ait: dadosNot?.Ait,
                    tipoCnh: data?.DadosCondutor?.tipoCnh,
                    categoriaCnh: data?.DadosCondutor?.categoriaCnh,
                  });

                  window.open(ret);

                  // abrirlink("".concat(api.retornaURL(), ret));
                }}
              >
                Download
              </Padrao.Botao>
            </div>
          </div>
        </Tema.CardFormTermo>
      </Tema.CardForm>
    </React.Fragment>
  );
};

export default DownloadTermo;
