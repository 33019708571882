import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  html, body, #root {
    height: 100%;
    width: 100%;
    background: var(--color-background);
  }
  *, button, input {
    font-family: -apple-system,system-ui,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Fira Sans,Ubuntu,Oxygen,Oxygen Sans,Cantarell,Droid Sans,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Lucida Grande,Helvetica,Arial,sans-serif;
  }


  :root {
    /* --color-cliente: #03588C;
    --color-secundary: #f4f2ec;
    --color-tertiary: #0177C1;
    --color-headerMenu: linear-gradient(to right, #fff, #0177C1, #0177C1,#03588C,#03588C);  */

    
    --color-cliente: ${process.env.REACT_APP_COLOR_CLIENTE};
    --color-secundary: ${process.env.REACT_APP_COLOR_SECUNDARY};
    --color-tertiary: ${process.env.REACT_APP_COLOR_TERTIARY}; 
    --color-headerMenu: linear-gradient(to right, ${process.env.REACT_APP_COLOR_HEADER_MENU}); 
    --background-Menu:  linear-gradient(to bottom, ${process.env.REACT_APP_BACKGROUND_MENU}) ;
    --color-usuario: ${process.env.REACT_APP_COLOR_USUARIO}; 


 /* --color-cliente: #00925b; 
    --color-secundary: #f4f2ec;
    --color-tertiary: #f99a0b;
   --color-headerMenu: var(--color-cliente);// linear-gradient(to right, #fff, #f7aa62, #f7aa62*/
    
    --color-primary: var(--color-cliente);
    --color-background: #f5f5f5;
    --color-header: var(--color-tertiary);
   
    --color-linkedin: #0077b5;
    --color-input: #e1e9ee;
    --color-icons: #c7d1d8;
    --color-hashtag: #7a8b98;
    --color-ad-text: #434649;
    --color-panel: #fff;
    --color-link: #0073b1;
    --color-black: rgba(0,0,0,.9);
    --color-gray: rgba(0,0,0,.6);
    --color-separator: rgba(0,0,0,0.15);
    --color-white: #fff;
    --font-padrao: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  }
`;
