import * as Yup from 'yup';
// import * as valid from '../utils/validacoes'
import * as utils from '../utils'

export default Yup.object().shape({


        placa: Yup.string().optional(),

        dataIni: Yup.date().required('Campo Obrigatório')
          .min( new Date(utils.yearMin, 1, 1) , "Digite uma data valida")
          .max( new Date(utils.yearMax, 1, 1) , "Digite uma data valida"),

        dataFim: Yup.date().required('Campo Obrigatório')
          .min( new Date(utils.yearMin, 1, 1) , "Digite uma data valida")
          .max( new Date(utils.yearMax, 1, 1) , "Digite uma data valida"),
  
  })