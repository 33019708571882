import React, { useState, useCallback } from "react";

import Chart, { Props } from "react-apexcharts";

const Grafico = ({
  style,
  data,
  colorPrimaria,
  colorSecundaria,
  backgroundColor,
  borderColor,
  exibirLegenda,
  exibirOpcoes,
  titulo,
  dados,
  labels,
  onClick,
}) => {
  const paradas = backgroundColor?.map((x, i) => {
    return x.pos;
  });
  const cores = backgroundColor?.map((x, i) => {
    return x.cor;
  });
  const coresStop = backgroundColor?.map((x, i) => {
    return {
      offset: x.pos,
      color: x.cor,
      opacity: 1,
    };
  });

  const props: Props = {};

  props.options = {
    grid: {
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
        tickAmount: 10,
      },
      padding: {
        left: 5,
        right: 15,
      },
    },
    chart: {
      toolbar: {
        show: false,
      },
      animations: { speed: 800, easing: "linear" },
      type: "bar",
      height: 150,
      width: "100%",
      events: {
        dataPointSelection:async function (e, chart, option) {
          if (onClick) return await onClick(option)
        },
      },
      // background: '#fff',
      sparkline: {
        enabled: false,
      },
      dropShadow: {
        enabled: false,
        enabledOnSeries: undefined,
        top: 8,
        left: 7,
        blur: 11,
        color: "#000",
        opacity: 0.5,
      },
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        borderRadius: 5,
        horizontal: true,
        barHeight: "20%",
        // rangeBarGroupRows:false
      },
    },
    fill: {
      type: "gradient",

      gradient: {
        shade: "dark",
        type: "horizontal",
        shadeIntensity: 0.5,
        // gradientToColors: [ "#ABE5A1", "#5A0"],
        gradientToColors: cores,

        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 1,
        stops: paradas,
        colorStops: coresStop,
      },
    },
    stroke: {
      lineCap: "butt",
    },
    labels: labels,
    xaxis: {
      miin: 0,
      // max: Math.max(data) + 1,

      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: true,
      },
    },
  };

  return (
    <React.Fragment>
      <div style={style}>
        <Chart
          options={props.options}
          series={[
            {
              name: "Notificações",
              data,
            },
          ]}
          type={props.options.chart.type}
          height={350}
          width="100%"

          //  height="100%"
        />
      </div>
    </React.Fragment>
  );
};

export default Grafico;
