import React, {} from 'react';

const Panel = ({titulo,key, children}) => {
    
  
return (
    <div/>

)
}

export default Panel;