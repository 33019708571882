import React from 'react';


import { Container } from './style';

const ColunaDireita = ({ isLoading }) => {
  return (
    <Container className="colum-d">
     
    </Container>
  );
};

export default ColunaDireita;