import React from "react";
import * as Tema from "../../tela/style";

const Dado = ({
  titulo,
  valor,
  icone,
  colunaUnica,
  colorIcon,
  colorTitulo,
  onClick,
  minWidth,
  maxWidth,
  fontSizeValor,
  height,
  style, // Adicionando a propriedade de estilo
}) => {
  const min = minWidth ?? "0";
  const max = maxWidth ?? "0";

  if (colunaUnica) {
    return (
      <Tema.CardDados
        height={height}
        style={{
          width: "100%",
          alignItems: "unset",
          justifyContent: "unset",
          padding: 10,
          maxWidth: maxWidth,
          ...style, // Aplicando os estilos passados como propriedade
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Tema.Icone
            className={icone}
            color={colorIcon}
            style={{
              fontSize: "1.3em",
              marginLeft: 0,
              marginTop: 0,
              marginBottom: 0,
              marginRight: 5,
            }}
          />
          <Tema.CardCabecalhoTitulo
            color={colorTitulo}
            style={{ fontSize: "0.8em", margin: 0 }}
          >
            {titulo}
          </Tema.CardCabecalhoTitulo>
        </div>
        <Tema.CardCabecalhoSubTitulo fontSize={fontSizeValor} onClick={onClick}>
          {valor}
        </Tema.CardCabecalhoSubTitulo>
      </Tema.CardDados>
    );
  }
  return (
    <Tema.CardDados
      style={{
        maxWidth: maxWidth ?? "100%",
        minWidth: min,
        ...style, // Aplicando os estilos passados como propriedade
      }}
      height={height}
    >
      <Tema.Icone
        color={colorIcon}
        className={icone}
        style={{ fontSize: "2.3em", margin: 0 }}
      />
      <Tema.CardCabecalhoTitulo
        color={colorTitulo}
        style={{ fontSize: "0.8em", margin: 0 }}
      >
        {titulo}
      </Tema.CardCabecalhoTitulo>
      <Tema.CardCabecalhoSubTitulo fontSize={fontSizeValor} onClick={onClick}>
        {valor}
      </Tema.CardCabecalhoSubTitulo>
    </Tema.CardDados>
  );
};

export default Dado;
