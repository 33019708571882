import React, { useRef, useLayoutEffect } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/maps";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

// import JSONMapa from './sourceMapa.json'

am4core.useTheme(am4themes_animated);

const Grafico =({onClick, style, titulo,dados})=> { 
    
    const data = dados?.map((x,i)=>  { return  {id: 'BR-' + x[0], value: x[1]}})
    // const color = am4core.color("#ffb732");
    const color = am4core.color("#ff0000");

 
    const minValue = 0
    const maxValue = data?.length > 0 ?  Math.max.apply(Math, data.map((x,i) =>  x.value)) :0 

    const chart = useRef(null);
    const source = {
        BR:{country:"Brazil",continent_code:"SA",continent:"South America",maps:["brazilLow","brazilHigh"]}
    }
    useLayoutEffect(() => {
      let x = am4core.create("chartdiv", am4charts.MapChart);
      var currentMap = source.BR.maps[0];
      
      x.geodataSource.url = "https://www.amcharts.com/lib/4/geodata/json/" + currentMap + ".json";
        // x.geodataSource.url = "../source/sourceMapa.json";
        // x.geodataSource.data = JSONMapa;
        
    //   x.geodataSource.events.on("parseended", function(ev) {
    //     var data = [];
    //     for(var i = 0; i < ev.target.data.features.length; i++) {
    //       data.push({
    //         id: ev.target.data.features[i].id,
    //         value: Math.round( Math.random() * 10000 )
    //       })
    //     }
    //   })
  // Set projection
  x.projection = new am4charts.projections.Mercator();

  
  // Create map polygon series
  var polygonSeries = x.series.push(new am4charts.MapPolygonSeries());

  polygonSeries.data = data;



  //Set min/max fill color for each area
  polygonSeries.heatRules.push({
    property: "fill",
    target: polygonSeries.mapPolygons.template,
    min: color.saturate(0),
    max: color.saturate(1)
  });


  // Make map load polygon data (state shapes and names) from GeoJSON
  polygonSeries.useGeodata = true;

  // Set up heat legend
  let heatLegend = x.createChild(am4charts.HeatLegend);
  heatLegend.series = polygonSeries;
  heatLegend.align = "right";
  heatLegend.width = am4core.percent(25);
  heatLegend.marginRight = am4core.percent(4);
  heatLegend.minValue = minValue;
  heatLegend.maxValue = maxValue;
  heatLegend.valign = "bottom";

  // Set up custom heat map legend labels using axis ranges
  var minRange = heatLegend.valueAxis.axisRanges.create();
  minRange.value = heatLegend.minValue;
  minRange.label.text =  heatLegend.minValue;
  var maxRange = heatLegend.valueAxis.axisRanges.create();
  maxRange.value = heatLegend.maxValue;
  maxRange.label.text = heatLegend.maxValue;

  // Blank out internal heat legend value axis labels
  heatLegend.valueAxis.renderer.labels.template.adapter.add("text", function(labelText) {
    return "";
  });

  // Configure series tooltip
  var polygonTemplate = polygonSeries.mapPolygons.template;
  polygonTemplate.tooltipText = "{name}: {value}";
  polygonTemplate.nonScalingStroke = true;
  polygonTemplate.strokeWidth = 0.5;

  //OnCLICK
  polygonTemplate.events.on("hit", function(ev) {
    if (onClick){

    var data = ev.target.dataItem.dataContext;
    const ret = {uf: data.id.replace('BR-', ''), nome: data.name, valor: data.value}
    onClick(ret)
    }
  });

  // var circle = polygonTemplate.createChild(am4core.Circle);
  // circle.radius = 10;
  // circle.fillOpacity = 0.7;
  // circle.verticalCenter = "middle";
  // circle.horizontalCenter = "middle";
  // circle.nonScaling = true;

  // var label = polygonTemplate.createChild(am4core.Label);
  // label.text = "{stores}";
  // label.fill = am4core.color("#fff");
  // label.verticalCenter = "middle";
  // label.horizontalCenter = "middle";
  // label.nonScaling = true;

  var button = x.createChild(am4core.SwitchButton);
  button.align = "right";
  button.marginTop = 5;
  button.marginRight = 5;
  button.valign = "top";
  button.leftLabel.text = "Mapa";
  button.rightLabel.text = "Globo";
  
  button.events.on("toggled", function() {

  x.deltaLatitude = 0;
  x.deltaLongitude = 0;
  x.deltaGamma = 0;

  if (button.isActive) {
    x.projection = new am4charts.projections.Orthographic;
    x.panBehavior = "rotateLongLat";
  }
  else {
    x.projection = new am4charts.projections.Miller;
    x.panBehavior = "move";
  }
})
x.seriesContainer.draggable = false;
x.seriesContainer.resizable = false;
      x.chartContainer.wheelable =false;
      x.maxZoomLevel = 1;
      x.align = 'center';
      chart.current = x;
  
      return () => {
        x.dispose();

      };
    }, []);
  
    return (
      <div id="chartdiv" style={style}></div>
    );

}

export default Grafico;

