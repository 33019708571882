import React from "react";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import Icons from "@material-ui/icons/Check";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import Typography from "@mui/material/Typography";

import * as Padrao from "../../../style/config";
import moment from "moment";

// const tes = [
//   {
//     CodFlowExecuteTask: 5,
//     tipo: "Indicação",
//     tarefa: "Indicação Realizada",
//     dataFinalizacao: "29/01/2022 15:48"
//   },
//   {
//     CodFlowExecuteTask: 4,
//     tipo: "Indicação",
//     tarefa: "Indicação em análise operacional",
//     dataFinalizacao: "22/01/2022 15:48"
//   },
//   {
//     CodFlowExecuteTask: 3,
//     tipo: "Indicação",
//     tarefa: "Indicação recepcionada",
//     dataFinalizacao: "22/01/2022 15:47"
//   },
//   {
//     CodFlowExecuteTask: 2,
//     tipo: "Notificação",
//     tarefa: "E-mail com a notificação enviado",
//     dataFinalizacao: "12/01/2022 15:48"
//   },
//   {
//     CodFlowExecuteTask: 1,
//     tipo: "Notificação",
//     tarefa: "Notificação recepcionada e validada",
//     dataFinalizacao: "10/01/2022 15:48"
//   }
// ];

export default function AlternateTimeline(res) {
  const getModules = () => {
    return JSON.parse(res.res).map((r, i) => {
      const className = i % 2 === 0 ? "custom-even-class" : "custom-odd-class";
      const txtAlign =
        className === "custom-even-class"
          ? "end !important"
          : "left !important";
      return (
        <TimelineItem
          classes={{ alignAlternate: className }}
          key={r.CodFlowExecuteTask}
        >
          <TimelineOppositeContent
            sx={{ m: "auto 0" }}
            variant="body2"
            textAlign={txtAlign}
            color="var(--color-ad-text)"
          >
            {r.dataFinalizacao}
          </TimelineOppositeContent>
          <TimelineSeparator>
            <Padrao.BotaoIcone>
              <Icons />
            </Padrao.BotaoIcone>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Typography
              variant="h6"
              component="span"
              color="var(--color-cliente)"
            >
              {r.tipo}
            </Typography>
            <Typography varinat="body2" color="var(--color-ad-text)">
              {r.tarefa}
            </Typography>
          </TimelineContent>
        </TimelineItem>
      );
    });
  };

  return <Timeline align="alternate">{getModules()}</Timeline>;
}
