import React,{useState} from 'react';
import {useDadosGlobalDispatch, useDadosGlobal } from "../../context";

import { Formik } from "formik";
import Modal from '../../layout/modal/modal'
import Input from '../../layout/tela/campos/inputUI'
import HubTooltip from '../../layout/tela/campos/tooltip'
import {Carregando} from '../../loading';

import  * as Padrao from '../../../style/config'
import * as Tema from './style'

import  schema from "../../../schemas/atualizarSenha";
import * as utils from "../../../utils";
import * as api from "../../../API/consulta";


function Senha({abrir, onAbrir}) {
    const {usuario} = useDadosGlobal();
    const {atualizarUsuario} = useDadosGlobalDispatch();
    const [propsAlerta, setPropsAlerta]=useState(utils.propsAlerta);

    const finalizar = async() =>{
        const tAlerta = propsAlerta.tipoAnimacao
        setPropsAlerta(utils.semAlerta)
        if (tAlerta == utils.animacoes.sucesso){
            usuario.acesso=2;
            await atualizarUsuario({usuario});
            console.log('chegou')
            onAbrir();
        }
    }

    const alterarSenha = async({tipo, values, errors})=>{
        try {

            if (tipo==2 && Object.entries(errors).length >0 ) return;
            console.log('chegou')
            setPropsAlerta(utils.alertaCarregando({titulo: 'Enviando'}));
            const msg = tipo==1 ? 'Senha Padrão mantida' : 'Senha Alterada com Sucesso';

            if(tipo==1)
                await api.cancelarSenhaPrimeiroAcesso({tipo: usuario.tipo, idUsuario:usuario.id})
            else if (tipo==2)
                await api.alterarSenhaPrimeiroAcesso({tipo: usuario.tipo, idUsuario:usuario.id, senha: values.senha})

            setPropsAlerta(utils.alertaSucesso({titulo: msg}));
            
        } catch (e) {
            setPropsAlerta(utils.montaAlerta({titulo: e.message, origem: e.origem, tAnimacao: e.tipoAlerta}));

        }
        
    }

    return(
    <Modal abrir={abrir} onFechar={() => onAbrir(false)} fecharClicarFora={false}>
        <Tema.SenhaModal>
        <Formik validationSchema={schema} onSubmit={() => { }} validateOnMount initialValues={{ }}>
        {({ values, validateForm, errors, handleChange, setFieldValue }) => (
            <React.Fragment>

            <Tema.SenhaCabecalho>
            <Tema.SenhaTitulo>Primeiro acesso? Seja bem-vindo!</Tema.SenhaTitulo>

        </Tema.SenhaCabecalho>
            <Tema.SenhaConteudo>
                <div style={{display:'flex', flexDirection:'column', gap:15}}>
                    
                    <span style={{margin:10}}>Por motivos de segurança troque sua senha padrão.</span>
                    <Tema.SenhaDivInput>

                        <Input labelAtivo={true} onChange={handleChange} label ={'Nova Senha'} 
                        name={'senha'} maxLength ={usuario.tipo == 1 ? 6 : 10}
                        type={'password'} placeHolder={`Digite sua nova senha. Máximo de ${usuario.tipo == 1 ? '6' : '10'} caracteres.`}
                        />
                        <Input labelAtivo={true} onChange={handleChange} label ={'Confirmar Senha'} 
                        name={'confirmarSenha'} maxLength ={usuario.tipo == 1 ? 6 : 10}
                        type={'password'} placeHolder={'Confirme sua nova senha'} />
                    </Tema.SenhaDivInput>
                </div>
            </Tema.SenhaConteudo>
            <Tema.SenhaRodape>
    
                <HubTooltip texto={<span>Ao escolher manter sua senha padrão, só será possível alterá-la posteriormente em <b>Esqueci minha Senha.</b></span>}>
                    <Padrao.Icone className={'pe-7s-info'} style={{fontSize:25, fontWeight:'bold',cursor:'pointer', color:'var(--color-primary)'}} />
                </HubTooltip>
                
                <Padrao.Botao size={'small'} onClick={async()=> { await alterarSenha({tipo:1,values,errors})}} >Manter senha padrão</Padrao.Botao>
                <Padrao.Botao size={'small'} onClick={()=> {alterarSenha({tipo:2,values,errors})}} principal={true}>Salvar mudanças</Padrao.Botao>
            </Tema.SenhaRodape>
            </React.Fragment>
        )}
        </Formik>
        <Carregando tipo={propsAlerta.tipoAnimacao} exibir={propsAlerta.exibir}  
            userClose={propsAlerta.usuarioFechar} loop={propsAlerta.loop}
            titulo={propsAlerta.titulo}
            origem={propsAlerta.origem}
            setExibir={()=>{setPropsAlerta(utils.semAlerta)}}
            onFinish={async() => {await finalizar()}}
            // styleFundo={{width:'90%', height:'90%'}}
            // styleAnimacao={{width:'70%'}}
        />
        </Tema.SenhaModal>
        
        
  </Modal>
)
}

export default Senha;
