import Lottie from "react-lottie";

import * as utils from "../../utils";


import erro from "../../assets/animated/error.json";
import aviso from "../../assets/animated/aviso.json";
import sucesso from "../../assets/animated/sucesso.json";
import carregando from "../../assets/animated/enviando.json";
import dashboard from "../../assets/animated/dashboard.json";
import assinaturaTermo from "../../assets/animated/assinaturaTermo.json";
import uploadCNH from "../../assets/animated/uploadcnh.json";
import uploadTermo from "../../assets/animated/uploadTermo.json";
import indicacaoExpirada from "../../assets/animated/IndicacaoExpirada.json";


const retornaSource = (tipo) => {
    const obj = {
      source: null,
    };
    if (tipo == utils.animacoes.sucesso) {
      obj.source = sucesso;
    } else if (tipo == utils.animacoes.aviso) {
      obj.source = aviso;
    } else if (tipo == utils.animacoes.erro) {
      obj.source = erro;
    } else if (tipo == utils.animacoes.carregando) {
      obj.source = carregando;
    } else if (tipo == utils.animacoes.dashboard) {
      obj.source = dashboard;
    } else if (tipo == utils.animacoes.assinaturaTermo) {
      obj.source = assinaturaTermo;
    }else if (tipo == utils.animacoes.uploadTermo) {
      obj.source = uploadTermo;
    }else if (tipo == utils.animacoes.uploadCNH) {
      obj.source = uploadCNH;
    }else if (tipo == utils.animacoes.indicacaoExpirada) {
      obj.source = indicacaoExpirada;
    }
    return obj;
  };

export const AnimacaoLottie = ({tipo,loop, finalizar}) => {

  const dadosSource = retornaSource(tipo);

    const defaultOptions = {
        loop: loop,
        autoplay: true,
        animationData: dadosSource.source,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice",
          hideOnTransparent: true,
        },
      };

    return (<Lottie
              options={defaultOptions}
              
              height={"100%"}
              width={"100%"}
              eventListeners={[
                {
                  eventName: "complete",
                  callback: () => finalizar(),
                },
                //   {
                //     eventName: 'loopComplete',
                //     callback: () => alert('the animation loopComplete:')
                //   }
              ]}
            />)
};