import React, { useLayoutEffect, useCallback, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { Formik } from "formik";
import Tela from "../../layout/tela";
import * as Padrao from "../../../style/config";
import DadosCondutor from "./DadosCondutor";
import DownloadTermo from "./DownloadTermo";
import UploadTermo from "./UploadTermo";
import Finalizacao from "./Finalizacao";

import schema from "../../../schemas/indicacao";
import * as utils from "../../../utils";
import * as api from "../../../API/consulta";
import { Carregando } from "../../loading";
import * as Tema from "../../layout/tela/style";

import doneSVG from "../../../image/done.svg";

export default function MinhaIndicacao() {
  const [propsAlerta, setPropsAlerta] = useState(utils.propsAlerta);
  const history = useHistory();

  const { ait } = useParams();
  const [data, setData] = useState(null);
  const [dataFim, setDataFim] = useState(null);

  const [etapas, setEtapas] = useState(1);
  const nomeEtapas = [
    "DadosCondutor",
    "DadosDownload",
    "DadosUpload",
    "DadosFinalizado",
  ];
  const SubTitulo = [
    "Dados do Condutor",
    "Download do Termo",
    "Upload dos Arquivos",
    "Finalização",
  ];

  const voltar = () => {
    history.push("/login", {});
  };

  const Indicar = async () => {
    try {
      if (ait) {
        const obj = utils.montaAlerta({
          // titulo: "Montando seu Dashboard",
          tAnimacao: utils.animacoes.carregando,
        });
        setPropsAlerta(obj);

        const data = await api.filtraNotificacaoAit({ ait });
        const dataLimite = new Date(
          new Date(data?.dataLimiteIndicacao).setDate(
            new Date(data?.dataLimiteIndicacao).getDate() + 1
          )
        );

        if (data.Id == 0) {
          const obj = utils.montaAlerta({
            titulo: "Não existe multa com o AIT informado",
            tAnimacao: utils.animacoes.aviso,
          });
          setPropsAlerta(obj);
          return;
        }
        if (data.FezIndicacao == 1) {
          const obj = utils.montaAlerta({
            titulo:
              "Já foi realizado a indicação dessa notificação. Estamos analisando.",
            tAnimacao: utils.animacoes.aviso,
          });
          setPropsAlerta(obj);
          return;
        }
        if (data.IndicacaoCancelada == 1) {
          const obj = utils.montaAlerta({
            titulo:
              "Não será possível realizar a indicação pelo site, por favor entre em contato com o seu gestor de frotas.",
            tAnimacao: utils.animacoes.aviso,
          });
          setPropsAlerta(obj);
          return;
        }
        if (data.OptouNaoIndicar == 1) {
          const obj = utils.montaAlerta({
            titulo:
              "Você optou por não indicar, já registramos isso em sistema.",
            tAnimacao: utils.animacoes.aviso,
          });
          setPropsAlerta(obj);
          return;
        }
        if (dataLimite < new Date()) {
          const obj = utils.montaAlerta({
            titulo:
              "A data limite de indicação online expirou, faça a indicação pelo Orgão.",
            tAnimacao: utils.animacoes.erro,
          });
          setPropsAlerta(obj);
          return;
        }
        if (parseInt(data.idTipoIndicacao) != 5) {
          const obj = utils.montaAlerta({
            titulo:
              "Algo impede que você execute a indicação no portal neste momento. Caso haja dúvidas entre em contato com nossa central.",
            tAnimacao: utils.animacoes.aviso,
          });
          setPropsAlerta(obj);
          return;
        }

        // const ret = await api.validarIndicacao({idNotificacao: data?.Id});

        setPropsAlerta(utils.semAlerta);

        setData(data);
      }
    } catch (e) {
      setPropsAlerta(
        utils.montaAlerta({
          titulo: e.message,
          origem: e.origem,
          tAnimacao: e.tipoAlerta,
        })
      );
    }
  };

  useLayoutEffect(() => {
    if (utils.toTexto(ait) == "") {
      voltar();
      return;
    }

    Indicar();
  }, []);

  const validarProximo = ({ erros }) => {
    if (erros?.[nomeEtapas[etapas - 1]]) return false;

    return true;
  };

  const navegacao = async ({ proximo, values, erros }) => {
    try {
      if (proximo) {
        if (!validarProximo({ erros })) return false;
        if (etapas == 3) {
          const obj = utils.montaAlerta({
            titulo: "Processando a sua indicação",
            tAnimacao: utils.animacoes.carregando,
          });
          setPropsAlerta(obj);

          const ret = await api.uploadsIndicacao({
            dados: values?.DadosCondutor,
            dadosNotificacao: data,
            uploads: values?.Uploads,
          });

          setPropsAlerta(utils.semAlerta);
          setDataFim(ret);
          setEtapas(etapas + 1);
        } else {
          setEtapas(etapas + 1);
        }
      } else {
        setEtapas(etapas - 1);
      }
    } catch (e) {
      setPropsAlerta(
        utils.montaAlerta({
          titulo: e.message,
          origem: e.origem,
          tAnimacao: e.tipoAlerta,
        })
      );
    }
  };

  return (
    <Tela
      titulo={"Indicação"}
      icone={"pe-7s-car"}
      subTitulo={`Etapa - ${SubTitulo[etapas - 1]}`}
      menu={true}
    >
      <Formik
        validationSchema={schema}
        onSubmit={() => {}}
        validateOnMount
        initialValues={{}}
      >
        {({ values, validateForm, errors, handleChange, setFieldValue }) => (
          <React.Fragment>
            {etapas == 1 ? (
              <DadosCondutor
                onChange={handleChange}
                data={values}
                dadosNot={data}
                erros={errors}
              />
            ) : etapas == 2 ? (
              <DownloadTermo
                onChange={handleChange}
                data={values}
                dadosNot={data}
                erros={errors}
              />
            ) : etapas == 3 ? (
              <UploadTermo
                onChange={handleChange}
                data={values}
                dadosNot={data}
                erros={errors}
                setField={setFieldValue}
              />
            ) : etapas == 4 ? (
              <div style={{ display: "flex", padding: 10, color: "#70899b" }}>
                <div style={{ width: "60%" }}>
                  <img
                    src={doneSVG}
                    alt="Obrigado por realizar a Indicação"
                    style={{ padding: "10px", width: "100%" }}
                  />
                </div>
                <div>
                  <Tema.CardCabecalhoTitulo
                    style={{ textTransform: "uppercase" }}
                  >
                    Obrigado
                  </Tema.CardCabecalhoTitulo>
                  <Tema.CardCabecalhoSubTitulo>
                    Sua indicação foi realizada com sucesso, neste momento será
                    enviada para um de nossos analistas para realizarem a
                    validação.
                  </Tema.CardCabecalhoSubTitulo>
                  <div style={{ display: "flex", gap: 10, padding: 10 }}>
                    <span
                      style={{ textTransform: "uppercase", fontWeight: "bold" }}
                    >
                      Seu número de protocolo:
                    </span>
                    <span>{dataFim?.protocolo}</span>
                  </div>
                </div>
              </div>
            ) : null}

            {etapas < 4 ? (
              <Padrao.Navegacao>
                <Padrao.Botao
                  size="small"
                  principal={false}
                  style={{
                    visibility: etapas == 1 ? "hidden" : "visible",
                    background: "white",
                    color: "red",
                  }}
                  onClick={async () => {
                    await navegacao({ proximo: false, values, erros: errors });
                  }}
                >
                  Voltar
                </Padrao.Botao>

                <Padrao.Botao
                  size="small"
                  principal={true}
                  onClick={async () => {
                    // const erros = Object.entries(errors).length === 0  ? await validateForm() : errors;
                    await navegacao({ proximo: true, values, erros: errors });
                  }}
                >
                  Próximo
                </Padrao.Botao>
              </Padrao.Navegacao>
            ) : null}
          </React.Fragment>
        )}
      </Formik>

      <Carregando
        tipo={propsAlerta.tipoAnimacao}
        exibir={propsAlerta.exibir}
        userClose={propsAlerta.usuarioFechar}
        loop={propsAlerta.loop}
        titulo={propsAlerta.titulo}
        origem={propsAlerta.origem}
        setExibir={() => {
          setPropsAlerta(utils.semAlerta);
        }}
        onFinish={() => {
          if (
            [utils.animacoes.aviso, utils.animacoes.erro].includes(
              propsAlerta.tipoAnimacao
            ) &&
            etapas == 1
          ) {
            voltar();
          }
        }}
        // styleFundo={{width:'90%', height:'90%'}}
        // styleAnimacao={{width:'70%'}}
      />
    </Tela>
  );
}
