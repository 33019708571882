import styled from "styled-components";
const media = {
  mobile: "@media(max-width: 900px)",
  desktop: "@media (min-width: 1180px)",
};

export const Container = styled.div`
  background: #f1f4f6;
  height: 100%;
  width: 100%;
  /* width: calc(100% - ${(props) => (props.isMenuVisible ? `70px` : `275px`)}); */
  ${media.mobile} {
    width: 100%;
    height: 100%;
    overflow: auto;
    padding: 5px;
  }
`;
