import React, { useEffect, useCallback, useState } from "react";
import Helmet from "react-helmet";
import { useHistory } from "react-router-dom";

import Tela from "../../layout/tela";
import Tabela from "../../layout/tabela/dataGrid";
import * as Tema from "../../layout/tela/style";
import * as Padrao from "../../../style/config";
import Input from "../../layout/tela/campos/inputUI";
import ButtonMaterial from "@material-ui/core/Button";
import Select from "../../layout/tela/campos/selectUI";
import { Formik } from "formik";
import schema from "../../../schemas/dashboard";
import Grafico from "../../layout/grafico/graficoDash";

import { Carregando } from "../../loading";

import * as api from "../../../API/consulta";
import * as utils from "../../../utils";
import { useDadosGlobal } from "../../context";

const dataAtual = new Date();
const getMonth = dataAtual.getMonth() + 1;
const mesAtual =
  getMonth < 10 ? "0" + getMonth.toString() : getMonth.toString();
const dtIni = dataAtual.getFullYear().toString() + "-" + mesAtual + "-01";
const dtFim = utils.formatDataHora(dataAtual, "yyyy-MM-dd");
const tipoDataFiltro = api.tipoDataFiltro;

const dadosIni = { Dados: [], Grafico: null };

// const base = `${window.location.origin}/`;
// const urlProducao = process.env.REACT_APP_URL_PRODUCAO;
// const urlHomolog = process.env.REACT_APP_URL_HOMOLOG;
// const urlDesenv = process.env.REACT_APP_URL_DESENV;

// let url = "";
// if (base.includes("localhost")) {
//   urlAPI = urlDesenv;
// } else if (base.includes("homolog")) {
//   urlAPI = urlHomolog;
// } else {
//   urlAPI = urlProducao;
// }

const Listagem = ({}) => {
  const history = useHistory();
  const { usuario } = useDadosGlobal();
  const [data, setData] = useState(dadosIni);
  const [carregando, setCarregando] = useState(true);
  const [propsAlerta, setPropsAlerta] = useState(utils.propsAlerta);
  const [filterText, setFilterText] = React.useState("");
  const [filtros, setFiltros] = useState({
    codGestor: usuario.codGestor,
    placa: usuario.placa,
    dataIni: dtIni,
    dataFim: dtFim,
    gestorMaster: usuario.gestorMaster,
    tipoDataFiltro: tipoDataFiltro,
    ait: "",
  });

  const columnsMaterialUI = [
    {
      field: "Placa",
      headerName: "Placa",
      type: "string",
      headerClassName: "super-app-theme--header",
      width: 175,
      hide: !usuario.codGestor,
    },
    {
      field: "Ait",
      headerName: "AIT",
      type: "string",
      headerClassName: "super-app-theme--header",
      flex: 1,
    },
    {
      field: "DataInfracao",
      headerName: "Data Infração",
      type: "string",
      headerAlign: "center",
      align: "center",
      headerClassName: "super-app-theme--header",
      width: 175,
      // format:'dd/MM/yyyy', 2019-03-27T00:00:00
      // valueGetter: (params) => utils.formatDataHora(params.value, "dd/MM/yyyy"),
      sortComparator: (v1, v2, param1, param2) =>
        utils.formatData(param1.row.DataInfracao, "dd/MM/yyyy").getTime() -
        utils.formatData(param2.row.DataInfracao, "dd/MM/yyyy").getTime(),
    },
    {
      field: "HoraInfracao",
      headerName: "Hora infração",
      type: "string",
      headerAlign: "center",
      align: "center",
      headerClassName: "super-app-theme--header",
      width: 175,
    },
    {
      field: "Valor",
      headerName: "Valor da Multa",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: 175,
      valueFormatter: (row) => utils.formataValor(row.value),
    },
    {
      field: "Pontos",
      headerName: "Pontos",
      headerAlign: "center",
      align: "center",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: 175,
    },
  ];

  const columnsMaterialUIGestorMaster = [
    {
      field: "IdManager",
      headerName: "Código Gestor",
      type: "string",
      headerClassName: "super-app-theme--header",
      width: 175,
      hide: !usuario.codGestor,
    },
    {
      field: "Placa",
      headerName: "Placa",
      type: "string",
      headerClassName: "super-app-theme--header",
      width: 175,
      hide: !usuario.codGestor,
    },
    {
      field: "Ait",
      headerName: "AIT",
      type: "string",
      headerClassName: "super-app-theme--header",
      flex: 1,
    },
    {
      field: "DataInfracao",
      headerName: "Data Infração",
      type: "string",
      headerAlign: "center",
      align: "center",
      headerClassName: "super-app-theme--header",
      width: 175,
      // format:'dd/MM/yyyy', 2019-03-27T00:00:00
      // valueGetter: (params) => utils.formatDataHora(params.value, "dd/MM/yyyy"),
      sortComparator: (v1, v2, param1, param2) =>
        utils.formatData(param1.row.DataInfracao, "dd/MM/yyyy").getTime() -
        utils.formatData(param2.row.DataInfracao, "dd/MM/yyyy").getTime(),
    },
    {
      field: "HoraInfracao",
      headerName: "Hora infração",
      type: "string",
      headerAlign: "center",
      align: "center",
      headerClassName: "super-app-theme--header",
      width: 175,
    },
    {
      field: "Valor",
      headerName: "Valor da Multa",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: 175,
      valueFormatter: (row) => utils.formataValor(row.value),
    },
    {
      field: "Pontos",
      headerName: "Pontos",
      headerAlign: "center",
      align: "center",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: 175,
    },
  ];

  const ConfigExport = {
    //  Fellipe - 02/04/2024 => Conforme solicitado na especificação "Hub – Remover dados de pagamento",
    //  vamos remover as colunas "Optou Não Indicar", "Pago" e "Valor pago", porém vou manter comentado, caso seja necessário voltar.
    CSV: {
      nomeArquivo: `notificacoes${usuario?.placa ?? usuario?.codGestor}`,
      exibir: true,
      colunas: [
        {
          titulo: "Placa",
          name: "Placa",
          format: (row, i) => (row.Placa ? row.Placa.toUpperCase() : ""),
        },
        {
          titulo: "Fez Indicação",
          name: "FezIndicacao",
          format: (row, i) => (row.FezIndicacao == "1" ? "Sim" : "Não"),
        },
        { titulo: "Data da Infracao", name: "DataInfracao" },
        { titulo: "Hora da Infracao", name: "HoraInfracao" },
        {
          titulo: "Data limite indicação HUB",
          name: "dataLimiteIndicacao",
          format: (row, i) => utils.formatDataBrazil(row.dataLimiteIndicacao),
        },
        {
          titulo: "Data limite indicação Órgão",
          name: "dataLimiteIndicacaoOrgao",
          format: (row, i) =>
            utils.formatDataBrazil(row.dataLimiteIndicacaoOrgao),
        },
        {
          titulo: "Data envio de email",
          name: "DataEnvioEmail",
          format: (row, i) => utils.formatDataBrazil(row.DataEnvioEmail),
        },
        {
          titulo: "Auto de Infração de Trânsito (AIT)",
          name: "Ait",
          format: (row, i) => (row.Ait ? row.Ait.toUpperCase() : ""),
        },
        {
          titulo: "Infracao",
          name: "Infracao",
          format: (row, i) => (row.Infracao ? row.Infracao.toUpperCase() : ""),
        },
        {
          titulo: "UF",
          name: "UF",
          format: (row, i) => (row.UF ? row.UF.toUpperCase() : ""),
        },
        {
          titulo: "Municipio",
          name: "municipio",
          format: (row, i) =>
            row.municipio ? row.municipio.toUpperCase() : "",
        },
        {
          titulo: "Código enquadramento",
          name: "CodInfracao",
        },
        {
          titulo: "Sigla órgão",
          name: "SiglaOrgao",
          format: (row, i) =>
            row.SiglaOrgao ? row.SiglaOrgao.toUpperCase() : "",
        },
        {
          titulo: "Órgão autuador",
          name: "nomeOrgaoEmissor",
          format: (row, i) =>
            row.nomeOrgaoEmissor ? row.nomeOrgaoEmissor.toUpperCase() : "",
        },

        { titulo: "Pontuacao", name: "Pontos" },
        {
          titulo: "Gravidade da multa",
          name: "Pontos",
          format: (row, i) =>
            row.Pontos == 3
              ? "LEVE"
              : row.Pontos == 4
              ? "MÉDIA"
              : row.Pontos == 5
              ? "GRAVE"
              : "GRAVÍSSIMA",
        },
        {
          titulo: "Local da infracao",
          name: "LocalInfracao",
          format: (row, i) =>
            row.LocalInfracao ? row.LocalInfracao.toUpperCase() : "",
        },
        { titulo: "Valor da Multa", name: "Valor" },
        { titulo: "Pra Quem Enviou", name: "EmailResponsavel" },
        { titulo: "Condutor Indicado", name: "CondutorIndicado" },
        {
          titulo: "Link indicação online",
          name: "CondutorIndicado",
          format: (row, i) =>
            row.idTipoIndicacao == 5
              ? `${
                  window.location.origin
                }/minhaIndicacao/${row.Ait.toUpperCase()}`
              : "",
        },
      ],
    },
  };

  useEffect(() => {
    async function carregar() {
      try {
        await consultar({ user: false, values: filtros, errors: {} });
      } catch (e) {
        setPropsAlerta(
          utils.montaAlerta({
            titulo: e.message,
            origem: e.origem,
            tAnimacao: e.tipoAlerta,
          })
        );
      }
    }
    carregar();
  }, []);

  // Fellipe - 07/08/2024 => Conforme a solicitaçã da Viviane na especificação "".
  //                      Vamos abrir em uma nova aba o detalhamento, não vamos manter na mesma aba para não perder os filtros
  //                      E não ter que refazer as consultas.
  const onLinhaClique = useCallback((data, event) => {
    const url = "/notificacaofiltrar/" + data?.Id?.toString();
    abrirNovaAba({ url, state: { data } });
  }, []);

  const abrirNovaAba = ({ url, state }) => {
    const newWindow = window.open(url, "_blank");
    if (newWindow) {
      newWindow.onload = () => {
        if (state) {
          newWindow.history.replaceState(state, "");
        }
      };
    }
  };

  // const onLinhaClique = useCallback(
  //   (data, event) => {
  //     window.scrollTo(0, 0);

  //     history.push("/notificacaodetalhe", {
  //       data,
  //     });
  //   },
  //   [history]
  // );

  const consultar = async ({ user, values, errors }) => {
    try {
      if (Object.entries(errors).length > 0) return;
      setCarregando(true);
      if (user) setFiltros(values);

      await setData(dadosIni);
      const ret = await api.retornaNotificacoes({
        placa: values?.placa,
        codGestor: values?.codGestor,
        dtInicio: values?.dataIni,
        dtFim: values?.dataFim,
        gestorMaster: usuario.gestorMaster,
        tipoDataFiltro: values?.tipoDataFiltro,
        ait: values?.ait,
      });

      console.log(ret);
      await setData(ret);

      setPropsAlerta(utils.semAlerta);
    } catch (e) {
      setData(dadosIni);
      setPropsAlerta(utils.semAlerta);

      if (user) {
        setPropsAlerta(
          utils.montaAlerta({
            titulo: e.message,
            origem: e.origem,
            tAnimacao: e.tipoAlerta,
          })
        );
      }
    }
    setCarregando(false);
  };

  //#region CONSULTA API DASHBOARD
  const filtrarNotificacaoRecebida = async () => {
    try {
      const data = await api.retornaDetNotificacaoRecebida({
        dataIni: filtros?.dataIni,
        dataFim: filtros?.dataFim,
        placa: filtros?.placa,
        codGestor: filtros?.codGestor,
        gestorMaster: usuario.gestorMaster,
        tipoDataFiltro: filtros?.tipoDataFiltro,
        ait: filtros?.ait,
      });
      const ret = {
        dados: data,
        titulo: "Notificações recebidas",
        subTitulo: "",
        opcoes: [],
      };
      return ret;
    } catch (e) {
      setPropsAlerta(
        utils.montaAlerta({
          titulo: e.message,
          origem: e.origem,
          tAnimacao: e.tipoAlerta,
        })
      );
    }
  };

  const filtrarIndicacao = async ({ tipo }) => {
    try {
      const fezIndicacao = tipo.toLowerCase() == "indicadas";
      const data = await api.retornaDetFezIndicacao({
        dataIni: filtros?.dataIni,
        dataFim: filtros?.dataFim,
        placa: filtros?.placa,
        codGestor: filtros?.codGestor,
        gestorMaster: usuario.gestorMaster,
        fezIndicacao,
        tipoDataFiltro: filtros?.tipoDataFiltro,
        ait: filtros?.ait,
      });
      const ret = {
        dados: data,
        titulo: "Indicações do Condutor",
        subTitulo: tipo,
        opcoes: ["indicadas", "não indicadas"],
      };
      return ret;
    } catch (e) {
      setPropsAlerta(
        utils.montaAlerta({
          titulo: e.message,
          origem: e.origem,
          tAnimacao: e.tipoAlerta,
        })
      );
    }
  };

  // const abrirNovaAba = ({ url }) => {
  //   const win = window.open(url, "_blank");
  //   win.focus();
  // };
  ////#endregion

  return (
    <Tela
      titulo={"Notificações"}
      subTitulo={"Veja abaixo todas as notificações"}
      icone={"pe-7s-car"}
      menu={true}
    >
      {/* ... */}
      {/* ... */}
      {/* Inserindo a data pendente a função consultar */}

      <Formik
        validationSchema={schema}
        onSubmit={() => {}}
        validateOnMount
        initialValues={{
          placa: usuario.placa,
          dataIni: dtIni,
          dataFim: dtFim,
          codGestor: usuario.gestorMaster ? "" : usuario.codGestor,
          tipoDataFiltro: "1",
          ait: "",
        }}
      >
        {({ values, validateForm, errors, handleChange, setFieldValue }) => (
          <Tema.CardForm
            style={{
              minHeight: "80px",
              width: "100%",
              alignItems: "center",
              rowGap: "10px",
            }}
          >
            <Tema.CardFormHome>
              <Select
                label="Período consulta"
                name={"tipoDataFiltro"}
                value={values?.tipoDataFiltro}
                onChange={handleChange}
                placeHolder={"Selecione o período"}
                itens={tipoDataFiltro}
              />
              <Input
                align={"center"}
                label="Data Início"
                name={"dataIni"}
                type={"date"}
                max={"2999-12-31"}
                labelAtivo={true}
                value={values?.dataIni}
                onChange={handleChange}
              />
              <Input
                align={"center"}
                label="Data Fim"
                name={"dataFim"}
                type={"date"}
                max={"2999-12-31"}
                labelAtivo={true}
                value={values?.dataFim}
                onChange={handleChange}
              />

              <Input
                labelAtivo={true}
                align={"center"}
                label="Placa"
                name={"placa"}
                value={values?.placa}
                onChange={handleChange}
                desabilitado={usuario.placa}
              />
              <Input
                labelAtivo={true}
                align={"center"}
                label="CódigoGestor"
                name={"codGestor"}
                value={values?.codGestor}
                onChange={handleChange}
                desabilitado={!usuario.gestorMaster}
              />

              <Input
                labelAtivo={true}
                align={"center"}
                label="AIT"
                name={"ait"}
                value={values?.ait}
                onChange={handleChange}
              />

              <ButtonMaterial
                style={Padrao.styleBotaoPequeno}
                size="small"
                onClick={async () => {
                  await consultar({ user: true, values, errors });
                }}
              >
                Pesquisar
              </ButtonMaterial>
            </Tema.CardFormHome>
          </Tema.CardForm>
        )}
      </Formik>
      <div
        style={{
          height: "100%",
          width: "100%",
          marginTop: -20,
          marginBottom: -20,
          display: data?.Dados?.length == 0 ? "none" : "flex",
          flexDirection: "column",
        }}
      >
        <Tema.DivLinhaTotais style={{}}>
          <Grafico
            idGrafico={utils.graficos.notificacaoRecebida}
            data={data?.Grafico}
            onClick={async (e) => {
              return await filtrarNotificacaoRecebida();
            }}
            onClickLinhaTabela={async (row, e) => {
              const url = "/notificacaofiltrar/" + row?.Id?.toString();
              await abrirNovaAba({ url });
            }}
          />

          <div
            style={{
              margin: "0px -59px",
              filter: "drop-shadow(0 20px 8px rgba(0, 0, 0, .3))",
            }}
          >
            <Grafico
              idGrafico={utils.graficos.indicacaoCondutor}
              data={data?.Grafico}
              onPesquisar={async (tipo) => {
                return await filtrarIndicacao({ tipo });
              }}
              onClick={async (e) => {
                const tipo = e?.w?.config?.labels[e?.seriesIndex];
                return await filtrarIndicacao({ tipo });
              }}
              onClickLinhaTabela={async (row, e) => {
                const url = "/notificacaofiltrar/" + row?.Id?.toString();
                await abrirNovaAba({ url });
              }}
            />
          </div>
          <Grafico
            idGrafico={utils.graficos.notificacaoExtra}
            data={data?.Grafico}
            onClick={async (e) => {
              return await filtrarNotificacaoRecebida();
            }}
            onClickLinhaTabela={async (row, e) => {
              const url = "/notificacaofiltrar/" + row?.Id?.toString();
              await abrirNovaAba({ url });
            }}
          />
        </Tema.DivLinhaTotais>
      </div>

      {/* ... */}
      {/* ... */}
      {/* Teste que estou fazendo com o DataGrid do MaterialUI */}

      <Tabela
        columns={
          usuario.gestorMaster
            ? columnsMaterialUIGestorMaster
            : columnsMaterialUI
        }
        carregando={carregando}
        data={data?.Dados}
        ConfigExport={ConfigExport}
        onLinhaClique={onLinhaClique}
      />

      {/* <Tabela
        // defaultFilterValue={defaultFilterValue}
        colunas={columns}
        data={data}
        onRowClick={onLinhaClique}
        carregando={carregando}
        filtrarSoVisivel={true}
        textoSemDados={"Sem notificações"}
        exportConfig={ConfigExport}
      /> */}

      {/* ... */}
      {/* ... */}

      <Carregando
        tipo={propsAlerta.tipoAnimacao}
        exibir={propsAlerta.exibir}
        userClose={propsAlerta.usuarioFechar}
        loop={true}
        titulo={propsAlerta.titulo}
        origem={propsAlerta.origem}
        setExibir={() => {
          setPropsAlerta(utils.semAlerta);
        }}
        // styleFundo={{width:'90%', height:'90%'}}
        // styleAnimacao={{width:'70%'}}
      />
    </Tela>
  );
};

export default Listagem;
