import React, { useEffect } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import * as api from "../API/consulta";

import Home from "../components/pages/home";
import Contato from "../components/pages/contato";
import Usuario from "../components/pages/usuario";
import Notificacao from "../components/pages/notificacao";
import Boleto from "../components/pages/boleto";
import Filtrar from "../components/pages/notificacao/filtrar";

import DetCrlv from "../components/pages/crlvDigital/condutor";
import CrlvGestor from "../components/pages/crlvDigital/gestor";

import DetNotificacao from "../components/pages/notificacao/detalhe";
import BoletoNotificacao from "../components/pages/boleto/detalhe";

import Licenciamento from "../components/pages/licenciamento";
import DetLicenciamento from "../components/pages/licenciamento/detalhe";

import Indicacao from "../components/pages/indicacao";
import IndicacaoFinalizacao from "../components/pages/indicacao/Finalizacao";
// import IndicacaoUpload from "../components/pages/indicacaoUpload";
// import IndicacaoTermo from "../components/pages/indicacaoTermo";

import primeiroEmplacamento from "../components/pages/primeiroEmplacamento";
import DetPrimeiroEmplacamento from "../components/pages/primeiroEmplacamento/detalhe";

const Abrir = ({ url, func, id }) => {
  const history = useHistory();

  useEffect(() => {
    async function carregar() {
      const data = await func({ id });
      history.push(url, { data });
    }
    carregar();
  }, []);

  return null;
};

const RoutePrivada = () => {
  return (
    <Switch>
      <Route path="/home" exact component={Home} />
      <Route path="/contato" exact component={Contato} />
      <Route path="/usuario" exact component={Usuario} />

      {/* Notificação */}
      <Route path="/notificacao" component={Notificacao} />
      <Route path="/notificacaodetalhe" component={DetNotificacao} />
      <Route
        path="/notificacaofiltrar/:id"
        component={(props) => {
          return (
            <Filtrar
              url={"/notificacaodetalhe"}
              func={api.filtraNotificacao}
              id={props.match.params.id}
            />
          );
        }}
      />
      <Route path="/notificacaoFinalizacao" component={IndicacaoFinalizacao} />

      {/* Boletos */}
      <Route path="/boleto" component={Boleto} />
      <Route path="/boletodetalhe" component={BoletoNotificacao} />
      <Route
        path="/boletofiltrar/:id"
        component={(props) => {
          return (
            <Filtrar
              url={"/boletodetalhe"}
              func={api.filtraBoleto}
              id={props.match.params.id}
            />
          );
        }}
      />

      {/* Indicação */}
      <Route path="/indicacao" component={Indicacao} />

      {/* Licenciamento */}
      <Route path="/licenciamento" component={Licenciamento} />
      <Route path="/licenciamentodetalhe" component={DetLicenciamento} />
      <Route
        path="/licenciamentofiltrar/:id"
        component={(props) => {
          return (
            <Filtrar
              url={"/licenciamentodetalhe"}
              func={api.filtraLicenciamento}
              id={props.match.params.id}
            />
          );
        }}
      />

      {/* CRLV Digital */}
      <Route path="/crlvDigital" component={DetCrlv} />
      <Route path="/crlvDigitalGestor" component={CrlvGestor} />

      {/* Primeiro Emplacamento */}
      <Route path="/primeiroEmplacamento" component={primeiroEmplacamento} />
      <Route
        path="/primeiroEmplacamentodetalhe"
        component={DetPrimeiroEmplacamento}
      />
      <Route
        path="/primeiroEmplacamentofiltrar/:id"
        component={(props) => {
          return (
            <Filtrar
              url={"/primeiroEmplacamentodetalhe"}
              func={api.filtraPrimeiroEmplacamento}
              id={props.match.params.id}
            />
          );
        }}
      />
    </Switch>
  );
};

export default RoutePrivada;
