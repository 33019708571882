import React, { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";

import { useDadosGlobal, useDadosGlobalDispatch } from "../../context";

import * as utils from "../../../utils";
import * as Tema from "./style";
import Dado from "../../layout/tela/infoGraficos/dado";
import Senha from "../../pages/usuario/atualizarSenha";

import CardMenu from "../cardDropDown";

import Burger from "../../menu/Burger/Burger";

let logo;
import(`../../../image/${process.env.REACT_APP_NOME_CLIENTE}/logo.png`).then((module) => {
  logo = module.default; // <= base64 image
});


const Cabecalho = ({ isLoading, isMenu, isMenuVisible, onMenuVisible }) => {
  const history = useHistory();
  const [exibirMenu, setExibirMenu] = useState(false);
  const { usuario } = useDadosGlobal();
  const { sair } = useDadosGlobalDispatch();
  const [abrir, setAbrir] = useState(usuario.acesso == 1);

  const abrirUsuario = useCallback(
    (data, event) => {
      window.scrollTo(0, 0);

      history.push("/usuario", {});
    },
    [history]
  );

  const minhaArea = (
    <span
      onClick={() => {
        abrirUsuario();
      }}
      style={{ cursor: "pointer" }}
    >
      Minha Área
    </span>
  );
  return (
    <Tema.Container isMenu={isMenu} className="cabecalho">
      {isMenu && (
        <Tema.Wrapper>
          <div
            style={{
              display: "flex",
              width: "195px",
              flexDirection: isMenuVisible ? "row" : "row",
              justifyContent: "space-between",
              transition: "all 300ms",
            }}
          >
            <Tema.MenuHamburguer>
              {" "}
              <Burger />
            </Tema.MenuHamburguer>

            <Tema.BtnHamburger
              type="button"
              isMenuVisible={!isMenuVisible}
              onClick={() => {
                onMenuVisible();
              }}
            >
              <Tema.SpanBurguer>
                <Tema.SpanBurguerInner />
              </Tema.SpanBurguer>
            </Tema.BtnHamburger>

            <Tema.BtnHamburger
              type="button"
              isMenuVisible={isMenuVisible}
              onClick={() => {
                onMenuVisible();
              }}
            >
              <Tema.SpanBurguer>
                <Tema.SpanBurguerMobileInner />
              </Tema.SpanBurguer>
            </Tema.BtnHamburger>

            <img src={logo} className={"left logo"} alt="logo" style={{}} />
          </div>
        </Tema.Wrapper>
      )}

      <Tema.DivDireita>
        <Tema.DivDireitaDados>
          <Tema.DivGrupoUsuario>
            <Tema.DivOpcoesUsuario>
              <div
                onMouseOver={() => {
                  setExibirMenu(true);
                }}
                onMouseOut={() => {
                  setExibirMenu(false);
                }}
              >
                <Tema.UsuarioLink
                  onClick={() => {
                    setExibirMenu(!exibirMenu);
                  }}
                >
                  {/* <img width="42" src="assets/images/avatars/1.jpg" alt="" /> */}
                  <Tema.UsuarioIcone className="pe-7s-user" />
                  {/* <i style={{color:'#fff'}} className="fa fa-angle-down ml-2 opacity-8"></i> */}
                </Tema.UsuarioLink>
                <CardMenu
                  sourceImgFundo={
                    "url('https://www.lumis.com.br/data/files/DD/61/68/E8/E0678610D9EF57868E0BF9C2/a-importancia-do-contexto-de-uso-na-experiencia-do-usuario-FB.png')"
                  }
                  exibir={exibirMenu}
                  onBlur={() => {
                    setExibirMenu(false);
                  }}
                  nomeBotaoCabecalho={"Sair"}
                  onBotaoCabecalho={() => {
                    sair();
                  }}
                  titulo={minhaArea}
                  subTitulo={"Perfil do usuário"}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      color: "#70899b",
                    }}
                  >
                    <Dado
                      icone={"pe-7s-id"}
                      height={"70px"}
                      fontSizeValor={"12px"}
                      minWidth={"32.5%"}
                      titulo={usuario.codGestor ? "Gestor" : "Condutor"}
                      valor={usuario.codGestor ?? usuario.placa}
                      colorIcon={"#b78109"}
                      colorTitulo={"#b78109"}
                    />
                    <Dado
                      icone={"pe-7s-car"}
                      height={"70px"}
                      fontSizeValor={"12px"}
                      colorIcon={"#412280"}
                      colorTitulo={"#412280"}
                      minWidth={"32.5%"}
                      titulo={"Renavam"}
                      valor={usuario.renavam}
                    />
                    <Dado
                      icone={"pe-7s-date"}
                      height={"70px"}
                      fontSizeValor={"12px"}
                      minWidth={"35%"}
                      titulo={"Último login"}
                      colorIcon={"#ad0a0a"}
                      colorTitulo={"#ad0a0a"}
                      valor={utils.formatDataHora(
                        usuario.ultimoLogin,
                        "dd/MM/yyyy H:mm:ss"
                      )}
                    />
                  </div>
                  <div
                    style={{
                      display: usuario.nome ? "flex" : "none",
                      flexDirection: "row",
                      color: "#70899b",
                    }}
                  >
                    <Dado
                      colunaUnica={true}
                      height={"70px"}
                      icone={"pe-7s-user"}
                      colorIcon={"var(--color-cliente)"}
                      colorTitulo={"var(--color-cliente)"}
                      titulo={"Nome"}
                      fontSizeValor={"14px"}
                      valor={usuario.nome}
                    />
                  </div>

                  {/* Fellipe - 27/04/2022 => Conforme chamado TICKET-13349, remover o campo de e-mail do Hub. */}
                  
                  {/* <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      color: "#70899b",
                    }}
                  >
                    <Dado
                      colunaUnica={true}
                      height={"70px"}
                      icone={"pe-7s-mail"}
                      colorIcon={"var(--color-cliente)"}
                      colorTitulo={"var(--color-cliente)"}
                      minWidth={"50%"}
                      fontSizeValor={"13px"}
                      titulo={"Email"}
                      valor={usuario.email}
                    />
                  </div> */}
                </CardMenu>

                {/*    <Config.DropDownMenu tabindex="0" exibir={exibirMenu}  onBlur={() => {setExibirMenu(false)}}>
                                            <Config.DropDownMenuCabecalho>
                                                <Config.DropDownMenuCabecalhoInterno>
                                                    <Config.DropDownMenuCabecalhoFundoIMG source={"url('https://www.lumis.com.br/data/files/DD/61/68/E8/E0678610D9EF57868E0BF9C2/a-importancia-do-contexto-de-uso-na-experiencia-do-usuario-FB.png')"}  />
                                                     <Config.DropDownMenuCabecalhoConteudo >
                                                     <Config.DropDownMenuCabecalhoGrupo>
                                                     <Config.DropDownMenuCabecalhoGrupoInterno>

                                                     <Config.DropDownMenuCabecalhoGrupoLogo>
                                                     <Config.DropDownMenuIMG />

                                                     </Config.DropDownMenuCabecalhoGrupoLogo>

                                                     <Config.DropDownMenuCabecalhoGrupoTitulo>
                                                     <Config.DropDownMenuTitulo>
                                                         Minha Área
                                                         {/* {usuario.codGestor ?? usuario.placa} 
                                                     </Config.DropDownMenuTitulo>
                                                     <Config.DropDownMenuSubTitulo>
                                                         Perfil do usuário
                                                         {/* {usuario.nome ?? (usuario.codGestor ? "Gestor" : "Condutor")} 
                                                     </Config.DropDownMenuSubTitulo>
                                                     </Config.DropDownMenuCabecalhoGrupoTitulo>

                                                     <Config.DropDownMenuCabecalhoGrupoBotao>
                                                     <Config.DropDownMenuBotao onClick ={() => {sair()}}>
                                                     Sair
                                                     </Config.DropDownMenuBotao>
                                                     </Config.DropDownMenuCabecalhoGrupoBotao>
                                                     </Config.DropDownMenuCabecalhoGrupoInterno>

                                                     </Config.DropDownMenuCabecalhoGrupo>
                                                     </Config.DropDownMenuCabecalhoConteudo>
                                                </Config.DropDownMenuCabecalhoInterno>
                                            </Config.DropDownMenuCabecalho>
                                        </Config.DropDownMenu>
                                        <Tema.DivMenuUsuario tabindex="0" exibir={exibirMenu}  onBlur={() => {setExibirMenu(false)}}>
                                            <Tema.DivItemMenuUsuario    tabindex="0">Minha conta</Tema.DivItemMenuUsuario>
                                            <Tema.DivItemMenuUsuario  tabindex="0">Configurações</Tema.DivItemMenuUsuario>
                                            
                                            
                                            <div tabindex="-1"></div>
                                            <Tema.DivItemMenuUsuario  tabindex="0">{usuario.codGestor ?? usuario.placa}</Tema.DivItemMenuUsuario>
                                            <Tema.DivItemMenuUsuario  tabindex="1" onClick ={() => {sair()}}>Sair</Tema.DivItemMenuUsuario>
                                        </Tema.DivMenuUsuario> */}
              </div>
              <Tema.LinkTela to="/contato" title={"Contato"}>
                {/* <img width="42" src="assets/images/avatars/1.jpg" alt="" /> */}
                <Tema.UsuarioIcone className="pe-7s-headphones" />
              </Tema.LinkTela>
            </Tema.DivOpcoesUsuario>
          </Tema.DivGrupoUsuario>
        </Tema.DivDireitaDados>
      </Tema.DivDireita>
      <Senha
        abrir={abrir}
        onAbrir={() => {
          setAbrir(false);
        }}
      />
    </Tema.Container>
  );
};

export default Cabecalho;
