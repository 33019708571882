import logo from './logo.svg';
import './App.css';
import Rotas from './routes';
import { BrowserRouter } from 'react-router-dom';

import GlobalStyles from './style/GlobalStyle';
import { DadosGlobalProvider } from "./components/context";

function App() {
  return (
    <>
    <DadosGlobalProvider>

        <Rotas />

      <GlobalStyles />
    </DadosGlobalProvider>
    </>
  );


}
export default App;
