import React, { useState } from "react";

import Line from "../../layout/grafico/chartist/lineTotal";
import Pie from "../../layout/grafico/apex/pie";
import RadialBars from "../../layout/grafico/apex/radialBars";

import Bar from "../../layout/grafico/apex/bar";
import Barra from "../../layout/grafico/apex/barra";
import Donut from "../../layout/grafico/apex/donut";
import Mapa from "../../layout/grafico/amchart/mapa";
import { AnimationOrgao, Animation } from "../../loading/animation";
import CountUp from "../../layout/grafico/countUp";

import GrafLine from "../../layout/grafico/chartist/line";
import Detalhes from "./detalhes";

import * as Tema from "../../layout/tela/style";
import * as utils from "../../../utils";
import { toInteger } from "lodash";

const Dashboard = ({
  idGrafico,
  data,
  onPesquisar,
  onClick,
  onClickLinhaTabela,
}) => {
  const [dados, setDados] = useState([]);
  const [exibir, setExibir] = useState(false);

  const click = async (e) => {
    if (onClick) {
      const ret = await onClick(e);
      await exibirDetalhes(ret);
    }
  };

  const pesquisar = async (tipo) => {
    if (onPesquisar) {
      const ret = await onPesquisar(tipo);
      await exibirDetalhes(ret);
    }
  };

  const exibirDetalhes = async (ret) => {
    if (ret) {
      if (ret?.dados.length > 0) {
        setDados(ret);
        setExibir(true);
      }
    }
  };
  const clickLinhaTabela = async (row, e) => {
    if (onClickLinhaTabela) await onClickLinhaTabela(row, e);
  };

  const Grafico = () => {
    if (idGrafico == utils.graficos.notificacaoRecebida) {
      const dados = {
        valor: data?.CardTotalInfracao?.m_Item2.ValorTotal,
        qtd: data?.CardTotalInfracao?.m_Item1.Quantidade,
      };
      const labels = ["Notificações Recebidas"];
      return (
        <Tema.DivTotaisFundo
          corFundo={
            "linear-gradient(45deg,rgb(116, 65, 219), rgb(200, 156, 255))"
          }
        >
          <Tema.DivTotaisBody>
            <Tema.DivTotaisMedia>
              <Tema.DivTotaisMediaBody>
                <Tema.DivTotaisTitulo>
                  <CountUp end={0} valor={dados.qtd} />
                </Tema.DivTotaisTitulo>
                <Tema.DivTotaisSubTitulo>Notificações</Tema.DivTotaisSubTitulo>
              </Tema.DivTotaisMediaBody>
              {/* <Tema.DivTotaisMediaBody style={{ textAlign: "right" }}>
                <CountUp
                  end={0}
                  valor={dados.valor}
                  prefix={"R$ "}
                  caractermilhar="."
                  casasDecimais={2}
                  sepadorDecimal={","}
                />
              </Tema.DivTotaisMediaBody> */}
            </Tema.DivTotaisMedia>
          </Tema.DivTotaisBody>

          <Line
            height={75}
            data={[[50, 45, 60, 55, 70, 55, 60, 55, 65, 57, 60, 53, 53]]}
            name={"totNot"}
            labels={[
              "1",
              "2",
              "3",
              "4",
              "5",
              "6",
              "7",
              "8",
              "9",
              "10",
              "11",
              "12",
              "13",
            ]}
            idGrafico={idGrafico}
            labels={labels}
            onClick={async (e) => {
              await click(e);
            }}
          />
        </Tema.DivTotaisFundo>
      );
    } else if (idGrafico == utils.graficos.indicacaoCondutor) {
      const qtd = data?.CardTotalInfracao?.m_Item1.Quantidade;
      const dados = [
        Number.parseFloat(
          (
            ((data?.GraficoIndicacoesFeitas?.["Fez indicação"] ?? 0) /
              (qtd == 0 ? 1 : qtd)) *
            100
          ).toFixed(2)
        ),
      ];
      const labels = ["Indicadas", "Não Indicadas"];

      return (
        <Pie
          data={dados}
          labels={labels}
          colorPrimaria={"#ABE5A1"}
          colorSecundaria={"#1b998b7d"}
          idGrafico={idGrafico}
          onClick={async (e) => {
            await click(e);
          }}
        />
      );
    } else if (idGrafico == utils.graficos.naoIndicacaoCondutor) {
      const qtd = data?.CardTotalInfracao?.m_Item1.Quantidade;

      const NaoIndicadas =
        qtd - (data?.GraficoIndicacoesFeitas?.["Fez indicação"] ?? 0);

      const dados = [
        Number.parseFloat(
          ((NaoIndicadas / (qtd == 0 ? 1 : qtd)) * 100).toFixed(2)
        ),
      ];
      const labels = ["Não Indicadas", "Indicadas"];

      return (
        <Pie
          data={dados}
          labels={labels}
          colorPrimaria={"#d99e73"}
          colorSecundaria={"#f33167"}
          idGrafico={idGrafico}
          onClick={async (e) => {
            await click(e);
          }}
        />
      );
    } else if (idGrafico == utils.graficos.boletoRecebido) {
      const dados = {
        valor: data?.CardTotalBoleto?.m_Item2.ValorTotal,
        qtd: data?.CardTotalBoleto?.m_Item1.Quantidade,
      };
      const labels = ["Boletos Recebidos"];
      return (
        <Tema.DivTotaisFundo
          corFundo={"linear-gradient(45deg, #ee0979, #ff6a00)"}
        >
          <Tema.DivTotaisBody>
            <Tema.DivTotaisMedia>
              <Tema.DivTotaisMediaBody>
                <Tema.DivTotaisTitulo>
                  <CountUp end={0} valor={dados.qtd} />
                </Tema.DivTotaisTitulo>
                <Tema.DivTotaisSubTitulo>Boletos</Tema.DivTotaisSubTitulo>
              </Tema.DivTotaisMediaBody>
              <Tema.DivTotaisMediaBody style={{ textAlign: "right" }}>
                <CountUp
                  end={0}
                  valor={dados.valor}
                  prefix={"R$ "}
                  caractermilhar="."
                  casasDecimais={2}
                  sepadorDecimal={","}
                />
              </Tema.DivTotaisMediaBody>
            </Tema.DivTotaisMedia>
          </Tema.DivTotaisBody>

          <Line
            height={75}
            data={[[0, 5, 13, 8]]}
            name={"totBol"}
            idGrafico={idGrafico}
            labels={labels}
            onClick={async (e) => {
              await click(e);
            }}
          />
        </Tema.DivTotaisFundo>
      );
    } else if (idGrafico == utils.graficos.nic) {
      const dados = {
        valor: data?.CardTotalNIC?.m_Item2.ValorTotal,
        qtd: data?.CardTotalNIC?.m_Item1.Quantidade,
      };
      const labels = ["NIC"];
      return (
        <Tema.DivTotaisFundo
          corFundo={"linear-gradient(45deg, #004b91, #78cc37)"}
        >
          <Tema.DivTotaisBody>
            <Tema.DivTotaisMedia>
              <Tema.DivTotaisMediaBody>
                <Tema.DivTotaisTitulo>
                  <CountUp end={0} valor={dados.qtd} />
                </Tema.DivTotaisTitulo>
                <Tema.DivTotaisSubTitulo>NIC</Tema.DivTotaisSubTitulo>
              </Tema.DivTotaisMediaBody>
              <Tema.DivTotaisMediaBody style={{ textAlign: "right" }}>
                <CountUp
                  end={0}
                  valor={dados.valor}
                  prefix={"R$ "}
                  casasDecimais={2}
                  caractermilhar="."
                  sepadorDecimal={","}
                />
              </Tema.DivTotaisMediaBody>
            </Tema.DivTotaisMedia>
          </Tema.DivTotaisBody>

          <Line
            height={75}
            data={[[0, 1, 0, 3, 4, 0]]}
            name={"totNic"}
            idGrafico={idGrafico}
            labels={labels}
            onClick={async (e) => {
              await click(e);
            }}
          />
        </Tema.DivTotaisFundo>
      );
    } else if (idGrafico == utils.graficos.pagamentoBoleto) {
      const dados = {
        total: data?.CardTotalBoleto?.m_Item1.Quantidade,
        pagos: data?.GraficoBoletosPagos?.["Pago dentro do prazo"],
      };
      const labels = ["Pago com Desconto", "Pago sem Desconto"];
      return (
        <Pie
          data={[
            Number.parseFloat(
              ((dados.total > 0 ? dados.pagos / dados.total : 0) * 100).toFixed(
                2
              )
            ),
          ]}
          labels={labels}
          colorPrimaria={"#f7b924"}
          colorSecundaria={"#FA8B0C"}
          idGrafico={idGrafico}
          onClick={async (e) => {
            await click(e);
          }}
        />
      );
    } else if (idGrafico == utils.graficos.autuacaoNatureza) {
      const dados = {
        Leve: data?.GraficoAutuacoesNatureza?.["Leve"] ?? 0,
        Media: data?.GraficoAutuacoesNatureza?.["Média"] ?? 0,
        Grave: data?.GraficoAutuacoesNatureza?.["Grave"] ?? 0,
        Gravissima: data?.GraficoAutuacoesNatureza?.["Gravíssima"] ?? 0,
      };
      const labels = ["Leve", "Média", "Grave", "Gravíssima"];
      const cores = [
        { cor: "#16d9e3", pos: 0 },
        { cor: "#30c7ec", pos: 60 },
        { cor: "#46aef7", pos: 100 },
      ];
      return (
        <div
          style={{
            width: "100%",
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "row",
            margin: "1% 0px",
          }}
        >
          <div
            style={{
              width: "100%",
              filter: "drop-shadow(0 20px 8px rgba(0, 0, 0, .3))",
            }}
          >
            <Bar
              data={[dados.Leve, dados.Media, dados.Grave, dados.Gravissima]}
              labels={labels}
              backgroundColor={cores}
              idGrafico={idGrafico}
              onClick={async (e) => {
                await click(e);
              }}
            />
          </div>
          <div style={{ width: "100%" }}>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                flexDirection: "row",
                height: "100%",
              }}
            >
              <Tema.CardDetalhes
                style={{ background: "#fff" }}
                onClick={async () => {
                  await pesquisar("Leve");
                }}
              >
                <Tema.CardDetalhesIcone>
                  <Tema.CardDetalhesIconeFundo corFundo={"#023579"}>
                    <Tema.Icone className={"pe-7s-medal"} color={"white"} />
                  </Tema.CardDetalhesIconeFundo>
                </Tema.CardDetalhesIcone>
                <Tema.CardDetalhesTitulo style={{ color: "#023579" }}>
                  <CountUp end={0} valor={dados.Leve} />
                </Tema.CardDetalhesTitulo>
                <Tema.CardDetalhesSubTitulo>Leve</Tema.CardDetalhesSubTitulo>
              </Tema.CardDetalhes>
              <Tema.CardDetalhes
                style={{ background: "#fff" }}
                onClick={async () => {
                  await pesquisar("Média");
                }}
              >
                <Tema.CardDetalhesIcone>
                  <Tema.CardDetalhesIconeFundo corFundo={"#02545E"}>
                    <Tema.Icone className={"pe-7s-medal"} color={"white"} />
                  </Tema.CardDetalhesIconeFundo>
                </Tema.CardDetalhesIcone>
                <Tema.CardDetalhesTitulo style={{ color: "#02545E" }}>
                  <CountUp end={0} valor={dados.Media} />
                </Tema.CardDetalhesTitulo>
                <Tema.CardDetalhesSubTitulo>Média</Tema.CardDetalhesSubTitulo>
              </Tema.CardDetalhes>
              <Tema.CardDetalhes
                style={{ background: "#fff" }}
                onClick={async () => {
                  await pesquisar("Grave");
                }}
              >
                <Tema.CardDetalhesIcone>
                  <Tema.CardDetalhesIconeFundo corFundo={"#A65814"}>
                    <Tema.Icone className={"pe-7s-medal"} color={"white"} />
                  </Tema.CardDetalhesIconeFundo>
                </Tema.CardDetalhesIcone>
                <Tema.CardDetalhesTitulo style={{ color: "#A65814" }}>
                  <CountUp end={0} valor={dados.Grave} />
                </Tema.CardDetalhesTitulo>
                <Tema.CardDetalhesSubTitulo>Grave</Tema.CardDetalhesSubTitulo>
              </Tema.CardDetalhes>
              <Tema.CardDetalhes
                style={{ background: "#fff" }}
                onClick={async () => {
                  await pesquisar("Gravíssima");
                }}
              >
                <Tema.CardDetalhesIcone>
                  <Tema.CardDetalhesIconeFundo corFundo={"#8C2626"}>
                    <Tema.Icone className={"pe-7s-medal"} color={"white"} />
                  </Tema.CardDetalhesIconeFundo>
                </Tema.CardDetalhesIcone>
                <Tema.CardDetalhesTitulo style={{ color: "#8C2626" }}>
                  <CountUp end={0} valor={dados.Gravissima} />
                </Tema.CardDetalhesTitulo>
                <Tema.CardDetalhesSubTitulo>
                  Gravíssima
                </Tema.CardDetalhesSubTitulo>
              </Tema.CardDetalhes>
            </div>
          </div>
        </div>
      );
    } else if (idGrafico == utils.graficos.topOrgao) {
      const nomes = data?.GraficoTop5Orgaos
        ? Object.getOwnPropertyNames(data?.GraficoTop5Orgaos ?? [])
        : [];

      return (
        <div
          style={{
            width: "100%",
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "row",
            margin: "1% 0px",
          }}
        >
          <div
            style={{
              display: "flex",
              width: "100%",
              flexWrap: "wrap",
              flexDirection: "row",
              gap: " 2%",
              padding: "0px 4%",

              justifyContent: "space-between",
              filter: "drop-shadow(rgba(0, 0, 0, 0.9) 3px 15px 6px)",
            }}
          >
            {data?.GraficoTop5Orgaos &&
              Object.values(data?.GraficoTop5Orgaos ?? []).map((x, i) => {
                return (
                  <div key={i}>
                    <AnimationOrgao
                      onClick={async () => {
                        await pesquisar(nomes[i]);
                      }}
                    >
                      <CountUp end={0} valor={x} />
                    </AnimationOrgao>
                  </div>
                );
              })}
          </div>
          <div style={{ width: "100%" }}>
            <GrafLine
              height={470}
              showLinhaH={false}
              name={"orgao"}
              data={[
                data?.GraficoTop5Orgaos
                  ? Object.values(data?.GraficoTop5Orgaos ?? [])
                  : [],
              ]}
              labels={data?.GraficoTop5Orgaos ? nomes : ""}
              colors={["#fff"]}
              corLabel={"#ffff"}
              onClick={async (e) => {
                await click(e);
              }}
            />
          </div>
          <div style={{ width: "100%" }}>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                flexDirection: "row",
                height: "100%",
              }}
            ></div>
          </div>
        </div>
      );
    } else if (idGrafico == utils.graficos.rackingInfracao) {
      const lista = Object.values(data?.GraficoRackingMotivos ?? []);

      const total =
        lista.length > 0 ? lista.reduce((total, valor) => total + valor) : 0;
      const coresRaking = [
        "#D51970",
        "#7D0B8B",
        "#8BD858",
        "#F2C002",
        "#F06800",
        "#0ABFBC",
      ];
      return (
        <div
          style={{
            width: "100%",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "flex-start",
          }}
        >
          <div style={{ minWidth: 331 }}>
            <Donut
              data={(lista.length > 0 ? lista : []).map((x) =>
                Number.parseFloat(((x / total) * 100).toFixed(2))
              )}
              labels={
                data?.GraficoRackingMotivos
                  ? Object.getOwnPropertyNames(
                      data?.GraficoRackingMotivos ?? []
                    )
                  : []
              }
              backgroundColor={coresRaking}
              onClick={async (e) => {
                await click(e);
              }}
            />
          </div>
          <div style={{ flex: 1, alignSelf: "center" }}>
            <div
              style={{
                display: "flex",
                width: "100%",
                flexWrap: "wrap",
                flexDirection: "column",
                gap: "3%",
                padding: "0px 4%",

                justifyContent: "center",
                filter: "drop-shadow(rgba(0, 0, 0, 0.4) 3px 15px 6px)",
              }}
            >
              {(lista.length > 0 ? lista : []).map((x, i) => {
                return (
                  <div key={i}>
                    <Animation
                      style={{
                        display: "flex",
                        alignItems: "center",
                        height: "100%",
                        gap: "5px",
                      }}
                    >
                      <div style={{ width: "25%" }}>
                        <div
                          style={{
                            background: coresRaking[i],
                            width: `${Number.parseFloat(
                              ((x / total) * 100).toFixed(2)
                            )}%`,
                            height: 20,
                          }}
                        ></div>
                      </div>
                      <div style={{ width: "75%" }}>
                        {data?.GraficoRackingMotivos
                          ? Object.getOwnPropertyNames(
                              data?.GraficoRackingMotivos
                            )[i]
                          : ""}
                      </div>
                    </Animation>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      );
    } else if (idGrafico == utils.graficos.topPlaca) {
      const coresTopPlaca = [
        { cor: "#f5f8fb", pos: 0 },
        { cor: "#f5f8fb", pos: 40 },
        { cor: "#fff", pos: 100 },
      ];
      //debugger;
      const dados = Object.values(data?.GraficoTop5Placas ?? []);
      // const dados = data?.GraficoTop5Placas
      //   ? Object.values(data?.GraficoTop5Placas)
      //   : [];
      const labels = data?.GraficoTop5Placas
        ? Object.getOwnPropertyNames(data?.GraficoTop5Placas ?? [])
        : [];
      return (
        <div
          style={{
            width: "100%",
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "row",
            margin: "5px 0px",
          }}
        >
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <Barra
              data={dados}
              labels={labels}
              backgroundColor={coresTopPlaca}
              style={{ width: "90%" }}
              idGrafico={idGrafico}
              onClick={async (e) => {
                await click(e);
              }}
            />
          </div>
        </div>
      );
    } else if (idGrafico == utils.graficos.topLocatario) {
      const coresTopLocatario = [
        { cor: "#f5f8fb", pos: 0 },
        { cor: "#f5f8fb", pos: 40 },
        { cor: "#fff", pos: 100 },
      ];
      //debugger;
      const dados = Object.values(data?.GraficoTop5Locatarios ?? []);
      // const dados = data?.GraficoTop5Placas
      //   ? Object.values(data?.GraficoTop5Placas)
      //   : [];
      const labels = data?.GraficoTop5Locatarios
        ? Object.getOwnPropertyNames(data?.GraficoTop5Locatarios ?? [])
        : [];
      return (
        <div
          style={{
            width: "100%",
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "row",
            margin: "5px 0px",
          }}
        >
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <Barra
              data={dados}
              labels={labels}
              backgroundColor={coresTopLocatario}
              style={{ width: "90%" }}
              idGrafico={idGrafico}
              onClick={async (e) => {
                await click(e);
              }}
            />
          </div>
        </div>
      );
    } else if (idGrafico == utils.graficos.notificacaoPorEstado) {
      const dados = data?.GraficoNotificacaoPorUF
        ? Object.entries(data.GraficoNotificacaoPorUF ?? [])
        : [];
      return (
        <Mapa
          dados={dados}
          style={{ width: "100%", height: 650 }}
          onClick={async (e) => {
            await click(e);
          }}
        />
      );
    } else if (idGrafico == utils.graficos.notificacaoExtra) {
      const dados = {
        valor: data?.CardTotalInfracao?.m_Item2.ValorTotal,
        qtd: data?.CardTotalInfracao?.m_Item1.Quantidade,
      };
      const labels = ["Notificações Recebidas"];
      return (
        <Tema.DivTotaisFundo
          corFundo={"linear-gradient(45deg, #007991, #78ffd6)"}
        >
          <Tema.DivTotaisBody>
            <Tema.DivTotaisMedia>
              <Tema.DivTotaisMediaBody>
                <Tema.DivTotaisTitulo>
                  <CountUp
                    end={0}
                    valor={dados.valor}
                    prefix={"R$ "}
                    caractermilhar="."
                    casasDecimais={2}
                    sepadorDecimal={","}
                  />
                </Tema.DivTotaisTitulo>
                <Tema.DivTotaisSubTitulo>Valor</Tema.DivTotaisSubTitulo>
              </Tema.DivTotaisMediaBody>
            </Tema.DivTotaisMedia>
          </Tema.DivTotaisBody>

          <Line
            height={75}
            data={[[50, 45, 60, 55, 70, 55, 60, 55, 65, 57, 60, 53, 53]]}
            name={"totNot"}
            labels={[
              "1",
              "2",
              "3",
              "4",
              "5",
              "6",
              "7",
              "8",
              "9",
              "10",
              "11",
              "12",
              "13",
            ]}
            idGrafico={idGrafico}
            labels={labels}
            onClick={async (e) => {
              await click(e);
            }}
          />
        </Tema.DivTotaisFundo>
      );
    } else if (idGrafico == utils.graficos.primeiroEmplacamentoTotal) {
      const dados = {
        // valor: data?.CardTotalPrimeiroEmplacamento?.m_Item2.ValorTotal,
        qtd: data?.CardTotal.Quantidade,
      };
      const labels = ["Total"];
      return (
        <Tema.DivTotaisFundo
          corFundo={
            "linear-gradient(62deg,rgb(251, 171, 126), rgb(247, 206, 104))"
          }
        >
          <Tema.DivTotaisBody>
            <Tema.DivTotaisMedia>
              <Tema.DivTotaisMediaBody>
                <Tema.DivTotaisTitulo>
                  <CountUp end={0} valor={dados.qtd} />
                </Tema.DivTotaisTitulo>
                <Tema.DivTotaisSubTitulo>Total</Tema.DivTotaisSubTitulo>
              </Tema.DivTotaisMediaBody>
              {/* <Tema.DivTotaisMediaBody style={{ textAlign: "right" }}>
                <CountUp
                  end={0}
                  valor={dados.valor}
                  prefix={"R$ "}
                  caractermilhar="."
                  casasDecimais={2}
                  sepadorDecimal={","}
                />
              </Tema.DivTotaisMediaBody> */}
            </Tema.DivTotaisMedia>
          </Tema.DivTotaisBody>

          <Line
            height={75}
            data={[[50, 45, 80, 55, 80, 55, 60, 55, 42, 57, 84, 90, 53]]}
            name={"totNot"}
            labels={[
              "1",
              "2",
              "3",
              "4",
              "5",
              "6",
              "7",
              "8",
              "9",
              "10",
              "11",
              "12",
              "13",
            ]}
            idGrafico={idGrafico}
            labels={labels}
            onClick={async (e) => {
              await click(e);
            }}
          />
        </Tema.DivTotaisFundo>
      );
    } else if (idGrafico == utils.graficos.primeiroEmplacamentoPendencia) {
      const dados = {
        // valor: data?.CardTotalPrimeiroEmplacamento?.m_Item2.ValorTotal,
        qtd: data?.CardTotalPendencias.Quantidade,
      };
      const labels = ["Pendente"];
      return (
        <Tema.DivTotaisFundo
          corFundo={
            "linear-gradient(45deg,rgb(203, 53, 107), rgb(189, 63, 50))"
          }
        >
          <Tema.DivTotaisBody>
            <Tema.DivTotaisMedia>
              <Tema.DivTotaisMediaBody>
                <Tema.DivTotaisTitulo>
                  <CountUp end={0} valor={dados.qtd} />
                </Tema.DivTotaisTitulo>
                <Tema.DivTotaisSubTitulo>Pendente</Tema.DivTotaisSubTitulo>
              </Tema.DivTotaisMediaBody>
              {/* <Tema.DivTotaisMediaBody style={{ textAlign: "right" }}>
                <CountUp
                  end={0}
                  valor={dados.valor}
                  prefix={"R$ "}
                  caractermilhar="."
                  casasDecimais={2}
                  sepadorDecimal={","}
                />
              </Tema.DivTotaisMediaBody> */}
            </Tema.DivTotaisMedia>
          </Tema.DivTotaisBody>

          <Line
            height={75}
            data={[[50, 45, 60, 60, 70, 55, 60, 55, 65, 60, 60, 53, 60]]}
            name={"totNot"}
            labels={[
              "1",
              "2",
              "3",
              "4",
              "5",
              "6",
              "7",
              "8",
              "9",
              "10",
              "11",
              "12",
              "13",
            ]}
            idGrafico={idGrafico}
            labels={labels}
            onClick={async (e) => {
              await click(e);
            }}
          />
        </Tema.DivTotaisFundo>
      );
    } else if (idGrafico == utils.graficos.primeiroEmplacamentoEmAndamento) {
      const dados = {
        // valor: data?.CardTotalPrimeiroEmplacamento?.m_Item2.ValorTotal,
        qtd: data?.GraficoEmAndamento.Quantidade,
      };
      const labels = ["Em andamento"];
      return (
        <Tema.DivTotaisFundo
          corFundo={
            "linear-gradient(160deg,rgb(0, 147, 233), rgb(128, 208, 199))"
          }
        >
          <Tema.DivTotaisBody>
            <Tema.DivTotaisMedia>
              <Tema.DivTotaisMediaBody>
                <Tema.DivTotaisTitulo>
                  <CountUp end={0} valor={dados.qtd} />
                </Tema.DivTotaisTitulo>
                <Tema.DivTotaisSubTitulo>Em andamento</Tema.DivTotaisSubTitulo>
              </Tema.DivTotaisMediaBody>
              {/* <Tema.DivTotaisMediaBody style={{ textAlign: "right" }}>
                <CountUp
                  end={0}
                  valor={dados.valor}
                  prefix={"R$ "}
                  caractermilhar="."
                  casasDecimais={2}
                  sepadorDecimal={","}
                />
              </Tema.DivTotaisMediaBody> */}
            </Tema.DivTotaisMedia>
          </Tema.DivTotaisBody>

          <Line
            height={75}
            data={[[50, 45, 60, 55, 70, 55, 60, 55, 65, 57, 60, 53, 53]]}
            name={"totNot"}
            labels={[
              "1",
              "2",
              "3",
              "4",
              "5",
              "6",
              "7",
              "8",
              "9",
              "10",
              "11",
              "12",
              "13",
            ]}
            idGrafico={idGrafico}
            labels={labels}
            onClick={async (e) => {
              await click(e);
            }}
          />
        </Tema.DivTotaisFundo>
      );
    } else if (idGrafico == utils.graficos.primeiroEmplacamentoConcluido) {
      const dados = {
        // valor: data?.CardTotalPrimeiroEmplacamento?.m_Item2.ValorTotal,
        qtd: data?.GraficoConcluido.Quantidade,
      };
      const labels = ["Concluído"];
      return (
        <Tema.DivTotaisFundo
          corFundo={"linear-gradient(19deg,rgb(32, 0, 44), rgb(203, 180, 212))"}
        >
          <Tema.DivTotaisBody>
            <Tema.DivTotaisMedia>
              <Tema.DivTotaisMediaBody>
                <Tema.DivTotaisTitulo>
                  <CountUp end={0} valor={dados.qtd} />
                </Tema.DivTotaisTitulo>
                <Tema.DivTotaisSubTitulo>Concluído</Tema.DivTotaisSubTitulo>
              </Tema.DivTotaisMediaBody>
              {/* <Tema.DivTotaisMediaBody style={{ textAlign: "right" }}>
                <CountUp
                  end={0}
                  valor={dados.valor}
                  prefix={"R$ "}
                  caractermilhar="."
                  casasDecimais={2}
                  sepadorDecimal={","}
                />
              </Tema.DivTotaisMediaBody> */}
            </Tema.DivTotaisMedia>
          </Tema.DivTotaisBody>

          <Line
            height={75}
            data={[[70, 45, 65, 55, 65, 55, 45, 55, 65, 45, 60, 53, 65]]}
            name={"totNot"}
            labels={[
              "1",
              "2",
              "3",
              "4",
              "5",
              "6",
              "7",
              "8",
              "9",
              "10",
              "11",
              "12",
              "13",
            ]}
            idGrafico={idGrafico}
            labels={labels}
            onClick={async (e) => {
              await click(e);
            }}
          />
        </Tema.DivTotaisFundo>
      );
    } else if (idGrafico == utils.graficos.notificacaoRecebidaBoletoComum) {
      const dados = {
        qtd: data?.CardNotificacaoComBoletos?.m_Item1.Quantidade,
      };

      const labels = ["Notificação com Boletos"];
      return (
        <Tema.DivTotaisFundo
          corFundo={
            "linear-gradient(45deg,rgb(251, 214, 100), rgb(255, 99, 198))"
          }
        >
          <Tema.DivTotaisBody>
            <Tema.DivTotaisMedia>
              <Tema.DivTotaisMediaBody>
                <Tema.DivTotaisTitulo>
                  <CountUp end={0} valor={dados.qtd} />
                </Tema.DivTotaisTitulo>
                <Tema.DivTotaisSubTitulo>
                  Notificação com Boletos
                </Tema.DivTotaisSubTitulo>
              </Tema.DivTotaisMediaBody>
            </Tema.DivTotaisMedia>
          </Tema.DivTotaisBody>

          <Line
            height={75}
            data={[[50, 45, 60, 55, 70, 55, 60, 55, 65, 57, 60, 53, 53]]}
            name={"totNot"}
            labels={[
              "1",
              "2",
              "3",
              "4",
              "5",
              "6",
              "7",
              "8",
              "9",
              "10",
              "11",
              "12",
              "13",
            ]}
            idGrafico={idGrafico}
            labels={labels}
            onClick={async (e) => {
              await click(e);
            }}
          />
        </Tema.DivTotaisFundo>
      );
    } else if (idGrafico == utils.graficos.notificacaoRecebidaBoletoNic) {
      const dados = {
        qtd: data?.CardNotificacaoComNIC?.m_Item1.Quantidade,
      };
      const labels = ["Notificação com NIC"];
      return (
        <Tema.DivTotaisFundo
          corFundo={
            "linear-gradient(45deg,rgb(241, 208, 67), rgb(126, 252, 206))"
          }
        >
          <Tema.DivTotaisBody>
            <Tema.DivTotaisMedia>
              <Tema.DivTotaisMediaBody>
                <Tema.DivTotaisTitulo>
                  <CountUp end={0} valor={dados.qtd} />
                </Tema.DivTotaisTitulo>
                <Tema.DivTotaisSubTitulo>
                  Notificação com NIC
                </Tema.DivTotaisSubTitulo>
              </Tema.DivTotaisMediaBody>
            </Tema.DivTotaisMedia>
          </Tema.DivTotaisBody>

          <Line
            height={75}
            data={[[50, 45, 60, 55, 70, 55, 60, 55, 65, 57, 60, 53, 53]]}
            name={"totNot"}
            labels={[
              "1",
              "2",
              "3",
              "4",
              "5",
              "6",
              "7",
              "8",
              "9",
              "10",
              "11",
              "12",
              "13",
            ]}
            idGrafico={idGrafico}
            labels={labels}
            onClick={async (e) => {
              await click(e);
            }}
          />
        </Tema.DivTotaisFundo>
      );
    } else if (idGrafico == utils.graficos.boletoSemNotificacao) {
      const dados = {
        qtd: data?.CardBoletoSemNotificacao?.m_Item1.Quantidade,
      };
      const labels = ["Boleto sem Notificação"];
      return (
        <Tema.DivTotaisFundo
          corFundo={
            "linear-gradient(45deg,rgb(135, 190, 227), rgb(149, 154, 255))"
          }
        >
          <Tema.DivTotaisBody>
            <Tema.DivTotaisMedia>
              <Tema.DivTotaisMediaBody>
                <Tema.DivTotaisTitulo>
                  <CountUp end={0} valor={dados.qtd} />
                </Tema.DivTotaisTitulo>
                <Tema.DivTotaisSubTitulo>
                  Boleto sem Notificação
                </Tema.DivTotaisSubTitulo>
              </Tema.DivTotaisMediaBody>
            </Tema.DivTotaisMedia>
          </Tema.DivTotaisBody>

          <Line
            height={75}
            data={[[50, 45, 60, 55, 70, 55, 60, 55, 65, 57, 60, 53, 53]]}
            name={"totNot"}
            labels={[
              "1",
              "2",
              "3",
              "4",
              "5",
              "6",
              "7",
              "8",
              "9",
              "10",
              "11",
              "12",
              "13",
            ]}
            idGrafico={idGrafico}
            labels={labels}
            onClick={async (e) => {
              await click(e);
            }}
          />
        </Tema.DivTotaisFundo>
      );
    } else if (idGrafico == utils.graficos.nicSemNotificacao) {
      const dados = {
        qtd: data?.CardNICSemNotificacao?.m_Item1.Quantidade,
      };
      const labels = ["NIC sem Notificação"];
      return (
        <Tema.DivTotaisFundo
          corFundo={
            "linear-gradient(45deg,rgb(135, 190, 227), rgb(149, 154, 255))"
          }
        >
          <Tema.DivTotaisBody>
            <Tema.DivTotaisMedia>
              <Tema.DivTotaisMediaBody>
                <Tema.DivTotaisTitulo>
                  <CountUp end={0} valor={dados.qtd} />
                </Tema.DivTotaisTitulo>
                <Tema.DivTotaisSubTitulo>
                  NIC sem Notificação
                </Tema.DivTotaisSubTitulo>
              </Tema.DivTotaisMediaBody>
            </Tema.DivTotaisMedia>
          </Tema.DivTotaisBody>

          <Line
            height={75}
            data={[[50, 45, 60, 55, 70, 55, 60, 55, 65, 57, 60, 53, 53]]}
            name={"totNot"}
            labels={[
              "1",
              "2",
              "3",
              "4",
              "5",
              "6",
              "7",
              "8",
              "9",
              "10",
              "11",
              "12",
              "13",
            ]}
            idGrafico={idGrafico}
            labels={labels}
            onClick={async (e) => {
              await click(e);
            }}
          />
        </Tema.DivTotaisFundo>
      );
    } else if (idGrafico == utils.graficos.licenciamentoConcluido) {
      const total = data?.CardTotalLicenciamento?.["Total"] ?? 0;
      const valor = data?.CardTotalLicenciamento?.["Concluído"] ?? 0;
      const dados = [
        Number.parseFloat(((valor > 0 ? valor / total : 0) * 100).toFixed(2)),
      ];
      const labels = ["Concluído", "Pendente"];

      return (
        <Pie
          data={dados}
          labels={labels}
          colorPrimaria={"#ABE5A1"}
          colorSecundaria={"#1b998b7d"}
          idGrafico={idGrafico}
          onClick={async (e) => {
            await click(e);
          }}
        />
      );
    } else if (idGrafico == utils.graficos.licenciamentoPendente) {
      const total = data?.CardTotalLicenciamento?.["Total"] ?? 0;
      const valor = data?.CardTotalLicenciamento?.["Pendente"] ?? 0;
      const dados = [
        Number.parseFloat(((valor > 0 ? valor / total : 0) * 100).toFixed(2)),
      ];

      const labels = ["Pendente", "Concluído"];

      return (
        <Pie
          data={dados}
          labels={labels}
          colorPrimaria={"#d99e73"}
          colorSecundaria={"#f33167"}
          idGrafico={idGrafico}
          onClick={async (e) => {
            await click(e);
          }}
        />
      );
    } else if (idGrafico == utils.graficos.licenciamentoStatus) {
      const total = toInteger(
        data?.CardTotalLicenciamentoStatus?.["Total"] ?? 0
      );
      const valorEmAndamento = toInteger(
        data?.CardTotalLicenciamentoStatus?.["LicenciamentoInicializado"] ?? 0
      );
      const valorCrlv = toInteger(
        data?.CardTotalLicenciamentoStatus?.["CrlvEmitido"] ?? 0
      );
      const valorConcluido = toInteger(
        data?.CardTotalLicenciamentoStatus?.["Concluido"] ?? 0
      );

      const dados = [
        Number.parseFloat(
          ((valorEmAndamento > 0 ? valorEmAndamento / total : 0) * 100).toFixed(
            2
          )
        ),
        Number.parseFloat(
          ((valorCrlv > 0 ? valorCrlv / total : 0) * 100).toFixed(2)
        ),
        Number.parseFloat(
          ((valorConcluido > 0 ? valorConcluido / total : 0) * 100).toFixed(2)
        ),
      ];

      const labels = ["Em andamento", "CRLV emitido", "Concluído"];

      return (
        <RadialBars
          // data={[0,0,0]}
          data={dados}
          totalQtd={total}
          Labels={labels}
          idGrafico={idGrafico}
          onClick={async (e) => {
            await click(e);
          }}
        />
      );
    } else if (idGrafico == utils.graficos.boletoPorEstado) {
      const dados = data?.GraficoBoletoPorUF
        ? Object.entries(data.GraficoBoletoPorUF ?? [])
        : [];
      return (
        <Mapa
          dados={dados}
          style={{ width: "100%", height: 650 }}
          onClick={async (e) => {
            await click(e);
          }}
        />
      );
    } else if (idGrafico == utils.graficos.boletoTopOrgao) {
      const nomes = data?.GraficoBoletoTop5Orgaos
        ? Object.getOwnPropertyNames(data?.GraficoBoletoTop5Orgaos ?? [])
        : [];

      return (
        <div
          style={{
            width: "100%",
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "row",
            margin: "1% 0px",
          }}
        >
          <div
            style={{
              display: "flex",
              width: "100%",
              flexWrap: "wrap",
              flexDirection: "row",
              gap: " 2%",
              padding: "0px 4%",

              justifyContent: "space-between",
              filter: "drop-shadow(rgba(0, 0, 0, 0.9) 3px 15px 6px)",
            }}
          >
            {data?.GraficoBoletoTop5Orgaos &&
              Object.values(data?.GraficoBoletoTop5Orgaos ?? []).map((x, i) => {
                return (
                  <div key={i}>
                    <AnimationOrgao
                      onClick={async () => {
                        await pesquisar(nomes[i]);
                      }}
                    >
                      <CountUp end={0} valor={x} />
                    </AnimationOrgao>
                  </div>
                );
              })}
          </div>
          <div style={{ width: "100%" }}>
            <GrafLine
              height={470}
              showLinhaH={false}
              name={"orgao"}
              data={[
                data?.GraficoBoletoTop5Orgaos
                  ? Object.values(data?.GraficoBoletoTop5Orgaos ?? [])
                  : [],
              ]}
              labels={data?.GraficoBoletoTop5Orgaos ? nomes : ""}
              colors={["#fff"]}
              corLabel={"#ffff"}
              onClick={async (e) => {
                await click(e);
              }}
            />
          </div>
          <div style={{ width: "100%" }}>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                flexDirection: "row",
                height: "100%",
              }}
            ></div>
          </div>
        </div>
      );
    } else if (idGrafico == utils.graficos.boletoRankingInfracao) {
      const lista = Object.values(data?.GraficoBoletoRankingMotivos ?? []);

      const total =
        lista.length > 0 ? lista.reduce((total, valor) => total + valor) : 0;
      const coresRaking = [
        "#D51970",
        "#7D0B8B",
        "#8BD858",
        "#F2C002",
        "#F06800",
        "#0ABFBC",
      ];
      return (
        <div
          style={{
            width: "100%",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "flex-start",
          }}
        >
          <div style={{ minWidth: 331 }}>
            <Donut
              data={(lista.length > 0 ? lista : []).map((x) =>
                Number.parseFloat(((x / total) * 100).toFixed(2))
              )}
              labels={
                data?.GraficoBoletoRankingMotivos
                  ? Object.getOwnPropertyNames(
                      data?.GraficoBoletoRankingMotivos ?? []
                    )
                  : []
              }
              backgroundColor={coresRaking}
              onClick={async (e) => {
                await click(e);
              }}
            />
          </div>
          <div style={{ flex: 1, alignSelf: "center" }}>
            <div
              style={{
                display: "flex",
                width: "100%",
                flexWrap: "wrap",
                flexDirection: "column",
                gap: "3%",
                padding: "0px 4%",

                justifyContent: "center",
                filter: "drop-shadow(rgba(0, 0, 0, 0.4) 3px 15px 6px)",
              }}
            >
              {(lista.length > 0 ? lista : []).map((x, i) => {
                return (
                  <div key={i}>
                    <Animation
                      style={{
                        display: "flex",
                        alignItems: "center",
                        height: "100%",
                        gap: "5px",
                      }}
                    >
                      <div style={{ width: "25%" }}>
                        <div
                          style={{
                            background: coresRaking[i],
                            width: `${Number.parseFloat(
                              ((x / total) * 100).toFixed(2)
                            )}%`,
                            height: 20,
                          }}
                        ></div>
                      </div>
                      <div style={{ width: "75%" }}>
                        {data?.GraficoBoletoRankingMotivos
                          ? Object.getOwnPropertyNames(
                              data?.GraficoBoletoRankingMotivos
                            )[i]
                          : ""}
                      </div>
                    </Animation>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      );
    } else if (idGrafico == utils.graficos.BoletoTopPlaca) {
      const coresTopPlaca = [
        { cor: "#f5f8fb", pos: 0 },
        { cor: "#f5f8fb", pos: 40 },
        { cor: "#fff", pos: 100 },
      ];
      const dados = Object.values(data?.GraficoBoletoTop5Placas ?? []);
      const labels = data?.GraficoBoletoTop5Placas
        ? Object.getOwnPropertyNames(data?.GraficoBoletoTop5Placas ?? [])
        : [];
      return (
        <div
          style={{
            width: "100%",
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "row",
            margin: "5px 0px",
          }}
        >
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <Barra
              data={dados}
              labels={labels}
              backgroundColor={coresTopPlaca}
              style={{ width: "90%" }}
              idGrafico={idGrafico}
              onClick={async (e) => {
                await click(e);
              }}
            />
          </div>
        </div>
      );
    }
  };

  return (
    <React.Fragment>
      <Grafico />
      <Detalhes
        idGrafico={idGrafico}
        data={dados?.dados}
        titulo={dados?.titulo}
        subTitulo={dados?.subTitulo}
        opcoes={dados?.opcoes}
        onPesquisar={pesquisar}
        mostrar={exibir}
        onMonstrar={() => {
          setExibir(false);
        }}
        onLinhaClique={clickLinhaTabela}
      />
    </React.Fragment>
  );
};
export default Dashboard;
