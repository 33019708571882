
import React,{useEffect,useState,useLayoutEffect, useRef, useCallback}  from 'react';

import InputMask from "react-input-mask";
import Input from './inputUI'
import  * as Padrao from '../../../../style/config'
import { Field } from "formik";

const CampoMask = ({label, name, mask, labelAtivo, value, onChange, desabilitado, type, placeHolder, align, widthAuto, width, minWidth, maxLength}) => (
  <Field
      name={name}
      render={({field, meta}) => {
  const textAlign = align ?? 'left';
  return (
          <InputMask
            {...field}
            {...meta}
            mask={mask}
            > 
              {(innerProps) => (
                  <Padrao.Input
                    {...innerProps}
                    label={label}
                    widthAuto={widthAuto}
                    minWidth={minWidth}
                    value={value}
                    onChange={onChange}
                    helperText={meta.error}
                    error={meta.error}
                    width={width}
                    size="small"
                    variant="outlined"
                    disabled={desabilitado}
                    type={type}
                    inputProps={{ maxLength, style: { textAlign} }}
                    placeholder={placeHolder}
                    InputLabelProps={{
                        shrink: labelAtivo,
                      }}
                    
                    />

              )}
            </InputMask>

        )
      }}
    />


);


// const CampoMask  =({label, name, mask, labelAtivo, value, onChange, desabilitado, type, placeHolder, align, widthAuto, width, minWidth, maxLength})=>{

//   function ComponenteMask(props) {
//     const { inputRef, onChange, ...other } = props;
//     return (
//       <InputMask 
//         {...other}
//         mask={mask} // Format you need implemented
//         ref ={inputRef}
//  onChange={(e) =>{
//    console.log('mask', e)
//    onChange(e)
//  }}


// //  required disableUnderline 

//  maskChar= {'_'}
//  /> 
//     );
//   }
  
    
//   const textAlign = align ?? 'left';

//     return(

//       <Field name={name} >
// {({ field, form, meta }) => (
//         <Padrao.Input
//         {...field}
//         label={label}
//         widthAuto={widthAuto}
//         minWidth={minWidth}
//         value={value}
//         onChange={(e) =>{
//           console.log('input', e)
//           onChange(e)
//         }}
//         width={width}
//         size="small"
//         variant="outlined"
//         disabled={desabilitado}
//         type={type}
//         inputProps={{ maxLength, style: { textAlign} }}
//         placeholder={placeHolder}
//         InputLabelProps={{
//             shrink: labelAtivo,
//           }}
//           helperText={meta.error}
//           error={meta.error}
//           InputProps={{inputComponent: ComponenteMask,
//           }}
//           />
//       )}
//         </Field>
  
//         // <Input
//         // name= {name}
//         // label={label}
//         // labelAtivo={labelAtivo}
//         // value={value}
//         // desabilitado={desabilitado}
//         // type={type}
//         // placeHolder={placeHolder}
//         // onChange={onChange}
//         // align={align} widthAuto={ widthAuto} width={width} minWidth={minWidth} maxLength={maxLength}
//         // Component={}
//         //   />
   
//     )
// };

export default ( CampoMask)