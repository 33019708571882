import styled from "styled-components";

const media = {
  mobile: "@media(max-width: 900px)",
  desktop: "@media (min-width: 1180px)",
};

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background: black;
  > main {
    height: auto;
    min-height: 100%;
    /* min-height: calc(100% - 60px); */

  }
  > span {
    margin-top: 48px;
    display: flex;
  }
  @media (min-width: 1180px) {
    > main {
      // height: calc(100% - 60px);
    }
    > span {
      margin-top: 52px;
      padding: 8px 0;
    }
    > main {
      /* margin: 0 30px; */
      display: flex;
      justify-content: center;
    }
  }

  .left-column,
  .right-column,
  .ad-banner {
    display: none;
  }
  @media (min-width: 1180px) {
    .left-column,
    .right-column,
    .ad-banner {
      display: unset;
    }
    .middle-column {
      margin: 0 25px 16px;
    }
  }
  ${media.mobile} {
    > main {
      height: 100%;
      min-height: 100%;
    /* min-height: calc(100% - 60px); */
    }
  }
`;

export const Linha = styled.div`
  width: 100%;
  height: auto;
  display: flex;

  ${media.mobile} {
    width: 100%;
    height: 100%;
    display: flex;
  }
`;
