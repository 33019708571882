import React,{useState} from 'react';

import Tooltip from '@material-ui/core/Tooltip';
import { withStyles, makeStyles } from '@material-ui/core/styles';
const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
      
    },
  }))(Tooltip);

function HubTooltip({texto, children}) {

    const title = typeof texto === 'string' ? <span>{texto}</span>: texto

    return(

        <HtmlTooltip
        title={
          <React.Fragment>
            {/* Ao escolher manter sua senha padrão, só será possível alterá-la posteriormente em <b>Esqueci minha Senha.</b> */}
            {title}
          </React.Fragment>
        }
      >
         {children}
      </HtmlTooltip>
    )
};

export default HubTooltip;