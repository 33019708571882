import React, { useEffect, useCallback, useState } from "react";
import { useHistory } from "react-router-dom";

import Tela from "../../layout/tela";
import Tabela from "../../layout/tabela/dataGrid";

import * as Tema from "../../layout/tela/style";
import * as Padrao from "../../../style/config";
import Input from "../../layout/tela/campos/inputUI";
import ButtonMaterial from "@material-ui/core/Button";
import { Formik } from "formik";

import * as TemaTab from "../../layout/tabela/style";
import { Carregando } from "../../loading";

import * as api from "../../../API/consulta";
import * as utils from "../../../utils";
import { useDadosGlobal } from "../../context";

const dadosIni = { Dados: [], Grafico: null };

const Listagem = ({}) => {
  const history = useHistory();
  const { usuarioState } = useDadosGlobal();
  const { usuario } = usuarioState;
  const [data, setData] = useState(dadosIni);
  const [carregando, setCarregando] = useState(true);
  const [propsAlerta, setPropsAlerta] = useState(utils.propsAlerta);

  const [filtros, setFiltros] = useState({
    placa: usuario.placa,
    codGestor: usuario.codGestor,
    gestorMaster: usuario.gestorMaster,
  });

  // Colunas da tabela que iremos preencher
  const columnsMaterialUI = [
    {
      field: "CodGestor",
      headerName: "Código Gestor",
      type: "string",
      headerClassName: "super-app-theme--header",
      flex: 1,
    },

    {
      field: "Placa",
      headerName: "Placa",
      type: "string",
      headerClassName: "super-app-theme--header",
      flex: 1,
      hide: !usuario.codGestor,
    },

    {
      field: "download",
      headerName: "Download",
      type: "string",
      headerClassName: "super-app-theme--header",
      flex: 1,
      align: "center",
      renderCell: (params) => (
        <strong>
          <TemaTab.Icone
            className={"pe-7s-cloud-download"}
            onClick={async () => {
              await downloadCRLV({ placa: params.row.Placa });
            }}
          />
        </strong>
      ),
    },
  ];

  useEffect(() => {
    async function carregar() {
      try {
        await consultar({ user: false, values: filtros, errors: {} });
      } catch (e) {
        setPropsAlerta(
          utils.montaAlerta({
            titulo: e.message,
            origem: e.origem,
            tAnimacao: e.tipoAlerta,
          })
        );
      }
    }
    carregar();
  }, []);

  const consultar = async ({ user, values, errors }) => {
    try {
      if (Object.entries(errors).length > 0) return;
      setCarregando(true);
      if (user) setFiltros(values);
      await setData(dadosIni);
      const ret = await api.retornaVeiculos({
        placa: values?.placa,
        codGestor: values?.codGestor,
        gestorMaster: usuario.gestorMaster,
      });

      await setData(ret);
      setCarregando(false);
      setPropsAlerta(utils.semAlerta);
    } catch (e) {
      setData(dadosIni);
      setPropsAlerta(utils.semAlerta);

      if (user) {
        setPropsAlerta(
          utils.montaAlerta({
            titulo: e.message,
            origem: e.origem,
            tAnimacao: e.tipoAlerta,
          })
        );
      }
    }
  };

  //  Fellipe - 21/12/2023 => Construtor para realizar a requisição do CRLV Digital do PRC043 - Conforme especificação "LummaHub – CRLV Digital" aprovada.
  const downloadCRLV = async ({ placa }) => {
    try {
      setCarregando(true);
      const ret = await api.anexarCRLV({
        placa: placa,
      });

      setPropsAlerta(utils.semAlerta);
    } catch (e) {
      setData(dadosIni);
      setPropsAlerta(
        utils.montaAlerta({
          titulo: e.message,
          origem: e.origem,
          tAnimacao: e.tipoAlerta,
        })
      );
    }
    setCarregando(false);
  };

  return (
    <Tela
      titulo={"CRLV Digital"}
      icone={"pe-7s-car"}
      subTitulo={`Faça o Download do seu CRLV`}
      menu={true}
    >
      <Formik
        onSubmit={() => {}}
        validateOnMount
        initialValues={{
          placa: usuario.placa,
          codGestor: usuario.gestorMaster ? "" : usuario.codGestor,
        }}
      >
        {({ values, validateForm, errors, handleChange, setFieldValue }) => (
          <Tema.CardForm
            style={{
              minHeight: "80px",
              width: "100%",
              alignItems: "center",
              rowGap: "10px",
            }}
          >
            <Tema.CardFormHome>
              <Input
                labelAtivo={true}
                align={"center"}
                label="Placa"
                name={"placa"}
                value={values?.placa}
                onChange={handleChange}
                desabilitado={usuario.placa}
              />
              <Input
                labelAtivo={true}
                align={"center"}
                label="CódigoGestor"
                name={"codGestor"}
                value={values?.codGestor}
                onChange={handleChange}
                desabilitado={!usuario.gestorMaster}
              />

              <ButtonMaterial
                style={Padrao.styleBotaoPequeno}
                size="small"
                onClick={async () => {
                  await consultar({ user: true, values, errors });
                }}
              >
                Pesquisar
              </ButtonMaterial>
            </Tema.CardFormHome>
          </Tema.CardForm>
        )}
      </Formik>

      <Tabela
        columns={columnsMaterialUI}
        carregando={carregando}
        data={data?.Dados}
      />

      <Carregando
        tipo={propsAlerta.tipoAnimacao}
        exibir={propsAlerta.exibir}
        userClose={propsAlerta.usuarioFechar}
        loop={true}
        titulo={propsAlerta.titulo}
        origem={propsAlerta.origem}
        setExibir={() => {
          setPropsAlerta(utils.semAlerta);
        }}
      />
    </Tela>
  );
};

export default Listagem;
