import React, { useEffect, useState } from "react";
import { useDadosGlobalDispatch } from "../../context";
import { useHistory } from "react-router-dom";

import log from "../../../image/Condutor.svg";
import register from "../../../image/Gestor.svg";

import "./style.css";
import { ContactSupportOutlined } from "@material-ui/icons";
import { ClapSpinner } from "react-spinners-kit";

import Modal from "../../layout/modal/modal";
import Contato from "../../pages/contato";
import RecuperarSenha from "../../pages/usuario/recuperarSenha";

import * as Tema from "../../layout/tela/style";

import Fade from "@material-ui/core/Fade";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
//teste
const Login = ({}) => {
  const history = useHistory();

  const [carregando, setCarregando] = useState(true);
  const [login, setLogin] = useState("");
  const [senha, setSenha] = useState("");
  const [abrir, setAbrir] = useState(0);

  const { logar } = useDadosGlobalDispatch();

  useEffect(() => {
    const sign_in_btn = document.querySelector("#sign-in-btn");
    const sign_up_btn = document.querySelector("#sign-up-btn");
    const container = document.querySelector(".container");
    const script = document.createElement("script");

    sign_up_btn.addEventListener("click", () => {
      container.classList.add("sign-up-mode");
      setSenha("");
      setLogin("");
    });

    sign_in_btn.addEventListener("click", () => {
      container.classList.remove("sign-up-mode");
      setSenha("");
      setLogin("");
    });

    script.src = "https://kit.fontawesome.com/64d58efce2.js";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  async function Entrar(tipoLogin) {
    try {
      var erro = new Error();

      if (login == "") erro.message = "Informe o Login";
      if (senha == "") erro.message += "\nInforme a Senha";
      if (erro.message != "") {
        erro.tipo = "valid";

        throw erro;
      }
      setLoading(true);

      var ret = await logar(login, senha, tipoLogin);
      setLoading(false);
      history.push("/home", {});
    } catch (e) {
      setLoading(false);
      alert(e.message);
    }
  }

  const [loading, setLoading] = React.useState(false);

  // const handleClickLoading = () => {
  //   setLoading((prevLoading) => !prevLoading);
  // };

  // const handleClickLoading = () => {
  //   setCarregando({ carregando });
  // };

  return (
    <div className="container">
      <div className="loading">
        {/* <Fade
          in={loading}
          style={{
            transitionDelay: "800ms" ,
          }}
          unmountOnExit
        > */}
        <ClapSpinner
          frontColor="var(--color-primary)"
          backColor="var(--color-secundary)"
          loading={loading}
        />
        {/* </Fade> */}
      </div>
      <div className="forms-container">
        <div className="signin-signup">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              Entrar(1);
              // handleClickLoading();
            }}
            id="formCond"
            className="sign-in-form"
          >
            <h2 className="title">Condutor</h2>
            <div className="input-field">
              <i className="fas fa-user"></i>
              <input
                type="text"
                name={"txtLogin"}
                onChange={(e) => {
                  setLogin(e.target.value);
                }}
                placeholder="Placa"
              />
            </div>
            <div className="input-field">
              <i className="fas fa-lock"></i>
              <input
                type="password"
                name={"txtSenha"}
                onChange={(e) => {
                  setSenha(e.target.value);
                }}
                placeholder="Senha"
              />
            </div>
            <button
              type="submit"
              name={"btnEntrar"}
              className="btn solid"
              // onClick={handleClickLoading}
            >
              Login
            </button>

            {/* Login com as mídias sociais */}
            <div
              className="social-media"
              style={{ width: "90%", justifyContent: "space-evenly" }}
            >
              <p
                className="social-text"
                onClick={() => setAbrir(3)}
                style={{ cursor: "pointer" }}
              >
                Contato
              </p>
              <p
                className="social-text"
                onClick={() => setAbrir(1)}
                style={{ cursor: "pointer" }}
              >
                Esqueci a senha
              </p>
            </div>
            {/* <div className="social-media">
                    <a href="#" className="social-icon">
                        <i className="fab fa-facebook-f"></i>
                    </a>
                    <a href="#" className="social-icon">
                        <i className="fab fa-twitter"></i>
                    </a>
                    <a href="#" className="social-icon">
                        <i className="fab fa-google"></i>
                    </a>
                    <a href="#" className="social-icon">
                        <i className="fab fa-linkedin-in"></i>
                    </a>
                </div> */}
          </form>
          <form
            id="formGest"
            className="sign-up-form"
            onSubmit={(e) => {
              e.preventDefault();
              Entrar(2);
            }}
          >
            <h2 className="title">Gestor</h2>
            <div className="input-field">
              <i className="fas fa-user"></i>
              <input
                type="text"
                placeholder="Usuário"
                onChange={(e) => {
                  setLogin(e.target.value);
                }}
              />
            </div>

            {/* <div className="input-field">
                    <i className="fas fa-envelope"></i>
                    <input type="email" placeholder="Email" />
                </div> */}

            <div className="input-field">
              <i className="fas fa-lock"></i>
              <input
                type="password"
                placeholder="Senha"
                onChange={(e) => {
                  setSenha(e.target.value);
                }}
              />
            </div>
            <input type="submit" className="btn" value="Login" />
            <div
              className="social-media"
              style={{ width: "90%", justifyContent: "space-evenly" }}
            >
              <p
                className="social-text"
                onClick={() => setAbrir(3)}
                style={{ cursor: "pointer" }}
              >
                Contato
              </p>
              <p
                className="social-text"
                onClick={() => setAbrir(2)}
                style={{ cursor: "pointer" }}
              >
                Esqueci a senha
              </p>
            </div>
          </form>
        </div>
      </div>

      <div className="panels-container">
        <div className="panel left-panel">
          <div className="content">
            <h3>Gestor ?</h3>
            <p>
              Acesse a aba de gestor e gerencie a sua frota de veículos completa
            </p>
            <button className="btn transparent" id="sign-up-btn">
              Clique aqui
            </button>
          </div>
          <div className={"div-image"}>
            <img src={log} className="image" alt="" />
          </div>
        </div>
        <div className="panel right-panel">
          <div className="content">
            <h3>Condutor ?</h3>
            <p>
              Veja tudo o que acontece com o seu veículo clicando no botão
              abaixo
            </p>
            <button className="btn transparent" id="sign-in-btn">
              Clique aqui
            </button>
          </div>
          <img
            src={register}
            className="image"
            alt=""
            style={{ paddingLeft: 40 }}
          />
        </div>
      </div>
      <Modal
        abrir={abrir > 0}
        onFechar={() => {
          setAbrir(0);
        }}
        fecharClicarFora={true}
      >
        {/* <div style={{width: abrir == 3 ? '70%' : 'inherit', background:'white', padding: abrir == 3 ? 10 :0, outline:'none', borderRadius:10}}> /// Colocar no StyledComponent e receber a informação do código do Abrir. para montar o css. */}
        {abrir == 3 ? (
          <Tema.CardFormLoginContato>
            <Contato semCabecalho={true} />
          </Tema.CardFormLoginContato>
        ) : (
          <RecuperarSenha
            tipo={abrir}
            onAbrir={() => {
              setAbrir(0);
            }}
          />
        )}
      </Modal>
    </div>
  );
};

export default Login;
