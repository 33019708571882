import React, {useCallback,useState} from 'react';
import { Children } from 'react';

import * as Tema from './style';

const CardDropDown = ({children, sourceImgFundo, titulo, subTitulo, exibir, onBlur, nomeBotaoCabecalho, onBotaoCabecalho }) => {

    const blur= ()=>{
        if(onBlur) onBlur();

    }


    return (
  
        <Tema.DropDownMenu tabindex="0" exibir={exibir}  onBlur={() => {blur()}}>
    
            <Tema.DropDownMenuCabecalho>
            
                <Tema.DropDownMenuCabecalhoInterno>
                    <Tema.DropDownMenuCabecalhoFundoIMG source={sourceImgFundo}  />

                    <Tema.DropDownMenuCabecalhoConteudo >
                        <Tema.DropDownMenuCabecalhoGrupo>
                            <Tema.DropDownMenuCabecalhoGrupoInterno>

                                <Tema.DropDownMenuCabecalhoGrupoLogo>
                                <Tema.DropDownMenuIMG />

                                </Tema.DropDownMenuCabecalhoGrupoLogo>

                                    <Tema.DropDownMenuCabecalhoGrupoTitulo>
                                        <Tema.DropDownMenuTitulo>
                                        {titulo}
                                        {/* {usuario.codGestor ?? usuario.placa} */}
                                        </Tema.DropDownMenuTitulo>
                                        <Tema.DropDownMenuSubTitulo>
                                        {subTitulo}
                                        {/* {usuario.nome ?? (usuario.codGestor ? "Gestor" : "Condutor")} */}
                                        </Tema.DropDownMenuSubTitulo>
                                    </Tema.DropDownMenuCabecalhoGrupoTitulo>

                                    <Tema.DropDownMenuCabecalhoGrupoBotao>
                                        <Tema.DropDownMenuBotao onClick ={(e) => {onBotaoCabecalho(e)}}>
                                            {nomeBotaoCabecalho}
                                        </Tema.DropDownMenuBotao>
                                    </Tema.DropDownMenuCabecalhoGrupoBotao>
                            </Tema.DropDownMenuCabecalhoGrupoInterno>

                        </Tema.DropDownMenuCabecalhoGrupo>
                    </Tema.DropDownMenuCabecalhoConteudo>
                
                </Tema.DropDownMenuCabecalhoInterno>
            
            </Tema.DropDownMenuCabecalho>
            {children}
        </Tema.DropDownMenu>
                                      
  );
};

export default CardDropDown;