
import  * as Padrao from '../../../../style/config'
import { Field } from "formik";
import {formatDataHora} from '../../../../utils'

const Input =({label, name, Component, labelAtivo, value, onChange, desabilitado, type, placeHolder, align, widthAuto, width, minWidth, maxLength})=>{

    const textAlign = align ?? 'left';
    function maxLengthCheck(e) {
      if (type === 'number'){

        if (e.target.value.length > e.target.maxLength)
          e.target.value = e.target.value.slice(0, e.target.maxLength)
      }
      else if(type === 'date'){
        if(e.target.value.length > 10){
          
          var dataSplit = e.target.value.split('-')
          
          var data = `${dataSplit[0].slice(0,4)}-${dataSplit[1].slice(0,2)}-${dataSplit[1].slice(0,2)}`
          
          e.target.value = data
          
        }
      }
    }
    
    return(
        <Field name={name} >
{({ field, form, meta }) => (
        <Padrao.Input
        {...field}
        label={label}
        widthAuto={widthAuto}
        minWidth={minWidth}
    
        width={width}
        size="small"
        variant="outlined"
        value={value}
        onChange={onChange}
        disabled={desabilitado}
        type={type}
        inputProps={{ maxlength:maxLength, onInput:(e) => maxLengthCheck(e), style: { textAlign} }}
        placeholder={placeHolder}
        InputLabelProps={{
            shrink: labelAtivo,
          }}
          helperText={meta.error}
          error={meta.error}
        //   InputProps={{inputComponent: Component,
        //     value: value,
        //     onChange: onChange
        // }}
          />
      )}
        </Field>
    )
};

export default Input