import React, {useState, useCallback} from 'react';

import Chart, {Props}  from "react-apexcharts";

const Grafico =({style, data, colorPrimaria, colorSecundaria, backgroundColor, borderColor , exibirLegenda, exibirOpcoes
    , titulo, dados, labels, onClick})=> { 
     
      const paradas = backgroundColor?.map((x,i)=>{
        return  x.pos
      });
      const cores = backgroundColor?.map((x,i)=>{
        return  x.cor
      });
      const coresStop = backgroundColor?.map((x,i)=>{
        return {
          offset: x.pos,
          color: x.cor,
          opacity: 1
        };
      });
      
    const props: Props={}
    
      
  

    
    props.options = {

      
            chart: {
              type: "donut",
              events:{
                dataPointMouseEnter: function(e) {
                  if (onClick) {
                    e.path[0].style.cursor = "pointer";
                  }
                },
                dataPointSelection: async function(e, chart, option){
                      if (onClick) {
                       return await onClick(option)
                      }
                    
                  }
              },
              // width: 380,
              dropShadow: {
                enabled: false,
                top: 2,
                left: 0,
                color: '#999',
                opacity: 1,
                blur: 2
              }
            },
            plotOptions: {
              pie: {
                startAngle: -90,
                endAngle: 270,

              },


            },
            
              dataLabels: {
                enabled: true,
                formatter: function (val) {
                  return val.toFixed(2) + "%"
                },
              },
              fill: {
                type: 'solid',
              },
              
            series:data,
            labels: labels,
            legend: {
              show: false,
              position: "right",
              offsetX: -30,
              offsetY: -10,
              formatter: function (val, opts) {
                return val + " - " + opts.w.globals.series[opts.seriesIndex] + "%";
              }
            },
            colors:backgroundColor
            
          
      }
    ;

    return (
<React.Fragment>

       
        <div   style={style}>
         

            <Chart 
              options={props.options}
              series={props.options.series}
              type={props.options.chart.type}
              height={330}
              width="100%"

              //  height="100%"
            />


        </div>

</React.Fragment>
        
    
      );

}

export default Grafico;

