import React, {useLayoutEffect} from 'react';
import { useHistory } from 'react-router-dom';
import Spinner from "../../loading/spinner";
const Filtrar = ({url, func, id}) => {
    const history = useHistory();
    useLayoutEffect(() => {
  
        async function carregar(){
            const data = await func({id});
            window.scrollTo(0, 0);
            history.push(url,{data} );

    
        }
        carregar();
        
    },[]);

    return (<Spinner carregando={true}/>)
    
}

export default Filtrar;