import styled from "styled-components";
import { Card, Icone } from "../style";

const media = {
  mobile: "@media(max-width: 900px)",
  desktop: "@media (min-width: 1180px)",
};

export const Container = styled.div`
  background: rgba(255, 255, 255, 0.55);
  height: 80px;
  margin: 0px -30px 15px 0px;
  padding: 5px 15px;
  display: flex;
  align-items: center;
  width: 100%;
  ${media.mobile} {
    width: 100%;
  }
`;

export const IconeFundo = styled(Card)`
  height: 60px;
  width: 60px;
  margin-right: 20px;
  ${(props) => props.onClick && "cursor: pointer;"};
  ${media.mobile} {
    display: none;
  }
`;

export const IconeVoltar = styled(Card)`
  height: 60px;
  width: 60px;
  /* margin-right: 20px; */
  ${(props) => props.onClick && "cursor: pointer;"};
  ${media.mobile} {
    margin-right:0px;
  }
  }
`;

export const IconeTitulo = styled(Icone)`
  font-size: 2rem;
  margin: auto;
  background-image: ${(props) =>
    props.voltar
      ? "linear-gradient(120deg,#c71a1a 0%,#eb7e7e 100%) !important"
      : "linear-gradient(120deg, #fccb90 0%, #d57eeb 100%)"};
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const DivTitulo = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
`;

export const Titulo = styled.span`
  font-size: 1.25rem;
  font-weight: 400;
  text-transform: uppercase;
  color: #495057;
  ${media.mobile} {
    font-size: 1.25rem;
  }
`;

export const SubTitulo = styled.span`
  padding: 3px 0 0;
  font-size: 0.88rem;
  opacity: 0.6;
  ${media.mobile} {
    font-size: 0.88rem;
  }
`;

export const MenuHamburguer = styled.div`
  display: none;

  ${media.mobile} {
    width: 90%;
    display: flex;
    justify-content: flex-end;
  }
`;
