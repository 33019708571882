import React, { useState, useEffect } from "react";
import { Children } from "react";
import { Group, Label, TextSelect } from "./style";

const Select = ({name,label,type,valor,soLeitura,align,autoSize,onChange,onBlur,erro,source, styleGroup, styleCampo
}) => {
  const [isActive, setIsActive] = useState(false);
  const [value, setValue] = useState("");

  useEffect(() => {
    const val = valor ? valor.toString() : "";
    setValue(val);

    setIsActive(val.length > 0);
  }, [valor]);

  const validaAnimacaoLabel = (text) => {
    if (text !== "") {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  };
  const handleTextChange = (text) => {
    setValue(text);

    if (onChange) onChange(text);
  };

  return (
    <Group style={styleGroup}>
      <TextSelect
      style={styleCampo}
        name={name}
        type={type}
        value={value}
        readOnly={soLeitura}
        align={align}
        size={autoSize && value.length + 5}
        onChange={(e) => {
          handleTextChange(e.target.value);
        }}
        onFocus={(e) => {
          validaAnimacaoLabel("s");
        }}
        onBlur={(e) => {
          validaAnimacaoLabel(value);
        if(onBlur)onBlur(e);
    
        }}
      
      >
        {source.map((item, index) => {
                  return <option value={item.valor}>{item.descricao}</option>;
                })}
        </TextSelect>
      <Label htmlFor={name} selecionado={isActive}>
        {label}
      </Label>
    </Group>
  );
};

export default Select;
