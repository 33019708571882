import React, { useEffect, useCallback, useState } from "react";
import { DataGrid, ptBR } from "@material-ui/data-grid";
import {
  createMuiTheme,
  ThemeProvider,
  makeStyles,
} from "@material-ui/core/styles";

import SearchBar from "material-ui-search-bar";

import Painel from "./painel";
import Opcoes from "../../tabela/opcoes";
import * as Tema from "../style";

const useStyles = makeStyles((theme) => ({
  root: {
    border: 0,
    color: "var(--color-secundary)",
    //theme.palette.type === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.85)',
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    WebkitFontSmoothing: "auto",
    letterSpacing: "normal",
    "& .MuiDataGrid-columnsContainer": {
      backgroundColor: "var(--color-primary)", // theme.palette.type === 'light' ? '#fafafa' : '#1d1d1d',
    },
    "& .MuiDataGrid-iconSeparator": {
      display: "none",
    },
    "& .MuiDataGrid-colCell, .MuiDataGrid-cell": {
      borderRight: `1px solid ${
        theme.palette.type === "light" ? "#f0f0f0" : "#303030"
      }`,
    },
    "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
      borderBottom: `1px solid ${
        theme.palette.type === "light" ? "#f0f0f0" : "#303030"
      }`,
    },
    "& .MuiDataGrid-cell": {
      color:
        theme.palette.type === "light"
          ? "rgba(0,0,0,.85)"
          : "rgba(255,255,255,0.65)",
    },
    "& .MuiPaginationItem-root": {
      borderRadius: 0,
    },
    "& .MuiIconButton-label": {
      color: "#f0f0f0",
    },
    "& .MuiSvgIcon-root":{
      fill: "var(--color-primary)",
    },
  },
}));

const theme = createMuiTheme(
  {
    overrides: {
      MuiTypography: {
        h6: {
          fontWeight: "bolder !important",
          color: "#5CDB94",
        },
      },
      MuiButton: {
        textPrimary: {
          color: "var(--color-primary)",
        },
      },
    },
  },
  ptBR
);

export default function Grid({
  columns,
  data,
  carregando,
  ConfigExport,
  onLinhaClique,
}) {
  const [rows, setRows] = useState(data);
  const [searched, setSearched] = useState("");
  const requestSearch = (searchedVal) => {
    const filteredRows = data.filter((row) => {
      return Object.values(row)
        .toString()
        .toLowerCase()
        .includes(searchedVal.toLowerCase());
    });
    setRows(filteredRows);
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  useEffect(() => {
    setRows(data);
  }, [data]);

  return (
    <React.Fragment>
      <Tema.DivTabelaOpcoes>
      <SearchBar
        value={searched}
        onChange={(searchVal) => requestSearch(searchVal)}
        onCancelSearch={() => cancelSearch()}
        placeholder="Buscar"
        style={{
          margin: "1% 1% 1% 1%",
          minWidth: "30%",
          
        }}
      />
        <Opcoes exportConfig={ConfigExport} data={data} />
      </Tema.DivTabelaOpcoes>
      <Tema.DivTabela>
        <ThemeProvider theme={theme}>
          <DataGrid
            columns={columns}
            rows={rows}
            getRowId={(row) => row.Id}
            pageSize={10}
            rowsPerPageOptions={[5, 10, 15, 20, 25, 30, 40, 50]}
            pagination
            autoHeight={true}
            density={"compact"}
            disableSelectionOnClick
            loading={carregando}
            onRowDoubleClick={(param) => onLinhaClique(param.row)}
            scrollbarSize={1}
            components={{ Panel: Painel }}
          />
        </ThemeProvider>
      </Tema.DivTabela>
    </React.Fragment>
  );
}
