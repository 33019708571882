import React from 'react';
import {ChartitGraphLineProps} from 'react-chartist';

import  './style.css'
// import  '../style/plugin.scss'

import * as Tema from './style'

const Grafico =({name,data, labels, height, onClick})=>{
 const [update, setUpdate] = React.useState(false);
    

      var dataGraf =  {
          labels: labels,
          series: data
        };   

        
      let op :ChartitGraphLineProps={
        data: dataGraf,
        type: 'Line',
        options:{
            // high: 10,
        // low: 0,
        showArea: false,
        showLine: true,
        showPoint: false,
        fullWidth: true,
        classNames:{
          series: name + '-series'
        },
        axisX: {
          showLabel: false,
          showGrid: true,
          offset: 0,
        },
        axisY: {
          showGrid: false,
          low: 40,
          showLabel: false,
          offset: 0,
          
          // scaleMinSpace:1
        },
        height:height,
        width: "100%",
        chartPadding:{
          bottom:15
        }
    // plugins: [
    //     pluginLegend({
    //     legendNames: ['Blue pill', 'Red pill', 'Purple pill', 'Teste'],
    //         })
    // ],
    
        },
        
        
    }
    return (
      <div style={{}}>
          <Tema.Grafico data={op.data} options={op.options} type={op.type} 
          name={name} cursorClick = {name + '-series'}
          
          listener={{
            draw:e=>{
              if(e.type === 'line') {
               
              if (onClick){
                
                e.element._node.onclick = event => onClick(e);
                

              }
             
             }
            },
            created: e=>{
              setUpdate(true)
             //  setTamLinha( e.axisX.stepLength * e.axisX.ticks.length)
             
          }
          }}
          />
        </div>
      )
};

export default Grafico