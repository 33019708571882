import styled from "styled-components";

const media = {
  mobile: "@media(max-width: 900px)",
  desktop: "@media (min-width: 1180px)",
};

export const Corpo = styled.div`
  display: flex;
  width: 80%;
  background: transparent;
  height: 340px;
  top: 0;
  align-items: center;
  justify-content: center;
`;
export const DivImg = styled.div`
  z-index: 1;
  height: 100%;
  width: 30%;
  background: var(--color-usuario);
  border-radius: 10px;
  box-shadow: 0 6.46875rem 2.1875rem rgb(185 76 76 / 3%),
    0 0.9375rem 1.40625rem rgb(0 0 0 / 3%), 0 0.25rem 0.53125rem rgb(0 0 0 / 5%),
    0 0.125rem 0.1875rem rgb(0 0 0 / 3%);
`;

export const Img = styled.img`
  width: 100%;
  height: 100%;
`;

export const DivTela = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
  justify-content: flex-start;
  position: relative;
  height: 90%;
  width: 75%;
  background: white;
  margin-left: -19%;
  align-self: center;
  border-radius: 10px;
  margin-top: 15%;
  box-shadow: 0 6.46875rem 2.1875rem rgb(185 76 76 / 3%),
    0 0.9375rem 1.40625rem rgb(0 0 0 / 3%), 0 0.25rem 0.53125rem rgb(0 0 0 / 5%),
    0 0.125rem 0.1875rem rgb(0 0 0 / 3%);
`;

export const DivTitulo = styled.div`
  position: relative;
  z-index: 1;
  margin-top: -13%;
  margin-left: 12%;
  font-size: 35px;
  font-weight: bold;
  text-transform: uppercase;
  font-family: "Poppins";
  color: #131010;
`;

export const DivTexto = styled.div`
  z-index: 1;
  width: 100%;
  display: flex;
  gap: 10px;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: baseline;
  justify-content: space-between;
`;
export const DivConteudo = styled.div`
  display: flex;
  /* justify-content: flex-end; */
  margin-top: 5%;
  margin-left: 27%;
`;
export const DivColuna = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DivCampo = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
`;
export const Icone = styled.i`
  font-size: 35px;
  color: var(--color-cliente);
`;

export const Titulo = styled.span`
  font-weight: 900;
  text-transform: uppercase;
  color: var(--color-cliente);
`;
export const Texto = styled.span`
  font-weight: normal;
  text-transform: inherit;
`;

export const DivAcao = styled.div`
  display: flex;
  margin-left: -10%;
  margin-top: 58%;
`;

/*********************************************/
export const SenhaModal = styled.div`
  background: white;
  outline: none;
  border-radius: 10px;
  min-width: 400px;
  min-height: 255px;
  /* height:50%; */
  ${media.mobile} {
    background: white;
    outline: none;
    border-radius: 10px;
    min-width: 90%;
    min-height: 30%;
  }
`;

export const SenhaCabecalho = styled.div`
  display: flex;
  justify-content: center;
  background: var(--color-primary);
  border-radius: 10px 10px 0px 0px;
  margin-top: 0px;
  padding: 15px;
  height: 50px;
`;

export const SenhaTitulo = styled.span`
  margin: 0;
  color: white;
  text-transform: uppercase;
  font-size: large;
  font-weight: bold;
`;

export const SenhaConteudo = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  padding: 10px;
  gap: 20px;
  height: 60%;
`;
export const SenhaDivInput = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  & input {
    font-size: 14px;
  }
`;
export const SenhaRodape = styled.div`
  display: flex;
  justify-content: flex-end;
  background: transparent;
  border-radius: 0px 0px 10px 10px;
  margin-top: 0px;
  padding: 10px;
  gap: 10px;
`;
