import * as Yup from 'yup';
// import * as valid from '../utils/validacoes'
import * as utils from '../utils'

export default Yup.object().shape({


    codigo:Yup.string().required('Campo obrigatório'),

    

  
  })