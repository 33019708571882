import { retornaGoogleKey } from "../../../API";
import Geocode from "react-geocode";
const apiKEY = retornaGoogleKey();

// set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
Geocode.setApiKey(apiKEY);

// set response language. Defaults to english.
Geocode.setLanguage("pt-BR");

// set response region. Its optional.
// A Geocoding request with region=es (Spain) will return the Spanish city.
Geocode.setRegion("br");

// set location_type filter . Its optional.
// google geocoder returns more that one address for given lat/lng.
// In some case we need one address as response for which google itself provides a location_type filter.
// So we can easily parse the result for fetching address components
// ROOFTOP, RANGE_INTERPOLATED, GEOMETRIC_CENTER, APPROXIMATE are the accepted values.
// And according to the below google docs in description, ROOFTOP param returns the most accurate result.
Geocode.setLocationType("ROOFTOP");

// Enable or disable logs. Its optional.
Geocode.enableDebug();

export const retornaEndereco = ({ latitude, longitude }) => {
  let retorno = {};
  try {
    // Get address from latitude & longitude.
    Geocode.fromLatLng(latitude, longitude).then(
      (response) => {
        retorno = response.results[0].formatted_address;
      },
      (error) => {
        throw new Error(error);
      }
    );
  } catch (e) {
    throw e;
  }

  return retorno;
};

export const retornaLocation = async ({ endereco }) => {
  if (!endereco) return {};
  let retorno = {};
  try {
    // Get address from latitude & longitude.
    const response = await Geocode.fromAddress(endereco.endereco);
    // const response = await Geocode.fromAddress(endereco);
    // console.log(response)
    retorno = response.results[0].geometry.location;
  } catch (e) {
    throw e;
  }

  return retorno;
};
