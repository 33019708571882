import React, { useLayoutEffect, useCallback, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";

import { Formik } from "formik";
import TimeLine from "../../layout/timeline";
import DadosCondutor from "./DadosCondutor";
import DownloadTermo from "./DownloadTermo";
import UploadTermo from "./UploadTermo";
import { Carregando } from "../../loading";

import Tela from "../../layout/tela";
import * as Padrao from "../../../style/config";

import schema from "../../../schemas/indicacao";
import * as api from "../../../API/consulta";
import * as utils from "../../../utils";

export default function Indicacao() {
  const history = useHistory();
  const location = useLocation();
  const dataUrl = location.state;
  const [data, setData] = useState(null);
  const [propsAlerta, setPropsAlerta] = useState(utils.propsAlerta);

  const [etapas, setEtapas] = useState(1);
  const nomeEtapas = ["DadosCondutor", "DadosDownload", "DadosUpload"];
  const SubTitulo = [
    "Dados do Condutor",
    "Download do Termo",
    "Upload dos Arquivos",
  ];

  //Função para voltar para a tela de notificação
  const voltar = useCallback(
    (data) => {
      window.scrollTo(0, 0);

      history.push("/notificacao", {
        data,
      });
    },
    [history]
  );

  useLayoutEffect(() => {
    if (!dataUrl) {
      voltar();
      return;
    }

    async function funcao() {
      // const dados = dataUrl ? dataUrl.data :  await api.filtraNotificacao({id});
      const dados = dataUrl.data;
      await setData(dados?.data);
    }
    funcao();
  }, [dataUrl, voltar]);

  const validarProximo = ({ erros }) => {
    if (erros?.[nomeEtapas[etapas - 1]]) return false;

    return true;
  };

  const finalizar = async ({ data }) => {
    try {
      window.scrollTo(0, 0);

      history.push("/notificacaoFinalizacao", {
        data,
      });
    } catch (error) {}
  };
  const navegacao = async ({ proximo, values, erros }) => {
    try {
      if (proximo) {
        if (!validarProximo({ erros })) return false;
        if (etapas == 3) {
          const obj = utils.montaAlerta({
            titulo: "Processando a sua indicação",
            tAnimacao: utils.animacoes.carregando,
          });
          setPropsAlerta(obj);
          const ret = await api.uploadsIndicacao({
            dados: values?.DadosCondutor,
            dadosNotificacao: data,
            uploads: values?.Uploads,
          });
          finalizar({ data: ret });
        } else {
          setEtapas(etapas + 1);
        }
      } else {
        setEtapas(etapas - 1);
      }
    } catch (e) {
      setPropsAlerta(
        utils.montaAlerta({
          titulo: e.message,
          origem: e.origem,
          tAnimacao: e.tipoAlerta,
        })
      );
    }
  };

  return (
    <Tela
      titulo={"Indicação"}
      icone={"pe-7s-car"}
      subTitulo={`Etapa - ${SubTitulo[etapas - 1]}`}
      menu={true}
    >
      <Formik
        validationSchema={schema}
        onSubmit={() => {}}
        validateOnMount
        initialValues={{}}
      >
        {({ values, validateForm, errors, handleChange, setFieldValue }) => (
          <React.Fragment>
            {etapas == 1 ? (
              <DadosCondutor
                onChange={handleChange}
                data={values}
                dadosNot={data}
                erros={errors}
              />
            ) : etapas == 2 ? (
              <DownloadTermo
                onChange={handleChange}
                data={values}
                dadosNot={data}
                erros={errors}
              />
            ) : etapas == 3 ? (
              <UploadTermo
                onChange={handleChange}
                data={values}
                dadosNot={data}
                erros={errors}
                setField={setFieldValue}
              />
            ) : null}

            <Padrao.Navegacao>
              <Padrao.Botao
                size="small"
                principal={false}
                style={{
                  visibility: etapas == 1 ? "hidden" : "visible",
                  background: "white",
                  color: "red",
                }}
                onClick={async () => {
                  await navegacao({ proximo: false, values, erros: errors });
                }}
              >
                Voltar
              </Padrao.Botao>

              <Padrao.Botao
                size="small"
                principal={true}
                onClick={async () => {
                  // const erros = Object.entries(errors).length === 0  ? await validateForm() : errors;
                  await navegacao({ proximo: true, values, erros: errors });
                }}
              >
                Próximo
              </Padrao.Botao>
            </Padrao.Navegacao>
          </React.Fragment>
        )}
      </Formik>

      <Carregando
        tipo={propsAlerta.tipoAnimacao}
        exibir={propsAlerta.exibir}
        userClose={propsAlerta.usuarioFechar}
        loop={propsAlerta.loop}
        titulo={propsAlerta.titulo}
        origem={propsAlerta.origem}
        setExibir={() => {
          setPropsAlerta(utils.semAlerta);
        }}
        // onFinish={() => {finalizar()}}
        // styleFundo={{width:'90%', height:'90%'}}
        // styleAnimacao={{width:'70%'}}
      />
    </Tela>
  );
}
