import styled from "styled-components";

import { Link } from 'react-router-dom';

export const Container = styled.div`

width: 100%;
padding: 2px 1.0rem 1.0rem;
color: rgba(255, 255, 255, 0.7);

& a:hover{
     cursor: pointer;
     color: white;
     background: rgba(255, 255, 255, 0.1);
 }
`
export const TituloMenu = styled.h5`
width: 100%;
text-transform: uppercase;
font-size: 0.8rem;
margin: 0.75rem 0;
font-weight: bold;
/* color: #033C73; */
white-space: nowrap;
position: relative;
line-height: 1.2;
`
export const MenuDiv = styled.div`
position: relative;
*, *::before, *::after {
    box-sizing: border-box;
}
`


export const MenuContainer = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;


`

export const MenuLista = styled.li`
    margin: 0;
    padding: 0;
    position: relative;

`


export const MenuLink = styled.a`
    font-size: 0.88rem;
    transition: transform 0.2s, height 300ms, color 300ms, background-color 300ms, -webkit-transform 0.2s;
    display: block;
    line-height: 2.5rem;
    height: 2.5rem;
    padding: 0 1.5rem 0 45px;
    position: relative;
    border-radius: 0.25rem;
    white-space: nowrap;

   
`

export const MenuIcone = styled.i`
text-align: center;
    width: 34px;
    height: 34px;
    line-height: 34px;
    position: absolute;
    left: 2%;
    top: 50%;
    margin-top: -17px;
    font-size: 1.5rem;
    transition: color 300ms;
    opacity: 0.5;

    &.esquerda{
        text-align: center;
        width: 34px;
        height: 34px;
        line-height: 34px;
        position: absolute;
        top: 50%;
        margin-top: -17px;
        font-size: 1.5rem;
        transition: transform 300ms, -webkit-transform 300ms;
        left: auto;
        right: 0;
        &.visible{
            transform: rotate(-180deg);
        }
    }
`

export const MenuListaOpcoes = styled.ul`
    list-style: none;
    margin: 0;
    position: relative;
    visibility: hidden;
    transition: padding 300ms;

    &.visible {
        /* padding: 0.5em 0 0 2rem; */
        padding: .5em 0 0 1.3rem;
        visibility: visible !important;
    }
    &.visible:before{
        content: '';
        height: 100%;
        opacity: 1;
        width: 3px;
        background: rgba(255, 255, 255, 0.3);
        position: absolute;
        left: 20px;
        top: 0;
        border-radius: 15px;
    }

`

export const MenuItem = styled.a`
    font-size: 0.88rem;
    height: 2.3em;
    line-height: 2.3em;
    overflow: hidden;
    padding-left: 1em;
    transition: transform .2s, height 300ms, color 300ms, background-color 300ms, -webkit-transform .2s;
    display: block;
    padding: 0 1.5rem 0 45px;
    position: relative;
    border-radius: 0.25rem;
    white-space: nowrap;
    outline: none !important;
    text-decoration: none;
    background-color: transparent;
    padding-left: 1em;
    
`


export const ItemLink = styled(Link)`
    color: unset;
    text-decoration: none;
` 
