import { Modal } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';

import * as Tema from '../../../style/config'
const useStyles = makeStyles((theme) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }));

const ModalHub =({abrir, onFechar, children, fecharClicarFora})=>{
    const classes = useStyles();
    return(
        <Tema.ModalHub
        className={classes.modal}
  open={abrir}
  onClose={() =>{onFechar()}}
  closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        disableBackdropClick={!fecharClicarFora}
        disableEscapeKeyDown={true}
>

  {children}
</Tema.ModalHub>
    )
};

export default ModalHub