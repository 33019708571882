import styled from "styled-components";
import Tabs from "react-responsive-tabs";
// import 'react-responsive-tabs/styles.css';
import "./style.css";
const media = {
  mobile: "@media(max-width: 900px)",
  desktop: "@media (min-width: 1180px)",
};

export const Tab = styled(Tabs)``;

export const DivPainel = styled.div`
  padding: 15px;
  flex: auto;

  ${media.mobile} {
    padding: 15px 10px;
  }
`;
