import React, { useEffect, useCallback, useState } from "react";
import Helmet from "react-helmet";
import { useHistory } from "react-router-dom";

import Tela from "../../layout/tela";
import Tabela from "../../layout/tabela/dataGrid";

import * as Tema from "../../layout/tela/style";
import * as Padrao from "../../../style/config";
import Input from "../../layout/tela/campos/inputUI";
import ButtonMaterial from "@material-ui/core/Button";
import { Formik } from "formik";
import schema from "../../../schemas/dashboard";
import Grafico from "../../layout/grafico/graficoDash";

import * as TemaTab from "../../layout/tabela/style";
import { Carregando } from "../../loading";

import * as api from "../../../API/consulta";
import * as utils from "../../../utils";
import { useDadosGlobal } from "../../context";
import { parse } from "dotenv";
import { upperCase } from "lodash";

const dataAtual = new Date();
const getMonth = dataAtual.getMonth() + 1;
const mesAtual =
  getMonth < 10 ? "0" + getMonth.toString() : getMonth.toString();
const dtIni = dataAtual.getFullYear().toString() + "-01-01";
const dtFim = utils.formatDataHora(dataAtual, "yyyy-MM-dd");

const dadosIni = { Dados: [], Grafico: null };

const Listagem = ({}) => {
  const history = useHistory();
  const { usuarioState } = useDadosGlobal();
  const { usuario } = usuarioState;
  const [data, setData] = useState(dadosIni);
  const [carregando, setCarregando] = useState(true);
  const [propsAlerta, setPropsAlerta] = useState(utils.propsAlerta);

  const [filtros, setFiltros] = useState({
    placa: usuario.placa,
    dataIni: dtIni,
    dataFim: dtFim,
    codGestor: usuario.codGestor,
    gestorMaster: usuario.gestorMaster,
  });

  const link1ComprovanteLicenciamento =
    process.env.REACT_APP_URL_LICENSING_DOC_1;
  const link2ComprovanteLicenciamento =
    process.env.REACT_APP_URL_LICENSING_DOC_2;
  const link3ComprovanteLicenciamento =
    process.env.REACT_APP_URL_LICENSING_DOC_3;

  const abrirNovaAba = ({ url }) => {
    console.log("clicou para abrir =>" + url);

    const win = window.open(url, "_blank");
    win.focus();
  };

  const columnsMaterialUI = [
    {
      field: "CodFlowExecute",
      headerName: "CodFlowExecute",
      type: "string",
      headerClassName: "super-app-theme--header",
      flex: 1,
    },

    {
      field: "Placa",
      headerName: "Placa",
      type: "string",
      headerClassName: "super-app-theme--header",
      flex: 1,
      hide: !usuario.codGestor,
    },

    {
      field: "AnoExercicio",
      headerName: "Ano Exercício",
      type: "string",
      headerClassName: "super-app-theme--header",
      flex: 1,
    },

    {
      field: "StatusProcess",
      headerName: "Status",
      type: "string",
      headerClassName: "super-app-theme--header",
      flex: 1,
    },

    {
      field: "Documentos",
      headerName: "Documentos",
      type: "string",
      headerClassName: "super-app-theme--header",
      flex: 1,
      align: "center",
      renderCell: (params) =>
        ["CONCLUÍDO", "CRLV EMITIDO"].includes(
          params.row.StatusProcess.toUpperCase()
        ) ? (
          <strong>
            <TemaTab.Icone
              className={"pe-7s-cloud-download"}
              onClick={async () => {
                await downloadCRLVLicenciamento({
                  placa: params.row.Placa,
                  codFlowExecute: params.row.CodFlowExecute,
                });
              }}
            />
          </strong>
        ) : null,
    },
  ];

  const columnsMaterialUIGestorMaster = [
    {
      field: "CodFlowExecute",
      headerName: "CodFlowExecute",
      type: "string",
      headerClassName: "super-app-theme--header",
      flex: 1,
    },

    {
      field: "CodGestor",
      headerName: "Código Gestor",
      type: "string",
      headerClassName: "super-app-theme--header",
      flex: 1,
    },

    {
      field: "Placa",
      headerName: "Placas",
      type: "string",
      headerClassName: "super-app-theme--header",
      flex: 1,
      hide: !usuario.codGestor,
    },

    {
      field: "AnoExercicio",
      headerName: "Ano Exercício",
      type: "string",
      headerClassName: "super-app-theme--header",
      flex: 1,
    },

    {
      field: "StatusProcess",
      headerName: "Status",
      type: "string",
      headerClassName: "super-app-theme--header",
      flex: 1,
    },

    {
      field: "download",
      headerName: "Download",
      type: "string",
      headerClassName: "super-app-theme--header",
      flex: 1,
      align: "center",
      renderCell: (params) =>
        ["CONCLUÍDO", "CRLV EMITIDO"].includes(
          params.row.StatusProcess.toUpperCase()
        ) ? (
          <strong>
            <TemaTab.Icone
              className={"pe-7s-cloud-download"}
              onClick={async () => {
                await downloadCRLVLicenciamento({
                  placa: params.row.Placa,
                  codFlowExecute: params.row.CodFlowExecute,
                });
              }}
            />
          </strong>
        ) : null,
    },
  ];

  const ConfigExport = {
    CSV: {
      nomeArquivo: `Licenciamento${usuario?.placa ?? usuario?.codGestor}`,
      exibir: true,
      colunas: [
        { titulo: "Código Gestor", name: "CodGestor" },
        { titulo: "Placa", name: "Placa" },
        { titulo: "Ano Exercício", name: "AnoExercicio" },
        { titulo: "Status", name: "StatusProcess" },
      ],
    },
  };

  useEffect(() => {
    async function carregar() {
      try {
        await consultar({ user: false, values: filtros, errors: {} });
      } catch (e) {
        setPropsAlerta(
          utils.montaAlerta({
            titulo: e.message,
            origem: e.origem,
            tAnimacao: e.tipoAlerta,
          })
        );
      }
    }
    carregar();
  }, []);

  const consultar = async ({ user, values, errors }) => {
    try {
      if (Object.entries(errors).length > 0) return;
      setCarregando(true);
      if (user) setFiltros(values);
      await setData(dadosIni);
      const ret = await api.retornaLicenciamentos({
        placa: values?.placa,
        codGestor: values?.codGestor,
        gestorMaster: usuario.gestorMaster,
        dataIni: dtIni,
        dataFim: dtFim,
      });

      await setData(ret);
      setCarregando(false);
      setPropsAlerta(utils.semAlerta);
    } catch (e) {
      setData(dadosIni);
      setPropsAlerta(utils.semAlerta);

      if (user) {
        setPropsAlerta(
          utils.montaAlerta({
            titulo: e.message,
            origem: e.origem,
            tAnimacao: e.tipoAlerta,
          })
        );
      }
    }
  };

  const anexarCRLV = async ({ user, values, errors }) => {
    try {
      if (Object.entries(errors).length > 0) return;
      setCarregando(true);
      if (user) setFiltros(values);

      const ret = await api.anexarCRLV({
        placa: values?.placa,
      });

      setPropsAlerta(utils.semAlerta);
    } catch (e) {
      setData(dadosIni);
      setPropsAlerta(utils.semAlerta);

      if (user) {
        setPropsAlerta(
          utils.montaAlerta({
            titulo: e.message,
            origem: e.origem,
            tAnimacao: e.tipoAlerta,
          })
        );
      }
    }
    setCarregando(false);
  };

  //  Fellipe - Download do CRLV baseado no fluxo do CRLV Digital (PRC043 - CRLV Digital)
  const downloadCRLV = async ({ placa }) => {
    try {
      setCarregando(true);
      const ret = await api.anexarCRLV({
        placa: placa,
      });

      setPropsAlerta(utils.semAlerta);
    } catch (e) {
      setData(dadosIni);
      setPropsAlerta(
        utils.montaAlerta({
          titulo: e.message,
          origem: e.origem,
          tAnimacao: e.tipoAlerta,
        })
      );
    }
    setCarregando(false);
  };

  //  Fellipe - 10/01/2023 => Download do CRLV baseado no fluxo do Licenciamento (PRC025 - Licenciamento)
  const downloadCRLVLicenciamento = async ({ placa, codFlowExecute }) => {
    try {
      // Setando um "Loading" para que o usuário aguarde.
      setPropsAlerta(
        utils.montaAlerta({
          titulo: "Buscando CRLV. Por favor aguarde",
          origem: "Licenciamento - CRLV",
          tAnimacao: utils.animacoes.carregando,
        })
      );

      // Fellipe - 20/12/2023 => Chamando o método para recuperar o CRLV do PRC043 - Conforme especificação "LummaHub – CRLV Digital" aprovada.
      const ret = await api.anexarCRLVLicenciamento({
        placa: placa,
        codFlowExecute: codFlowExecute,
      });

      // Removendo o alerta.
      setPropsAlerta(utils.semAlerta);
    } catch (e) {
      setData(dadosIni);
      setPropsAlerta(
        utils.montaAlerta({
          titulo: e.message,
          origem: e.origem,
          tAnimacao: e.tipoAlerta,
        })
      );
    }
    setCarregando(false);
  };

  //  Fellipe - 13/01/2023 => Download baseado no GestorMaster, quando exibido a tabela, vamos fazer um "compilado" dos documentos baseado no código do gestor
  const downloadCRLVGM = async ({ codGestor }) => {
    try {
      setCarregando(true);
      const ret = await api.anexarCRLVGestor({
        codGestor: codGestor,
      });

      setPropsAlerta(utils.semAlerta);
    } catch (e) {
      setData(dadosIni);
      setPropsAlerta(
        utils.montaAlerta({
          titulo: e.message,
          origem: e.origem,
          tAnimacao: e.tipoAlerta,
        })
      );
    }
    setCarregando(false);
  };

  //  Fellipe - Download da Planilha do Gestor
  const downloadPlanilha = async ({ codGestor }) => {
    try {
      setCarregando(true);
      const ret = await api.downloadPlanilha({
        codGestor: codGestor,
      });

      setPropsAlerta(utils.semAlerta);
    } catch (e) {
      setData(dadosIni);
      setPropsAlerta(
        utils.montaAlerta({
          titulo: e.message,
          origem: e.origem,
          tAnimacao: e.tipoAlerta,
        })
      );
    }
    setCarregando(false);
  };

  //  Fellipe - Download dos CRLV ano Vigente
  const downloadCrlvVigente = async ({ user, values, errors }) => {
    try {
      if (Object.entries(errors).length > 0) return;
      setCarregando(true);
      if (user) setFiltros(values);

      setPropsAlerta(
        utils.montaAlerta({
          titulo: "Buscando CRLV. Por favor aguarde",
          origem: "Licenciamento - CRLV",
          tAnimacao: utils.animacoes.carregando,
        })
      );

      const ret = await api.downloadCrlvVigente({
        placa: values?.placa,
        codGestor: values?.codGestor,
        gestorMaster: usuario.gestorMaster,
      });

      setPropsAlerta(utils.semAlerta);
    } catch (e) {
      setData(dadosIni);
      setPropsAlerta(
        utils.montaAlerta({
          titulo: e.message,
          origem: e.origem,
          tAnimacao: e.tipoAlerta,
        })
      );
    }
    setCarregando(false);
  };

  const filtrarLicenciamentoConcluido = async ({ tipo }) => {
    try {
      const data = await api.retornaDetLicenciamentoConcluido({
        placa: filtros?.placa,
        codGestor: filtros?.codGestor,
        gestorMaster: usuario.gestorMaster,
        tipo: upperCase(tipo),
      });
      const ret = {
        dados: data,
        titulo: "Status Licenciamento",
        opcoes: [tipo, tipo == "Concluído" ? "Pendente" : "Concluído"],
      };
      return ret;
    } catch (e) {
      setPropsAlerta(
        utils.montaAlerta({
          titulo: e.message,
          origem: e.origem,
          tAnimacao: e.tipoAlerta,
        })
      );
    }
  };

  const filtrarLicenciamentoStatus = async ({ tipo }) => {
    try {
      const data = await api.retornaDetLicenciamentoStatus({
        placa: filtros?.placa,
        codGestor: filtros?.codGestor,
        gestorMaster: usuario.gestorMaster,
        tipo: upperCase(tipo),
      });

      const opt2 =
        tipo == "Licenciamento inicializado"
          ? "CRLV Emitido"
          : tipo == "CRLV Emitido"
          ? "Concluído"
          : "Licenciamento inicializado";
      const opt3 =
        opt2 == "Licenciamento inicializado"
          ? "CRLV Emitido"
          : opt2 == "CRLV Emitido"
          ? "Concluído"
          : "Licenciamento inicializado";

      const ret = {
        dados: data,
        titulo: "Status Licenciamento",
        opcoes: [tipo, opt2, opt3],
      };
      return ret;
    } catch (e) {
      setPropsAlerta(
        utils.montaAlerta({
          titulo: e.message,
          origem: e.origem,
          tAnimacao: e.tipoAlerta,
        })
      );
    }
  };

  const onLinhaClique = useCallback(
    (data, event) => {
      window.scrollTo(0, 0);

      history.push("/licenciamentodetalhe", {
        data,
      });
    },
    [history]
  );

  return (
    <Tela
      titulo={"Licenciamento"}
      icone={"pe-7s-car"}
      subTitulo={"Veja abaixo todos os Licenciamentos"}
      menu={true}
    >
      <Formik
        validationSchema={schema}
        onSubmit={() => {}}
        validateOnMount
        initialValues={{
          placa: usuario.placa,
          dataIni: dtIni,
          dataFim: dtFim,
          codGestor: usuario.gestorMaster ? "" : usuario.codGestor,
        }}
      >
        {({ values, validateForm, errors, handleChange, setFieldValue }) => (
          <Tema.CardForm
            style={{
              minHeight: "80px",
              width: "100%",
              alignItems: "center",
              rowGap: "10px",
            }}
          >
            <Tema.CardFormHome>
              {/* <Input
                align={"center"}
                label="Data Início"
                name={"dataIni"}
                type={"date"}
                max={"2999-12-31"}
                labelAtivo={true}
                value={values?.dataIni}
                onChange={handleChange}
              />
              <Input
                align={"center"}
                label="Data Fim"
                name={"dataFim"}
                type={"date"}
                max={"2999-12-31"}
                labelAtivo={true}
                value={values?.dataFim}
                onChange={handleChange}
              /> */}
              <Input
                labelAtivo={true}
                align={"center"}
                label="Placa"
                name={"placa"}
                value={values?.placa}
                onChange={handleChange}
                desabilitado={usuario.placa}
              />
              <Input
                labelAtivo={true}
                align={"center"}
                label="CódigoGestor"
                name={"codGestor"}
                value={values?.codGestor}
                onChange={handleChange}
                desabilitado={!usuario.gestorMaster}
              />
              <ButtonMaterial
                style={Padrao.styleBotaoPequeno}
                size="small"
                onClick={async () => {
                  await consultar({ user: true, values, errors });
                }}
              >
                Pesquisar
              </ButtonMaterial>

              <ButtonMaterial
                style={{
                  background: "var(--color-primary)",
                  color: "white",
                  fontfamily: "Roboto",
                  fontweight: 400,
                  width: "160px",
                  height: "40px",
                  borderradius: "10px",
                  cursor: "pointer",
                  transition: "transform 0.3s ease",
                  display: !usuario.gestorMaster ? "none" : "block",
                }}
                size="small"
                onClick={async () => {
                  await downloadPlanilha({ codGestor: usuario?.codGestor });
                }}
              >
                Download Planilha
              </ButtonMaterial>
            </Tema.CardFormHome>
            <div
              style={{
                display: !usuario.gestorMaster ? "none" : "flex",
              }}
            >
              <Tema.CardFormHome>
                <p>
                  <span
                    style={{
                      color: "red",
                      fontWeight: "bolder",
                    }}
                  >
                    *
                  </span>{" "}
                  Para obter todos os CRLV do <b>ano vigente</b>, baseado no
                  filtro, clique{" "}
                  <a
                    href="#"
                    onClick={async () => {
                      await downloadCrlvVigente({
                        user: true,
                        values,
                        errors,
                      });
                    }}
                  >
                    aqui
                  </a>
                </p>
              </Tema.CardFormHome>
            </div>
            <div
              style={{
                display: !usuario.gestorMaster ? "none" : "flex",
              }}
            >
              <Tema.CardFormHome>
                <p>
                  <span
                    style={{
                      color: "red",
                      fontWeight: "bolder",
                    }}
                  >
                    *
                  </span>{" "}
                  Comprovantes de pagamento <b>Exercício Atual</b>, verifique
                  nos links abaixo:
                </p>
              </Tema.CardFormHome>
            </div>
            <div
              style={{
                display: !usuario.gestorMaster ? "none" : "flex",
              }}
            >
              <Tema.CardFormHome>
                <ButtonMaterial
                  style={{
                    background: "var(--color-primary)",
                    color: "white",
                    fontfamily: "Roboto",
                    fontweight: 400,
                    width: "160px",
                    height: "40px",
                    borderradius: "10px",
                    cursor: "pointer",
                    transition: "transform 0.3s ease",
                    display: !usuario.gestorMaster ? "none" : "block",
                  }}
                  size="small"
                  onClick={async () => {
                    abrirNovaAba({ url: link1ComprovanteLicenciamento });
                  }}
                >
                  LINK 1
                </ButtonMaterial>
                <ButtonMaterial
                  style={{
                    background: "var(--color-primary)",
                    color: "white",
                    fontfamily: "Roboto",
                    fontweight: 400,
                    width: "160px",
                    height: "40px",
                    borderradius: "10px",
                    cursor: "pointer",
                    transition: "transform 0.3s ease",
                    display: !usuario.gestorMaster ? "none" : "block",
                  }}
                  size="small"
                  onClick={async () => {
                    abrirNovaAba({ url: link2ComprovanteLicenciamento });
                  }}
                >
                  LINK 2
                </ButtonMaterial>
                <ButtonMaterial
                  style={{
                    background: "var(--color-primary)",
                    color: "white",
                    fontfamily: "Roboto",
                    fontweight: 400,
                    width: "160px",
                    height: "40px",
                    borderradius: "10px",
                    cursor: "pointer",
                    transition: "transform 0.3s ease",
                    display: !usuario.gestorMaster ? "none" : "block",
                  }}
                  size="small"
                  onClick={async () => {
                    abrirNovaAba({ url: link3ComprovanteLicenciamento });
                  }}
                >
                  LINK 3
                </ButtonMaterial>
              </Tema.CardFormHome>
            </div>
          </Tema.CardForm>
        )}
      </Formik>

      <div
        style={{
          height: "100%",
          width: "100%",
          marginTop: -20,
          marginBottom: -20,
          display: usuario?.tipo == 1 ? "none" : "flex",
          flexDirection: "column",
        }}
      >
        <Tema.DivLinhaTotais>
          <div
            style={{
              margin: "0px -59px",
              filter: "drop-shadow(0 20px 8px rgba(0, 0, 0, .3))",
            }}
          >
            <Grafico
              idGrafico={utils.graficos.licenciamentoConcluido}
              data={data?.Grafico}
              onPesquisar={async (tipo) => {
                return await filtrarLicenciamentoConcluido({ tipo });
              }}
              onClick={async (e) => {
                const tipo = "Concluído";
                return await filtrarLicenciamentoConcluido({ tipo });
              }}
              onClickLinhaTabela={async (row, e) => {
                const url = "/licenciamentofiltrar/" + row?.Id?.toString();
                await abrirNovaAba({ url });
              }}
            />
          </div>
          <div
            style={{
              margin: "0px -59px",
              filter: "drop-shadow(0 20px 8px rgba(0, 0, 0, .3))",
            }}
          >
            <Grafico
              idGrafico={utils.graficos.licenciamentoPendente}
              data={data?.Grafico}
              onPesquisar={async (tipo) => {
                return await filtrarLicenciamentoConcluido({ tipo });
              }}
              onClick={async (e) => {
                const tipo = "Pendente";
                return await filtrarLicenciamentoConcluido({ tipo });
              }}
              onClickLinhaTabela={async (row, e) => {
                const url = "/licenciamentofiltrar/" + row?.Id?.toString();
                await abrirNovaAba({ url });
              }}
            />
          </div>
          <div
            style={{
              margin: "0px -59px",
              filter: "drop-shadow(0 20px 8px rgba(0, 0, 0, .3))",
            }}
          >
            <Grafico
              idGrafico={utils.graficos.licenciamentoStatus}
              data={data?.Grafico}
              onPesquisar={async (tipo) => {
                return await filtrarLicenciamentoStatus({ tipo });
              }}
              onClick={async (e) => {
                const tipo = e?.w?.config?.labels[e?.dataPointIndex];
                return await filtrarLicenciamentoStatus({ tipo });
              }}
              onClickLinhaTabela={async (row, e) => {
                const url = "/licenciamentofiltrar/" + row?.Id?.toString();
                await abrirNovaAba({ url });
              }}
            />
          </div>
        </Tema.DivLinhaTotais>
      </div>

      <Tabela
        columns={
          usuario?.gestorMaster
            ? columnsMaterialUIGestorMaster
            : columnsMaterialUI
        }
        carregando={carregando}
        data={data?.Dados}
        ConfigExport={ConfigExport}
        onLinhaClique={onLinhaClique} // Fellipe - 16/01/2022 => Não vou colocar o detalhe neste momento, pois temos os três níveis de acesso.
      />

      {/* <Tema.CardForm
            style={{
              minHeight: "80px",
              width: "100%",
              alignItems: "flex-start",
              rowGap: "10px",
              display: !usuario?.gestorMaster ? "none" : "block",
            }}
          >
            <Tema.CardFormHome>
{console.log("Data:" + data + " || Data.Dados: " + data?.Dados + " || Placa: " + data?.Dados.placa + " || values?.codGestor: "
+ values?.codGestor + " || values?.placa: " + values?.placa)}
                <p>* Para obter todos os CRLV do ano vigente, clique <a href="#" onClick={async () => {
                  await downloadCrlvVigente({ 
                    Placa: data?.Dados.placa, 
                    CodGestor: data?.Dados.codGestor,
                    gestorMaster: usuario?.gestorMaster
                   });
                }}>aqui</a></p>
                

            </Tema.CardFormHome>
          </Tema.CardForm> */}

      <Carregando
        tipo={propsAlerta.tipoAnimacao}
        exibir={propsAlerta.exibir}
        userClose={propsAlerta.usuarioFechar}
        loop={true}
        titulo={propsAlerta.titulo}
        origem={propsAlerta.origem}
        setExibir={() => {
          setPropsAlerta(utils.semAlerta);
        }}
        // styleFundo={{width:'90%', height:'90%'}}
        // styleAnimacao={{width:'70%'}}
      />
    </Tela>
  );
};

export default Listagem;
