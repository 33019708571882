import React from "react";

import Routes from "../../../../routes/routes.privada";
import { Container } from "./style";

const ColunaCentro = ({ isLoading, children }) => {
  return (
    <Container className="colum-c">
      <Routes />
    </Container>
  );
};

export default ColunaCentro;
