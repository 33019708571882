import React from "react";

import { Container } from "./style";

import Menu from "../../../menu";

const ColunaEsquerda = ({ isLoading, isMenuVisible }) => {
  const [hoverMenu, setHoverMenu] = React.useState(false);
  return (
    <Container className="colum-e" isMenuVisible={isMenuVisible }>
      <Menu
        isMenuVisible={isMenuVisible}
        hoverMenu={hoverMenu}
        onHoverMenu={setHoverMenu}
      />
    </Container>
  );
};

export default ColunaEsquerda;
