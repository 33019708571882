import React, { useEffect, useState } from "react";

import { useHistory } from "react-router-dom";

import { Formik } from "formik";
import schema from "../../../schemas/dashboard";

import * as Tema from "../../layout/tela/style";
import * as Padrao from "../../../style/config";

import Tela from "../../layout/tela";
import { Carregando } from "../../loading";
// import Input from "../../layout/tela/campos/input";
import Input from "../../layout/tela/campos/inputUI";

import { useDadosGlobal } from "../../context";

import * as api from "../../../API/consulta";
import * as utils from "../../../utils";
import CardDashboard from "../../layout/grafico/cardDashboard";
import TabPanel from "../../layout/tabPanel";
import Panel from "../../layout/tabPanel/panel";
import ButtonMaterial from "@material-ui/core/Button";
import Select from "../../layout/tela/campos/selectUI";

import Grafico from "../../layout/grafico/graficoDash";

import GrafLine from "../../layout/grafico/chartist/line";

const dataAtual = new Date();
const dtIni = dataAtual.getFullYear().toString() + "-01-01";
const dtFim = utils.formatDataHora(dataAtual, "yyyy-MM-dd");
const tipoDataFiltro = api.tipoDataFiltro;

// const coresTopPlaca= ['#480048', '#051937','#A8EB12','#414d0b', '#DE6262','#0ABFBC']

const Home = ({}) => {
  const { usuario } = useDadosGlobal();
  const [propsAlerta, setPropsAlerta] = useState(utils.propsAlerta);
  const [data, setData] = useState(null);
  const [filtros, setFiltros] = useState({
    placa: usuario.placa,
    dataIni: dtIni,
    dataFim: dtFim,
    codGestor: usuario.codGestor,
    tipoDataFiltro: tipoDataFiltro,
    ait: "",
  });

  const abrirNovaAba = ({ url }) => {
    const win = window.open(url, "_blank");
    win.focus();
  };

  //#region CONSULTAR

  useEffect(() => {
    async function carregar() {
      await consultar({ user: false, values: filtros, errors: {} });
    }
    carregar();
  }, []);

  const consultar = async ({ user, values, errors }) => {
    try {
      if (Object.entries(errors).length > 0) return;
      if (user) setFiltros(values);
      await setData(null);

      const obj = utils.montaAlerta({
        titulo: "Montando seu Dashboard",
        tAnimacao: utils.animacoes.dashboard,
      });
      setPropsAlerta(obj);

      const ret = await api.retornaDashboard({
        dataIni: values?.dataIni,
        dataFim: values?.dataFim,
        placa: values?.placa,
        codGestor: values?.codGestor,
        gestorMaster: usuario.gestorMaster,
        tipoDataFiltro: values?.tipoDataFiltro,
        ait: values?.ait,
      });

      await setData(ret);
      setPropsAlerta(utils.semAlerta);
    } catch (e) {
      setData(null);
      setPropsAlerta(utils.semAlerta);

      if (user) {
        setPropsAlerta(
          utils.montaAlerta({
            titulo: e.message,
            origem: e.origem,
            tAnimacao: e.tipoAlerta,
          })
        );
      }
    }
  };

  //#endregion

  //#region DETALHES GRÁFICOS

  const filtrarNotificacaoRecebida = async () => {
    try {
      const data = await api.retornaDetNotificacaoRecebida({
        dataIni: filtros?.dataIni,
        dataFim: filtros?.dataFim,
        placa: filtros?.placa,
        codGestor: filtros?.codGestor,
        gestorMaster: usuario.gestorMaster,
        tipoDataFiltro: filtros?.tipoDataFiltro,
        ait: filtros?.ait,
      });
      const ret = {
        dados: data,
        titulo: "Notificações recebidas",
        subTitulo: "",
        opcoes: [],
      };
      return ret;
    } catch (e) {
      setPropsAlerta(
        utils.montaAlerta({
          titulo: e.message,
          origem: e.origem,
          tAnimacao: e.tipoAlerta,
        })
      );
    }
  };

  const filtrarNotificacaoRecebidaBoletoComum = async () => {
    try {
      const data = await api.retornaDetNotificacaoRecebidaBoletoComum({
        dataIni: filtros?.dataIni,
        dataFim: filtros?.dataFim,
        placa: filtros?.placa,
        codGestor: filtros?.codGestor,
        gestorMaster: usuario.gestorMaster,
        tipoDataFiltro: filtros?.tipoDataFiltro,
        ait: filtros?.ait,
      });
      const ret = {
        dados: data,
        titulo: "Notificações com Boleto",
        subTitulo: "",
        opcoes: [],
      };
      return ret;
    } catch (e) {
      setPropsAlerta(
        utils.montaAlerta({
          titulo: e.message,
          origem: e.origem,
          tAnimacao: e.tipoAlerta,
        })
      );
    }
  };

  const filtrarNotificacaoRecebidaBoletoNic = async () => {
    try {
      const data = await api.retornaDetNotificacaoRecebidaBoletoNic({
        dataIni: filtros?.dataIni,
        dataFim: filtros?.dataFim,
        placa: filtros?.placa,
        codGestor: filtros?.codGestor,
        gestorMaster: usuario.gestorMaster,
        tipoDataFiltro: filtros?.tipoDataFiltro,
        ait: filtros?.ait,
      });
      const ret = {
        dados: data,
        titulo: "Notificações com NIC",
        subTitulo: "",
        opcoes: [],
      };
      return ret;
    } catch (e) {
      setPropsAlerta(
        utils.montaAlerta({
          titulo: e.message,
          origem: e.origem,
          tAnimacao: e.tipoAlerta,
        })
      );
    }
  };

  const filtrarBoletoRecebido = async () => {
    try {
      const data = await api.retornaDetBoletoRecebido({
        dataIni: filtros?.dataIni,
        dataFim: filtros?.dataFim,
        placa: filtros?.placa,
        codGestor: filtros?.codGestor,
        gestorMaster: usuario.gestorMaster,
        tipoDataFiltro: filtros?.tipoDataFiltro,
        ait: filtros?.ait,
      });
      const ret = {
        dados: data,
        titulo: "Boletos Recebidos",
        subTitulo: "",
        opcoes: [],
      };
      return ret;
    } catch (e) {
      setPropsAlerta(
        utils.montaAlerta({
          titulo: e.message,
          origem: e.origem,
          tAnimacao: e.tipoAlerta,
        })
      );
    }
  };

  const filtrarBoletoSemNotificacao = async () => {
    try {
      const data = await api.retornaDetBoletoSemNotificacao({
        dataIni: filtros?.dataIni,
        dataFim: filtros?.dataFim,
        placa: filtros?.placa,
        codGestor: filtros?.codGestor,
        gestorMaster: usuario.gestorMaster,
        tipoDataFiltro: filtros?.tipoDataFiltro,
        ait: filtros?.ait,
      });
      const ret = {
        dados: data,
        titulo: "Boletos Sem Notificação",
        subTitulo: "",
        opcoes: [],
      };
      return ret;
    } catch (e) {
      setPropsAlerta(
        utils.montaAlerta({
          titulo: e.message,
          origem: e.origem,
          tAnimacao: e.tipoAlerta,
        })
      );
    }
  };

  const filtrarNicSemNotificacao = async () => {
    try {
      const data = await api.retornaDetNicSemNotificacao({
        dataIni: filtros?.dataIni,
        dataFim: filtros?.dataFim,
        placa: filtros?.placa,
        codGestor: filtros?.codGestor,
        gestorMaster: usuario.gestorMaster,
        tipoDataFiltro: filtros?.tipoDataFiltro,
        ait: filtros?.ait,
      });
      const ret = {
        dados: data,
        titulo: "NIC Sem Notificação",
        subTitulo: "",
        opcoes: [],
      };
      return ret;
    } catch (e) {
      setPropsAlerta(
        utils.montaAlerta({
          titulo: e.message,
          origem: e.origem,
          tAnimacao: e.tipoAlerta,
        })
      );
    }
  };

  const filtrarNic = async () => {
    try {
      const data = await api.retornaDetNic({
        dataIni: filtros?.dataIni,
        dataFim: filtros?.dataFim,
        placa: filtros?.placa,
        codGestor: filtros?.codGestor,
        gestorMaster: usuario.gestorMaster,
        tipoDataFiltro: filtros?.tipoDataFiltro,
        ait: filtros?.ait,
      });
      const ret = {
        dados: data,
        titulo: "NIC",
        subTitulo: "",
        opcoes: [],
      };
      return ret;
    } catch (e) {
      setPropsAlerta(
        utils.montaAlerta({
          titulo: e.message,
          origem: e.origem,
          tAnimacao: e.tipoAlerta,
        })
      );
    }
  };

  const filtrarPrazoIndicacao = async ({ tipo }) => {
    try {
      const dentroPrazo = tipo.toLowerCase() == "dentro do prazo";
      // e[0]?._model?.label
      const data = await api.retornaDetPrazoIndicacao({
        dataIni: filtros?.dataIni,
        dataFim: filtros?.dataFim,
        placa: filtros?.placa,
        codGestor: filtros?.codGestor,
        gestorMaster: usuario.gestorMaster,
        dentroPrazo,
        tipoDataFiltro: filtros?.tipoDataFiltro,
        ait: filtros?.ait,
      });
      const ret = {
        dados: data,
        titulo: "Notificações recebidas",
        subTitulo: tipo,
      };
      return ret;
    } catch (e) {
      setPropsAlerta(
        utils.montaAlerta({
          titulo: e.message,
          origem: e.origem,
          tAnimacao: e.tipoAlerta,
        })
      );
    }
  };

  const filtrarPagamentoBoleto = async ({ tipo }) => {
    try {
      const antesVencimento = tipo.toLowerCase() == "pago com desconto";
      const data = await api.retornaDetBoletoPagEmDia({
        dataIni: filtros?.dataIni,
        dataFim: filtros?.dataFim,
        placa: filtros?.placa,
        codGestor: filtros?.codGestor,
        gestorMaster: usuario.gestorMaster,
        antesVencimento,
        tipoDataFiltro: filtros?.tipoDataFiltro,
        ait: filtros?.ait,
      });
      const ret = {
        dados: data,
        titulo: "Pagamento dos boletos",
        subTitulo: tipo,
        opcoes: ["Pago com Desconto", "Pago sem Desconto"],
      };
      return ret;
    } catch (e) {
      setPropsAlerta(
        utils.montaAlerta({
          titulo: e.message,
          origem: e.origem,
          tAnimacao: e.tipoAlerta,
        })
      );
    }
  };

  const filtrarTopPlaca = async ({ tipo }) => {
    try {
      const nomes = Object.getOwnPropertyNames(data?.GraficoTop5Placas);

      const Placa = tipo.toLocaleUpperCase();

      // const Placa = e[0]?._model?.label.toLocaleUpperCase();
      const dados = await api.retornaDetTopPlaca({
        dataIni: filtros?.dataIni,
        dataFim: filtros?.dataFim,
        placa: Placa,
        codGestor: filtros?.codGestor,
        gestorMaster: usuario.gestorMaster,
        tipoDataFiltro: filtros?.tipoDataFiltro,
        ait: filtros?.ait,
      });
      const ret = {
        dados: dados,
        titulo: "Top 10 Placas com mais indicações",
        subTitulo: tipo,
        opcoes: nomes,
      };
      return ret;
    } catch (e) {
      setPropsAlerta(
        utils.montaAlerta({
          titulo: e.message,
          origem: e.origem,
          tAnimacao: e.tipoAlerta,
        })
      );
    }
  };

  const filtrarTopLocatario = async ({ tipo }) => {
    try {
      const nomes = Object.getOwnPropertyNames(data?.GraficoTop5Locatarios);

      const CodGestor = tipo.toLocaleUpperCase();

      // const Placa = e[0]?._model?.label.toLocaleUpperCase();
      const dados = await api.retornaDetTopLocatario({
        dataIni: filtros?.dataIni,
        dataFim: filtros?.dataFim,
        placa: filtros?.Placa,
        codGestor: CodGestor,
        gestorMaster: usuario.gestorMaster,
        tipoDataFiltro: filtros?.tipoDataFiltro,
        ait: filtros?.ait,
      });
      const ret = {
        dados: dados,
        titulo: "Top 10 Locatarios com mais indicações",
        subTitulo: tipo,
        opcoes: nomes,
      };
      return ret;
    } catch (e) {
      setPropsAlerta(
        utils.montaAlerta({
          titulo: e.message,
          origem: e.origem,
          tAnimacao: e.tipoAlerta,
        })
      );
    }
  };

  const filtrarRackingMotivos = async ({ tipo }) => {
    try {
      const nomes = Object.getOwnPropertyNames(data?.GraficoRackingMotivos);

      // const Placa = e[0]?._model?.label.toLocaleUpperCase();
      const dados = await api.retornaDetRackingMotivos({
        dataIni: filtros?.dataIni,
        dataFim: filtros?.dataFim,
        placa: filtros?.placa,
        codGestor: filtros?.codGestor,
        gestorMaster: usuario.gestorMaster,
        infracao: tipo,
        tipoDataFiltro: filtros?.tipoDataFiltro,
        ait: filtros?.ait,
      });
      const ret = {
        dados: dados,
        titulo: "Racking Motivos de Infração",
        subTitulo: tipo,
        opcoes: nomes,
      };
      return ret;
    } catch (e) {
      setPropsAlerta(
        utils.montaAlerta({
          titulo: e.message,
          origem: e.origem,
          tAnimacao: e.tipoAlerta,
        })
      );
    }
  };

  const filtrarNotificacaoPorEstado = async ({ tipo }) => {
    try {
      const nomes = Object.getOwnPropertyNames(data?.GraficoNotificacaoPorUF);
      console.log(nomes);
      const dados = await api.retornaDetNotificacaoPorEstado({
        dataIni: filtros?.dataIni,
        dataFim: filtros?.dataFim,
        placa: filtros?.placa,
        codGestor: filtros?.codGestor,
        gestorMaster: usuario.gestorMaster,
        estado: tipo,
        tipoDataFiltro: filtros?.tipoDataFiltro,
        ait: filtros?.ait,
      });
      const ret = {
        dados: dados,
        titulo: "Notificações por Estado",
        subTitulo: tipo,
        opcoes: nomes,
      };
      return ret;
    } catch (e) {
      setPropsAlerta(
        utils.montaAlerta({
          titulo: e.message,
          origem: e.origem,
          tAnimacao: e.tipoAlerta,
        })
      );
    }
  };

  const filtrarTopOrgao = async ({ tipo }) => {
    try {
      const nomes = Object.getOwnPropertyNames(data?.GraficoTop5Orgaos);
      const dados = await api.retornaDetTopOrgao({
        dataIni: filtros?.dataIni,
        dataFim: filtros?.dataFim,
        placa: filtros?.placa,
        codGestor: filtros?.codGestor,
        gestorMaster: usuario.gestorMaster,
        nomeOrgao: tipo,
        tipoDataFiltro: filtros?.tipoDataFiltro,
        ait: filtros?.ait,
      });
      const ret = {
        dados: dados,
        titulo: "Top 5 Órgãos com mais indicações",
        subTitulo: tipo,
        opcoes: nomes,
      };
      return ret;
    } catch (e) {
      setPropsAlerta(
        utils.montaAlerta({
          titulo: e.message,
          origem: e.origem,
          tAnimacao: e.tipoAlerta,
        })
      );
    }
  };

  const filtrarAtuacao = async ({ tipo }) => {
    try {
      const natureza = tipo;
      const data = await api.retornaDetPorNatureza({
        dataIni: filtros?.dataIni,
        dataFim: filtros?.dataFim,
        placa: filtros?.placa,
        codGestor: filtros?.codGestor,
        gestorMaster: usuario.gestorMaster,
        natureza,
        tipoDataFiltro: filtros?.tipoDataFiltro,
        ait: filtros?.ait,
      });
      const ret = {
        dados: data,
        titulo: "Autuação por natureza",
        subTitulo: tipo,
        opcoes: ["Leve", "Média", "Grave", "Gravíssima"],
      };
      return ret;
    } catch (e) {
      setPropsAlerta(
        utils.montaAlerta({
          titulo: e.message,
          origem: e.origem,
          tAnimacao: e.tipoAlerta,
        })
      );
    }
  };

  const filtrarIndicacao = async ({ tipo }) => {
    try {
      tipo = tipo.toLowerCase();
      const fezIndicacao = tipo == "indicadas";
      const data = await api.retornaDetFezIndicacao({
        dataIni: filtros?.dataIni,
        dataFim: filtros?.dataFim,
        placa: filtros?.placa,
        codGestor: filtros?.codGestor,
        gestorMaster: usuario.gestorMaster,
        fezIndicacao,
        tipoDataFiltro: filtros?.tipoDataFiltro,
        ait: filtros?.ait,
      });
      const ret = {
        dados: data,
        titulo: "Indicações do Condutor",
        subTitulo: tipo,
        opcoes: ["indicadas", "não indicadas"],
      };
      return ret;
    } catch (e) {
      setPropsAlerta(
        utils.montaAlerta({
          titulo: e.message,
          origem: e.origem,
          tAnimacao: e.tipoAlerta,
        })
      );
    }
  };

  const filtrarBoletoPorEstado = async ({ tipo }) => {
    try {
      const nomes = Object.getOwnPropertyNames(data?.GraficoBoletoPorUF);
      console.log(nomes);
      const dados = await api.retornaDetBoletoPorEstado({
        dataIni: filtros?.dataIni,
        dataFim: filtros?.dataFim,
        placa: filtros?.placa,
        codGestor: filtros?.codGestor,
        gestorMaster: usuario.gestorMaster,
        estado: tipo,
        tipoDataFiltro: filtros?.tipoDataFiltro,
        ait: filtros?.ait,
      });
      const ret = {
        dados: dados,
        titulo: "Boleto por Estado",
        subTitulo: tipo,
        opcoes: nomes,
      };
      return ret;
    } catch (e) {
      setPropsAlerta(
        utils.montaAlerta({
          titulo: e.message,
          origem: e.origem,
          tAnimacao: e.tipoAlerta,
        })
      );
    }
  };

  const filtrarBoletoTopOrgao = async ({ tipo }) => {
    try {
      const nomes = Object.getOwnPropertyNames(data?.GraficoBoletoTop5Orgaos);
      const dados = await api.retornaDetBoletoTopOrgao({
        dataIni: filtros?.dataIni,
        dataFim: filtros?.dataFim,
        placa: filtros?.placa,
        codGestor: filtros?.codGestor,
        gestorMaster: usuario.gestorMaster,
        nomeOrgao: tipo,
        tipoDataFiltro: filtros?.tipoDataFiltro,
        ait: filtros?.ait,
      });
      const ret = {
        dados: dados,
        titulo: "Top 5 Órgãos com mais boletos",
        subTitulo: tipo,
        opcoes: nomes,
      };
      return ret;
    } catch (e) {
      setPropsAlerta(
        utils.montaAlerta({
          titulo: e.message,
          origem: e.origem,
          tAnimacao: e.tipoAlerta,
        })
      );
    }
  };

  const filtrarBoletoRankingMotivos = async ({ tipo }) => {
    try {
      const nomes = Object.getOwnPropertyNames(
        data?.GraficoBoletoRankingMotivos
      );

      // const Placa = e[0]?._model?.label.toLocaleUpperCase();
      const dados = await api.retornaDetBoletoRankingMotivos({
        dataIni: filtros?.dataIni,
        dataFim: filtros?.dataFim,
        placa: filtros?.placa,
        codGestor: filtros?.codGestor,
        gestorMaster: usuario.gestorMaster,
        infracao: tipo,
        tipoDataFiltro: filtros?.tipoDataFiltro,
        ait: filtros?.ait,
      });
      const ret = {
        dados: dados,
        titulo: "Racking Motivos de Infração",
        subTitulo: tipo,
        opcoes: nomes,
      };
      return ret;
    } catch (e) {
      setPropsAlerta(
        utils.montaAlerta({
          titulo: e.message,
          origem: e.origem,
          tAnimacao: e.tipoAlerta,
        })
      );
    }
  };

  const filtrarBoletoTopPlaca = async ({ tipo }) => {
    try {
      const nomes = Object.getOwnPropertyNames(data?.GraficoBoletoTop5Placas);

      const Placa = tipo.toLocaleUpperCase();

      const dados = await api.retornaDetBoletoTopPlaca({
        dataIni: filtros?.dataIni,
        dataFim: filtros?.dataFim,
        placa: Placa,
        codGestor: filtros?.codGestor,
        gestorMaster: usuario.gestorMaster,
        tipoDataFiltro: filtros?.tipoDataFiltro,
        ait: filtros?.ait,
      });
      const ret = {
        dados: dados,
        titulo: "Top 10 Placas com mais boletos",
        subTitulo: tipo,
        opcoes: nomes,
      };
      return ret;
    } catch (e) {
      setPropsAlerta(
        utils.montaAlerta({
          titulo: e.message,
          origem: e.origem,
          tAnimacao: e.tipoAlerta,
        })
      );
    }
  };

  //#endregion

  return (
    <Tela
      titulo={"Home"}
      icone={"pe-7s-display1"}
      subTitulo={"Dashboard"}
      menu={true}
    >
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          flexFlow: "wrap",
          gap: 20,
          padding: 5,
        }}
      >
        <Formik
          validationSchema={schema}
          onSubmit={() => {}}
          validateOnMount
          initialValues={{
            placa: usuario.placa,
            dataIni: dtIni,
            dataFim: dtFim,
            codGestor: usuario.gestorMaster ? "" : usuario.codGestor,
            tipoDataFiltro: "1",
            ait: "",
          }}
        >
          {({ values, validateForm, errors, handleChange, setFieldValue }) => (
            <Tema.CardForm
              style={{
                minHeight: "80px",
                width: "100%",
                alignItems: "center",
                rowGap: "10px",
              }}
            >
              {/* {console.log("Gestor master: " + usuario.gestorMaster + " || usuario.codGestor: " + usuario.codGestor + " || Usuário Placa: " + usuario.placa + " || values?.codGestor: " + values?.codGestor + " || values?.placa " + values?.placa)} */}

              <Tema.CardFormHome>
                <Select
                  label="Período consulta"
                  name={"tipoDataFiltro"}
                  value={values?.tipoDataFiltro}
                  onChange={handleChange}
                  placeHolder={"Selecione o período"}
                  itens={tipoDataFiltro}
                />
                <Input
                  align={"center"}
                  label="Data Início"
                  name={"dataIni"}
                  type={"date"}
                  max={"2999-12-31"}
                  labelAtivo={true}
                  value={values?.dataIni}
                  onChange={handleChange}
                />
                <Input
                  align={"center"}
                  label="Data Fim"
                  name={"dataFim"}
                  type={"date"}
                  max={"2999-12-31"}
                  labelAtivo={true}
                  value={values?.dataFim}
                  onChange={handleChange}
                />

                <Input
                  labelAtivo={true}
                  align={"center"}
                  label="Placa"
                  name={"placa"}
                  value={values?.placa}
                  onChange={handleChange}
                  desabilitado={usuario.placa}
                />
                <Input
                  labelAtivo={true}
                  align={"center"}
                  label="CódigoGestor"
                  name={"codGestor"}
                  value={values?.codGestor}
                  onChange={handleChange}
                  desabilitado={!usuario.gestorMaster}
                />

                <Input
                  labelAtivo={true}
                  align={"center"}
                  label="AIT"
                  name={"ait"}
                  value={values?.ait}
                  onChange={handleChange}
                />

                <ButtonMaterial
                  style={Padrao.styleBotao}
                  size="small"
                  onClick={async () => {
                    await consultar({ user: true, values, errors });
                  }}
                >
                  Pesquisar
                </ButtonMaterial>
              </Tema.CardFormHome>
            </Tema.CardForm>
          )}
        </Formik>
        <TabPanel defaultPanel={"1"} hidden={data == null}>
          <Panel titulo={"Notificações"} key={"1"}>
            <React.Fragment>
              {/* Fellipe - 28/09/2023 => Conforme solicitado pela Meire/Marcio/Diego no e-mail "HUB ALD" (26/09/2023).
                                      Alterar as consultas pela data da infração. Vamos remover os textos também */}

              {/* <div
                style={{
                  height: "100%",
                  width: "100%",
                  marginTop: 15,
                  display: data == null ? "none" : "flex",
                  flexDirection: "column",
                  textAlign: "center",
                }}
              >
                <p>
                  <span
                    style={{
                      fontWeight: "bold",
                      color: "red",
                    }}>Atenção: </span>Esta consulta utiliza a data da infração.
                </p>
              </div> */}

              <div
                style={{
                  height: "100%",
                  width: "100%",
                  marginTop: 15,
                  display: data == null ? "none" : "flex",
                  flexDirection: "column",
                }}
              >
                <Tema.DivLinhaTotais>
                  <Grafico
                    idGrafico={utils.graficos.notificacaoRecebida}
                    data={data}
                    onClick={async (e) => {
                      return await filtrarNotificacaoRecebida();
                    }}
                    onClickLinhaTabela={async (row, e) => {
                      const url = "/notificacaofiltrar/" + row?.Id?.toString();
                      await abrirNovaAba({ url });
                    }}
                  />

                  <div
                    style={{
                      margin: "0px -59px",
                      filter: "drop-shadow(0 20px 8px rgba(0, 0, 0, .3))",
                    }}
                  >
                    <Grafico
                      idGrafico={utils.graficos.indicacaoCondutor}
                      data={data}
                      onPesquisar={async (tipo) => {
                        return await filtrarIndicacao({ tipo });
                      }}
                      onClick={async (e) => {
                        const tipo = e?.w?.config?.labels[e?.seriesIndex];
                        return await filtrarIndicacao({ tipo });
                      }}
                      onClickLinhaTabela={async (row, e) => {
                        const url =
                          "/notificacaofiltrar/" + row?.Id?.toString();
                        await abrirNovaAba({ url });
                      }}
                    />
                  </div>

                  <div
                    style={{
                      margin: "0px -59px",
                      filter: "drop-shadow(0 20px 8px rgba(0, 0, 0, .3))",
                    }}
                  >
                    <Grafico
                      idGrafico={utils.graficos.naoIndicacaoCondutor}
                      data={data}
                      onPesquisar={async (tipo) => {
                        return await filtrarIndicacao({ tipo });
                      }}
                      onClick={async (e) => {
                        const tipo = e?.w?.config?.labels[e?.seriesIndex];
                        return await filtrarIndicacao({ tipo });
                      }}
                      onClickLinhaTabela={async (row, e) => {
                        const url =
                          "/notificacaofiltrar/" + row?.Id?.toString();
                        await abrirNovaAba({ url });
                      }}
                    />
                  </div>
                  <Grafico
                    idGrafico={utils.graficos.notificacaoExtra}
                    data={data}
                    onClick={async (e) => {
                      return await filtrarNotificacaoRecebida();
                    }}
                    onClickLinhaTabela={async (row, e) => {
                      const url = "/notificacaofiltrar/" + row?.Id?.toString();
                      await abrirNovaAba({ url });
                    }}
                  />
                </Tema.DivLinhaTotais>

                <Tema.DivLinhaTotais>
                  <Grafico
                    idGrafico={utils.graficos.notificacaoRecebidaBoletoComum}
                    data={data}
                    onClick={async (e) => {
                      return await filtrarNotificacaoRecebidaBoletoComum();
                    }}
                    onClickLinhaTabela={async (row, e) => {
                      const url = "/notificacaofiltrar/" + row?.Id?.toString();
                      await abrirNovaAba({ url });
                    }}
                  />

                  <div
                    style={{
                      margin: "0px -59px",
                      filter: "drop-shadow(0 20px 8px rgba(0, 0, 0, .3))",
                    }}
                  ></div>

                  <Grafico
                    idGrafico={utils.graficos.notificacaoRecebidaBoletoNic}
                    data={data}
                    onClick={async (e) => {
                      return await filtrarNotificacaoRecebidaBoletoNic();
                    }}
                    onClickLinhaTabela={async (row, e) => {
                      const url = "/notificacaofiltrar/" + row?.Id?.toString();
                      await abrirNovaAba({ url });
                    }}
                  />
                </Tema.DivLinhaTotais>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexFlow: "wrap",
                    justifyContent: "center",
                  }}
                >
                  <CardDashboard
                    titulo={"Multas por Estado"}
                    width={"100%"}
                    height={"100%"}
                    // backGroundColor ={"#1e2128"}
                  >
                    <Grafico
                      idGrafico={utils.graficos.notificacaoPorEstado}
                      data={data}
                      onClick={async (e) => {
                        const tipo = e?.uf;
                        if (e?.valor > 0)
                          return await filtrarNotificacaoPorEstado({ tipo });
                      }}
                      onClickLinhaTabela={async (row, e) => {
                        const url =
                          "/notificacaofiltrar/" + row?.Id?.toString();
                        await abrirNovaAba({ url });
                      }}
                      onPesquisar={async (tipo) => {
                        return await filtrarNotificacaoPorEstado({ tipo });
                      }}
                    />
                  </CardDashboard>

                  <CardDashboard titulo={"Autuação por natureza"} width={"35%"}>
                    <Grafico
                      idGrafico={utils.graficos.autuacaoNatureza}
                      data={data}
                      onPesquisar={async (tipo) => {
                        return await filtrarAtuacao({ tipo });
                      }}
                      onClick={async (e) => {
                        const tipo = e?.w?.config?.labels[e?.dataPointIndex];

                        return await filtrarAtuacao({ tipo });
                      }}
                      onClickLinhaTabela={async (row, e) => {
                        const url =
                          "/notificacaofiltrar/" + row?.Id?.toString();
                        await abrirNovaAba({ url });
                      }}
                    />
                  </CardDashboard>

                  <CardDashboard
                    titulo={"Top 5 Órgãos"}
                    width={"57%"}
                    height={"100%"}
                    backGroundColor={
                      "linear-gradient(to right ,  #9D50BB, #6E48AA)"
                    }
                  >
                    <Grafico
                      idGrafico={utils.graficos.topOrgao}
                      data={data}
                      onClick={async (e) => {
                        const tipo = e?.axisX?.ticks[e?.index];

                        return await filtrarTopOrgao({ tipo });
                      }}
                      onClickLinhaTabela={async (row, e) => {
                        const url =
                          "/notificacaofiltrar/" + row?.Id?.toString();
                        await abrirNovaAba({ url });
                      }}
                      onPesquisar={async (tipo) => {
                        return await filtrarTopOrgao({ tipo });
                      }}
                    />
                  </CardDashboard>

                  <CardDashboard
                    titulo={"Ranking motivos de infração"}
                    width={"100%"}
                  >
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        flexWrap: "wrap",
                        flexDirection: "row",
                        margin: "5px 0px",
                      }}
                    >
                      <Grafico
                        idGrafico={utils.graficos.rackingInfracao}
                        data={data}
                        onPesquisar={async (tipo) => {
                          return await filtrarRackingMotivos({ tipo });
                        }}
                        onClick={async (e) => {
                          const tipo = e?.w?.config?.labels[e?.dataPointIndex];
                          return await filtrarRackingMotivos({ tipo });
                        }}
                        onClickLinhaTabela={async (row, e) => {
                          const url =
                            "/notificacaofiltrar/" + row?.Id?.toString();
                          await abrirNovaAba({ url });
                        }}
                      />
                    </div>
                  </CardDashboard>

                  {usuario.codGestor ? (
                    <CardDashboard
                      titulo={"Top 10 Placas"}
                      width={"100%"}
                      animacao={true}
                      backGroundColor={
                        "linear-gradient(1deg, #4e89c3, #53a3b0, #53b098)"
                      }
                    >
                      <Grafico
                        idGrafico={utils.graficos.topPlaca}
                        data={data}
                        onPesquisar={async (tipo) => {
                          return await filtrarTopPlaca({ tipo });
                        }}
                        onClick={async (e) => {
                          const tipo = e?.w?.config?.labels[e?.dataPointIndex];
                          return await filtrarTopPlaca({ tipo });
                        }}
                        onClickLinhaTabela={async (row, e) => {
                          const url =
                            "/notificacaofiltrar/" + row?.Id?.toString();
                          await abrirNovaAba({ url });
                        }}
                      />
                    </CardDashboard>
                  ) : null}

                  {usuario.gestorMaster ? (
                    <CardDashboard
                      titulo={"Top 10 Locatários"}
                      width={"100%"}
                      animacao={true}
                      backGroundColor={
                        "linear-gradient(1deg, #ab7f92, #b8b0cd, #86a4c8)"
                      }
                    >
                      <Grafico
                        idGrafico={utils.graficos.topLocatario}
                        data={data}
                        onPesquisar={async (tipo) => {
                          return await filtrarTopLocatario({ tipo });
                        }}
                        onClick={async (e) => {
                          const tipo = e?.w?.config?.labels[e?.dataPointIndex];
                          return await filtrarTopLocatario({ tipo });
                        }}
                        onClickLinhaTabela={async (row, e) => {
                          const url =
                            "/notificacaofiltrar/" + row?.Id?.toString();
                          await abrirNovaAba({ url });
                        }}
                      />
                    </CardDashboard>
                  ) : null}
                </div>
              </div>
            </React.Fragment>
          </Panel>
          <Panel titulo={"Boletos"} key={"2"}>
            <React.Fragment>
              {/* Fellipe - 28/09/2023 => Conforme solicitado pela Meire/Marcio/Diego no e-mail "HUB ALD" (26/09/2023).
                                      Alterar as consultas pela data da infração. Vamos remover os textos também */}
              {/* <div
                style={{
                  height: "100%",
                  width: "100%",
                  marginTop: 15,
                  display: data == null ? "none" : "flex",
                  flexDirection: "column",
                  textAlign: "center",
                }}
              >
                <p>
                  <span
                    style={{
                      fontWeight: "bold",
                      color: "red",
                    }}>Atenção: </span>Esta consulta utiliza a data de abertura do processo.
                </p>
              </div> */}

              <div
                style={{
                  height: "100%",
                  width: "100%",
                  marginTop: 15,
                  display: data == null ? "none" : "flex",
                  flexDirection: "column",
                }}
              >
                {/* Gráficos CARD */}
                <Tema.DivLinhaTotais>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "inherit",
                    }}
                  >
                    <Grafico
                      idGrafico={utils.graficos.boletoRecebido}
                      data={data}
                      onClick={async (e) => {
                        return await filtrarBoletoRecebido();
                      }}
                      onClickLinhaTabela={async (row, e) => {
                        const url = "/boletofiltrar/" + row?.Id?.toString();
                        await abrirNovaAba({ url });
                      }}
                    />
                    <Grafico
                      idGrafico={utils.graficos.boletoSemNotificacao}
                      data={data}
                      onClick={async (e) => {
                        return await filtrarBoletoSemNotificacao();
                      }}
                      onClickLinhaTabela={async (row, e) => {
                        const url = "/boletofiltrar/" + row?.Id?.toString();
                        await abrirNovaAba({ url });
                      }}
                    />

                    {/* Fellipe - 02/04/2024 => Conforme solicitado na especificação "Hub – Remover dados de pagamento", 
                    vamos remover este gráfico, porém vou manter comentado, caso seja necessário voltar. */}
                    {/* <div
                      style={{
                        display: "flex",
                        margin: "-2% -5% 0% -5%",
                        filter: "drop-shadow(0 20px 8px rgba(0, 0, 0, .3))",
                      }}
                    >
                      <Grafico
                        idGrafico={utils.graficos.pagamentoBoleto}
                        data={data}
                        onPesquisar={async (tipo) => {
                          return await filtrarPagamentoBoleto({ tipo });
                        }}
                        onClick={async (e) => {
                          const tipo = e?.w?.config?.labels[e?.dataPointIndex];

                          return await filtrarPagamentoBoleto({ tipo });
                        }}
                        onClickLinhaTabela={async (row, e) => {
                          const url = "/boletofiltrar/" + row?.Id?.toString();
                          await abrirNovaAba({ url });
                        }}
                      />
                    </div> */}

                    <Grafico
                      idGrafico={utils.graficos.nic}
                      data={data}
                      onClick={async (e) => {
                        return await filtrarNic();
                      }}
                      onClickLinhaTabela={async (row, e) => {
                        const url = "/boletofiltrar/" + row?.Id?.toString();
                        await abrirNovaAba({ url });
                      }}
                    />
                    <Grafico
                      idGrafico={utils.graficos.nicSemNotificacao}
                      data={data}
                      onClick={async (e) => {
                        return await filtrarNicSemNotificacao();
                      }}
                      onClickLinhaTabela={async (row, e) => {
                        const url = "/boletofiltrar/" + row?.Id?.toString();
                        await abrirNovaAba({ url });
                      }}
                    />
                  </div>
                </Tema.DivLinhaTotais>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexFlow: "wrap",
                    justifyContent: "center",
                  }}
                >
                  <CardDashboard
                    titulo={"Boleto por Estado"}
                    width={"57%"}
                    height={"100%"}
                  >
                    <Grafico
                      idGrafico={utils.graficos.boletoPorEstado}
                      data={data}
                      onClick={async (e) => {
                        const tipo = e?.uf;
                        if (e?.valor > 0)
                          return await filtrarBoletoPorEstado({ tipo });
                      }}
                      onClickLinhaTabela={async (row, e) => {
                        const url = "/boletofiltrar/" + row?.Id?.toString();
                        await abrirNovaAba({ url });
                      }}
                      onPesquisar={async (tipo) => {
                        return await filtrarBoletoPorEstado({ tipo });
                      }}
                    />
                  </CardDashboard>

                  <CardDashboard
                    titulo={"Top 5 Órgãos"}
                    width={"100%"}
                    height={"100%"}
                    backGroundColor={
                      "linear-gradient(to right ,  #9D50BB, #6E48AA)"
                    }
                  >
                    <Grafico
                      idGrafico={utils.graficos.boletoTopOrgao}
                      data={data}
                      onClick={async (e) => {
                        const tipo = e?.axisX?.ticks[e?.index];

                        return await filtrarBoletoTopOrgao({ tipo });
                      }}
                      onClickLinhaTabela={async (row, e) => {
                        const url = "/boletofiltrar/" + row?.Id?.toString();
                        await abrirNovaAba({ url });
                      }}
                      onPesquisar={async (tipo) => {
                        return await filtrarBoletoTopOrgao({ tipo });
                      }}
                    />
                  </CardDashboard>

                  <CardDashboard
                    titulo={"Ranking motivos de infração"}
                    width={"100%"}
                  >
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        flexWrap: "wrap",
                        flexDirection: "row",
                        margin: "5px 0px",
                      }}
                    >
                      <Grafico
                        idGrafico={utils.graficos.boletoRankingInfracao}
                        data={data}
                        onPesquisar={async (tipo) => {
                          return await filtrarBoletoRankingMotivos({ tipo });
                        }}
                        onClick={async (e) => {
                          const tipo = e?.w?.config?.labels[e?.dataPointIndex];
                          return await filtrarBoletoRankingMotivos({ tipo });
                        }}
                        onClickLinhaTabela={async (row, e) => {
                          const url = "/boletofiltrar/" + row?.Id?.toString();
                          await abrirNovaAba({ url });
                        }}
                      />
                    </div>
                  </CardDashboard>

                  {usuario.codGestor ? (
                    <CardDashboard
                      titulo={"Top 10 Placas"}
                      width={"100%"}
                      animacao={true}
                      backGroundColor={
                        "linear-gradient(1deg, #4e89c3, #53a3b0, #53b098)"
                      }
                    >
                      <Grafico
                        idGrafico={utils.graficos.BoletoTopPlaca}
                        data={data}
                        onPesquisar={async (tipo) => {
                          return await filtrarBoletoTopPlaca({ tipo });
                        }}
                        onClick={async (e) => {
                          const tipo = e?.w?.config?.labels[e?.dataPointIndex];
                          return await filtrarBoletoTopPlaca({ tipo });
                        }}
                        onClickLinhaTabela={async (row, e) => {
                          const url = "/boletofiltrar/" + row?.Id?.toString();
                          await abrirNovaAba({ url });
                        }}
                      />
                    </CardDashboard>
                  ) : null}
                </div>
              </div>
            </React.Fragment>
          </Panel>
        </TabPanel>
      </div>

      <Carregando
        tipo={propsAlerta.tipoAnimacao}
        exibir={propsAlerta.exibir}
        userClose={propsAlerta.usuarioFechar}
        loop={true}
        titulo={propsAlerta.titulo}
        origem={propsAlerta.origem}
        setExibir={() => {
          setPropsAlerta(utils.semAlerta);
        }}
        // styleFundo={{width:'90%', height:'90%'}}
        // styleAnimacao={{width:'70%'}}
      />
    </Tela>
  );
};

export default Home;
