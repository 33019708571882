import React, { useLayoutEffect, useCallback, useState } from "react";
import Helmet from "react-helmet";
import { useLocation, useHistory } from "react-router-dom";

import Tela from "../../layout/tela";
import * as Tema from "../../layout/tela/style";
import * as Padrao from "../../../style/config";
import * as utils from "../../../utils";
import {
  retornaDownloadBoletos,
  retornaDownloadComprovanteBoletos,
} from "../../../API/consulta";

import Dado from "../../layout/tela/infoGraficos/dado";
import Input from "../../layout/tela/campos/input";
import RadioButton from "../../layout/tela/campos/inputRadio";
import TabPanel from "../../layout/tabPanel";
import Panel from "../../layout/tabPanel/panel";
import { Carregando } from "../../loading";

const Boleto = () => {
  const corInfoGrafico = "#8a5859";
  const history = useHistory();
  const location = useLocation();
  const dataUrl = location.state;
  const [data, setData] = useState(null);
  const [propsAlerta, setPropsAlerta] = useState(utils.propsAlerta);

  // Fellipe - 08/07/2024 => Como estamos criando uma nova aba, então vamos apenas fechar a aba.
  const voltar = useCallback(() => {
    window.scrollTo(0, 0);
    window.close();
  }, []);

  // const voltar = useCallback(() => {
  //   window.scrollTo(0, 0);
  //   history.push("/boleto", {});
  // }, [history]);

  const download = () => {
    try {
      const ret = retornaDownloadBoletos({
        CodFlowExecute: data?.CodFlowExecute,
        ait: data?.Ait,
      });

      window.open(ret);
    } catch (e) {
      setPropsAlerta(
        utils.montaAlerta({
          titulo: e.message,
          origem: e.origem,
          tAnimacao: e.tipoAlerta,
        })
      );
    }
  };

  const downloadComprovante = () => {
    try {
      const ret = retornaDownloadComprovanteBoletos({
        CodFlowExecute: data?.CodFlowExecute,
        ait: data?.Ait,
      });
      console.log("RetornoComprovante" + ret);
      window.open(ret);
    } catch (e) {
      setPropsAlerta(
        utils.montaAlerta({
          titulo: e.message,
          origem: e.origem,
          tAnimacao: e.tipoAlerta,
        })
      );
    }
  };

  useLayoutEffect(() => {
    if (!dataUrl) {
      voltar();
      return;
    }
    async function funcao() {
      setData(dataUrl.data);
    }
    funcao();
  }, [dataUrl, voltar]);

  return (
    <Tela
      titulo={"Boletos"}
      subTitulo={"Detalhamento do Boleto"}
      icone={"pe-7s-car"}
      sair={true}
      onSair={(e) => voltar()}
    >
      <TabPanel defaultPanel={"1"}>
        <Panel titulo={"Dados"} key={"1"}>
          <Tema.CardForm style={{ minHeight: "150px", width: "100%" }}>
            <div
              style={{ padding: 5, display: "flex", gap: 10, flexFlow: "wrap" }}
            >
              <RadioButton
                label={"NIC"}
                soLeitura={true}
                valor={data?.tipoNic == "Sim"}
                name={"tipoNic"}
              />
              <Input
                label={"Placa"}
                type={"text"}
                soLeitura={true}
                valor={data?.Placa}
                autoSize={true}
              />
              <Input
                label={"AIT"}
                type={"text"}
                soLeitura={true}
                valor={data?.Ait}
                autoSize={true}
              />

              <Input
                label={"Data da Infração"}
                type={"text"}
                soLeitura={true}
                valor={data?.DataInfracao}
                align={"center"}
                autoSize={true}
              />
              <Input
                label={"Hora da Infração"}
                type={"text"}
                soLeitura={true}
                valor={data?.HoraInfracao}
                align={"center"}
                autoSize={true}
              />
            </div>
            {/* <div style={{padding:5,display: 'flex',gap:10, flexFlow: 'wrap', width: '100%'}}>
            <Input label={"Infração"} type={"text"} soLeitura={true}
            valor={data?.Infracao}
            />
            </div>
            <div style={{padding:5,display: 'flex',gap:10, flexFlow: 'wrap', width: '100%'}}>
            <Input label={"Local da Infração"} type={"text"} soLeitura={true}
            valor={data?.LocalInfracao}
            />
            </div> */}
            <div
              style={{ padding: 5, display: "flex", gap: 10, flexFlow: "wrap" }}
            >
              <Input
                label={"Vencimento"}
                type={"text"}
                soLeitura={true}
                valor={data?.Vencimento}
                align={"center"}
                autoSize={true}
              />
              <Input
                label={"Valor"}
                type={"text"}
                soLeitura={true}
                valor={utils.formataValor(data?.Valor)}
                align={"right"}
                autoSize={true}
              />
              <Input
                label={"Valor com Desconto"}
                type={"text"}
                soLeitura={true}
                valor={utils.formataValor(data?.ValorDesconto)}
                align={"right"}
                autoSize={true}
              />

              {/* Fellipe - 02/04/2024 => Conforme solicitado na especificação "Hub – Remover dados de pagamento", 
			            vamos remover os inputs abaixo, porém vou manter comentado, caso seja necessário voltar. */}
              {/* <Input
                label={"Data do Pagamento"}
                type={"text"}
                soLeitura={true}
                valor={((data?.DataDebaixa == '1900-01-01T00:00:00' || data?.DataDebaixa == '1900-01-01T00:00:00' || data?.DataDebaixa == null) ? " " : utils.formatDataHora(data?.DataDebaixa))}
                align={"center"}
              />
              <Input
                label={"Valor do Pagamento"}
                type={"text"}
                soLeitura={true}
                valor={utils.formataValor(data?.ValorItemFinance)}
                align={"right"}
              /> */}
            </div>

            {/* Fellipe - 02/04/2024 => Conforme solicitado na especificação "Hub – Remover dados de pagamento", 
                vamos remover os inputs abaixo, porém vou manter comentado, caso seja necessário voltar. */}
            {/* <div
              style={{
                padding: 5,
                display: "flex",
                gap: 10,
                flexFlow: "wrap",
                width: "100%",
              }}
            >
              <Input
                label={"Status"}
                type={"text"}
                soLeitura={true}
                valor={
                  data?.baixado
                    ? "Pagamento realizado no órgão emissor"
                    : "Consulte o botão 'Ver Comprovante' abaixo"
                }
              />
            </div> */}

            <Tema.CardFormNotificacaoDetalheMultiLine>
              <Input
                label={"Data envio E-mail"}
                type={"text"}
                soLeitura={true}
                valor={utils.formatDataHora(data?.DataEnvioEmail)}
                align={"center"}
              />
              <div
                style={{
                  width: "100%",
                }}
              >
                <Input
                  label={"E-mail"}
                  type={"text"}
                  soLeitura={true}
                  valor={data?.DataEnvioEmail ? data?.EmailResponsavel : ""}
                />
              </div>
            </Tema.CardFormNotificacaoDetalheMultiLine>

            <div
              style={{
                padding: 5,
                display: "flex",
                gap: 10,
                flexFlow: "wrap",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: 10,
                  gap: 15,
                }}
              >
                <Padrao.Botao
                  principal={true}
                  size="small"
                  onClick={() => {
                    download();
                  }}
                >
                  Ver Boleto
                </Padrao.Botao>
                <Padrao.Botao
                  principal={true}
                  size="small"
                  onClick={() => {
                    downloadComprovante();
                  }}
                >
                  Ver Comprovante
                </Padrao.Botao>
              </div>
            </div>
          </Tema.CardForm>
        </Panel>
        <Panel titulo={"Info Gráfico"} key={"2"}>
          <Tema.CardFormInfoGrafico>
            <Tema.CardForm>
              <Tema.CardCabecalho style={{ backgroundColor: corInfoGrafico }}>
                <Tema.CardCabecalhoFundoImagem />
                <Tema.CardCabecalhoDivTitulo>
                  <Tema.CardCabecalhoTitulo>
                    {" "}
                    Dados do Boleto
                  </Tema.CardCabecalhoTitulo>
                  <Tema.CardCabecalhoSubTitulo>
                    Abaixo dados do boleto
                  </Tema.CardCabecalhoSubTitulo>
                </Tema.CardCabecalhoDivTitulo>
              </Tema.CardCabecalho>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  color: "#70899b",
                  flexWrap: "wrap",
                }}
              >
                <Dado
                  icone={"pe-7s-news-paper"}
                  titulo={"NIC?"}
                  valor={data?.tipoNic}
                />
                <Dado
                  icone={"pe-7s-date"}
                  titulo={"Vencimento"}
                  valor={data?.Vencimento}
                />
                <Tema.LineBreak />
                <Dado
                  icone={"pe-7s-cash"}
                  titulo={"Valor"}
                  valor={utils.formataValor(data?.Valor)}
                />
                <Dado
                  icone={"pe-7s-cash"}
                  titulo={"Valor com Desconto"}
                  valor={utils.formataValor(data?.ValorDesconto)}
                  minWidth={"122px"}
                />
              </div>

              {/* Fellipe - 02/04/2024 => Conforme solicitado na especificação "Hub – Remover dados de pagamento", 
                  vamos remover as informações do infográfico, porém vou manter comentado, caso seja necessário voltar. */}
              {/* <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  color: "#70899b",
                }}
              >
                <Dado
                  colunaUnica={true}
                  icone={data?.baixado ? "pe-7s-check" : "pe-7s-hourglass"}
                  titulo={"Status"}
                  valor={
                    data?.baixado
                      ? "Pagamento realizado no órgão emissor"
                      : "Pendente de Pagamento"
                  }
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  color: "#70899b",
                  justifyContent: "center",
                }}
              >
                <Dado
                  icone={"pe-7s-date"}
                  titulo={"Data do Pagamento"}
                  valor={utils.formatDataHora(data?.DataDebaixa)}
                  minWidth={"122px"}
                />
                <Dado
                  icone={"pe-7s-cash"}
                  titulo={"Valor do Pagamento"}
                  valor={utils.formataValor(data?.ValorItemFinance)}
                  minWidth={"122px"}
                />
              </div> */}
            </Tema.CardForm>
            <Tema.CardForm>
              <Tema.CardCabecalho style={{ backgroundColor: corInfoGrafico }}>
                <Tema.CardCabecalhoFundoImagem />
                <Tema.CardCabecalhoDivTitulo>
                  <Tema.CardCabecalhoTitulo>
                    Documentos do Boleto
                  </Tema.CardCabecalhoTitulo>
                  <Tema.CardCabecalhoSubTitulo>
                    Abaixo faça o download dos documentos disponíveis
                  </Tema.CardCabecalhoSubTitulo>
                </Tema.CardCabecalhoDivTitulo>
              </Tema.CardCabecalho>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  color: "#70899b",
                }}
              ></div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  color: "#70899b",
                }}
              >
                <Dado
                  colunaUnica={true}
                  icone={"pe-7s-cloud-download"}
                  titulo={"Download"}
                  valor={"BOLETO + COMPROVANTE"}
                  onClick={() => {
                    download();
                  }}
                />
              </div>
            </Tema.CardForm>
          </Tema.CardFormInfoGrafico>
        </Panel>
      </TabPanel>

      <Carregando
        tipo={propsAlerta.tipoAnimacao}
        exibir={propsAlerta.exibir}
        userClose={propsAlerta.usuarioFechar}
        loop={true}
        titulo={propsAlerta.titulo}
        origem={propsAlerta.origem}
        setExibir={() => {
          setPropsAlerta(utils.semAlerta);
        }}
        // styleFundo={{width:'90%', height:'90%'}}
        // styleAnimacao={{width:'70%'}}
      />
    </Tela>
  );
};

export default Boleto;
