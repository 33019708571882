import React, {Children, useState} from 'react';
import { motion } from "framer-motion"


export const AnimationOrgao = ({children, onClick}) => (
    <motion.div
    onClick ={onClick}
    initial={{ opacity: 0, y: 50, scale: 0.3 }}
    animate={{ opacity: 1, y: 0, scale: 1, transition:{duration:2.5} }}
    whileHover={{ scale: 1.2,  rotate: [0, 0, 270, 270, 0]
      ,borderRadius: ["20%", "20%", "80%", "20%", "20%"],

    }}
    exit={{ scale: 1, borderRadius: ["20px"]}}
 
    style={{border:'#fff 1px solid', display: 'flex', alignItems:'center', justifyContent: 'center', color:'#fff' 
    , width:40,height:40, borderRadius:"80%", textAlign:'center', cursor: 'pointer' }}
  >
    {children}
    </motion.div>
  );

  export const Animation = ({children, style}) => (
    <motion.div
    initial={{ opacity: 0, x: -50, scale: 1 }}
    animate={{ opacity: 1, x: 0, scale: 1, transition:{duration:2.5} }}
    exit={{ scale: 1, }}
 
    style={style}
  >
    {children}
    </motion.div>
  );

