import * as date from "date-fns";
import * as dateTz from "date-fns-tz";
import { format, parseISO } from "date-fns";

import { ptBR } from "date-fns/locale";

export const macDesktop = window.navigator.userAgent.indexOf("Macintosh") > -1;

export const yearMin = new Date().getFullYear() - 99;
export const yearMax = new Date().getFullYear() + 99;

export const graficos = {
  notificacaoRecebida: 1,
  indicacaoCondutor: 2,
  boletoRecebido: 3,
  pagamentoBoleto: 4,
  nic: 5,
  autuacaoNatureza: 6,
  topOrgao: 7,
  rackingInfracao: 8,
  topPlaca: 9,
  notificacaoPorEstado: 10,
  notificacaoExtra: 11,
  primeiroEmplacamentoTotal: 12,
  primeiroEmplacamentoPendencia: 13,
  primeiroEmplacamentoEmAndamento: 14,
  naoIndicacaoCondutor: 15,
  topLocatario: 16,
  primeiroEmplacamentoConcluido: 17,
  notificacaoRecebidaBoletoComum: 18,
  notificacaoRecebidaBoletoNic: 19,
  boletoSemNotificacao: 20,
  nicSemNotificacao: 21,
  licenciamentoConcluido: 22,
  licenciamentoPendente: 23,
  licenciamentoStatus: 24,
  boletoPorEstado: 25,
  boletoTopOrgao: 26,
  boletoRankingInfracao: 27,
  BoletoTopPlaca: 28,
};

//#region  ALERTAS
export const animacoes = {
  carregando: 1,
  aviso: 2,
  erro: 3,
  sucesso: 4,
  dashboard: 5,
  assinaturaTermo: 6,
  uploadTermo: 7,
  uploadCNH: 8,
  indicacaoExpirada: 9,
  emConstrucao: 10,
};
export const propsAlerta = {
  titulo: "",
  subTitulo: "",
  origem: "",
  tipoAnimacao: 0,
  usuarioFechar: false,
  exibir: false,
  loop: true,
};

export const semAlerta = Object.assign({}, propsAlerta);

export const alertaCarregando = ({}) => {
  const obj = Object.assign({}, propsAlerta);
  // obj.titulo = titulo;
  // obj.subTitulo= subTitulo;
  // obj.origem= origem;
  obj.tipoAnimacao = animacoes.carregando;
  obj.usuarioFechar = false;
  obj.exibir = true;
  return obj;
};
export const alertaErro = ({ origem, titulo, subTitulo }) => {
  const obj = Object.assign({}, propsAlerta);
  obj.titulo = titulo;
  obj.subTitulo = subTitulo;
  obj.origem = origem;
  obj.tipoAnimacao = animacoes.erro;
  obj.usuarioFechar = true;
  obj.exibir = true;
  return obj;
};

export const alertaAviso = ({ origem, titulo, subTitulo }) => {
  const obj = Object.assign({}, propsAlerta);
  obj.titulo = titulo;
  obj.subTitulo = subTitulo;
  obj.origem = origem;
  obj.tipoAnimacao = animacoes.aviso;
  obj.usuarioFechar = true;
  obj.exibir = true;
  return obj;
};

export const alertaSucesso = ({ titulo, subTitulo }) => {
  const obj = Object.assign({}, propsAlerta);
  obj.titulo = titulo;
  obj.subTitulo = subTitulo;
  obj.tipoAnimacao = animacoes.sucesso;
  obj.usuarioFechar = false;
  obj.loop = false;
  obj.exibir = true;
  return obj;
};

export const montaAlerta = ({ tAnimacao, origem, titulo, subTitulo }) => {
  const obj = Object.assign({}, propsAlerta);
  obj.titulo = titulo;
  obj.subTitulo = subTitulo;
  obj.origem = origem;
  obj.tipoAnimacao = tAnimacao ?? animacoes.carregando;
  obj.usuarioFechar =
    obj.tipoAnimacao == animacoes.erro || obj.tipoAnimacao == animacoes.aviso;
  obj.exibir = true;
  return obj;
};

//#endregion

//#region TRATAMENTO DE ERRO

const retornaMsgErro = (ex) => {
  let ret = "";

  if (ex?.response?.data?.Message) ret = ex?.response?.data?.Message;
  if (ex?.response?.data?.message) ret += `\n ${ex?.response?.data?.message}`;
  if (!ret) {
    ret = ex.message;
  }

  if (ret.toLocaleLowerCase() == "network error") {
    ret = "Sem conexão com a internet.";
  }

  return ret;
};

export const trataErro = (ex, origem) => {
  try {
    let erro = ex;
    erro.message = retornaMsgErro(ex);

    if (!erro?.tipoAlerta) {
      erro.tipoAlerta = animacoes.erro;
      if (ex?.response?.status == "500") erro.tipoAlerta = animacoes.aviso;
    }

    erro.origem = montaOrigem(ex, origem);

    return erro;
  } catch (e) {
    return ex;
  }
};

export const retornaErroValidacao = (mensagem) => {
  try {
    let erro = new Error(mensagem);
    erro.tipoAlerta = animacoes.aviso;

    return erro;
  } catch (e) {
    return e;
  }
};

export const montaOrigem = (ex, origem) => {
  try {
    let erro = ex;

    erro.origem = "".concat(ex?.origem, ex?.origem && " - ", origem);

    return erro;
  } catch (e) {
    return ex;
  }
};
//#endregion

Date.prototype.isDate = function () {
  return this !== "Invalid Date" && !isNaN(this) ? true : false;
};
export const isNumerico = (texto) => /[0-9]/.test(texto);
export const formataValor = (valor, casasDecimais) => {
  if (!valor) valor = 0.0;
  if (!casasDecimais) casasDecimais = 2;
  if (!isNumerico(valor)) valor = 0;

  return parseFloat(valor).toLocaleString("pt-BR", {
    minimumFractionDigits: casasDecimais,
  });
};
export function formatDataHora(valor, formatoReturn, emPT) {
  try {
    if (!valor) return "";
    if (!emPT) emPT = false;
    let ret = "";

    if (valor.length < 11) return "";
    const formatoValid = "yyyy-MM-dd HH:mm:ss";
    if (!formatoReturn) formatoReturn = "dd/MM/yyyy";
    var data = dateTz.toDate(valor);

    if (!data.isDate()) return "";

    if (emPT) {
      ret = date.format(data, formatoReturn, { locale: ptBR });
    } else {
      ret = date.format(data, formatoReturn);
    }
    // if (emPT) date.locale('en');

    return ret;
  } catch (e) {
    throw new Error(e.message);
  }
}

export const toDouble = (texto) => {
  if (!texto) return 0;
  if (!isNumerico(texto)) texto = 0;

  return parseFloat(parseFloat(texto).toFixed(2));
};
export const toStringDouble = (texto) => {
  if (!texto) return 0;
  if (!isNumerico(texto)) texto = 0;

  return parseFloat(
    parseFloat(texto.toString().replace(".", "").replace(",", ".")).toFixed(2)
  );
};
export const doubleToInterger = (valor) => {
  if (!valor) return 0;

  var ret = formataValor(valor, 0);
  return parseFloat(ret.toString().replace(".", "").replace(",", ""));
};

export function convertToDateTime(dateString) {
  console.log(`convertToDateTime => ${dateString} || ${parseISO(dateString)}`);
  return parseISO(dateString);
}

export function dataServidor(valor, strFormato) {
  try {
    if (!valor) return "";
    let ret = "";
    if (!strFormato) strFormato = "yyyy-MM-dd";

    if (!new Date(valor).isDate()) return "";
    var data = date.parse(valor, strFormato, new Date());

    return date.format(data, "yyyy-MM-dd");
  } catch (e) {
    throw new Error(e.message);
  }
}

export function formatData(valor, formato, formatoReturn, emPT) {
  try {
    if (!valor) return "";
    if (!formato) formato = "yyyy-MM-dd";
    if (!emPT) emPT = false;
    if (!formatoReturn) formatoReturn = "yyyy-MM-dd";
    let ret = "";

    if (toSoNumero(valor.toString()).length < 8) return null;

    var data = date.parse(valor, formato, new Date());
    if (emPT) {
      ret = date.format(data, formatoReturn, { locale: ptBR });
    } else {
      ret = data;
    }
    // }else{
    //     ret = date.format(data, formatoReturn);

    // }

    return ret;
  } catch (e) {
    throw new Error(e.message);
  }
}

export function formatDataBrazil(dateString) {
  if (!dateString) return ""; // Tratar caso a data seja nula ou vazia
  if (dateString == "0001-01-01T00:00:00") return ""; // Tratar caso a data seja data inicial

  const dateObject = parseISO(dateString); // Converte a string para objeto Date

  console.log(
    `formatDataBrazil => ${dateObject} || ${format(dateObject, "dd/MM/yyyy", {
      locale: ptBR,
    })}`
  );

  return format(dateObject, "dd/MM/yyyy", { locale: ptBR });
}

export const toSoNumero = (valor) => {
  if (!valor) return "";
  return valor.replace(/[^0-9]/g, "");
};

export const toSoAlphaNumerico = (valor) => {
  if (!valor) return "";
  return valor.replace(/[^A-Za-z0-9À-ÿ_]/g, "");
};

export const validaRegex = (valor, oRegex) => {
  if (valor.match(oRegex)) return oRegex;
  return false;
};

export const validaEmail = (valor) => {
  if (!valor) return "";

  const regex = validaRegex(
    valor,
    /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i
  );
  if (!regex) {
    return "Email inválido";
  }

  return "";
};

export const validaCPF = (numero) => {
  try {
    if (!numero) return "";
    var i;
    numero = numero.replace(/[^a-zA-Z0-9 ]/gi, "");

    var s = numero;
    var c = s.substr(0, 9);
    var dv = s.substr(9, 2);
    var d1 = 0;
    for (i = 0; i < 9; i++) {
      d1 += c.charAt(i) * (10 - i);
    }
    if (d1 == 0) {
      return "CPF Inválido";
    }
    d1 = 11 - (d1 % 11);
    if (d1 > 9) d1 = 0;
    if (dv.charAt(0) != d1) {
      return "CPF Inválido";
    }
    d1 *= 2;
    for (i = 0; i < 9; i++) {
      d1 += c.charAt(i) * (11 - i);
    }
    d1 = 11 - (d1 % 11);
    if (d1 > 9) d1 = 0;
    if (dv.charAt(1) != d1) {
      return "CPF Inválido";
    }

    return "";
  } catch (e) {
    throw new Error(e);
  }
};

export function toTexto(valor) {
  if (!valor) return "";
  return valor;
}
