import React, { useLayoutEffect, useCallback, useState } from "react";
import Helmet from "react-helmet";
import { useLocation, useHistory } from "react-router-dom";

import Dado from "../../layout/tela/infoGraficos/dado";
import TabPanel from "../../layout/tabPanel";
import Panel from "../../layout/tabPanel/panel";
import { Carregando } from "../../loading";
import Timeline from "../../layout/timeline";

import { FiSearch, FiTag, FiCheck } from "react-icons/fi";
import { FaCarAlt } from "react-icons/fa";

import Tela from "../../layout/tela";
import * as Tema from "../../layout/tela/style";

import * as utils from "../../../utils";
import * as api from "../../../API/consulta";

const Licenciamento = () => {
  const corInfoGrafico = "#a76f12";
  const history = useHistory();
  const location = useLocation();
  const dataUrl = location.state;
  const [data, setData] = useState(null);
  const [propsAlerta, setPropsAlerta] = useState(utils.propsAlerta);

  const voltar = useCallback(() => {
    window.scrollTo(0, 0);

    history.push("/licenciamento", {});
  }, [history]);

  const download = () => {
    try {
      const ret = api.downloadLicenciamento({ placa: data?.Placa });

      window.open(ret);
    } catch (e) {
      setPropsAlerta(
        utils.montaAlerta({
          titulo: e.message,
          origem: e.origem,
          tAnimacao: e.tipoAlerta,
        })
      );
    }
  };

  const downloadCRLV = async ({ placa }) => {
    try {
      const ret = await api.anexarCRLV({
        placa: placa,
      });

      setPropsAlerta(utils.semAlerta);
    } catch (e) {
      setPropsAlerta(
        utils.montaAlerta({
          titulo: e.message,
          origem: e.origem,
          tAnimacao: e.tipoAlerta,
        })
      );
    }
  };

  const retornaTimelineLicenciamento = async (codFlowExecute) => {
    try {
      return await api.retornaTimelineLicenciamento({ codFlowExecute });
    } catch (e) {
      // console.log(e);
    }
  };

  useLayoutEffect(() => {
    if (!dataUrl) {
      voltar();
      return;
    }
    async function funcao() {
      try {
        const dados = dataUrl.data;
        {
          console.log("codFloeExecute:" + dados?.CodFlowExecute);
        }
        dados.timeline = await retornaTimelineLicenciamento({
          codFlowExecute: dados?.CodFlowExecute,
        });
        setData(dados);
      } catch (e) {
        setPropsAlerta(
          utils.montaAlerta({
            titulo: e.message,
            origem: e.origem,
            tAnimacao: e.tipoAlerta,
          })
        );
      }
    }
    funcao();
  }, [dataUrl, voltar]);

  const montaEtapas = () => {
    const etapas = [
      {
        titulo: "Levantamento de débito",
        subTitulo: "20/06/2018",
        ativo: true,
        icone: FiSearch,
      },
      {
        titulo: "Detran",
        subTitulo: "20/06/2018",
        ativo: true,
        icone: FaCarAlt,
      },
      {
        titulo: "Gerar Etiqueta",
        subTitulo: "20/06/2018",
        ativo: false,
        icone: FiTag,
      },
      {
        titulo: "Licenciamento Concluído",
        subTitulo: "22/06/2018",
        ativo: false,
        icone: FiCheck,
      },
    ];
    return etapas;
  };

  return (
    <Tela
      titulo={"Licenciamento"}
      subTitulo={"Detalhamento do Licenciamento"}
      icone={"pe-7s-car"}
      sair={true}
      menu={true}
      onSair={(e) => voltar()}
    >
      <TabPanel defaultPanel={"1"}>
        <Panel titulo={"Info Gráfico"} key={"1"}>
          <Tema.CardFormInfoGrafico>
            <Tema.CardForm>
              <Tema.CardCabecalho style={{ backgroundColor: corInfoGrafico }}>
                <Tema.CardCabecalhoFundoImagem />
                <Tema.CardCabecalhoDivTitulo>
                  <Tema.CardCabecalhoTitulo>
                    {" "}
                    Dados do Licenciamento
                  </Tema.CardCabecalhoTitulo>
                  <Tema.CardCabecalhoSubTitulo>
                    Abaixo dados do licenciamento
                  </Tema.CardCabecalhoSubTitulo>
                </Tema.CardCabecalhoDivTitulo>
              </Tema.CardCabecalho>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  color: "#70899b",
                  justifyContent: "center",
                }}
              >
                <Dado
                  icone={"pe-7s-car"}
                  titulo={"Placa"}
                  valor={data?.Placa}
                />
                <Dado
                  icone={"pe-7s-date"}
                  titulo={"Ano Exercício"}
                  // Fellipe - 24/08/2023 => Alterando a Exibição para "anoExecicio"
                  valor={data?.AnoExercicio}
                />
              </div>
            </Tema.CardForm>
            <Tema.CardForm>
              <Tema.CardCabecalho style={{ backgroundColor: corInfoGrafico }}>
                <Tema.CardCabecalhoFundoImagem />
                <Tema.CardCabecalhoDivTitulo>
                  <Tema.CardCabecalhoTitulo>
                    Documentos do Licenciamento
                  </Tema.CardCabecalhoTitulo>
                  <Tema.CardCabecalhoSubTitulo>
                    Abaixo faça o download do último CRLV disponível
                  </Tema.CardCabecalhoSubTitulo>
                </Tema.CardCabecalhoDivTitulo>
              </Tema.CardCabecalho>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  color: "#70899b",
                }}
              ></div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  color: "#70899b",
                }}
              >
                <Dado
                  style={{
                    width: "100%",
                    alignItems: "center",
                    margin: "auto",
                    padding: 20,
                  }}
                  colunaUnica={true}
                  icone={"pe-7s-cloud-download"}
                  titulo={"Download"}
                  valor={
                    "Clique aqui para baixar o último CRLV vigente deste veículo"
                  }
                  onClick={() => {
                    // download();
                    // Fellipe - 24/08/2023 => Incluindo para fazer o download do mais atual
                    downloadCRLV({ placa: data?.Placa });
                  }}
                />
              </div>
            </Tema.CardForm>

            {/* <Tema.CardForm style={{minHeight: '150px', width: '100%', padding:0, textAlign:'center'}}>
            <TimeLine colorActive={"var(--color-cliente)"} colorDefault={"#c3ccc2"} etapas={montaEtapas()}  />
        </Tema.CardForm> */}
          </Tema.CardFormInfoGrafico>
        </Panel>
        <Panel titulo={"Timeline"} key={"2"}>
          <Tema.CardForm style={{ minHeight: "150px", width: "100%" }}>
            <Tema.CardCabecalho
              style={{ backgroundColor: `var(--color-cliente)` }}
            >
              <Tema.CardCabecalhoFundoImagem />
              <Tema.CardCabecalhoDivTitulo>
                <Tema.CardCabecalhoTitulo>
                  {" "}
                  Placa: {data?.Placa}
                </Tema.CardCabecalhoTitulo>
                <Tema.CardCabecalhoSubTitulo>
                  Ano Exercício: {data?.AnoExercicio}
                </Tema.CardCabecalhoSubTitulo>
              </Tema.CardCabecalhoDivTitulo>
            </Tema.CardCabecalho>
          </Tema.CardForm>
          <Tema.CardForm style={{ minHeight: "150px", width: "100%" }}>
            <Tema.CardFormNotificacaoDetalheMultiLine>
              <Timeline res={data?.timeline} />
            </Tema.CardFormNotificacaoDetalheMultiLine>
          </Tema.CardForm>
        </Panel>
      </TabPanel>

      <Carregando
        tipo={propsAlerta.tipoAnimacao}
        exibir={propsAlerta.exibir}
        userClose={propsAlerta.usuarioFechar}
        loop={true}
        titulo={propsAlerta.titulo}
        origem={propsAlerta.origem}
        setExibir={() => {
          setPropsAlerta(utils.semAlerta);
        }}
        // styleFundo={{width:'90%', height:'90%'}}
        // styleAnimacao={{width:'70%'}}
      />
    </Tela>
  );
};

export default Licenciamento;
