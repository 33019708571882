import React,{useState} from 'react';

import Tabela from '../tabela/dataGrid';
import ModalHub from '../modal/modalui'
import * as api from '../../../API/consulta'
import * as Tema from "./style";

import Select from '../tela/campos/select'

const customStyles = {
    content:{
      style: {
        backgroundColor:'red',
      height: '17px',
    },

    },
    header: {
      style: {
        display:'none',
      },
    },
    headRow: {
      style: {
        borderTopStyle: 'solid',
        borderTopWidth: '1px',
        paddingRight: '17px',
        borderTopColor: 'rgba(0,0,0,0.12)',
      },
    },
    headCells: {
      style: {
        '&:not(:last-of-type)': {
          borderRightStyle: 'solid',
          borderRightWidth: '1px',
          borderRightColor: 'rgba(0,0,0,0.12)',
        },
      },
    },
    cells: {
      style: {
        '&:not(:last-of-type)': {
          borderRightStyle: 'solid',
          borderRightWidth: '1px',
          borderRightColor: 'rgba(0,0,0,0.12)',
        },
      },
    },
  };

const Detalhes =({idGrafico, titulo, subTitulo,opcoes, data, mostrar, onPesquisar, onMonstrar, onLinhaClique}) =>{


const controlaExibicao = (flag) =>{
    if(onMonstrar) onMonstrar(flag);
}
const clickLinha = async(row,e) =>{
  if(onLinhaClique) await onLinhaClique(row,e);
}
return (
    <ModalHub 
    abrir={mostrar}
    
    onFechar={() => {controlaExibicao(false)}}
    >
      <div>
        <div id="cabecalho" style={{padding: '5px 10px', display:'flex',justifyContent:'space-between',color:'white', background: 'var(--color-primary)'}}>
          <div style={{display:'flex', flexDirection:'column'}}>
        
        
          <span>{titulo}</span>
          {(opcoes?.length > 0?
          <Select name = 'opcoes' valor={subTitulo}
          styleGroup={{marginTop: 5}}
          styleCampo={{background: 'var(--color-cliente)',color:'white', height: 'auto',borderWidth: 0, paddingLeft: 0}}
          source={opcoes?.map(x=> ({valor: x, descricao: x}))}
          onChange={async(e)=>{
            if(onPesquisar){
              
              await onPesquisar(e);
            }
          }}
          />:
          null
          )}
          
          {/* <span onClick={async()=>{
            if(onPesquisar){
              await onPesquisar(opcoes[1]);
            }
          }}>{subTitulo}</span> */}
          </div>
          <div style={{alignSelf: 'center'}}>
            <a style={{cursor:'pointer'}} onClick={() => {controlaExibicao(false)}}>X</a>
          </div>
        </div>
        <div style={{padding:10}}>
          <Tabela columns={api.colunasGrafico({id: idGrafico})} data ={data} onLinhaClique={clickLinha} />

          {/* <Tabela 
          styles={customStyles}
          colunas={api.colunasGrafico({id: idGrafico})}
          data={data}
          onRowClick={clickLinha}
          filtrarSoVisivel={true}
          textoSemDados={"Sem registros"}
          /> */}
        </div>
        </div>
      
      </ModalHub>

        )
    }
    
    export default Detalhes;