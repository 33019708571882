import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

const media = {
  mobile: "@media(max-width: 900px)",
  desktop: "@media (min-width: 1180px)",
};

export const Container = styled.div`
  background: ${(props) =>
    props.isMenu ? `var(--color-headerMenu)` : `var(--color-header)`};
  padding: 0 30px 0 25px;
  width: 100%;

  /* position: fixed; */
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  // display: none;
  display: flex;
  box-shadow: 0 0.46875rem 2.1875rem rgba(0, 0, 0, 0.03),
    0 0.9375rem 1.40625rem rgba(0, 0, 0, 0.03),
    0 0.25rem 0.53125rem rgba(0, 0, 0, 0.05),
    0 0.125rem 0.1875rem rgba(0, 0, 0, 0.03);
  height: ${(props) => (props.isMenu ? `60px` : `60px`)};
  @media (min-width: 1180px) {
    display: flex;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
  max-width: 1128px;
  /* margin: 0 auto; */
  height: 100%;
  .logo {
    width: 149px;
    height: 40px;
  }
  .left,
  .right nav {
    display: flex;
    align-items: center;
    align-self: center;
  }
  .right nav {
    height: 100%;
    button {
      background: none;
      border: 0;
      outline: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      min-width: 90px;
      min-height: 100%;
      color: var(--color-icons);
      cursor: pointer;
      &:hover {
        color: var(--color-white);
      }
      &.active {
        border-bottom: 2px solid var(--color-white);
      }
    }
  }
`;

export const SearchInput = styled.input`
  margin-left: 12px;
  background: var(--color-input);
  color: var(--color-black);
  font-size: 14px;
  padding: 7.5px 8px;
  border: none;
  outline: none;
  border-radius: 2px;
  &:focus {
    background: var(--color-white);
  }
`;

const generalIconCSS = css`
  width: 24px;
  height: 24px;
`;

// export const HomeIcon = styled(AiFillHome)`
//   ${generalIconCSS}
// `;

// export const NotificationsIcon = styled(AiOutlineBell)`
//   ${generalIconCSS}
// `;

export const ProfileCircle = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px solid var(--color-icons);
`;

export const DivDireita = styled.div`
  align-items: center;
  display: flex;
  margin-left: auto;
  justify-content: flex-end;
`;

export const DivDireitaDados = styled.div`
  flex-direction: row;
  align-items: center;
`;

export const DivGrupoUsuario = styled.div`
  display: flex;
  flex: 1;
  position: relative;
  align-items: center;
  /* top: 10px; */
`;

export const DivOpcoesUsuario = styled.div`
  position: relative;
  display: flex;
  vertical-align: middle;
`;

export const UsuarioLink = styled.a`
  display: inline-block;
  padding: 0 !important;
  outline: none;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s, background-color 0.15s, border-color 0.15s,
    box-shadow 0.15s;
  font-size: 0.8rem;
  position: relative;
  flex: 1 1 auto;
  /* color: rgba(255,255,255,0.8); */
  &:not([href]):not([tabindex]) {
    text-decoration: none;
  }
  &:not(:disabled):not(.disabled) {
    cursor: pointer;
  }
`;

export const LinkTela = styled(Link)`
  display: inline-block;
  padding: 0 !important;
  outline: none;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s, background-color 0.15s, border-color 0.15s,
    box-shadow 0.15s;
  font-size: 0.8rem;
  position: relative;
  flex: 1 1 auto;
  /* color: rgba(255,255,255,0.8); */
  &:not([href]):not([tabindex]) {
    text-decoration: none;
  }
  &:not(:disabled):not(.disabled) {
    cursor: pointer;
  }
`;
export const UsuarioIcone = styled.i`
  text-align: center;
  width: 34px;
  height: 34px;
  line-height: 34px;
  left: 5px;
  font-size: xx-large;
  transition: color 300ms;
  opacity: 0.8;
  color: #fff;
`;

export const DivMenuUsuario = styled.div`
  position: absolute;
  will-change: transform;
  top: 0px;
  left: 0px;
  transform: translate3d(-130px, 30px, 0px);
  z-index: 1000;
  float: left;
  min-width: 150px;
  padding: 0.65rem 0;
  font-size: 0.88rem;
  color: #495057;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  box-shadow: 0 0.46875rem 2.1875rem rgb(4 9 20 / 3%),
    0 0.9375rem 1.40625rem rgb(4 9 20 / 3%),
    0 0.25rem 0.53125rem rgb(4 9 20 / 5%), 0 0.125rem 0.1875rem rgb(4 9 20 / 3%);
  margin: 0.125rem;
  bottom: auto;
  display: none;

  right: 0 !important;
  ${(props) =>
    props.exibir &&
    `display: block; animation: fade-in2 0.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;`}
`;

export const DivItemMenuUsuario = styled.button`
  display: block;
  width: 100%;
  padding: 0.4rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  &:first-child {
    border-top-left-radius: calc(0.25rem - 1px);
    border-top-right-radius: calc(0.25rem - 1px);
  }
  font-size: 0.88rem;
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease, color 0.3s ease;
  cursor: pointer;
  z-index: 6;
  position: relative;
`;

export const BtnHamburger = styled.button`
  padding: 0px 0px;
  display: ${(props) => (props.isMenuVisible ? `inline-block;` : `none`)};
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
  outline: none !important;
  transition: all 300ms;
  ${media.mobile} {
    display: none;
  }
`;

export const SpanBurguer = styled.span`
  width: 24px;
  height: 14px;
  display: inline-block;
  position: relative;
`;

export const SpanBurguerInner = styled.span`
  background-color: rgba(255, 255, 255, 0.8) !important;
  top: 1px;
  transition-duration: 0.275s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);

  width: 24px;
  height: 2px;
  border-radius: 10px;
  position: absolute;
  transition-property: transform;

  display: block;
  margin-top: -1px;

  &:before {
    background-color: rgba(255, 255, 255, 0.8) !important;
    top: 6px;
    transition: opacity 0.125s 0.275s ease;
    content: "";
    display: block;
    width: 24px;
    height: 2px;
    background-color: #3f6ad8;
    border-radius: 10px;
    position: absolute;
  }
  &:after {
    background-color: rgba(255, 255, 255, 0.8) !important;
    top: 12px;
    transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    bottom: -6px;
    content: "";
    display: block;
    width: 24px;
    height: 2px;
    background-color: #3f6ad8;
    border-radius: 10px;
    position: absolute;
  }
`;

export const SpanBurguerMobileInner = styled.span`
  background-color: rgba(255, 255, 255, 0.8) !important;
  top: 1px;
  transition-duration: 0.275s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);

  width: 24px;
  height: 2px;
  border-radius: 10px;
  position: absolute;
  transition-property: transform;

  display: block;
  margin-top: -1px;
  transform: translate3d(0, 6px, 0) rotate(135deg);
  transition-delay: 0.075s;
  &:before {
    background-color: rgba(255, 255, 255, 0.8) !important;
    transition-delay: 0s;
    opacity: 0;
    top: 6px;
    transition: opacity 0.125s 0.275s ease;
    content: "";
    display: block;
    width: 24px;
    height: 2px;
    background-color: #3f6ad8;
    border-radius: 10px;
    position: absolute;
  }
  &:after {
    background-color: rgba(255, 255, 255, 0.8) !important;
    transform: translate3d(0, -12px, 0) rotate(-270deg);
    transition-delay: 0.075s;
    top: 12px;
    transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    bottom: -6px;
    content: "";
    display: block;
    width: 24px;
    height: 2px;
    background-color: #3f6ad8;
    border-radius: 10px;
    position: absolute;
  }
`;

export const MenuHamburguer = styled.div`
  display: none;

  ${media.mobile} {
    width: 90%;
    display: flex;
    justify-content: flex-start;
  }
`;
