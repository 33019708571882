import styled from "styled-components";

const media = {
  mobile: "@media(max-width: 900px)",
  desktop: "@media (min-width: 1180px)",
};

export const Group = styled.div`
  display: flex;
  flex-direction: column;
  flex-flow: wrap;
  flex-grow: 1;
  position: relative;
  margin-top: 15px;
  width: inherit;
  ${media.mobile} {
    width: 100%;
  }
`;

export const Label = styled.label`
  font-size: 16px;
  font-family: var(--font-padrao);
  padding: 0 12px;
  width: max-content;
  color: #999;
  pointer-events: none;
  position: absolute;
  transform: translate(0, 14px) scale(1);
  transform-origin: top left;
  transition: all 0.2s ease-out;

  ${(props) =>
    props.selecionado &&
    `
  transform: translate(0,-7px) scale(0.75);
    background: white;
    margin-left: 10px;
    padding: 0 10px;
     color:var(--color-primary);
    `}
`;

export const TextInput = styled.input`
  width: 100%;
  height: 45px;
  padding: 0 16px 0 10px;
  outline: 0;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: #fff;
  font-family: var(--font-padrao);
  font-size: 16px;

  ${(props) =>
    props.align &&
    `
  text-align: ${props.align};
    `}
  &:focus {
    border-color: var(--color-primary);
  }
`;

export const RadioField = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`;
export const SpanRadioInput = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 21px;
  width: 21px;
  border: 1px solid var(--color-primary);
  background-color: #fff;
  border-radius: 6px;
  &:after {
    content: "";
    position: absolute;
    display: none;
  }
`;

export const DivRadioInput = styled.div`
  display: block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  &:hover input ~ ${SpanRadioInput} {
    background-color: var(--color-primary);
    opacity: 0.5;
  }
  & input:checked ~ ${SpanRadioInput} {
    background-color: var(--color-primary);
    border-radius: 6px;
  }
  & input:checked ~ ${SpanRadioInput}:after {
    display: block;
  }
  & ${SpanRadioInput}:after {
    left: 7px;
    top: 3px;
    width: 3px;
    height: 8px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  &:focus {
    border-color: var(--color-primary);
  }
`;

export const TextSelect = styled.select`
  width: 100%;
  height: 45px;
  padding: 0 16px 0 10px;
  outline: 0;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: #fff;
  font-family: var(--font-padrao);
  font-size: 16px;

  ${(props) =>
    props.align &&
    `
  text-align: ${props.align};
    `}
  &:focus {
    border-color: var(--color-primary);
  }
`;
