import styled from "styled-components";

const media = {
  mobile: "@media(max-width: 900px)",
  desktop: "@media (min-width: 1180px)",
};

export const DropDownMenu = styled.div`
  display: none;
  position: absolute;
  will-change: transform;
  top: -10px;
  left: -30px;
  transform: translate3d(-296px, 44px, 0px);
  min-width: 22rem;
  right: 0 !important;

  ${(props) =>
    props.exibir &&
    `display: block; animation: fade-in2 0.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;`}

  bottom: auto;
  box-shadow: 0 0.46875rem 2.1875rem rgb(8 10 37 / 3%),
    0 0.9375rem 1.40625rem rgb(8 10 37 / 3%),
    0 0.25rem 0.53125rem rgb(8 10 37 / 5%),
    0 0.125rem 0.1875rem rgb(8 10 37 / 3%);
  font-size: 0.88rem;
  margin: 0.125rem;
  z-index: 1000;
  float: left;
  padding: 0.65rem 0 0 0;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;

  ${media.mobile} {
    display: none;
    position: absolute;
    will-change: transform;
    top: 50%;
    left: 115%;
    -webkit-transform: translate3d(-296px, 44px, 0px);
    -ms-transform: translate3d(-296px, 44px, 0px);
    transform: translate3d(-296px, 44px, 0px);
    min-width: 19rem;
    right: 0 !important;

    ${(props) =>
      props.exibir &&
      `display: block; animation: fade-in2 0.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;`}
    -webkit-animation: fade-in2 0.2s cubic-bezier(0.39,0.575,0.565,1) both;
    animation: fade-in2 0.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    bottom: auto;
    box-shadow: 0 0.46875rem 2.1875rem rgb(8 10 37 / 3%),
      0 0.9375rem 1.40625rem rgb(8 10 37 / 3%),
      0 0.25rem 0.53125rem rgb(8 10 37 / 5%),
      0 0.125rem 0.1875rem rgb(8 10 37 / 3%);
    font-size: 0.88rem;
    margin: 0.125rem;
    z-index: 1000;
    float: left;
    padding: 0.65rem 0 0 0;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
  }
`;
export const DropDownMenuCabecalho = styled.div`
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  color: #fff;
  margin-top: -0.65rem;
  /* margin-bottom: .65rem; */
  position: relative;
  z-index: 6;
`;

export const DropDownMenuCabecalhoInterno = styled.div`
  margin: -1px -1px 0;
  padding: 1.5rem 0.5rem;
  position: relative;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  background-color: var(--color-tertiary);
`;

export const DropDownMenuCabecalhoFundoIMG = styled.div`
  background-size: contain;
  background-image: ${(props) => props.source};
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 8;
  filter: grayscale(80%);
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  opacity: 0.2;
`;

export const DropDownMenuCabecalhoConteudo = styled.div`
  padding-left: 0.5rem;
  position: relative;
  z-index: 10;
  text-align: left;
  display: block;
`;

export const DropDownMenuCabecalhoGrupo = styled.div`
  display: block;
  padding: 0;
  flex-direction: row;
  align-items: center;
`;

export const DropDownMenuCabecalhoGrupoInterno = styled.div`
  display: flex;
  flex: 1 1;
  position: relative;
  align-items: center;
`;

export const DropDownMenuCabecalhoGrupoLogo = styled.div`
  display: block;
  margin-right: 1rem;
`;

export const DropDownMenuIMG = styled.img`
  border-radius: 50%;
  vertical-align: middle;
  border-style: none;
  width: 42px;
`;

export const DropDownMenuCabecalhoGrupoTitulo = styled.div`
  display: block;
`;

export const DropDownMenuTitulo = styled.div`
  /* opacity: .8; */
  font-weight: 700;
`;
export const DropDownMenuSubTitulo = styled.div`
  /* opacity: .8; */
`;

export const DropDownMenuCabecalhoGrupoBotao = styled.div`
  margin-left: auto;
  margin-right: 0.5rem;
`;

export const DropDownMenuBotao = styled.button`
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  cursor: pointer;
  outline: none;
  color: #fff;
  background-color: var(--color-cliente);
  border-color: var(--color-cliente);

  position: relative;
  transition: color 0.15s, background-color 0.15s, border-color 0.15s,
    box-shadow 0.15s;
  font-size: 0.8rem;
  font-weight: 500;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  box-shadow: 0 0.125rem 0.625rem rgb(68 64 84 / 40%),
    0 0.0625rem 0.125rem rgb(68 64 84 / 50%);
`;
