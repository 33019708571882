import React from "react";

import * as Tema from "../../layout/tela/style";
import DropZone from "../../layout/tela/campos/dropzone";
import { AnimacaoLottie } from "../../loading/lottie";
import * as utils from "../../../utils";


const arquivos = { Termo: [], CNH: [] };



const UploadTermo = ({ dadosNot, setField, data, onChange, erros }) => {
  const editarArquivos = ({ tipo, files }) => {
    if (tipo == 1) {
      arquivos.Termo = files;
    } else if (tipo == 2) {
      arquivos.CNH = files;
    }
    setField("Uploads", arquivos);
  };

  const ImagemCNH =() =>{
    return (
    <div style={{height:'100%', width:'100%', display:'flex', justifyContent:'center'}}>
    <div style={{ width:'90%'}}>
         <AnimacaoLottie tipo ={utils.animacoes.uploadCNH} loop={true} />
         </div>
         </div>
    )
  }
  const ImagemTermo =() =>{
    return (
    <div style={{height:'100%', width:'100%', display:'flex', justifyContent:'center'}}>
    <div style={{ width:'50%'}}>
      <AnimacaoLottie tipo ={utils.animacoes.uploadTermo} loop={true} />
      </div>
      </div>
    )
  }

  return (
    <React.Fragment>
      <Tema.CardForm>
        {/* <Tema.FormTitulo>Dados da Notificação </Tema.FormTitulo> */}
        <div style={{ padding: 5, gap: 10, flexFlow: "wrap" }}>
          {/* <span> Dados do Notificação </span> */}
          <div
            style={{ padding: 5, display: "flex", gap: 10, flexFlow: "wrap" }}
          >
            <Tema.CardFormUpload>
      
              <div
                style={{
                  padding: 5,
                  display: "flex",
                  gap: 10,
                  width: "100%",
                  alignitems: "center",
                  justifyContent: "center",
                  color: "#228b22",
                }}
              >
                <DropZone
                  name={"Uploads.Termo"}
                  texto={"Arraste e solte o seu termo assinado aqui"}
                  arquivosAceitos={[".pdf", "image/*"]}
                  Icon = {ImagemTermo}
                  QtdArquivos={1}
                  onChange={(files) => {
                    editarArquivos({ tipo: 1, files });
                  }}
                />
              </div>

              <div
                style={{
                  padding: 5,
                  display: "flex",
                  gap: 10,
                  width: "100%",
                  alignitems: "center",
                  justifyContent: "center",
                  color: "#228b22",
                }}
              >
                <DropZone
                  name={"Uploads.CNH"}
                  texto={"Arraste e solte a sua CNH aqui"}
                  arquivosAceitos={[".pdf", "image/*"]}
                  Icon = {ImagemCNH}
                  QtdArquivos={1}
                  onChange={(files) => {
                    editarArquivos({ tipo: 2, files });
                  }}
                />
              </div>
            </Tema.CardFormUpload>
          </div>
        </div>
      </Tema.CardForm>
    </React.Fragment>
  );
};

export default UploadTermo;
