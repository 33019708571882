import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Login from '../components/pages/login';
import Indicacao from "../components/pages/indicacao";
import IndicacaoFinalizacao from "../components/pages/indicacao/Finalizacao";
import MinhaIndicacao from "../components/pages/indicacao/minhaIndicacao";


const RoutePublica = () => {
    return (
      <Switch>
        <Route path="/login" exact component={Login} />
        <Route path="/minhaindicacao"  component={MinhaIndicacao} />
 
        {/* <Route path="/indicacao" component={Indicacao} />
        <Route path="/notificacaoFinalizacao" component={IndicacaoFinalizacao} /> */}
      </Switch>
    );
  };

  

export default RoutePublica;