import React, { useLayoutEffect } from "react";
import DataTable from "react-data-table-component";

import * as Tema from "./style";

import Spinner from "../../loading/spinner";

import { AiOutlineClear } from "react-icons/ai";
import { FaFilter, FaFileCsv } from "react-icons/fa";

// const FilterComponent = ({ filterText, onFilter, onClear }) => (
//     <>
//       <Tema.TextField id="search" type="text" placeholder="Filtrar" aria-label="Search Input" value={filterText} onChange={onFilter} />
//       <Tema.ClearButton type="button" onClick={onClear}>X</Tema.ClearButton>
//     </>
//   );

const Filtrar = (array, texto, listaProps) => {
  if (!listaProps) listaProps = [];
  if (texto.length === 0) return array;
  const result = array.filter(function (o) {
    return Object.keys(o).some(function (k) {
      if (listaProps.length > 0) {
        if (!listaProps.some((x) => x.selector === k)) return false;
      }
      if (o[k] == null) return false;
      return (
        o[k].toString().toLowerCase().indexOf(texto.toString().toLowerCase()) !=
        -1
      );
    });
  });

  return result;
};

const customStyles = {
  table: {
    style: {},
  },
  // header: {
  //   style: {
  //     minHeight: '56px',
  //     backgroundColor: 'red',
  //     paddingTop:'40px',
  //   },
  // },
  subHeader: {
    style: {
      backgroundColor: "transparent",
    },
  },
  head: {
    style: { backgroundColor: "white", paddingTop: "25px" },
  },
  headRow: {
    style: {
      borderTopStyle: "solid",
      borderTopWidth: "1px",
      paddingRight: "17px",
      borderTopColor: "rgba(0,0,0,0.12)",
    },
  },
  headCells: {
    style: {
      "&:has(> span.center)": {
        justifyContent: "center",
      },
      "&:not(:last-of-type)": {
        borderRightStyle: "solid",
        borderRightWidth: "1px",
        borderRightColor: "rgba(0,0,0,0.12)",
      },
    },
  },
  cells: {
    style: {
      "&:not(:last-of-type)": {
        borderRightStyle: "solid",
        borderRightWidth: "1px",
        borderRightColor: "rgba(0,0,0,0.12)",
      },
    },
  },
};

const customStylesMobile = {
  table: {
    style: {},
  },
  // header: {
  //   style: {
  //     minHeight: '56px',
  //     backgroundColor: 'red',
  //     paddingTop:'40px',
  //   },
  // },
  subHeader: {
    style: {
      backgroundColor: "transparent",
    },
  },
  head: {
    style: { backgroundColor: "white", paddingTop: "25px" },
  },
  headRow: {
    style: {
      borderTopStyle: "solid",
      borderTopWidth: "1px",
      paddingRight: "0px",
      borderTopColor: "rgba(0,0,0,0.12)",
    },
  },
  headCells: {
    style: {
      "&:has(> span.center)": {
        justifyContent: "center",
      },
      "&:not(:last-of-type)": {
        borderRightStyle: "solid",
        borderRightWidth: "1px",
        borderRightColor: "rgba(0,0,0,0.12)",
      },
    },
  },
  cells: {
    style: {
      "&:not(:last-of-type)": {
        borderRightStyle: "solid",
        borderRightWidth: "1px",
        borderRightColor: "rgba(0,0,0,0.12)",
      },
    },
  },
};

const trataTexto = (texto) => {
  return texto.toString().replaceAll(";", ",").replaceAll("\n", " ");
};

function convertArrayOfObjectsToCSV(array, config) {
  let result;
  const colunas = config.colunas;

  const columnDelimiter = ";";
  const lineDelimiter = "\n";
  // const keys = Object.keys(array[0]).filter(x => colunas.map(x =>x.name).includes(x));
  const keys = colunas;

  result = "";
  result += keys.map((x) => x.titulo).join(columnDelimiter);
  result += lineDelimiter;

  array.forEach((item) => {
    let ctr = 0;
    keys.forEach((key) => {
      if (ctr > 0) result += columnDelimiter;

      if (key.format) result += trataTexto(key.format(item, ctr) ?? "");
      else result += trataTexto(item[key.name] ?? "");

      ctr++;
    });
    result += lineDelimiter;
  });

  return result;
}

function downloadCSV(array, config) {
  const link = document.createElement("a");
  let csv = convertArrayOfObjectsToCSV(array, config);
  if (csv == null) return;

  const filename = `${config.nomeArquivo}.csv`;

  // var processdata = "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURIComponent(csv);

  if (!csv.match(/^data:text\/csv/i)) {
    // csv = `data:text/csv;charset=utf-8,${csv}`;
    csv = "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURIComponent(csv);
  }
  // link.setAttribute('href', encodeURI(csv));

  link.setAttribute("href", csv);
  link.setAttribute("download", filename);
  link.click();
}

const Opcoes = ({
  exportConfig,
  data,
  filterText,
  setFilterText,
  handleClear,
}) => {
  const [exibirFiltro, setExibirFiltro] = React.useState(false);
  const controlaVisualizacaoFiltro = ({ texto }) => {
    if (texto) setExibirFiltro(true);
  };
  const onSetFitlter = (texto, paste) => {
    if (paste) setFilterText("");
    setFilterText(texto);
    controlaVisualizacaoFiltro({ texto });
  };
  const onClickIconeFiltro = (e) => {
    exibirFiltro && handleClear(e);
    setExibirFiltro(!exibirFiltro);
  };
  return (
    <div style={{ display: "flex", flexDirection: "row", gap: "15px" }}>
      {exportConfig?.CSV?.exibir && (
        <Tema.DivIcone onClick={() => downloadCSV(data, exportConfig.CSV)}>
          {/* <Tema.Icone className={'pe-7s-cloud-download'} /> */}
          <FaFileCsv />
        </Tema.DivIcone>
      )}

      {/* <div style={{ display: "flex" }}>
        <Tema.TextField
          exibir={exibirFiltro}
          id="search"
          type="text"
          placeholder="Filtrar"
          aria-label="Search Input"
          value={filterText}
          //onPaste={(e) => {onSetFitlter(e.clipboardData.getData('Text'), true);}}
          onChange={(e) => {
            onSetFitlter(e.target.value);
          }}
        />

        <Tema.DivIcone exibirFiltro={exibirFiltro} onClick={onClickIconeFiltro}>
          {exibirFiltro ? <AiOutlineClear /> : <FaFilter />}
        </Tema.DivIcone>
      </div> */}
    </div>
  );
};

export default Opcoes;
