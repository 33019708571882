import React, { useEffect, useCallback, useState } from "react";
import Helmet from "react-helmet";
import { useHistory } from "react-router-dom";

import Tela from "../../layout/tela";
import Tabela from "../../layout/tabela/dataGrid";
import * as Tema from "../../layout/tela/style";
import * as Padrao from "../../../style/config";
import Input from "../../layout/tela/campos/inputUI";
import ButtonMaterial from "@material-ui/core/Button";
import { Formik } from "formik";
import schema from "../../../schemas/dashboard";
import Grafico from "../../layout/grafico/graficoDash";

import { Carregando } from "../../loading";

import * as api from "../../../API/consulta";
import * as utils from "../../../utils";
import { useDadosGlobal } from "../../context";

const dataAtual = new Date();
const getMonth = dataAtual.getMonth() + 1;
const mesAtual =
  getMonth < 10 ? "0" + getMonth.toString() : getMonth.toString();
const dtIni = dataAtual.getFullYear().toString() + "-" + mesAtual + "-01";
const dtFim = utils.formatDataHora(dataAtual, "yyyy-MM-dd");

const dadosIni = { Dados: [], Grafico: null };

const Listagem = ({ }) => {
  const history = useHistory();
  const { usuario } = useDadosGlobal();
  const [data, setData] = useState(dadosIni);
  const [carregando, setCarregando] = useState(true);
  const [propsAlerta, setPropsAlerta] = useState(utils.propsAlerta);
  const [filterText, setFilterText] = React.useState("");
  const [filtros, setFiltros] = useState({
    codGestor: usuario.codGestor,
    placa: usuario.placa,
    dataIni: dtIni,
    dataFim: dtFim,
    gestorMaster: usuario.gestorMaster,
  });

  // ...
  // ...
  // Coluna de Teste apenas, depois apagar
  // ...
  // ...

  const columnsMaterialUI = [
    {
      field: "Placa",
      headerName: "Placa",
      type: "string",
      headerClassName: "super-app-theme--header",
      width: 175,
      hide: !usuario.codGestor,
    },
    {
      field: "Chassi",
      headerName: "Chassi",
      type: "string",
      headerClassName: "super-app-theme--header",
      flex: 1,
    },
    {
      field: "Renavam",
      headerName: "Renavam",
      type: "string",
      headerClassName: "super-app-theme--header",
      flex: 1,
    },
    {
      field: "MarcaModelo",
      headerName: "MarcaModelo",
      type: "string",
      headerClassName: "super-app-theme--header",
      flex: 1,
    },
    {
      field: "StrPendencia",
      headerName: "Pendencia",
      type: "string",
      headerClassName: "super-app-theme--header",
      flex: 1,
    },
    {
      field: "StatusProcess",
      headerName: "Status",
      type: "string",
      headerClassName: "super-app-theme--header",
      flex: 1,
    },
  ];

  const columnsMaterialUIGestorMaster = [
    {
      field: "Placa",
      headerName: "Placa",
      type: "string",
      headerClassName: "super-app-theme--header",
      width: 175,
      hide: !usuario.codGestor,
    },
    {
      field: "Chassi",
      headerName: "Chassi",
      type: "string",
      headerClassName: "super-app-theme--header",
      flex: 1,
    },
    {
      field: "Renavam",
      headerName: "Renavam",
      type: "string",
      headerClassName: "super-app-theme--header",
      flex: 1,
    },
    {
      field: "MarcaModelo",
      headerName: "MarcaModelo",
      type: "string",
      headerClassName: "super-app-theme--header",
      flex: 1,
    },
    {
      field: "StrPendencia",
      headerName: "Pendencia",
      type: "string",
      headerClassName: "super-app-theme--header",
      flex: 1,
    },
    {
      field: "StatusProcess",
      headerName: "Status",
      type: "string",
      headerClassName: "super-app-theme--header",
      flex: 1,
    },
  ];

  // ...
  // ...
  // Mexendo na parte de teste de cima
  // ...
  // ...

  const ConfigExport = {
    CSV: {
      nomeArquivo: `PrimeiroRegistro`,
      exibir: true,
      colunas: [
        { titulo: "Placa", name: "Placa" },
        { titulo: "Chassi", name: "Chassi"},
        { titulo: "Renavam", name: "Renavam"},
        { titulo: "MarcaModelo", name: "MarcaModelo"},
        { titulo: "Pendencia", name: "StrPendencia"},
        { 
          titulo: "PendenciaFinalPlaca", 
          name: "PendenciaFinalPlaca",
          format: (row, i) => (row.PendenciaFinalPlaca == "1" ? "SIM" : "NÃO"),
        },
        { 
          titulo: "PendenciaKit", 
          name: "PendenciaKit",
          format: (row, i) => (row.PendenciaKit == "1" ? "SIM" : "NÃO"),
        },
        { 
          titulo: "PendenciaBin", 
          name: "PendenciaBin",
          format: (row, i) => (row.PendenciaBin == "1" ? "SIM" : "NÃO"),
        },
        { titulo: "Status", name: "StatusProcess"},

      ],
    },
  };

  useEffect(() => {
    async function carregar() {
      try {
        await consultar({ user: false, values: filtros, errors: {} });
      } catch (e) {
        setPropsAlerta(
          utils.montaAlerta({
            titulo: e.message,
            origem: e.origem,
            tAnimacao: e.tipoAlerta,
          })
        );
      }
    }
    carregar();
  }, []);

  const onLinhaClique = useCallback(
    (data, event) => {
      window.scrollTo(0, 0);

      history.push("/primeiroEmplacamentodetalhe", {
        data,
      });
    },
    [history]
  );

  const consultar = async ({ user, values, errors }) => {
    try {
      if (Object.entries(errors).length > 0) return;
      setCarregando(true);
      if (user) setFiltros(values);

      await setData(dadosIni);
      const ret = await api.retornaEmplacamento({
        placa: values?.placa,
        codGestor: values?.codGestor,
        dtInicio: values?.dataIni,
        dtFim: values?.dataFim,
        gestorMaster: usuario.gestorMaster,
      });

      await setData(ret);

      setPropsAlerta(utils.semAlerta);
    } catch (e) {
      setData(dadosIni);
      setPropsAlerta(utils.semAlerta);

      if (user) {
        setPropsAlerta(
          utils.montaAlerta({
            titulo: e.message,
            origem: e.origem,
            tAnimacao: e.tipoAlerta,
          })
        );
      }
    }
    setCarregando(false);
  };

  //#region CONSULTA API DASHBOARD

  const filtrarPrimeiroEmplacamentoTotal = async () => {
    try {
      const data = await api.retornaDetPrimeiroEmplacamentoTotal({
        dataIni: filtros?.dataIni,
        dataFim: filtros?.dataFim,
        placa: filtros?.placa,
        codGestor: filtros?.codGestor,
        gestorMaster: usuario.gestorMaster,
      });
      const ret = {
        dados: data,
        titulo: "Primeiro Emplacamento Total",
        subTitulo: "",
        opcoes: [],
      };
      return ret;
    } catch (e) {
      setPropsAlerta(
        utils.montaAlerta({
          titulo: e.message,
          origem: e.origem,
          tAnimacao: e.tipoAlerta,
        })
      );
    }
  };


  const filtrarPrimeiroEmplacamentoPendencia = async () => {
    try {
      const data = await api.retornaDetPrimeiroEmplacamentoPendencia({
        dataIni: filtros?.dataIni,
        dataFim: filtros?.dataFim,
        placa: filtros?.placa,
        codGestor: filtros?.codGestor,
        gestorMaster: usuario.gestorMaster,
      });
      const ret = {
        dados: data,
        titulo: "Primeiro Emplacamento Pendencia",
        subTitulo: "",
        opcoes: [],
      };
      return ret;
    } catch (e) {
      setPropsAlerta(
        utils.montaAlerta({
          titulo: e.message,
          origem: e.origem,
          tAnimacao: e.tipoAlerta,
        })
      );
    }
  };


  const filtrarPrimeiroEmplacamentoEmAndamento = async () => {
    try {
      const data = await api.retornaDetPrimeiroEmplacamentoEmAndamento({
        dataIni: filtros?.dataIni,
        dataFim: filtros?.dataFim,
        placa: filtros?.placa,
        codGestor: filtros?.codGestor,
        gestorMaster: usuario.gestorMaster,
      });
      const ret = {
        dados: data,
        titulo: "Primeiro Emplacamento Em Andamento",
        subTitulo: "",
        opcoes: [],
      };
      return ret;
    } catch (e) {
      setPropsAlerta(
        utils.montaAlerta({
          titulo: e.message,
          origem: e.origem,
          tAnimacao: e.tipoAlerta,
        })
      );
    }
  };


  const filtrarPrimeiroEmplacamentoConcluido = async () => {
    try {
      const data = await api.retornaDetPrimeiroEmplacamentoConcluido({
        dataIni: filtros?.dataIni,
        dataFim: filtros?.dataFim,
        placa: filtros?.placa,
        codGestor: filtros?.codGestor,
        gestorMaster: usuario.gestorMaster,
      });
      const ret = {
        dados: data,
        titulo: "Primeiro Emplacamento Concluído",
        subTitulo: "",
        opcoes: [],
      };
      return ret;
    } catch (e) {
      setPropsAlerta(
        utils.montaAlerta({
          titulo: e.message,
          origem: e.origem,
          tAnimacao: e.tipoAlerta,
        })
      );
    }
  };


  const filtrarIndicacao = async ({ tipo }) => {
    try {
      const fezIndicacao = tipo.toLowerCase() == "indicadas";
      const data = await api.retornaDetFezIndicacao({
        dataIni: filtros?.dataIni,
        dataFim: filtros?.dataFim,
        placa: filtros?.placa,
        codGestor: usuario.codGestor,
        gestorMaster: usuario.gestorMaster,
        fezIndicacao,
      });
      const ret = {
        dados: data,
        titulo: "Indicações do Condutor",
        subTitulo: tipo,
        opcoes: ["indicadas", "não indicadas"],
      };
      return ret;
    } catch (e) {
      setPropsAlerta(
        utils.montaAlerta({
          titulo: e.message,
          origem: e.origem,
          tAnimacao: e.tipoAlerta,
        })
      );
    }
  };

  
  const abrirNovaAba = ({ url }) => {
    const win = window.open(url, "_blank");
    win.focus();
  };


  ////#endregion

  return (
    <Tela
      titulo={"Primeiro Emplacamento"}
      subTitulo={"Veja abaixo o primeiro emplacamento dos veículos"}
      icone={"pe-7s-car"}
      menu={true}
    >

      <Formik
        validationSchema={schema}
        onSubmit={() => { }}
        validateOnMount
        initialValues={{
          placa: usuario.placa,
          dataIni: dtIni,
          dataFim: dtFim,
          codGestor: usuario.gestorMaster ? "" : usuario.codGestor,
        }}
      >
        {({ values, validateForm, errors, handleChange, setFieldValue }) => (
          <Tema.CardForm
            style={{
              minHeight: "80px",
              width: "100%",
              alignItems: "center",
              rowGap: "10px",
            }}
          >
            <Tema.CardFormHome>
              <Input
                align={"center"}
                label="Data início"
                name={"dataIni"}
                type={"date"}
                max={"2999-12-31"}
                labelAtivo={true}
                value={values?.dataIni}
                onChange={handleChange}
              />
              <Input
                align={"center"}
                label="Data Fim"
                name={"dataFim"}
                type={"date"}
                max={"2999-12-31"}
                labelAtivo={true}
                value={values?.dataFim}
                onChange={handleChange}
              />

              <Input
                labelAtivo={true}
                align={"center"}
                label="Placa"
                name={"placa"}
                value={values?.placa}
                onChange={handleChange}
                desabilitado={usuario.placa}
              />
              <Input
                labelAtivo={true}
                align={"center"}
                label="CódigoGestor"
                name={"codGestor"}
                value={values?.codGestor}
                onChange={handleChange}
                desabilitado={!usuario.gestorMaster}
              />

              <ButtonMaterial
                style={Padrao.styleBotaoPequeno}
                size="small"
                onClick={async () => {
                  await consultar({ user: true, values, errors });
                }}
              >
                Pesquisar
              </ButtonMaterial>
            </Tema.CardFormHome>
          </Tema.CardForm>
        )}
      </Formik>
      <div
        style={{
          height: "100%",
          width: "100%",
          marginTop: 10,
          marginBottom: 20,
          display: data?.Dados?.length == 0 ? "none" : "flex",
          flexDirection: "column",
        }}
      >
        <Tema.DivLinhaTotais style={{}}>
          
          <Grafico
            idGrafico={utils.graficos.primeiroEmplacamentoTotal}
            data={data?.Grafico}
            onClick={async (e) => {
              return await filtrarPrimeiroEmplacamentoTotal();
            }}
            onClickLinhaTabela={async (row, e) => {
              const url = "/primeiroEmplacamentofiltrar/" + row?.Id?.toString();
              await abrirNovaAba({ url });
            }}
          />

          <Grafico
            idGrafico={utils.graficos.primeiroEmplacamentoPendencia}
            data={data?.Grafico}
            onClick={async (e) => {
              return await filtrarPrimeiroEmplacamentoPendencia();
            }}
            onClickLinhaTabela={async (row, e) => {
              const url = "/primeiroEmplacamentofiltrar/" + row?.Id?.toString();
              await abrirNovaAba({ url });
            }}
          />

          <Grafico
            idGrafico={utils.graficos.primeiroEmplacamentoEmAndamento}
            data={data?.Grafico}
            onClick={async (e) => {
              return await filtrarPrimeiroEmplacamentoEmAndamento();
            }}
            onClickLinhaTabela={async (row, e) => {
              const url = "/primeiroEmplacamentofiltrar/" + row?.Id?.toString();
              await abrirNovaAba({ url });
            }}
          />

          <Grafico
            idGrafico={utils.graficos.primeiroEmplacamentoConcluido}
            data={data?.Grafico}
            onClick={async (e) => {
              return await filtrarPrimeiroEmplacamentoConcluido();
            }}
            onClickLinhaTabela={async (row, e) => {
              const url = "/primeiroEmplacamentofiltrar/" + row?.Id?.toString();
              await abrirNovaAba({ url });
            }}
          />

        </Tema.DivLinhaTotais>
      </div>

      {/* ... */}
      {/* ... */}
      {/* Teste que estou fazendo com o DataGrid do MaterialUI */}

      <Tabela
        columns={usuario.gestorMaster ? columnsMaterialUIGestorMaster : columnsMaterialUI}
        carregando={carregando}
        data={data?.Dados}
        ConfigExport={ConfigExport}
        onLinhaClique={onLinhaClique}
      />

      {/* <Tabela
        // defaultFilterValue={defaultFilterValue}
        colunas={columns}
        data={data}
        onRowClick={onLinhaClique}
        carregando={carregando}
        filtrarSoVisivel={true}
        textoSemDados={"Sem notificações"}
        exportConfig={ConfigExport}
      /> */}

      {/* ... */}
      {/* ... */}

      <Carregando
        tipo={propsAlerta.tipoAnimacao}
        exibir={propsAlerta.exibir}
        userClose={propsAlerta.usuarioFechar}
        loop={true}
        titulo={propsAlerta.titulo}
        origem={propsAlerta.origem}
        setExibir={() => {
          setPropsAlerta(utils.semAlerta);
        }}
      // styleFundo={{width:'90%', height:'90%'}}
      // styleAnimacao={{width:'70%'}}
      />
    </Tela>
  );
};

export default Listagem;
