import React, { useEffect, useCallback, useState } from "react";

import { useHistory } from "react-router-dom";

import Tela from "../../layout/tela";

import * as api from "../../../API/consulta";
import * as utils from "../../../utils";
import { useDadosGlobal } from "../../context";

import  * as Padrao from '../../../style/config'
import * as Tema from './style';

import Senha from './atualizarSenha'

 const Usuario = ({}) => {
    const history = useHistory();
    const {usuario} = useDadosGlobal();
    const [abrir, setAbrir] = useState(false)
  
  return (
  <Tela titulo={"Usuário"} icone={'pe-7s-user'} subTitulo={""}>
        
    <div style={{display:"flex", justifyContent:'center'}}>

      <Tema.Corpo>
          <Tema.DivImg>
            <Tema.Img src={'https://gutsyninja.com/wp-content/uploads/2020/08/Hero-Image.svg'} />
          </Tema.DivImg>
          
          <Tema.DivTela>
          <Tema.DivTitulo>
          <span>Meus Dados</span>

          </Tema.DivTitulo>
        <Tema.DivConteudo>


        <Tema.DivTexto>
                <Tema.DivCampo>
                    <Tema.Icone className={'pe-7s-id'}  />
                    <Tema.DivColuna>
                        <Tema.Titulo>{usuario.tipo ==1 ? 'Condutor' : 'Cód. Gestor'}</Tema.Titulo>
                        <Tema.Texto>{usuario.tipo ==1 ? usuario?.placa : usuario?.codGestor}</Tema.Texto>
                    </Tema.DivColuna>
                </Tema.DivCampo>
                {usuario.tipo ==1 && 
                <Tema.DivCampo>
                <Tema.Icone className={'pe-7s-car'}  />
                <Tema.DivColuna>
                    <Tema.Titulo>Renavam</Tema.Titulo>
                    <Tema.Texto>{usuario?.renavam}</Tema.Texto>
                </Tema.DivColuna>
                </Tema.DivCampo>
                }
                
                <Tema.DivCampo>
                    <Tema.Icone className={'pe-7s-mail'}  />
                    <Tema.DivColuna>
                        <Tema.Titulo>Email</Tema.Titulo>
                        <Tema.Texto>{usuario?.email}</Tema.Texto>
                    </Tema.DivColuna>
                </Tema.DivCampo>
                {usuario?.nome &&
                
                <Tema.DivCampo>
                    <Tema.Icone className={'pe-7s-user'}  />
                    <Tema.DivColuna>
                        <Tema.Titulo>Nome</Tema.Titulo>
                        <Tema.Texto>{usuario?.nome}</Tema.Texto>
                    </Tema.DivColuna>
                </Tema.DivCampo>
                }

              </Tema.DivTexto>
        </Tema.DivConteudo>
                
                
    
          </Tema.DivTela>
          <Tema.DivAcao>
              {/* <Padrao.Botao size="small" principal={true} onClick={()=>{setAbrir(true)}}>
                Alterar Senha
              </Padrao.Botao> */}
          </Tema.DivAcao>
         {/* <Senha abrir={abrir} onAbrir={()=> {setAbrir(false) }} /> */}
      </Tema.Corpo>
    
    </div>
        
  </Tela>
  )
}

export default Usuario;
