import React from "react";
// import Tabs from 'react-responsive-tabs';
import { Tab, DivPainel } from "./style";
const TabPanel = ({ defaultPanel, onChange, children, hidden }) => {
  if (hidden) return null;
  function getTabs() {
    var lista = [];

    if (Array.isArray(children)) {
      lista = children;
    } else {
      lista.push(children);
    }

    return lista.map((tab, index) => ({
      title: tab.props.titulo,
      getContent: () => tab.props.children,
      key: tab.key,
    }));
  }

  return (
    <DivPainel>
      <Tab
        transform={false}
        showInkBar={true}
        selectedTabKey={defaultPanel}
        onChange={onChange}
        items={getTabs()}
      />
    </DivPainel>
  );
};

export default TabPanel;
