import * as api from "./index";
import * as utils from "../utils";
import * as geo from "../components/layout/mapa/geoCode";

//#region LOGIN

export const setarHeaderDefault = ({ token }) => {
  try {
    api.setarHeaderDefault(token);
  } catch (e) {
    throw e;
  }
};
export const logar = async ({ login, senha, tipo }) => {
  try {
    if (!login) throw new Error("Informe o login ");
    if (!senha) throw new Error("Informe a senha ");
    if (!tipo) throw new Error("Informe o tipo de login ");
    login = utils.toSoAlphaNumerico(login);
    let response;
    if (tipo == 1)
      response = await api.loginCondutor({ usuario: login, senha });
    else if (tipo == 2)
      response = await api.loginGestor({ usuario: login, senha });

    return response;
  } catch (e) {
    throw utils.trataErro(e, "logar");
  }
};

export const recuperarSenha = async ({ tipo, codigo }) => {
  try {
    if (!codigo) throw utils.retornaErroValidacao("Informe a Código ");
    if (!tipo) throw utils.retornaErroValidacao("Informe o tipo de login ");

    let response;
    if (tipo == 1)
      response = await api.modificarSenhaCondutor({ chassi: codigo });
    else if (tipo == 2)
      response = await api.modificarSenhaGestor({ codigo: codigo });

    if (response?.length == 0)
      throw utils.retornaErroValidacao("Erro ao recuperar Senha");
    const ret = response[0];

    if (ret.Error) throw utils.retornaErroValidacao(ret.Error);

    if (tipo == 1) {
      ret.mensagem =
        "Senha alterada para os 4 numeros finais do chassi. Acesse a plataforma para alterar a senha";
    } else {
      ret.mensagem =
        "Senha alterada para: 111@senha. Acesse a plataforma para alterar a senha";
    }

    return ret;
  } catch (e) {
    throw utils.trataErro(e, "recuperarSenha");
  }
};

export const cancelarSenhaPrimeiroAcesso = async ({ tipo, idUsuario }) => {
  try {
    if (!idUsuario) throw new Error("Informe o usuário ");

    let response;
    if (tipo == 1) {
      response = await api.cancelarSenhaPrimeiroAcesso({ idUsuario });
    } else {
      response = await api.cancelarSenhaPrimeiroAcessoGestor({ idUsuario });
    }

    return response;
  } catch (e) {
    throw utils.trataErro(e, "cancelarSenhaPrimeiroAcesso");
  }
};

export const alterarSenhaPrimeiroAcesso = async ({
  tipo,
  idUsuario,
  senha,
}) => {
  try {
    if (!idUsuario) throw new Error("Informe o usuário ");
    if (!senha) throw new Error("Informe a senha");

    let response;
    if (tipo == 1) {
      response = await api.alterarSenhaPrimeiroAcesso({ idUsuario, senha });
    } else {
      response = await api.alterarSenhaPrimeiroAcessoGestor({
        idUsuario,
        senha,
      });
    }

    return response;
  } catch (e) {
    throw utils.trataErro(e, "alterarSenhaPrimeiroAcesso");
  }
};

//#endregion

//#region API GOOGLE

const retornaLocalizacao = async (endereco) => {
  return await geo.retornaLocation({ endereco });
};
//#endregion

//#region NOTIFICAÇÃO

export const retornaNotificacoes = async ({
  placa,
  codGestor,
  gestorMaster,
  dtInicio,
  dtFim,
  tipoDataFiltro,
  ait,
}) => {
  try {
    if (!placa && !codGestor && !dtInicio && !dtFim) {
      throw new Error("Informe todos os parâmetros: placa e código Gestor");
    }
    console.log(`Chegou na consulta: retornaNotificacoes`);
    placa = utils.toSoAlphaNumerico(placa);
    const response = await api.RetornaNotificacoes({
      placa,
      codGestor,
      gestorMaster,
      dtInicio,
      dtFim,
      tipoDataFiltro,
      ait,
    });

    return response;
  } catch (e) {
    throw utils.trataErro(e, "retornaNotificacoes");
  }
};

export const filtraNotificacao = async ({ id }) => {
  try {
    if (!id) {
      throw new Error("id não informado");
    }
    const response = await api.filtraNotificacao({ id });

    return response;
  } catch (e) {
    throw utils.trataErro(e, "filtraNotificacao");
  }
};

export const filtraNotificacaoAit = async ({ ait }) => {
  try {
    if (!ait) {
      throw new Error("ait não informado");
    }
    const response = await api.filtraNotificacaoAIT({ ait });

    return response;
  } catch (e) {
    throw utils.trataErro(e, "filtraNotificacaoAit");
  }
};

export const retornaDownloadNotificacao = ({ CodFlowExecute, ait }) => {
  try {
    if (!CodFlowExecute) {
      throw new Error("Informe o CodFlowExecute");
    }
    if (!ait) {
      throw new Error("Informe o AIT");
    }

    const response = api.retornaDownloadNotificacao({ CodFlowExecute, ait });

    return response;
  } catch (e) {
    throw utils.trataErro(e, "retornaDownloadNotificacao");
  }
};

export const retornaTimelineNotificacao = async ({ codFlowExecute }) => {
  try {
    if (!codFlowExecute) {
      throw new Error("Não localizado o identificador da notificação");
    }
    const response = await api.retornaTimelineNotificacao({ codFlowExecute });

    return JSON.parse(JSON.stringify(response));
  } catch (e) {
    throw utils.trataErro(e, "retornaTimelineNotificacao");
  }
};

//#endregion

//#region BOLETO

export const retornaBoletos = async ({
  placa,
  codGestor,
  gestorMaster,
  dtInicio,
  dtFim,
  tipoDataFiltro,
  ait,
}) => {
  try {
    if (!placa && !codGestor && !dtInicio && !dtFim) {
      throw new Error("Informe todos os parâmetros: placa e código Gestor");
    }
    placa = utils.toSoAlphaNumerico(placa);
    const response = await api.RetornaBoletos({
      placa,
      codGestor,
      gestorMaster,
      dtInicio,
      dtFim,
      tipoDataFiltro,
      ait,
    });

    return response;
  } catch (e) {
    throw utils.trataErro(e, "retornaBoletos");
  }
};

export const filtraBoleto = async ({ id }) => {
  try {
    if (!id) {
      throw new Error("id não informado");
    }
    const response = await api.filtraBoleto({ id });

    return response;
  } catch (e) {
    throw utils.trataErro(e, "filtraBoleto");
  }
};

export const retornaDownloadBoletos = ({ CodFlowExecute, ait }) => {
  try {
    if (!CodFlowExecute) {
      throw new Error("Informe o CodFlowExecute");
    }
    if (!ait) {
      throw new Error("Informe o AIT");
    }

    const response = api.retornaDownloadBoletos({ CodFlowExecute, ait });

    return response;
  } catch (e) {
    throw utils.trataErro(e, "retornaDownloadBoletos");
  }
};

export const retornaDownloadComprovanteBoletos = ({ CodFlowExecute, ait }) => {
  try {
    if (!CodFlowExecute) {
      throw new Error("Informe o CodFlowExecute");
    }
    if (!ait) {
      throw new Error("Informe o AIT");
    }

    const response = api.retornaDownloadComprovanteBoletos({
      CodFlowExecute,
      ait,
    });

    return response;
  } catch (e) {
    throw utils.trataErro(e, "retornaDownloadBoletos");
  }
};

export const downloadBoletos = async ({ idMulta, ait }) => {
  try {
    if (!idMulta) {
      throw new Error("Informe o Id da Multa");
    }
    if (!ait) {
      throw new Error("Informe o AIT");
    }

    const response = await api.downloadBoletos({ idMulta, ait });

    return response;
  } catch (e) {
    throw utils.trataErro(e, "downloadBoletos");
  }
};
//#endregion

//#region LICENCIAMENTO

export const retornaLicenciamentos = async ({
  placa,
  codGestor,
  gestorMaster,
}) => {
  try {
    // if (!placa || !codGestor) {
    //   throw new Error("Informe todos os parâmetros: placa e código Gestor");
    // }
    const response = await api.retornaLicenciamentos({
      placa,
      codGestor,
      gestorMaster,
    });

    return response;
  } catch (e) {
    throw utils.trataErro(e, "retornaLicenciamentos");
  }
};

export const downloadLicenciamento = ({ placa }) => {
  try {
    if (!placa) {
      throw new Error("Informe todos os parâmetros: placa");
    }

    const response = api.retornaDownloadLicenciamento({ placa });

    return response;
  } catch (e) {
    throw utils.trataErro(e, "downloadLicenciamento");
  }
};

export const anexarCRLV = async ({ placa }) => {
  try {
    if (!placa) {
      throw new Error("Informe todos os parâmetros: placa e código Gestor");
    }
    placa = utils.toSoAlphaNumerico(placa);
    const response = await api.anexarCRLV({
      placa,
    });

    if (response.code == 301)
      throw utils.retornaErroValidacao(response.source_msg);

    const base64URL = response;
    const binary = atob(base64URL.replace(/\s/g, ""));
    const len = binary.length;
    const buffer = new ArrayBuffer(len);
    const view = new Uint8Array(buffer);

    for (let i = 0; i < len; i += 1) {
      view[i] = binary.charCodeAt(i);
    }

    // create the blob object with content-type "application/pdf"
    const blob = new Blob([view], { type: "application/pdf" });
    const url = URL.createObjectURL(blob);

    const fileName = `${placa}.pdf`;
    const downloadLink = document.createElement("a");
    document.body.appendChild(downloadLink);
    downloadLink.style = "display: none";
    downloadLink.href = url;
    downloadLink.download = fileName;
    // downloadLink.target = '_blank';
    downloadLink.click();

    return response;
  } catch (e) {
    throw utils.trataErro(e, "anexarCRLV");
  }
};

export const anexarCRLVLicenciamento = async ({ placa, codFlowExecute }) => {
  try {
    if (!placa || !codFlowExecute) {
      throw new Error(
        "Informe todos os parâmetros: placa e código da instância"
      );
    }
    //codFlowExecute = utils.toSoNumero(codFlowExecute);
    const response = await api.anexarCRLVLicenciamento({
      codFlowExecute,
    });

    if (response.code == 301)
      throw utils.retornaErroValidacao(response.source_msg);

    const base64URL = response;
    const binary = atob(base64URL.replace(/\s/g, ""));
    const len = binary.length;
    const buffer = new ArrayBuffer(len);
    const view = new Uint8Array(buffer);

    for (let i = 0; i < len; i += 1) {
      view[i] = binary.charCodeAt(i);
    }

    // create the blob object with content-type "application/pdf"
    const blob = new Blob([view], { type: "application/pdf" });
    const url = URL.createObjectURL(blob);

    const fileName = `${placa}.pdf`;
    const downloadLink = document.createElement("a");
    document.body.appendChild(downloadLink);
    downloadLink.style = "display: none";
    downloadLink.href = url;
    downloadLink.download = fileName;
    downloadLink.click();

    return response;
  } catch (e) {
    throw utils.trataErro(e, "anexarCRLVLicenciamento");
  }
};

export const anexarCRLVGestor = async ({ codGestor }) => {
  try {
    if (!codGestor) {
      throw new Error("Informe todos os parâmetros: placa e código Gestor");
    }
    codGestor = utils.toSoAlphaNumerico(codGestor);
    const response = await api.anexarCRLVGestor({
      codGestor,
    });

    if (response.code == 301)
      throw utils.retornaErroValidacao(response.source_msg);

    const base64URL = response;
    const binary = atob(base64URL.replace(/\s/g, ""));
    const len = binary.length;
    const buffer = new ArrayBuffer(len);
    const view = new Uint8Array(buffer);

    for (let i = 0; i < len; i += 1) {
      view[i] = binary.charCodeAt(i);
    }

    // create the blob object with content-type "application/pdf"
    const blob = new Blob([view], { type: "application/pdf" });
    const url = URL.createObjectURL(blob);

    const fileName = `${codGestor}.pdf`;
    const downloadLink = document.createElement("a");
    document.body.appendChild(downloadLink);
    downloadLink.style = "display: none";
    downloadLink.href = url;
    downloadLink.download = fileName;
    // downloadLink.target = '_blank';
    downloadLink.click();

    return response;
  } catch (e) {
    throw utils.trataErro(e, "anexarCRLV");
  }
};

export const retornaTimelineLicenciamento = async ({ codFlowExecute }) => {
  try {
    if (!codFlowExecute) {
      throw new Error("Não localizado o identificador da notificação");
    }
    const response = await api.retornaTimelineLicenciamento({ codFlowExecute });

    return JSON.parse(JSON.stringify(response));
  } catch (e) {
    throw utils.trataErro(e, "retornaTimelineLicenciamento");
  }
};

export const downloadPlanilha = async ({ codGestor }) => {
  try {
    if (!codGestor) {
      throw new Error("Informe todos os parâmetros: codGestor");
    }
    codGestor = utils.toSoAlphaNumerico(codGestor);
    const response = await api.downloadPlanilha({
      codGestor,
    });

    if (response.code == 301)
      throw utils.retornaErroValidacao(response.source_msg);

    const base64URL = response;
    const binary = atob(base64URL.replace(/\s/g, ""));
    const len = binary.length;
    const buffer = new ArrayBuffer(len);
    const view = new Uint8Array(buffer);

    for (let i = 0; i < len; i += 1) {
      view[i] = binary.charCodeAt(i);
    }

    // create the blob object with content-type "application/pdf"
    const blob = new Blob([view], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,",
    });
    const url = URL.createObjectURL(blob);

    const fileName = `${codGestor}.xlsx`;
    const downloadLink = document.createElement("a");
    document.body.appendChild(downloadLink);
    downloadLink.style = "display: none";
    downloadLink.href = url;
    downloadLink.download = fileName;
    // downloadLink.target = '_blank';
    downloadLink.click();

    return response;
  } catch (e) {
    throw utils.trataErro(e, "downloadPlanilha");
  }
};

export const downloadCrlvVigente = async ({
  placa,
  codGestor,
  gestorMaster,
}) => {
  try {
    if (!placa) placa = "null";
    if (!codGestor) codGestor = "null";

    {
      console.log(
        "Teste3: placa:" +
          placa +
          "|| codGestor:" +
          codGestor +
          "|| gestorMaster: " +
          gestorMaster
      );
    }

    const response = await api.downloadCrlvVigente({
      placa,
      codGestor,
      gestorMaster,
    });

    if (response.code == 301)
      throw utils.retornaErroValidacao(response.source_msg);

    const urlDonwload = response;

    const fileName = `CRLV.zip`;
    const downloadLink = document.createElement("a");
    document.body.appendChild(downloadLink);
    downloadLink.style = "display: none";
    downloadLink.href = urlDonwload;
    downloadLink.download = fileName;
    // downloadLink.target = '_blank';
    downloadLink.click();

    return response;

    // const base64URL = response;
    // const binary = atob(base64URL.replace(/\s/g, ""));
    // const len = binary.length;
    // const buffer = new ArrayBuffer(len);
    // const view = new Uint8Array(buffer);

    // for (let i = 0; i < len; i += 1) {
    //   view[i] = binary.charCodeAt(i);
    // }

    // // create the blob object with content-type "application/pdf"
    // const blob = new Blob([view], {
    //   type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,",
    // });
    // const url = URL.createObjectURL(blob);

    // const fileName = `CRLV.zip`;
    // const downloadLink = document.createElement("a");
    // document.body.appendChild(downloadLink);
    // downloadLink.style = "display: none";
    // downloadLink.href = url;
    // downloadLink.download = fileName;
    // // downloadLink.target = '_blank';
    // downloadLink.click();

    // return response;
  } catch (e) {
    throw utils.trataErro(e, "downloadCrlvVigente");
  }
};

export const filtraLicenciamento = async ({ id }) => {
  try {
    if (!id) {
      throw new Error("id não informado");
    }
    const response = await api.filtraLicenciamento({ id });

    return response;
  } catch (e) {
    throw utils.trataErro(e, "filtraLicenciamento");
  }
};

//#endregion

//#region 1º EMPLACAMENTO

export const retornaEmplacamento = async ({
  placa,
  codGestor,
  gestorMaster,
  dtInicio,
  dtFim,
}) => {
  try {
    if (!placa && !codGestor && !dtInicio && !dtFim) {
      throw new Error("Informe todos os parâmetros: placa e código Gestor");
    }
    placa = utils.toSoAlphaNumerico(placa);
    const response = await api.retornaEmplacamento({
      placa,
      codGestor,
      gestorMaster,
      dtInicio,
      dtFim,
    });

    return response;
  } catch (e) {
    throw utils.trataErro(e, "retornaEmplacamento");
  }
};

export const filtraPrimeiroEmplacamento = async ({ id }) => {
  try {
    if (!id) {
      throw new Error("id não informado");
    }
    const response = await api.filtraPrimeiroEmplacamento({ id });

    return response;
  } catch (e) {
    throw utils.trataErro(e, "filtraPrimeiroEmplacamento");
  }
};

//#endregion

//#region ... CRLV Digital ...

export const retornaVeiculos = async ({ placa, codGestor, gestorMaster }) => {
  try {
    const response = await api.retornaVeiculos({
      placa,
      codGestor,
      gestorMaster,
    });

    return response;
  } catch (e) {
    throw utils.trataErro(e, "retornaVeiculos");
  }
};

//#endregion

//#region CONTATO

export const enviarEmail = async ({
  nome,
  email,
  placa,
  tipoMotivo,
  descMotivo,
}) => {
  try {
    if (!nome || !email || !placa || !tipoMotivo) {
      throw new Error(
        "Informe todos os parâmetros: Nome, Email, Placa, Tipo Motivo"
      );
    }
    placa = utils.toSoAlphaNumerico(placa);

    const response = await api.enviarEmail({
      nome,
      email,
      placa,
      tipoMotivo,
      descMotivo,
    });
    return response;
  } catch (e) {
    throw utils.trataErro(e, "enviarEmail");
  }
};

//#endregion

//#region DASHBOARD

export const retornaDashboard = async ({
  dataIni,
  dataFim,
  placa,
  codGestor,
  gestorMaster,
  tipoDataFiltro,
  ait,
}) => {
  try {
    if (!gestorMaster && !placa && !codGestor) {
      throw new Error("Informe todos os parâmetros: placa e código Gestor");
    }
    if (!tipoDataFiltro) {
      throw new Error("Informe todos os parâmetros: Período de consulta");
    }
    placa = utils.toSoAlphaNumerico(placa);
    const response = await api.retornaDashboard({
      dataIni,
      dataFim,
      placa,
      codGestor,
      gestorMaster,
      tipoDataFiltro,
      ait,
    });

    return response;
  } catch (e) {
    throw utils.trataErro(e, "retornaDashboard");
  }
};

export const retornaDetNotificacaoRecebida = async ({
  dataIni,
  dataFim,
  placa,
  codGestor,
  gestorMaster,
  tipoDataFiltro,
  ait,
}) => {
  try {
    if (!gestorMaster && !placa && !codGestor) {
      throw new Error("Informe todos os parâmetros: placa e código Gestor");
    }
    placa = utils.toSoAlphaNumerico(placa);
    const response = await api.retornaDetNotificacaoRecebida({
      dataIni,
      dataFim,
      placa,
      codGestor,
      gestorMaster,
      tipoDataFiltro,
      ait,
    });

    return response;
  } catch (e) {
    throw utils.trataErro(e, "retornaDetNotificacaoRecebida");
  }
};

export const retornaDetNotificacaoRecebidaBoletoComum = async ({
  dataIni,
  dataFim,
  placa,
  codGestor,
  gestorMaster,
  tipoDataFiltro,
  ait,
}) => {
  try {
    if (!gestorMaster && !placa && !codGestor) {
      throw new Error("Informe todos os parâmetros: placa e código Gestor");
    }
    placa = utils.toSoAlphaNumerico(placa);
    const response = await api.retornaDetNotificacaoRecebidaBoletoComum({
      dataIni,
      dataFim,
      placa,
      codGestor,
      gestorMaster,
      tipoDataFiltro,
      ait,
    });

    return response;
  } catch (e) {
    throw utils.trataErro(e, "retornaDetNotificacaoRecebidaBoletoComum");
  }
};

export const retornaDetNotificacaoRecebidaBoletoNic = async ({
  dataIni,
  dataFim,
  placa,
  codGestor,
  gestorMaster,
  tipoDataFiltro,
  ait,
}) => {
  try {
    if (!gestorMaster && !placa && !codGestor) {
      throw new Error("Informe todos os parâmetros: placa e código Gestor");
    }
    placa = utils.toSoAlphaNumerico(placa);
    const response = await api.retornaDetNotificacaoRecebidaBoletoNic({
      dataIni,
      dataFim,
      placa,
      codGestor,
      gestorMaster,
      tipoDataFiltro,
      ait,
    });

    return response;
  } catch (e) {
    throw utils.trataErro(e, "retornaDetNotificacaoRecebidaBoletoNic");
  }
};

export const retornaDetBoletoSemNotificacao = async ({
  dataIni,
  dataFim,
  placa,
  codGestor,
  gestorMaster,
  tipoDataFiltro,
  ait,
}) => {
  try {
    if (!gestorMaster && !placa && !codGestor) {
      throw new Error("Informe todos os parâmetros: placa e código Gestor");
    }
    placa = utils.toSoAlphaNumerico(placa);
    const response = await api.retornaDetBoletoSemNotificacao({
      dataIni,
      dataFim,
      placa,
      codGestor,
      gestorMaster,
      tipoDataFiltro,
      ait,
    });

    return response;
  } catch (e) {
    throw utils.trataErro(e, "retornaDetBoletoSemNotificacao");
  }
};

export const retornaDetNicSemNotificacao = async ({
  dataIni,
  dataFim,
  placa,
  codGestor,
  gestorMaster,
  tipoDataFiltro,
  ait,
}) => {
  try {
    if (!gestorMaster && !placa && !codGestor) {
      throw new Error("Informe todos os parâmetros: placa e código Gestor");
    }
    placa = utils.toSoAlphaNumerico(placa);
    const response = await api.retornaDetNicSemNotificacao({
      dataIni,
      dataFim,
      placa,
      codGestor,
      gestorMaster,
      tipoDataFiltro,
      ait,
    });

    return response;
  } catch (e) {
    throw utils.trataErro(e, "retornaDetNicSemNotificacao");
  }
};

export const retornaDetBoletoRecebido = async ({
  dataIni,
  dataFim,
  placa,
  codGestor,
  gestorMaster,
  tipoDataFiltro,
  ait,
}) => {
  try {
    if (!gestorMaster && !placa && !codGestor) {
      throw new Error("Informe todos os parâmetros: placa e código Gestor");
    }
    placa = utils.toSoAlphaNumerico(placa);
    const response = await api.retornaDetBoletoRecebido({
      dataIni,
      dataFim,
      placa,
      codGestor,
      gestorMaster,
      tipoDataFiltro,
      ait,
    });

    return response;
  } catch (e) {
    throw utils.trataErro(e, "retornaDetBoletoRecebido");
  }
};

export const retornaDetNic = async ({
  dataIni,
  dataFim,
  placa,
  codGestor,
  gestorMaster,
  tipoDataFiltro,
  ait,
}) => {
  try {
    if (!gestorMaster && !placa && !codGestor) {
      throw new Error("Informe todos os parâmetros: placa e código Gestor");
    }
    placa = utils.toSoAlphaNumerico(placa);
    const response = await api.retornaDetNic({
      dataIni,
      dataFim,
      placa,
      codGestor,
      gestorMaster,
      tipoDataFiltro,
      ait,
    });

    return response;
  } catch (e) {
    throw utils.trataErro(e, "retornaDetNic");
  }
};

export const retornaDetPrazoIndicacao = async ({
  dataIni,
  dataFim,
  placa,
  codGestor,
  gestorMaster,
  dentroPrazo,
  tipoDataFiltro,
  ait,
}) => {
  try {
    if (!gestorMaster && !placa && !codGestor) {
      throw new Error("Informe todos os parâmetros: placa e código Gestor");
    }
    placa = utils.toSoAlphaNumerico(placa);
    const response = await api.retornaDetPrazoIndicacao({
      dataIni,
      dataFim,
      placa,
      codGestor,
      gestorMaster,
      dentroPrazo,
      tipoDataFiltro,
      ait,
    });

    return response;
  } catch (e) {
    throw utils.trataErro(e, "retornaDetPrazoIndicacao");
  }
};

export const retornaDetBoletoPagEmDia = async ({
  dataIni,
  dataFim,
  placa,
  codGestor,
  gestorMaster,
  antesVencimento,
  tipoDataFiltro,
  ait,
}) => {
  try {
    if (!gestorMaster && !placa && !codGestor) {
      throw new Error("Informe todos os parâmetros: placa e código Gestor");
    }
    placa = utils.toSoAlphaNumerico(placa);
    const response = await api.retornaDetBoletoPagEmDia({
      dataIni,
      dataFim,
      placa,
      codGestor,
      gestorMaster,
      antesVencimento,
      tipoDataFiltro,
      ait,
    });

    return response;
  } catch (e) {
    throw utils.trataErro(e, "retornaDetBoletoPagEmDia");
  }
};

export const retornaDetFezIndicacao = async ({
  dataIni,
  dataFim,
  placa,
  codGestor,
  gestorMaster,
  fezIndicacao,
  tipoDataFiltro,
  ait,
}) => {
  try {
    if (!gestorMaster && !placa && !codGestor) {
      throw new Error("Informe todos os parâmetros: placa e código Gestor");
    }

    placa = utils.toSoAlphaNumerico(placa);
    const response = await api.retornaDetFezIndicacao({
      dataIni,
      dataFim,
      placa,
      codGestor,
      gestorMaster,
      fezIndicacao,
      tipoDataFiltro,
      ait,
    });

    return response;
  } catch (e) {
    throw utils.trataErro(e, "retornaDetFezIndicacao");
  }
};

export const retornaDetTopPlaca = async ({
  dataIni,
  dataFim,
  placa,
  codGestor,
  gestorMaster,
  tipoDataFiltro,
  ait,
}) => {
  try {
    if (!gestorMaster && !placa && !codGestor) {
      throw new Error("Informe todos os parâmetros: placa e código Gestor");
    }
    placa = utils.toSoAlphaNumerico(placa);
    const response = await api.retornaDetTopPlaca({
      dataIni,
      dataFim,
      placa,
      codGestor,
      gestorMaster,
      tipoDataFiltro,
      ait,
    });

    return response;
  } catch (e) {
    throw utils.trataErro(e, "retornaDetTopPlaca");
  }
};

export const retornaDetTopLocatario = async ({
  dataIni,
  dataFim,
  placa,
  codGestor,
  gestorMaster,
  tipoDataFiltro,
  ait,
}) => {
  try {
    if (!gestorMaster && !placa && !codGestor) {
      throw new Error("Informe todos os parâmetros: placa e código Gestor");
    }
    placa = utils.toSoAlphaNumerico(placa);
    const response = await api.retornaDetTopLocatario({
      dataIni,
      dataFim,
      placa,
      codGestor,
      gestorMaster,
      tipoDataFiltro,
      ait,
    });

    return response;
  } catch (e) {
    throw utils.trataErro(e, "retornaDetTopLocatario");
  }
};

export const retornaDetRackingMotivos = async ({
  dataIni,
  dataFim,
  placa,
  codGestor,
  gestorMaster,
  infracao,
  tipoDataFiltro,
  ait,
}) => {
  try {
    if (!gestorMaster && !placa && !codGestor) {
      throw new Error("Informe todos os parâmetros: placa e código Gestor");
    }
    placa = utils.toSoAlphaNumerico(placa);
    const response = await api.retornaDetRackingMotivos({
      dataIni,
      dataFim,
      placa,
      codGestor,
      gestorMaster,
      infracao,
      tipoDataFiltro,
      ait,
    });

    return response;
  } catch (e) {
    throw utils.trataErro(e, "retornaDetRackingMotivos");
  }
};

export const retornaDetNotificacaoPorEstado = async ({
  dataIni,
  dataFim,
  placa,
  codGestor,
  gestorMaster,
  estado,
  tipoDataFiltro,
  ait,
}) => {
  try {
    if (!gestorMaster && !placa && !codGestor) {
      throw new Error("Informe todos os parâmetros: placa e código Gestor");
    }
    placa = utils.toSoAlphaNumerico(placa);
    const response = await api.retornaDetNotificacaoPorEstado({
      dataIni,
      dataFim,
      placa,
      codGestor,
      gestorMaster,
      estado,
      tipoDataFiltro,
      ait,
    });

    return response;
  } catch (e) {
    throw utils.trataErro(e, "retornaDetNotificacaoPorEstado");
  }
};

export const retornaDetTopOrgao = async ({
  dataIni,
  dataFim,
  placa,
  codGestor,
  gestorMaster,
  nomeOrgao,
  tipoDataFiltro,
  ait,
}) => {
  try {
    if (!gestorMaster && !placa && !codGestor) {
      throw new Error("Informe todos os parâmetros: placa e código Gestor");
    }
    placa = utils.toSoAlphaNumerico(placa);
    const response = await api.retornaDetTopOrgao({
      dataIni,
      dataFim,
      placa,
      codGestor,
      gestorMaster,
      nomeOrgao,
      tipoDataFiltro,
      ait,
    });

    return response;
  } catch (e) {
    throw utils.trataErro(e, "retornaDetTopOrgao");
  }
};

export const retornaDetPorNatureza = async ({
  dataIni,
  dataFim,
  placa,
  codGestor,
  gestorMaster,
  natureza,
  tipoDataFiltro,
  ait,
}) => {
  try {
    if (!gestorMaster && !placa && !codGestor) {
      throw new Error("Informe todos os parâmetros: placa e código Gestor");
    }
    placa = utils.toSoAlphaNumerico(placa);
    const response = await api.retornaDetPorNatureza({
      dataIni,
      dataFim,
      placa,
      codGestor,
      gestorMaster,
      natureza,
      tipoDataFiltro,
      ait,
    });

    return response;
  } catch (e) {
    throw utils.trataErro(e, "retornaDetPorNatureza");
  }
};

// #############################
// Licenciamento ###############
// #############################
export const retornaDetLicenciamentoConcluido = async ({
  placa,
  codGestor,
  gestorMaster,
  tipo,
}) => {
  try {
    if (!gestorMaster && !placa && !codGestor) {
      throw new Error("Informe todos os parâmetros: placa e código Gestor");
    }

    placa = utils.toSoAlphaNumerico(placa);
    const response = await api.retornaDetLicenciamentoConcluido({
      placa,
      codGestor,
      gestorMaster,
      tipo,
    });

    return response;
  } catch (e) {
    throw utils.trataErro(e, "retornaDetLicenciamentoConcluido");
  }
};

export const retornaDetLicenciamentoStatus = async ({
  placa,
  codGestor,
  gestorMaster,
  tipo,
}) => {
  try {
    if (!gestorMaster && !placa && !codGestor) {
      throw new Error("Informe todos os parâmetros: placa e código Gestor");
    }

    placa = utils.toSoAlphaNumerico(placa);
    const response = await api.retornaDetLicenciamentoStatus({
      placa,
      codGestor,
      gestorMaster,
      tipo,
    });

    return response;
  } catch (e) {
    throw utils.trataErro(e, "retornaDetLicenciamentoStatus");
  }
};

// #############################
// Boletos #####################
// #############################
export const retornaDetBoletoPorEstado = async ({
  dataIni,
  dataFim,
  placa,
  codGestor,
  gestorMaster,
  estado,
  tipoDataFiltro,
  ait,
}) => {
  try {
    if (!gestorMaster && !placa && !codGestor) {
      throw new Error("Informe todos os parâmetros: placa e código Gestor");
    }
    placa = utils.toSoAlphaNumerico(placa);
    const response = await api.retornaDetBoletoPorEstado({
      dataIni,
      dataFim,
      placa,
      codGestor,
      gestorMaster,
      estado,
      tipoDataFiltro,
      ait,
    });

    return response;
  } catch (e) {
    throw utils.trataErro(e, "retornaDetBoletoPorEstado");
  }
};

export const retornaDetBoletoTopOrgao = async ({
  dataIni,
  dataFim,
  placa,
  codGestor,
  gestorMaster,
  nomeOrgao,
  tipoDataFiltro,
  ait,
}) => {
  try {
    if (!gestorMaster && !placa && !codGestor) {
      throw new Error("Informe todos os parâmetros: placa e código Gestor");
    }
    placa = utils.toSoAlphaNumerico(placa);
    const response = await api.retornaDetBoletoTopOrgao({
      dataIni,
      dataFim,
      placa,
      codGestor,
      gestorMaster,
      nomeOrgao,
      tipoDataFiltro,
      ait,
    });

    return response;
  } catch (e) {
    throw utils.trataErro(e, "retornaDetBoletoTopOrgao");
  }
};

export const retornaDetBoletoRankingMotivos = async ({
  dataIni,
  dataFim,
  placa,
  codGestor,
  gestorMaster,
  infracao,
  tipoDataFiltro,
  ait,
}) => {
  try {
    if (!gestorMaster && !placa && !codGestor) {
      throw new Error("Informe todos os parâmetros: placa e código Gestor");
    }
    placa = utils.toSoAlphaNumerico(placa);
    const response = await api.retornaDetBoletoRankingMotivos({
      dataIni,
      dataFim,
      placa,
      codGestor,
      gestorMaster,
      infracao,
      tipoDataFiltro,
      ait,
    });

    return response;
  } catch (e) {
    throw utils.trataErro(e, "retornaDetBoletoRankingMotivos");
  }
};

export const retornaDetBoletoTopPlaca = async ({
  dataIni,
  dataFim,
  placa,
  codGestor,
  gestorMaster,
  tipoDataFiltro,
  ait,
}) => {
  try {
    if (!gestorMaster && !placa && !codGestor) {
      throw new Error("Informe todos os parâmetros: placa e código Gestor");
    }
    placa = utils.toSoAlphaNumerico(placa);
    const response = await api.retornaDetBoletoTopPlaca({
      dataIni,
      dataFim,
      placa,
      codGestor,
      gestorMaster,
      tipoDataFiltro,
      ait,
    });

    return response;
  } catch (e) {
    throw utils.trataErro(e, "retornaDetBoletoTopPlaca");
  }
};

// #############################
// Criar colunas gráficos ######
// #############################
export const colunasGrafico = ({ id }) => {
  let columns = [];
  if (
    [
      utils.graficos.autuacaoNatureza,
      utils.graficos.indicacaoCondutor,
      utils.graficos.notificacaoRecebida,
      utils.graficos.rackingInfracao,
      utils.graficos.topOrgao,
      utils.graficos.topPlaca,
      utils.graficos.topLocatario,
      utils.graficos.boletoTopOrgao,
      utils.graficos.boletoRankingInfracao,
      utils.graficos.BoletoTopPlaca,
    ].includes(id)
  ) {
    columns.push(
      {
        field: "Placa",
        headerName: "Placa",
        type: "string",
        headerClassName: "super-app-theme--header",
        width: 175,
        // hide: !usuario.codGestor,
      },
      {
        field: "Ait",
        headerName: "AIT",
        type: "string",
        headerClassName: "super-app-theme--header",
        flex: 1,
      },
      {
        field: "DataInfracao",
        headerName: "Data Infração",
        type: "string",
        headerAlign: "center",
        align: "center",
        headerClassName: "super-app-theme--header",
        width: 175,
        sortComparator: (v1, v2, param1, param2) =>
          utils.formatData(param1.row.DataInfracao, "dd/MM/yyyy").getTime() -
          utils.formatData(param2.row.DataInfracao, "dd/MM/yyyy").getTime(),
      },
      {
        field: "HoraInfracao",
        headerName: "Hora infração",
        type: "string",
        headerAlign: "center",
        align: "center",
        headerClassName: "super-app-theme--header",
        width: 175,
      },
      {
        field: "Valor",
        headerName: "Valor da Multa",
        type: "number",
        headerClassName: "super-app-theme--header",
        width: 175,
        valueFormatter: (row) => utils.formataValor(row.value),
      },
      {
        field: "Pontos",
        headerName: "Pontos",
        headerAlign: "center",
        align: "center",
        type: "number",
        headerClassName: "super-app-theme--header",
        width: 175,
      }
    );
  }

  // Detalhamento Licenciamento
  else if (
    [
      utils.graficos.licenciamentoConcluido,
      utils.graficos.licenciamentoPendente,
      utils.graficos.licenciamentoStatus,
    ].includes(id)
  ) {
    columns.push(
      {
        field: "CodGestor",
        headerName: "Código Gestor",
        type: "string",
        headerClassName: "super-app-theme--header",
        flex: 1,
      },
      {
        field: "Placa",
        headerName: "Placas",
        type: "string",
        headerClassName: "super-app-theme--header",
        flex: 1,
      },
      {
        field: "AnoExercicio",
        headerName: "Ano Exercício",
        type: "string",
        headerClassName: "super-app-theme--header",
        flex: 1,
      },
      {
        field: "StatusProcess",
        headerName: "Status",
        type: "string",
        headerClassName: "super-app-theme--header",
        flex: 1,
      }
    );
  }

  // ... PRIMEIRO EMPLACAMENTO ...
  else if (
    [
      utils.graficos.primeiroEmplacamentoTotal,
      utils.graficos.primeiroEmplacamentoEmAndamento,
      utils.graficos.primeiroEmplacamentoConcluido,
    ].includes(id)
  ) {
    columns.push(
      {
        field: "Placa",
        headerName: "Placa",
        type: "string",
        headerClassName: "super-app-theme--header",
        width: 100,
        //hide: !usuario.codGestor,
      },
      {
        field: "Chassi",
        headerName: "Chassi",
        type: "string",
        headerClassName: "super-app-theme--header",
        width: 180,
      },
      {
        field: "Renavam",
        headerName: "Renavam",
        type: "string",
        headerClassName: "super-app-theme--header",
        flex: 1,
      },
      {
        field: "MarcaModelo",
        headerName: "MarcaModelo",
        type: "string",
        headerClassName: "super-app-theme--header",
        flex: 1,
      },
      {
        field: "Pendencia",
        headerName: "Pendencia",
        valueFormatter: (row) => (row.value == "1" ? "SIM" : "NÃO"),
        type: "string",
        headerClassName: "super-app-theme--header",
        flex: 1,
      },
      {
        field: "StatusProcess",
        headerName: "Status",
        type: "string",
        headerClassName: "super-app-theme--header",
        width: 200,
      }
    );
  }

  // ... PRIMEIRO EMPLACAMENTO PENDENCIA ...
  else if ([utils.graficos.primeiroEmplacamentoPendencia].includes(id)) {
    columns.push(
      {
        field: "Placa",
        headerName: "Placa",
        type: "string",
        headerClassName: "super-app-theme--header",
        width: 100,
        //hide: !usuario.codGestor,
      },
      {
        field: "Chassi",
        headerName: "Chassi",
        type: "string",
        headerClassName: "super-app-theme--header",
        width: 180,
      },
      {
        field: "Renavam",
        headerName: "Renavam",
        type: "string",
        headerClassName: "super-app-theme--header",
        flex: 1,
      },
      {
        field: "MarcaModelo",
        headerName: "MarcaModelo",
        type: "string",
        headerClassName: "super-app-theme--header",
        flex: 1,
      },
      {
        field: "Pendencia",
        headerName: "Pendencia",
        valueFormatter: (row) => (row.value == "1" ? "SIM" : "NÃO"),
        type: "string",
        headerClassName: "super-app-theme--header",
        flex: 1,
      },
      {
        field: "PendenciaFinalPlaca",
        headerName: "Pendencia Final Placa",
        valueFormatter: (row) => (row.value == "1" ? "SIM" : "NÃO"),
        type: "string",
        headerClassName: "super-app-theme--header",
        flex: 1,
      },
      {
        field: "PendenciaKit",
        headerName: "Pendencia Kit",
        valueFormatter: (row) => (row.value == "1" ? "SIM" : "NÃO"),
        type: "string",
        headerClassName: "super-app-theme--header",
        flex: 1,
      },
      {
        field: "PendenciaBin",
        headerName: "Pendencia Bin",
        valueFormatter: (row) => (row.value == "1" ? "SIM" : "NÃO"),
        type: "string",
        headerClassName: "super-app-theme--header",
        flex: 1,
      },
      {
        field: "StatusProcess",
        headerName: "Status",
        type: "string",
        headerClassName: "super-app-theme--header",
        width: 200,
      }
    );
  } else {
    columns.push(
      {
        field: "Placa",
        headerName: "Placa",
        type: "string",
        headerClassName: "super-app-theme--header",
        width: 150,
        // hide: !usuario.codGestor,
      },
      {
        field: "Ait",
        headerName: "AIT",
        type: "string",
        headerClassName: "super-app-theme--header",
        flex: 1,
      },
      {
        field: "AitOriginaria",
        headerName: "AIT Notificação",
        type: "string",
        headerClassName: "super-app-theme--header",
        width: 200,
      },
      {
        field: "tipoNic",
        headerName: "NIC",
        type: "string",
        headerClassName: "super-app-theme--header",
        width: 100,
      },
      {
        field: "Vencimento",
        headerName: "Vencimento",
        type: "string",
        headerClassName: "super-app-theme--header",
        width: 180,
        headerAlign: "center",
        align: "center",
        format: "DD/MM/YYYY",
        sortComparator: (v1, v2, param1, param2) =>
          utils.formatData(param1.row.Vencimento, "dd/MM/yyyy").getTime() -
          utils.formatData(param2.row.Vencimento, "dd/MM/yyyy").getTime(),
        // sortFunction: (a, b) =>
        //   utils.formatData(a.Vencimento, "dd/MM/yyyy").getTime() -
        //   utils.formatData(b.Vencimento, "dd/MM/yyyy").getTime(),
      },
      {
        field: "Valor",
        headerName: "Valor",
        type: "number",
        headerClassName: "super-app-theme--header",
        width: 150,
        valueFormatter: (row) => utils.formataValor(row.value),
      },
      {
        field: "baixado",
        headerName: "Pago",
        type: "string",
        headerClassName: "super-app-theme--header",
        width: 150,
        valueFormatter: (row) => (row.value == "S" ? "Sim" : "Não"),
        // valueGetter: (params) => {

        //   let result = [];
        //   result = [params.row.baixado == "S" ? "Sim" : "Não"];
        //   return result.join(", ");
        // },
      }
    );
  }

  return columns;
};

//#endregion

//#region INDICAÇÃO

export const validarIndicacao = async ({ idNotificacao }) => {
  try {
    const response = await api.ValidarIndicacao({
      idNotificacao,
    });
    // const url = window.open(fileURL);
    return response;
  } catch (e) {
    throw utils.trataErro(e, "validarIndicacao");
  }
};

export const optouPorNaoIndicar = async ({ ait }) => {
  try {
    const response = await api.OptouPorNaoIndicar({
      ait,
    });
    // const url = window.open(fileURL);
    return response;
  } catch (e) {
    throw utils.trataErro(e, "optouPorNaoIndicar");
  }
};

export const retornaURL = () => {
  return api.retornaURL();
};

export const gerarPdfTermo = async ({
  locatario,
  dataInfracao,
  horaInfracao,
  nomeCondutor,
  email,
  vencimentoCnh,
  marca,
  modelo,
  placa,
  numeroRegistroInterno,
  numeroCnh,
  ait,
  tipoCnh,
  categoriaCnh,
}) => {
  try {
    const response = await api.GerarPdfTermo({
      locatario,
      dataInfracao,
      horaInfracao,
      nomeCondutor,
      email,
      vencimentoCnh,
      marca,
      modelo,
      placa,
      numeroRegistroInterno,
      numeroCnh,
      ait,
      tipoCnh,
      categoriaCnh,
    });
    // const url = window.open(fileURL);
    return response;
  } catch (e) {
    throw utils.trataErro(e, "gerarPdfTermo");
  }
};

export const uploadsIndicacao = async ({
  dados,
  dadosNotificacao,
  uploads,
}) => {
  try {
    if (!dados || !dadosNotificacao || !uploads) {
      throw new Error(
        "Informe todos os parâmetros: dados da indicação, dados da notificação e Arquivos"
      );
    }
    let response;

    try {
      response = await uploadIndicacaoTermo({
        ait: dadosNotificacao.Ait,
        uploads,
      });
    } catch (e) {
      const erro = new Error(`Upload Termo: ${e.message}`);
      throw erro;
    }

    try {
      response = await uploadIndicacaoCnh({
        ait: dadosNotificacao.Ait,
        uploads,
      });
    } catch (e) {
      const erro = new Error(`Upload CNH: ${e.message}`);
      throw erro;
    }

    response = await solicitarIndicacao({
      ait: dadosNotificacao.Ait,
      nomeCondutor: dados?.nomeCondutor,
      cpf: dados?.cpf,
      emailCondutor: dados?.emailCondutor,
      dtNascimento: dados?.dtNascimento,
      dtPrimeiraHabilitacao: dados?.dtPrimeiraHabilitacao,
      categoriaCnh: dados?.categoriaCnh,
      numeroCnh: dados?.numeroCnh,
      tipoCnh: dados?.tipoCnh,
      dtVencimentoCNH: dados?.dtVencimentoCNH,
      endereco: dados?.endereco,
      numeroEndereco: dados?.numeroEndereco,
      complementoEndereco: dados?.complementoEndereco,
      bairroEndereco: dados?.bairroEndereco,
      ufEndereco: dados?.ufEndereco,
      cidadeEndereco: dados?.cidadeEndereco,
      dataLimiteIndicacao: dadosNotificacao?.dataLimiteIndicacao,
      grauParentesco: dados?.grauParentesco,
      rg: dados?.numeroRG,
      ufRG: dados?.ufRG,
      ufCNH: dados?.ufCNH,
      cep: dados?.CEP,
    });

    return response;
  } catch (e) {
    throw utils.trataErro(e, "uploadsIndicacao");
  }
};

export const uploadIndicacaoTermo = async ({ ait, uploads }) => {
  try {
    if (!ait || !uploads) {
      throw new Error("Informe todos os parâmetros: Id e Arquivos");
    }
    if (uploads.Termo.length == 0) {
      throw new Error("Não foi informado o Arquivo do Termo para o Upload");
    }
    const response = await api.UploadIndicacaoTermo({
      ait,
      file: uploads.Termo[0],
    });
    return response;
  } catch (e) {
    throw utils.trataErro(e, "uploadIndicacaoTermo");
  }
};

export const uploadIndicacaoCnh = async ({ ait, uploads }) => {
  try {
    if (!ait || !uploads) {
      throw new Error("Informe todos os parâmetros: Id e Arquivos");
    }
    if (uploads.CNH.length == 0) {
      throw new Error("Não foi informado o Arquivo da CNH para o Upload");
    }
    const response = await api.UploadIndicacaoCnh({
      ait,
      file: uploads.CNH[0],
    });

    return response;
  } catch (e) {
    throw utils.trataErro(e, "uploadIndicacaoCnh");
  }
};

export const solicitarIndicacao = async ({
  ait,
  bairroEndereco,
  categoriaCnh,
  cidadeEndereco,
  complementoEndereco,
  cpf,
  dataLimiteIndicacao,
  dtPrimeiraHabilitacao,
  dtNascimento,
  dtVencimentoCNH,
  emailCondutor,
  endereco,
  nomeCondutor,
  numeroCnh,
  numeroEndereco,
  tipoCnh,
  ufEndereco,
  grauParentesco,
  rg,
  ufRG,
  ufCNH,
  cep,
}) => {
  try {
    const response = await api.SolicitarIndicacao({
      ait,
      dataLimiteIndicacao,
      bairroEndereco,
      categoriaCnh,
      cidadeEndereco,
      complementoEndereco,
      cpf,
      dtPrimeiraHabilitacao,
      dtNascimento,
      dtVencimentoCNH,
      emailCondutor,
      endereco,
      nomeCondutor,
      numeroCnh,
      numeroEndereco,
      tipoCnh,
      ufEndereco,
      grauParentesco,
      rg,
      ufRG,
      ufCNH,
      cep,
    });

    return response;
  } catch (e) {
    throw utils.trataErro(e, "solicitarIndicacao");
  }
};

//#endregion

//#region PRIMEIRO EMPLACAMENTO

export const retornaDetPrimeiroEmplacamentoTotal = async ({
  dataIni,
  dataFim,
  placa,
  codGestor,
  gestorMaster,
}) => {
  try {
    if (!gestorMaster && !placa && !codGestor) {
      throw new Error("Informe todos os parâmetros: placa e código Gestor");
    }
    placa = utils.toSoAlphaNumerico(placa);
    const response = await api.retornaDetPrimeiroEmplacamentoTotal({
      dataIni,
      dataFim,
      placa,
      codGestor,
      gestorMaster,
    });

    return response;
  } catch (e) {
    throw utils.trataErro(e, "retornaDetPrimeiroEmplacamentoTotal");
  }
};

export const retornaDetPrimeiroEmplacamentoPendencia = async ({
  dataIni,
  dataFim,
  placa,
  codGestor,
  gestorMaster,
}) => {
  try {
    if (!gestorMaster && !placa && !codGestor) {
      throw new Error("Informe todos os parâmetros: placa e código Gestor");
    }
    placa = utils.toSoAlphaNumerico(placa);
    const response = await api.retornaDetPrimeiroEmplacamentoPendencia({
      dataIni,
      dataFim,
      placa,
      codGestor,
      gestorMaster,
    });

    return response;
  } catch (e) {
    throw utils.trataErro(e, "retornaDetPrimeiroEmplacamentoPendencia");
  }
};

export const retornaDetPrimeiroEmplacamentoEmAndamento = async ({
  dataIni,
  dataFim,
  placa,
  codGestor,
  gestorMaster,
}) => {
  try {
    if (!gestorMaster && !placa && !codGestor) {
      throw new Error("Informe todos os parâmetros: placa e código Gestor");
    }
    placa = utils.toSoAlphaNumerico(placa);
    const response = await api.retornaDetPrimeiroEmplacamentoEmAndamento({
      dataIni,
      dataFim,
      placa,
      codGestor,
      gestorMaster,
    });

    return response;
  } catch (e) {
    throw utils.trataErro(e, "retornaDetPrimeiroEmplacamentoEmAndamento");
  }
};

export const retornaDetPrimeiroEmplacamentoConcluido = async ({
  dataIni,
  dataFim,
  placa,
  codGestor,
  gestorMaster,
}) => {
  try {
    if (!gestorMaster && !placa && !codGestor) {
      throw new Error("Informe todos os parâmetros: placa e código Gestor");
    }
    placa = utils.toSoAlphaNumerico(placa);
    const response = await api.retornaDetPrimeiroEmplacamentoConcluido({
      dataIni,
      dataFim,
      placa,
      codGestor,
      gestorMaster,
    });

    return response;
  } catch (e) {
    throw utils.trataErro(e, "retornaDetPrimeiroEmplacamentoConcluido");
  }
};

//#endregion

//#region  DADOS GERAIS

// Função para alimentar o TextField de Select de Tipo de CNH
export const tiposCNH = [
  {
    id: "Nacional",
    descricao: "Nacional",
  },
  {
    id: "Estrangeira",
    descricao: "Estrangeira",
  },
];

// Função para alimentar o TextField de Select de Categoria de CNH
export const categoriasCNH = [
  {
    id: "A",
    descricao: "A",
  },
  {
    id: "B",
    descricao: "B",
  },
  {
    id: "C",
    descricao: "C",
  },
  {
    id: "D",
    descricao: "D",
  },
  {
    id: "E",
    descricao: "E",
  },
  {
    id: "AB",
    descricao: "AB",
  },
  {
    id: "AC",
    descricao: "AC",
  },
  {
    id: "AD",
    descricao: "AD",
  },
  {
    id: "AE",
    descricao: "AE",
  },
];

// Função para alimentar o TextField de Select de Categoria de CNH
export const grauParentesco = [
  {
    id: "Cônjuge",
    descricao: "Cônjuge",
  },
  {
    id: "Filhos",
    descricao: "Filhos",
  },
  {
    id: "Pessoas que residam comigo",
    descricao: "Pessoas que residam comigo",
  },
  {
    id: "Motorista",
    descricao: "Motorista",
  },
];

// Função para alimentar o TextField de Select de UF
export const estadosUF = [
  {
    id: "AC",
    descricao: "AC",
  },
  {
    id: "AL",
    descricao: "AL",
  },
  {
    id: "AM",
    descricao: "AM",
  },
  {
    id: "AP",
    descricao: "AP",
  },
  {
    id: "BA",
    descricao: "BA",
  },
  {
    id: "CE",
    descricao: "CE",
  },
  {
    id: "DF",
    descricao: "DF",
  },
  {
    id: "ES",
    descricao: "ES",
  },
  {
    id: "GO",
    descricao: "GO",
  },
  {
    id: "MA",
    descricao: "MA",
  },
  {
    id: "MG",
    descricao: "MG",
  },
  {
    id: "MS",
    descricao: "MS",
  },
  {
    id: "MT",
    descricao: "MT",
  },
  {
    id: "PA",
    descricao: "PA",
  },
  {
    id: "PB",
    descricao: "PB",
  },
  {
    id: "PE",
    descricao: "PE",
  },
  {
    id: "PI",
    descricao: "PI",
  },
  {
    id: "PR",
    descricao: "PR",
  },
  {
    id: "RJ",
    descricao: "RJ",
  },
  {
    id: "RN",
    descricao: "RN",
  },
  {
    id: "RO",
    descricao: "RO",
  },
  {
    id: "RR",
    descricao: "RR",
  },
  {
    id: "RS",
    descricao: "RS",
  },
  {
    id: "SC",
    descricao: "SC",
  },
  {
    id: "SE",
    descricao: "SE",
  },
  {
    id: "SP",
    descricao: "SP",
  },
  {
    id: "TO",
    descricao: "TO",
  },
];

// Função para alimentar o TextField de Select do tipo da data para consulta
export const tipoDataFiltro = [
  {
    id: "1",
    descricao: "Data Processo",
  },
  {
    id: "2",
    descricao: "Data E-mail",
  },
];

//#endregion
