import React from "react";
import { useHistory } from 'react-router-dom';

import * as Tema from "../../layout/tela/style";
import * as utils from "../../../utils";
import * as api from "../../../API/consulta";

import * as Padrao from "../../../style/config";

import Input from "../../layout/tela/campos/inputUI";
import InputMask from "../../layout/tela/campos/inputMask";
import Select from "../../layout/tela/campos/selectUI";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import IndicationImage from "../../../image/indicationCar.jpg";

const DadosCondutor = ({ dadosNot, data, onChange, erros }) => {
  const history = useHistory();
  const tiposCNH = api.tiposCNH;
  const categoriasCNH = api.categoriasCNH;
  const grauParentesco = api.grauParentesco;
  const estadosUF = api.estadosUF;
  
  const [open, setOpen] = React.useState(false);
  const [openModal2, setOpenModal2] = React.useState(false);
  
  const [protocolo, setProtocolo] = React.useState("");
  

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClickOpen2 = () => {
    setOpenModal2(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseModal2 = () => {
    setOpenModal2(false);
  };

  const voltar =()=>{
    history.push(
      '/login',
      {
      });
  }
  

  return (
    <React.Fragment>
      <Tema.CardFormIndicacao>

        <Tema.CardFormIndicacaoDetalhe style={{width:'100%', height:'auto'}}>
      {/* <Tema.CardForm> */}
        <Tema.FormTitulo>Dados da Notificação </Tema.FormTitulo>
        <div
          style={{
            padding: 5,
            display: "flex",
            gap: 15,
            flexFlow: "wrap",
            width: "100%",
          }}
        >
          <Tema.FormLinha>
            <Tema.FormColuna>
              <Input
                labelAtivo={true}
                label="Placa"
                name={"DadosCondutor.placa"}
                value={dadosNot?.Placa}
                desabilitado={true}
                onChange={onChange}
              />
              <Tema.LineBreak />
              <Input
                labelAtivo={true}
                label="AIT"
                name={"DadosCondutor.ait"}
                value={dadosNot?.Ait}
                desabilitado={true}
                onChange={onChange}
              />
              <Tema.LineBreak />
              <Input
                labelAtivo={true}
                align={"center"}
                label="Data da Infração"
                name={"DadosCondutor.dataInfracao"}
                value={dadosNot?.DataInfracao}
                desabilitado={true}
                onChange={onChange}
              />
              <Tema.LineBreak />
              <Input
                labelAtivo={true}
                align={"center"}
                label="Hora da Infração"
                name={"DadosCondutor.horaInfracao"}
                value={dadosNot?.HoraInfracao}
                desabilitado={true}
                onChange={onChange}
              />
              <Tema.LineBreak />
              <Input
                labelAtivo={true}
                align={"center"}
                label="Pontuação"
                name={"DadosCondutor.pontos"}
                value={dadosNot?.Pontos}
                desabilitado={true}
                onChange={onChange}
              />
              <Tema.LineBreak />
              <Input
                labelAtivo={true}
                align={"center"}
                label="Data Limite para Recorrer"
                name={"DadosCondutor.dataLimiteIndicacao"}
                value={utils.formatDataHora(dadosNot?.dataLimiteIndicacao)}
                desabilitado={true}
                onChange={onChange}
              />
            </Tema.FormColuna>
          </Tema.FormLinha>
        </div>
        </Tema.CardFormIndicacaoDetalhe>
      {/* </Tema.CardForm> */}
      </Tema.CardFormIndicacao>

      {/* botão aqui */}
      <Tema.DivBotaoNaoIndicar>
        <Padrao.Botao
          size="small"
          principal={true}
          style={{
            color: "white",
            backgroundColor: "#dc3545",
            // backgroundImage:
            //   "linear-gradient( 360deg, #990000 0%, #ff0000 74%)",
          }}
          onClick={handleClickOpen}
          // onClick={async () => {
          //   await navegacao({ proximo: false, values, erros: errors });
          // }}
        >
          Não quero indicar
        </Padrao.Botao>

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Tem certeza que não quer indicar?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              A não indicação do condutor acarretará em multa NIC (Multa de Não
              Identificação do Condutor). Tem certeza?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Não
            </Button>
            <Button
              color="primary"
              autoFocus
              onClick={async () => {
                setProtocolo (await api.optouPorNaoIndicar({
                  ait: dadosNot?.Ait,
                }));
                handleClickOpen2()
              }}
              
            >
              Sim
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openModal2}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Você optou por não realizar a indicação."}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {`Você optou por não realizar a indicação. Segue o número do protocolo: ${protocolo}.`}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              autoFocus
              onClick={() => { handleCloseModal2(); handleClose(); voltar() }}
            >
              Fechar
            </Button>
          </DialogActions>
        </Dialog>
      </Tema.DivBotaoNaoIndicar>

      <Tema.CardFormIndicacao>
        <Tema.CardFormIndicacaoDetalhe>
          <Tema.FormTitulo> Dados do condutor </Tema.FormTitulo>
          <div
            style={{
              padding: 5,
              display: "flex",
              gap: 15,
              flexFlow: "wrap",
              width: "100%",
            }}
          >
            <Tema.FormLinha>
              <Tema.FormColuna>
                <Input
                  label="Nome"
                  name={"DadosCondutor.nomeCondutor"}
                  value={data?.DadosCondutor?.nomeCondutor}
                  onChange={onChange}
                />
              </Tema.FormColuna>
            </Tema.FormLinha>
            <Tema.FormLinha>
              <Tema.FormColuna>
                <Input
                  label="E-mail"
                  name={"DadosCondutor.emailCondutor"}
                  value={data?.DadosCondutor?.emailCondutor}
                  onChange={onChange}
                />
              </Tema.FormColuna>
            </Tema.FormLinha>
            <Tema.FormLinha>
              <Tema.FormColuna>
                <Input
                  align={"center"}
                  label="Data de nascimento"
                  name={"DadosCondutor.dtNascimento"}
                  type={"date"}
                  max={"2999-12-31"}
                  labelAtivo={true}
                  value={data?.DadosCondutor?.dtNascimento}
                  onChange={onChange}
                  maxLength={"8"}
                />
                <Tema.LineBreak />
                <InputMask
                  label="CPF"
                  name={"DadosCondutor.cpf"}
                  value={data?.DadosCondutor?.cpf}
                  onChange={onChange}
                  mask={"999.999.999-99"}
                />
              </Tema.FormColuna>
            </Tema.FormLinha>
             <Tema.FormLinha>
              <Tema.FormColuna>
                <InputMask
                  label="RG"
                  name={"DadosCondutor.numeroRG"}
                  value={data?.DadosCondutor?.numeroRG}
                  onChange={onChange}
                />
                <Tema.LineBreak />
                <Select
                  widthAuto={true}
                  label="UF"
                  name={"DadosCondutor.ufRG"}
                  value={data?.DadosCondutor?.ufRG}
                  onChange={onChange}
                  itens={estadosUF}
                  minWidth={"50px"}
                />
                
              </Tema.FormColuna>
            </Tema.FormLinha>
            <div style={{display: dadosNot?.formGrauParentesco == 1 ? "contents" : "none"}}>
            <Tema.FormLinha>
              <Tema.FormColuna>
              <Select
                  label="Grau de parentesco"
                  name={"DadosCondutor.grauParentesco"}
                  value={data?.DadosCondutor?.grauParentesco}
                  onChange={onChange}
                  itens={grauParentesco}
                  minWidth={"108px"}
                />
              </Tema.FormColuna>
            </Tema.FormLinha>
            </div>
          </div>
        </Tema.CardFormIndicacaoDetalhe>

        <Tema.CardFormIndicacaoDetalhe>
          <Tema.FormTitulo> Dados da CNH </Tema.FormTitulo>
          <div
            style={{
              padding: 5,
              display: "flex",
              gap: 15,
              flexFlow: "wrap",
              width: "100%",
            }}
          >
            <Tema.FormLinha>
              <Tema.FormColuna>
                <Input
                  align={"center"}
                  label="Data 1ª habilitação"
                  name={"DadosCondutor.dtPrimeiraHabilitacao"}
                  type={"date"}
                  max={"2999-12-31"}
                  labelAtivo={true}
                  value={data?.DadosCondutor?.dtPrimeiraHabilitacao}
                  onChange={onChange}
                  maxLength={"8"}
                />
                <Tema.LineBreak />

                <Select
                  label="Tipo CNH"
                  name={"DadosCondutor.tipoCnh"}
                  value={data?.DadosCondutor?.tipoCnh}
                  onChange={onChange}
                  itens={tiposCNH}
                  minWidth={"108px"}
                />
              </Tema.FormColuna>
            </Tema.FormLinha>
            <Tema.FormLinha>
              <Tema.FormColuna>
                <Input
                  label="Nº registro da CNH"
                  name={"DadosCondutor.numeroCnh"}
                  value={data?.DadosCondutor?.numeroCnh}
                  onChange={onChange}
                />
                <Tema.LineBreak />

                <Select
                  label="Categoria CNH"
                  name={"DadosCondutor.categoriaCnh"}
                  value={data?.DadosCondutor?.categoriaCnh}
                  onChange={onChange}
                  placeHolder={"Selecione o Tipo de CNH"}
                  minWidth={"108px"}
                  itens={categoriasCNH}
                />
              </Tema.FormColuna>
            </Tema.FormLinha>
            <Tema.FormLinha>
              <Tema.FormColuna>
                <Select
                  widthAuto={true}
                  label="UF"
                  name={"DadosCondutor.ufCNH"}
                  value={data?.DadosCondutor?.ufCNH}
                  onChange={onChange}
                  itens={estadosUF}
                  minWidth={"50px"}
                />
                
                <Input
                  align={"center"}
                  label="Data vencimento CNH"
                  name={"DadosCondutor.dtVencimentoCNH"}
                  type={"date"}
                  max={"2999-12-31"}
                  labelAtivo={true}
                  desabilitado={false}
                  value={data?.DadosCondutor?.dtVencimentoCNH}
                  onChange={onChange}
                  maxLength={"8"}
                />

              </Tema.FormColuna>
            </Tema.FormLinha>
          </div>
        </Tema.CardFormIndicacaoDetalhe>

        <Tema.CardFormIndicacaoDetalhe style={{width:'100%'}}>
          <Tema.FormTitulo> Endereço </Tema.FormTitulo>
          <div
            style={{
              padding: 5,
              display: "flex",
              gap: 15,
              flexFlow: "wrap",
              width: "100%",
            }}
          >
            <Tema.FormLinha>
              <Tema.FormColuna>
                <Input
                  label="Logradouro"
                  name={"DadosCondutor.endereco"}
                  value={data?.DadosCondutor?.endereco}
                  onChange={onChange}
     
                />
              </Tema.FormColuna>
                {/* <Tema.LineBreak /> */}
                <Tema.FormColuna auto ={true}>

                <Input
                  label="Nº"
                  name={"DadosCondutor.numeroEndereco"}
                  value={data?.DadosCondutor?.numeroEndereco}
                  onChange={onChange}
                 
                />
              </Tema.FormColuna>
            </Tema.FormLinha>
            <Tema.FormLinha>
              <Tema.FormColuna>
                <Input
                  label="Complemento"
                  name={"DadosCondutor.complementoEndereco"}
                  value={data?.DadosCondutor?.complementoEndereco}
                  onChange={onChange}
                />
              </Tema.FormColuna>
            </Tema.FormLinha>
            <Tema.FormLinha>
              <Tema.FormColuna>
                <Input
                  label="Bairro"
                  name={"DadosCondutor.bairroEndereco"}
                  value={data?.DadosCondutor?.bairroEndereco}
                  onChange={onChange}
                />
                <Tema.LineBreak />

                <Select
                  widthAuto={true}
                  label="UF"
                  name={"DadosCondutor.ufEndereco"}
                  value={data?.DadosCondutor?.ufEndereco}
                  onChange={onChange}
                  itens={estadosUF}
                  minWidth={"50px"}
                />
                <Tema.LineBreak />

                <InputMask
                  label="CEP"
                  name={"DadosCondutor.CEP"}
                  value={data?.DadosCondutor?.CEP}
                  onChange={onChange}
                  mask={"99999-999"}
                />
              </Tema.FormColuna>
            </Tema.FormLinha>
            <Tema.FormLinha>
              <Tema.FormColuna>
              <Input
                  label="Cidade"
                  name={"DadosCondutor.cidadeEndereco"}
                  value={data?.DadosCondutor?.cidadeEndereco}
                  onChange={onChange}
                />
              </Tema.FormColuna>
            </Tema.FormLinha>
          </div>
        </Tema.CardFormIndicacaoDetalhe>
      </Tema.CardFormIndicacao>
    </React.Fragment>
  );
};

export default DadosCondutor;
