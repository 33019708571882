import React from "react";

import * as Tema from "./style";
import { useDadosGlobal } from "../context";

const Menu = ({ isMenuVisible, hoverMenu, onHoverMenu }) => {
  const { usuario } = useDadosGlobal();
  const [idMenu, setIdMenu] = React.useState(0);
  const selecionar = (id) => {
    if (id === idMenu) id = 0;
    setIdMenu(id);
  };
  return (
    <Tema.Container
      onMouseOver={() => {
        onHoverMenu(true);
      }}
      onMouseOut={() => {
        onHoverMenu(false);
      }}
    >
      <Tema.TituloMenu>
        {isMenuVisible || hoverMenu ? "Dashboard" : "_______"}
      </Tema.TituloMenu>
      <Tema.MenuDiv>
        <Tema.MenuContainer>
          <Tema.MenuLista>
            <Tema.ItemLink to="/home">
              <Tema.MenuLink
                id={1}
                onClick={() => {
                  selecionar(1);
                }}
              >
                <Tema.MenuIcone className="pe-7s-display1" />
                {(isMenuVisible || hoverMenu) && "Home"}
                {/* <Tema.MenuIcone className={`pe-7s-angle-down esquerda ${idMenu ===1 && 'visible'}`} /> */}
              </Tema.MenuLink>
            </Tema.ItemLink>
            {/* <Tema.MenuListaOpcoes className={idMenu ===1 && 'visible'}>
                </Tema.MenuListaOpcoes> */}
          </Tema.MenuLista>
        </Tema.MenuContainer>
      </Tema.MenuDiv>

      {/* Menu Primeiro emplacamento  */}
      <div
        style={{
          display: usuario.gestorMaster ? "" : "none",
        }}
      >
        <Tema.TituloMenu>
          {isMenuVisible || hoverMenu ? "Primeiro Emplacamento" : "_______"}
        </Tema.TituloMenu>
        <Tema.MenuDiv>
          <Tema.MenuContainer>
            <Tema.MenuLista>
              <Tema.ItemLink to="/primeiroEmplacamento">
                <Tema.MenuLink
                  id={4}
                  onClick={() => {
                    selecionar(4);
                  }}
                >
                  <Tema.MenuIcone className="pe-7s-display1" />
                  {(isMenuVisible || hoverMenu) && "Primeiro Emplacamento"}
                </Tema.MenuLink>
              </Tema.ItemLink>
            </Tema.MenuLista>
          </Tema.MenuContainer>
        </Tema.MenuDiv>
      </div>

      <Tema.TituloMenu>
        {isMenuVisible || hoverMenu
          ? usuario?.tipo == 1
            ? "Condutor"
            : "Gestor"
          : "_______"}
      </Tema.TituloMenu>
      <Tema.MenuDiv>
        <Tema.MenuContainer>
          <Tema.MenuLista>
            <Tema.MenuLink
              id={3}
              onClick={() => {
                selecionar(3);
              }}
            >
              <Tema.MenuIcone className="pe-7s-car" />
              {(isMenuVisible || hoverMenu) && "Meu veículo"}
              {(isMenuVisible || hoverMenu) && (
                <Tema.MenuIcone
                  className={`pe-7s-angle-down esquerda ${
                    idMenu === 3 && "visible"
                  }`}
                />
              )}
            </Tema.MenuLink>
            <Tema.MenuListaOpcoes
              className={
                idMenu === 3 && (isMenuVisible || hoverMenu) && "visible"
              }
            >
              <Tema.ItemLink to="/notificacao">
                <Tema.MenuItem>Notificações</Tema.MenuItem>
              </Tema.ItemLink>
              <Tema.ItemLink to="/boleto">
                <Tema.MenuItem>Boletos</Tema.MenuItem>
              </Tema.ItemLink>

              {/* Fellipe - 24/08/2023 => O módulo de licenciamento SOMENTE será exibido para os Gestores */}
              <Tema.ItemLink
                style={{ display: usuario?.tipo != 1 ? "" : "none" }}
                to="/licenciamento"
              >
                <Tema.MenuItem>Licenciamento</Tema.MenuItem>
              </Tema.ItemLink>

              {/* Fellipe - 06/06/2023 => Vamos inserir uma "Tela" do CRLV Digital apenas para condutor */}

              <Tema.ItemLink
                to={
                  usuario?.tipo == 1
                    ? `/crlvDigital/${usuario.placa}`
                    : `/crlvDigitalGestor`
                }
              >
                {console.log(`Placa: ${usuario.placa}`)}
                <Tema.MenuItem>CRLV Digital</Tema.MenuItem>
              </Tema.ItemLink>
              {/* </div> */}
              {/* <Tema.ItemLink to="/indicacao">
                    <Tema.MenuItem>Indicação</Tema.MenuItem>
                    </Tema.ItemLink> 
                     <Tema.ItemLink to="/recurso">
                    <Tema.MenuItem>Recurso</Tema.MenuItem>
                    </Tema.ItemLink>
                    <Tema.ItemLink to="/recurso">
                    <Tema.MenuItem>Emplacamento </Tema.MenuItem>
                    </Tema.ItemLink>
                   
                    <Tema.ItemLink to="/recurso">
                    <Tema.MenuItem>Transferência</Tema.MenuItem>
                    </Tema.ItemLink>
                    <Tema.ItemLink to="/recurso">
                    <Tema.MenuItem>Gestão CNH</Tema.MenuItem>
                    </Tema.ItemLink>
                    <Tema.ItemLink to="/recurso">
                    <Tema.MenuItem>Solicitações avulsas</Tema.MenuItem>
                    </Tema.ItemLink> */}
            </Tema.MenuListaOpcoes>
          </Tema.MenuLista>
        </Tema.MenuContainer>
      </Tema.MenuDiv>
    </Tema.Container>
  );
};

export default Menu;
