import React, { Children, useState } from "react";

import * as Tema from "./style";

import Detalhes from "./detalhes";

const CardGrafico = ({
  idGrafico,
  titulo,
  backGroundColor,
  width,
  height,
  animacao,
  onClickGrafico,
  onClickCSV,
  onClickImprimir,
  onClickLinhaTabela,
  children,
}) => {
  const [data, setData] = useState([]);
  const [exibir, setExibir] = useState(false);

  const exibirDetalhes = async (e) => {
    if (onClickGrafico) {
      const ret = await onClickGrafico(e);
      if (ret?.dados.length > 0) {
        setData(ret);
        setExibir(true);
      }
    }
  };
  const clickLinhaTabela = async (row, e) => {
    if (onClickLinhaTabela) await onClickLinhaTabela(row, e);
  };

  return (
    <Tema.Card
      width={width}
      height={height}
      backGroundColor={backGroundColor}
      animacao={animacao}
    >
      <Tema.CardCabecalho
        gradient={backGroundColor && backGroundColor?.includes("gradient")}
      >
        <div style={{ width: "100%" }}>
          <Tema.CardTitulo>{titulo}</Tema.CardTitulo>
        </div>
        <Tema.GraficoOpcoes>
          <i className={"pe-7s-more"} style={{ transform: "rotate(90deg)" }} />
          <Tema.GraficoOpcoesLista>
            <button
              onClick={() => {
                onClickCSV && onClickCSV();
              }}
            >
              <i className={"pe-7s-file"} style={{ marginRight: 8 }} />
              CSV
            </button>
            <button
              onClick={() => {
                onClickImprimir && onClickImprimir();
              }}
            >
              <i className={"pe-7s-print"} style={{ marginRight: 8 }} />
              Imprimir
            </button>
          </Tema.GraficoOpcoesLista>
        </Tema.GraficoOpcoes>
      </Tema.CardCabecalho>
      <Tema.CardConteudo>{children}</Tema.CardConteudo>

      <Detalhes
        idGrafico={idGrafico}
        data={data?.dados}
        titulo={data?.titulo}
        subTitulo={data?.subTitulo}
        mostrar={exibir}
        onMonstrar={() => {
          setExibir(false);
        }}
        onLinhaClique={clickLinhaTabela}
      />
    </Tema.Card>
  );
};

export default CardGrafico;
