import styled from "styled-components";

const media = {
  mobile: "@media(max-width: 900px)",
  desktop: "@media (min-width: 1180px)",
};

export const Container = styled.div`
  background-image: var(--background-Menu) !important;
  display: flex;
  /* width: 14%; */
  width: ${(props) => (props.isMenuVisible ? `275px` : `70px`)};
  transition: all 300ms;
  ${media.mobile} {
    display: none;
  }
  &:hover{
  width: 275px;

  }
`;
