import React, { useState, useEffect } from 'react';

// import MobileHeader from '../MobileHeader';
// import AdBanner from '../AdBanner';
import ColunaEsquerda from '../layout/colunas/esquerda';
import ColunaCentro from '../layout/colunas/centro';
import ColunaDireita from '../layout/colunas/direita';
import Cabecalho from '../layout/cabecalho';

import { Container,Linha } from './style';

const Layout = () => {
  const [isLoading, setLoading] = useState(true);
  const [isMenuVisible, setIsMenuVisible] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  return (
    <Container>
      <header>
        <Linha>
          <Cabecalho isMenu={true} isMenuVisible ={isMenuVisible} onMenuVisible={()=> {setIsMenuVisible(!isMenuVisible)}} />
          {/* <Cabecalho isMenu={false} /> */}
        </Linha>
      </header>
      {/* <MobileHeader />
      */}
      {/* <span>{!isLoading && <AdBanner />}</span> */}

      <main>
        
        <Linha>

        <ColunaEsquerda isLoading={isLoading} isMenuVisible ={isMenuVisible}/>
        <ColunaCentro isLoading={isLoading} isMenuVisible ={isMenuVisible}/>
        <ColunaDireita isLoading={isLoading} />
        </Linha>
      </main>
    </Container>
  );
};

export default Layout;