
import styled, { css, keyframes } from "styled-components";

const AnimationName  = keyframes`
 0%{background-position:50% 0%}
    50%{background-position:51% 100%}
    100%{background-position:50% 0%}
`

export const Card = styled.div`
background: #fff;
color: #000;
${props => props.backGroundColor && props.backGroundColor.includes('gradient') ? `background-image: ${props.backGroundColor};`
: `background: ${props.backGroundColor};`
}

${props => props.colorFont  && `color: ${props.colorFont};`

}
width: ${props=>props.width};
height: ${props=>props.height};
margin:15px;
display: flex;
align-items: center;
flex-direction: column;
padding:8px;
border-Radius:5px;
box-shadow:-8px 8px 14px 0 rgb(25 42 70 / 11%);
min-width: 355px;
${props => props.animacao && css`animation-name: ${AnimationName}; animation-duration:10s; animation-iteration-count: infinite; background-size:400% 400%`}

`
export const CardCabecalho = styled.div`

padding-Bottom:15px;
padding-Top:5px;
width:100%;
${props => props.gradient ? 'color: white;' : 'border-Bottom: 1px #eae8e8 solid;'}

display:flex;

`
export const GraficoOpcoesLista = styled.div`

display: none;
left: -90px;
position: absolute;
background-color: #fff;
min-width: 25px;
width: max-content;
box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
z-index: 1;
padding: 10px 10px 10px 10px;
border-radius:5px;
button{
background-color: transparent;
  color: black;
  padding: 6px 0px 6px 5px;
  text-decoration: none;
  display: block;
  border: 0px;
  min-width: 100px;
  text-align: left;
  cursor: pointer;
}
button:hover{
  /* background-color: #ddd; */
  color: #1d358a;
}
`
export const GraficoOpcoes = styled.div`

position: relative;
display: inline-block;
  cursor: pointer;
&:hover ${GraficoOpcoesLista}{
    display: block;
}
&:hover ${GraficoOpcoesLista}{
    display: block;
}
`
export const CardConteudo = styled.div`

width:100%;
`
export const ModalCorpo = styled.div`
  width: 60rem;
  padding: 2rem 2rem;
  border-radius: .8rem;

  color: var(--color-secundary);
  background: linear-gradient(to right bottom, var(--color-primary), var(--color-tertiary));

  box-shadow: .4rem .4rem 2.4rem .2rem hsla(236, 50%, 50%, 0.3);
  position: relative;

  overflow: hidden;
  &:before{
    content: '';

  position: absolute;
  top: 0%;
  left: 100%;
  transform: translate(-50%, -50%);

  width: 190px;
  height: 190px;
  border: 1px solid hsla(0, 0%, 100%, 0.2);
  border-radius: 100rem;

  pointer-events: none;
  }
`;
export const ModalCabecalho = styled.div`
  text-align: center;

  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid hsla(0, 0%, 100%, .4);
`;

export const ModalTitulo = styled.h1`
  font-size: 3.2rem;
  margin: 0px;
`;
export const ModalSubTitulo = styled.p`
  margin: 0px;
font-size: 1.6rem;
font-style: italic;
`;

export const ModalFechar = styled.a`
  width: 40px;
  height: 40px;
  border: 1px solid hsla(0, 0%, 100%, .4);
  border-radius: 100rem;

  color: inherit;
  font-size: 25px;

  position: absolute;
  top: 15px;
  right: 15px;

  display: flex;
  justify-content: center;
  align-items: center;

  transition: 0.2s;
  &:before {
  content: '×';

  transform: translateY(-.1rem);
}

&:hover,
&:focus {
  border-color: hsla(0, 0%, 100%, .6);
  transform: translateY(-.2rem);
  cursor: pointer;
}
`;

export const CardTitulo = styled.div`

font-weight: 500;
    font-size: 1.25rem;
    margin: 0;
    text-transform: uppercase;
`