import React, { useState, useEffect } from "react";
import { Group, Label, TextInput } from "./style";

const Input = ({
  name,
  label,
  type,
  valor,
  soLeitura,
  align,
  autoSize,
  isAtivo,
  onChange,
  onBlur,
  erro,
  max,
  min,
}) => {
  const [isActive, setIsActive] = useState(false);
  const [value, setValue] = useState("");

  useEffect(() => {
    const val = valor ? valor.toString() : "";
    setValue(val);
    if (isAtivo) {
      setIsActive(true);
    } else {
      setIsActive(val.length > 0);
    }
  }, [valor]);

  const validaAnimacaoLabel = (text) => {
    if (isAtivo) {
      setIsActive(true);
    } else {
      if (text !== "") {
        setIsActive(true);
      } else {
        setIsActive(false);
      }
    }
  };

  const handleTextChange = (text) => {
    setValue(text);

    if (onChange) onChange(text);
  };
  const blur = (e) => {
    validaAnimacaoLabel(value);
    if (onBlur) onBlur(e);
  };

  return (
    <Group>
      <TextInput
        name={name}
        type={type}
        value={value}
        readOnly={soLeitura}
        align={align}
        onChange={(e) => {
          handleTextChange(e.target.value);
        }}
        onFocus={(e) => {
          validaAnimacaoLabel("s");
        }}
        onBlur={(e) => {
          blur(e);
        }}
        max={max}
        min={min}
      />
      <Label htmlFor={name} selecionado={isActive}>
        {label}
      </Label>
    </Group>
  );
};

export default Input;
