import styled from "styled-components";

export const TextField = styled.input`
  height: 32px;
  width: 200px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid var(--color-primary);
  padding: 0 32px 0 16px;
  border-right-width: 0px;
  display: none;
  ${(props) => props.exibir && `display: flex;`}
  &:focus {
    outline-style: none !important;
  }

  transition: all 0.2s;
`;

export const ClearButton = styled.button`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-width: 0.5px;
  border-style: solid;
  border-color: var(--color-primary);
  border-left-width: 0px;
  color: var(--color-primary);
  background: transparent;
  height: 32px;
  width: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    background: var(--color-primary);
    color: #fff;
  }
`;
export const DivIcone = styled.div`
  cursor: pointer;
  z-index: 1;
  white-space: nowrap;
  /* padding: 0.3rem; */
  transition: all 0.2s;
  color: var(--color-cliente);
  background: transparent;
  height: 32px;
  min-width: 40px;
  display: flex;
  justify-content: center;
  border-radius: 9px;
  align-items: center;
  ${(props) =>
    props.exibirFiltro &&
    `
    border-radius: 0px 9px 9px 0px;
    background: var(--color-cliente);
    color: white;
    
    `}
  & > svg {
    cursor: pointer;
    font-size: 1.9rem;
    margin-right:15px;
    /* box-shadow: 0 0.46875rem 2.1875rem rgba(0,0,0,0.03), 0 0.9375rem 1.40625rem rgba(0,0,0,0.03), 0 0.25rem 0.53125rem rgba(0,0,0,0.05), 0 0.125rem 0.1875rem rgba(0,0,0,0.03); */
  }
`;
export const Icone = styled.i`
  font-size: 1.2rem;
  color: var(--color-cliente);
  font-weight: bold;
  ${(props) =>
    props.exibirFiltro &&
    `
    font-size: 28px;
    
    `}
`;

export const DivTabelaOpcoes = styled.div`
  width:100%;
  display:flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

export const DivTabela = styled.div`

  & .super-app-theme--header {
      background-color: var(--color-primary);
      color: var(--color-secundary);
    }
    & .MuiPaginationItem-root {
      color: #f0f0f0
    }
    & .MuiIconButton-label {
      color:#f0f0f0
    }
    & .MuiDataGrid-row {
      cursor:pointer
    }
    & .MuiCircularProgress-root{
      color: var(--color-primary);

    }
    & .MuiSvgIcon-root{
      fill: var(--color-primary);

    }
`;