import React, { useLayoutEffect, useCallback, useState } from "react";
import Helmet from "react-helmet";
import { useLocation, useHistory } from "react-router-dom";

import Dado from "../../layout/tela/infoGraficos/dado";
// import Mapa from '../../layout/tela/infoGraficos/mapa';
import Mapa from "../../layout/mapa";
import Input from "../../layout/tela/campos/input";
import TabPanel from "../../layout/tabPanel";
import Panel from "../../layout/tabPanel/panel";
import { Carregando } from "../../loading";
import Timeline from "../../layout/timeline";

import Tela from "../../layout/tela";
import * as Tema from "../../layout/tela/style";
import * as Padrao from "../../../style/config";

import * as utils from "../../../utils";
import * as geo from "../../layout/mapa/geoCode";
import * as api from "../../../API/consulta";
import { date } from "yup";

const Notificacao = ({ id }) => {
  const corInfoGrafico = "#83588a";
  const history = useHistory();
  const location = useLocation();
  const dataUrl = location.state;
  const [data, setData] = useState(null);
  const [propsAlerta, setPropsAlerta] = useState(utils.propsAlerta);

  // Fellipe - 08/07/2024 => Como estamos criando uma nova aba, então vamos apenas fechar a aba.
  const voltar = useCallback(() => {
    window.scrollTo(0, 0);
    window.close();
  }, []);

  // const voltar = useCallback(() => {
  //   window.scrollTo(0, 0);
  //   history.push("/notificacao", {});
  // }, [history]);

  const retornaLocalizacao = async (endereco) => {
    try {
      return await geo.retornaLocation({ endereco });
    } catch (e) {
      // console.log(e);
    }
  };

  const retornaTimelineNotificacao = async (codFlowExecute) => {
    try {
      return await api.retornaTimelineNotificacao({ codFlowExecute });
    } catch (e) {
      // console.log(e);
    }
  };

  useLayoutEffect(() => {
    if (!dataUrl && !id) {
      voltar();
      return;
    }
    async function funcao() {
      try {
        const dados = dataUrl.data;
        dados.localizacao = await retornaLocalizacao({
          endereco: dados?.LocalInfracao?.replace(" ALTURA DO N°", ","),
        });
        dados.timeline = await retornaTimelineNotificacao({
          codFlowExecute: dados?.CodFlowExecute,
        });
        setData(dados);
      } catch (e) {
        setPropsAlerta(
          utils.montaAlerta({
            titulo: e.message,
            origem: e.origem,
            tAnimacao: e.tipoAlerta,
          })
        );
      }
    }
    funcao();
  }, [dataUrl, voltar]);

  const Indicar = async (data) => {
    try {
      const obj = utils.montaAlerta({
        // titulo: "Montando seu Dashboard",
        tAnimacao: utils.animacoes.carregando,
      });
      setPropsAlerta(obj);

      // const ret = await api.validarIndicacao({idNotificacao: data?.Id});

      setPropsAlerta(utils.semAlerta);

      ChamarTelaIndicacao(data);
    } catch (e) {
      setPropsAlerta(
        utils.montaAlerta({
          titulo: e.message,
          origem: e.origem,
          tAnimacao: e.tipoAlerta,
        })
      );
    }
  };

  const ChamarTelaIndicacao = useCallback(
    (data) => {
      window.scrollTo(0, 0);

      history.push("/indicacao", {
        data,
      });
    },
    [history]
  );

  const download = () => {
    try {
      const ret = api.retornaDownloadNotificacao({
        CodFlowExecute: data?.CodFlowExecute,
        ait: data?.Ait,
      });

      window.open(ret);
    } catch (e) {
      setPropsAlerta(
        utils.montaAlerta({
          titulo: e.message,
          origem: e.origem,
          tAnimacao: e.tipoAlerta,
        })
      );
    }
  };

  const dataLimite = new Date(
    new Date(data?.dataLimiteIndicacao).setDate(
      new Date(data?.dataLimiteIndicacao).getDate() + 1
    )
  );

  return (
    <Tela
      titulo={"Notificações"}
      subTitulo={"Detalhamento da notificaçao"}
      icone={"pe-7s-car"}
      sair={true}
      menu={true}
      onSair={(e) => voltar()}
    >
      <TabPanel defaultPanel={"1"}>
        <Panel titulo={"Dados"} key={"1"}>
          <Tema.CardForm style={{ minHeight: "150px", width: "100%" }}>
            <Tema.CardFormNotificacaoDetalheMultiLine>
              <div
                style={{
                  width: "100%",
                }}
              >
                <Input
                  label={"Placa"}
                  type={"text"}
                  soLeitura={true}
                  valor={data?.Placa}
                  align={"center"}
                />
              </div>

              <div
                style={{
                  width: "100%",
                }}
              >
                <Input
                  label={"AIT"}
                  type={"text"}
                  soLeitura={true}
                  valor={data?.Ait}
                  align={"center"}
                />
              </div>

              <div
                style={{
                  width: "100%",
                }}
              >
                <Input
                  label={"Data da Infração"}
                  type={"text"}
                  soLeitura={true}
                  valor={data?.DataInfracao}
                  align={"center"}
                />
              </div>

              <div
                style={{
                  width: "100%",
                }}
              >
                <Input
                  label={"Hora da Infração"}
                  type={"text"}
                  soLeitura={true}
                  valor={data?.HoraInfracao}
                  align={"center"}
                />
              </div>

              <div
                style={{
                  width: "100%",
                }}
              >
                <Input
                  label={"Pontuação"}
                  type={"text"}
                  soLeitura={true}
                  valor={data?.Pontos.toString()}
                  align={"center"}
                />
              </div>
            </Tema.CardFormNotificacaoDetalheMultiLine>

            {/* Infração cometida */}
            <Tema.CardFormNotificacaoDetalheMultiLine>
              <div
                style={{
                  width: "100%",
                }}
              >
                <Input
                  label={"Infração"}
                  type={"text"}
                  soLeitura={true}
                  valor={data?.Infracao}
                />
              </div>
            </Tema.CardFormNotificacaoDetalheMultiLine>

            {/* Local da Infração */}
            <Tema.CardFormNotificacaoDetalheMultiLine>
              <div
                style={{
                  width: "100%",
                }}
              >
                <Input
                  label={"Local da Infração"}
                  type={"text"}
                  soLeitura={true}
                  valor={data?.LocalInfracao}
                />
              </div>
            </Tema.CardFormNotificacaoDetalheMultiLine>

            {/* Dados e-mail */}
            <Tema.CardFormNotificacaoDetalheMultiLine>
              <Input
                label={"Data envio E-mail"}
                type={"text"}
                soLeitura={true}
                valor={utils.formatDataHora(data?.DataEnvioEmail)}
                align={"center"}
              />
              <div
                style={{
                  width: "100%",
                }}
              >
                <Input
                  label={"E-mail"}
                  type={"text"}
                  soLeitura={true}
                  valor={data?.DataEnvioEmail ? data?.EmailResponsavel : ""}
                />
              </div>
            </Tema.CardFormNotificacaoDetalheMultiLine>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: 10,
                padding: 5,
              }}
            >
              {/* Fellipe - 21/04/2022 => Fazendo a consulta se o condutor
                                          Fez a indicação ou 
                                          Se a data limite expirou ou 
                                          Se optou por não indicar */}
              <div
                style={{
                  display:
                    data?.FezIndicacao == 1 ||
                    data?.IndicacaoCancelada == 1 ||
                    dataLimite < new Date() ||
                    data?.OptouNaoIndicar == 1 ||
                    parseInt(data?.idTipoIndicacao) != 5 ||
                    data?.removeNaoQueroIndicar == 1
                      ? "none"
                      : "flex",
                }}
              >
                <Padrao.Botao
                  size="small"
                  principal={true}
                  onClick={() => {
                    Indicar({ data });
                  }}
                >
                  Indicar
                </Padrao.Botao>
              </div>
              <Padrao.Botao
                size="small"
                principal={true}
                onClick={() => {
                  download();
                }}
              >
                Ver Notificação
              </Padrao.Botao>
            </div>
          </Tema.CardForm>
        </Panel>
        <Panel titulo={"Info Gráfico"} key={"2"}>
          <Tema.CardFormInfoGrafico>
            <Tema.CardForm>
              <Tema.CardCabecalho style={{ backgroundColor: corInfoGrafico }}>
                <Tema.CardCabecalhoFundoImagem />
                <Tema.CardCabecalhoDivTitulo>
                  <Tema.CardCabecalhoTitulo>
                    {" "}
                    Dados da Infração
                  </Tema.CardCabecalhoTitulo>
                  <Tema.CardCabecalhoSubTitulo>
                    Abaixo dados da Infração
                  </Tema.CardCabecalhoSubTitulo>
                </Tema.CardCabecalhoDivTitulo>
              </Tema.CardCabecalho>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  color: "#70899b",
                  flexWrap: "wrap",
                }}
              >
                <Dado
                  icone={"pe-7s-date"}
                  titulo={"Data"}
                  valor={data?.DataInfracao}
                />
                <Dado
                  icone={"pe-7s-wristwatch"}
                  titulo={"Hora"}
                  valor={data?.HoraInfracao}
                />
                <Tema.LineBreak />
                {/* Colocar um IF para ter uma quebra de linha na mesma Div */}
                <Dado
                  icone={"pe-7s-key"}
                  titulo={"Código da Infração"}
                  valor={data?.Ait}
                />
                <Dado
                  icone={"pe-7s-bottom-arrow"}
                  titulo={"Pontuação"}
                  valor={data?.Pontos}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  color: "#70899b",
                }}
              >
                <Dado
                  colunaUnica={true}
                  icone={"pe-7s-ticket"}
                  titulo={"Infração"}
                  valor={data?.Infracao}
                />
              </div>
            </Tema.CardForm>
            <Tema.CardForm>
              <Tema.CardCabecalho style={{ backgroundColor: corInfoGrafico }}>
                <Tema.CardCabecalhoFundoImagem />
                <Tema.CardCabecalhoDivTitulo>
                  <Tema.CardCabecalhoTitulo>
                    {" "}
                    Local da Infração
                  </Tema.CardCabecalhoTitulo>
                  <Tema.CardCabecalhoSubTitulo>
                    Abaixo localização da Infração
                  </Tema.CardCabecalhoSubTitulo>
                </Tema.CardCabecalhoDivTitulo>
              </Tema.CardCabecalho>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  color: "#70899b",
                }}
              >
                <Mapa
                  height={"400px"}
                  zoom={15}
                  width={"1050px"}
                  localizacao={data?.localizacao}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  color: "#70899b",
                }}
              >
                <Dado
                  colunaUnica={true}
                  icone={"pe-7s-map"}
                  titulo={"Local da Infração"}
                  valor={data?.LocalInfracao}
                />
              </div>
            </Tema.CardForm>
          </Tema.CardFormInfoGrafico>
        </Panel>
        <Panel titulo={"Timeline"} key={"3"}>
          <Tema.CardForm style={{ minHeight: "150px", width: "100%" }}>
            <Tema.CardFormNotificacaoDetalheMultiLine>
              <Timeline res={data?.timeline} />
            </Tema.CardFormNotificacaoDetalheMultiLine>
          </Tema.CardForm>
        </Panel>
      </TabPanel>

      <Carregando
        tipo={propsAlerta.tipoAnimacao}
        exibir={propsAlerta.exibir}
        userClose={propsAlerta.usuarioFechar}
        loop={true}
        titulo={propsAlerta.titulo}
        origem={propsAlerta.origem}
        setExibir={() => {
          setPropsAlerta(utils.semAlerta);
        }}
        // styleFundo={{width:'90%', height:'90%'}}
        // styleAnimacao={{width:'70%'}}
      />
    </Tela>
  );
};

export default Notificacao;
