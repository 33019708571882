import React, { useState, useCallback } from "react";

import Chart, { Props } from "react-apexcharts";

const Grafico = ({
  style,
  data,
  colorPrimaria,
  colorSecundaria,
  backgroundColor,
  borderColor,
  exibirLegenda,
  exibirOpcoes,
  titulo,
  dados,
  labels,
  onClick,
}) => {
  const paradas = backgroundColor?.map((x, i) => {
    return x.pos;
  });
  const cores = backgroundColor?.map((x, i) => {
    return x.cor;
  });
  const coresStop = backgroundColor?.map((x, i) => {
    return {
      offset: x.pos,
      color: x.cor,
      opacity: 1,
    };
  });

  const props: Props = {};

  props.options = {
    grid: {
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },

        // tickAmount: 10,
      },

      padding: {
        top: 5,
        left: 5,
        right: 30,
      },
    },
    chart: {
      toolbar: {
        show: false,
      },
      events: {
        dataPointMouseEnter: function (e) {
          if (onClick) {
            e.path[0].style.cursor = "pointer";
          }
        },
        dataPointSelection: async function (e, chart, option) {
          if (onClick) {
            return await onClick(option);
          }
        },
      },

      animations: { speed: 800, easing: "linear" },
      type: "bar",
      height: 150,
      width: "100%",

      // background: '#fff',
      sparkline: {
        enabled: false,
      },
      dropShadow: {
        enabled: false,
        enabledOnSeries: undefined,
        top: 8,
        left: 7,
        blur: 11,
        color: "#fff",
        opacity: 0.5,
      },
    },

    dataLabels: {
      enabled: true,
      offsetY: -25,
      style: {
        colors: ["#fff"],
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 3,
        horizontal: false,
        columnWidth: "20%",
        dataLabels: {
          position: "top",
        },

        // rangeBarGroupRows:false
      },
    },
    fill: {
      type: "gradient",

      gradient: {
        shade: "dark",
        type: "horizontal",
        shadeIntensity: 0.5,
        // gradientToColors: [ "#ABE5A1", "#5A0"],
        gradientToColors: cores,

        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 1,
        stops: paradas,
        colorStops: coresStop,
      },
    },
    stroke: {
      //   lineCap: "butt",
    },
    yaxis: {
      min: 0,
      max: Math.max(...data) + 1,
      labels: {
        show: false,
        style: {
          colors: "#FFF",
        },
      },
    },
    labels: labels,
    xaxis: {
      labels: {
        show: true,
        style: {
          colors: "#fff",
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
  };

  return (
    <React.Fragment>
      <div style={style}>
        <Chart
          options={props.options}
          series={[
            {
              name: "Quantidade",
              data,
            },
          ]}
          type={props.options.chart.type}
          height={350}
          width="95%"

          //  height="100%"
        />
      </div>
    </React.Fragment>
  );
};

export default Grafico;
