import React,{useState, useEffect} from 'react';
import  {Group, Label, RadioField, DivRadioInput, SpanRadioInput} from './style'

const RadioButton =({name, label,valor,soLeitura, onClick, styleIput})=>{

    const [isActive, setIsActive] = useState(false);
    const validaAnimacaoLabel =()=>{
        // setIsActive(!isActive);

            }
    return(
            <DivRadioInput style={{marginTop: 10}}>
                <Label htmlFor={name} selecionado={isActive}
                style={{transform: "translate(0,1px) scale(1)", padding: 0, fontSize:14, position: 'relative'}}
                >{label}</Label>

                <RadioField name={name} type='checkbox' id={"".concat(name,valor) } 
                readOnly={soLeitura}
                checked={valor} onClick={onClick} style={styleIput}
                onFocus={(e) => {validaAnimacaoLabel()}}
                onBlur={(e) => {validaAnimacaoLabel()}}
                />
                <SpanRadioInput />

            </DivRadioInput>

    
        )

}
export default RadioButton;

// const InputRadio = ({name, label, type, valor, soLeitura, align, autoSize , onChange, onBlur, erro}) => {
//     const [isActive, setIsActive] = useState(false);
//     const [value, setValue] = useState("");

//     useEffect(() => {
//         const val = valor ? valor.toString() : "";
//         setValue(val);

//         setIsActive(val.length >0);

//     },[valor]);

//     const validaAnimacaoLabel =(text)=>{
//         if (text !== '') {
//             setIsActive(true);
//         } else {
//             setIsActive(false);
//         }
//     }
//     const handleTextChange =(text)=> {
//         setValue(text);

//         if(onChange)onChange();
//     }

//     return(
//         <Group>
//             <TextInput name={name} type={type} value={value} 
//             readOnly={soLeitura} align={align}
//             size={autoSize && value.length +5}
//             onChange={(e) => {handleTextChange(e.target.value)}}
//             onFocus={(e) => {validaAnimacaoLabel('s')}}
//             onBlur={(e) => {validaAnimacaoLabel(value)}}
//              />
//             <Label htmlFor={name} selecionado={isActive}>{label}</Label>
//         </Group>
//     )
// }

// export default InputRadio;
