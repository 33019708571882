import styled, { css, keyframes }  from 'styled-components';
import ChartistGraph from 'react-chartist';

const draw = keyframes`
  to {
    stroke-dashoffset: 0;
  }
`


const pathseries = ({ length, delay,time, strokecolor }) => css`
stroke-dasharray:  ${length};
stroke-dashoffset:  ${length};
animation: ${draw} ${time} ${delay} ease both;
fill: none;
stroke: ${strokecolor};
opacity: 0.8;
`;

export const Grafico = styled(ChartistGraph)`

  &&{
    background: transparent;
    width:100%;
    ${props => props.backGround ? props.backGround.includes('gradient') ? `background-image: ${props.backGround};`
: `background: ${props.backGround};`
: null
}
  }
  ${props => 
    props?.data?.series?.map((x,i) => {
      
      return css`
      & .ct-${props.type.toLowerCase()} {
          stroke-width: ${props.lineWidth ? props.lineWidth : '4'}px;
          filter: drop-shadow(0px 15px 5px rgba(0, 0, 0, 0.8));

      }
      &.ct-chart .${props.name}-series.${props.name}-series-${String.fromCharCode(96 + (i+1) )} .ct-${props.type.toLowerCase()} {
        ${pathseries({ length: document.querySelector(`.${props.name}-series.${props.name}-series-${String.fromCharCode(96 + (i+1) )} .ct-line`)?.getTotalLength() *1.5, delay: "0s", time:'10s', strokecolor:(props.colors? props.colors[i] : '#fff')})};
      }
      & .${props.name}-series-${String.fromCharCode(96 + (i+1) )} .ct-area{
        ${props.idGradient && `fill: url(#${props.idGradient})!important;`}
        
      }
      & .${props.name}-series-${String.fromCharCode(96 + (i+1) )} .ct-point{
        stroke: ${props => props.colors ? props.colors[0] : '#fff'};
        filter: drop-shadow(0px 15px 5px rgba(0, 0, 0, 0.8));
      }
      & .${props.cursorClick}{
        cursor: pointer;

      }
  `
        

    })
  }

& .ct-label{
  padding-top:15px;
  ${props => props.corLabel && `color: ${props.corLabel};`}
  filter: drop-shadow(0px 15px 5px rgba(0, 0, 0, 0.8));
}
 
  &.line .ct-point-circle {
    stroke-width: 2px;
    fill: #fff;
  }
  &.ct-chart .ct-grid {
    stroke: rgb(255 251 251 / 20%);
    stroke-width: 1px;
    stroke-dasharray: 0px;
  }
`;