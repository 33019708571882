import React, {createContext, useState, useEffect }  from 'react';
import AsyncStorage  from "@react-native-async-storage/async-storage";
import * as api from '../../API/consulta'

function consultar(state, action) {

  const{dadosGlobal} =action;


    return{dadosGlobal};


}
function verUsuario(state, action) {

    const{usuario} =action;
  
  
      return{usuario};
  
  
  }

const DadosGlobalContext = createContext({});
const DadosGlobalAtualizarContext = createContext();



export  function DadosGlobalProvider({children}) {
    const [objeto, objetoDispatch] = React.useReducer(consultar, {dadosGlobal:{}})
    const [usuarioState, usuarioDispatch] = React.useReducer(verUsuario, {usuario:iniUsuario()})
    const [carregando,setCarregando]=useState(true);
    const [exibirSair,setExibirSair]=useState(false);
    const {usuario} =usuarioState
     function iniUsuario(){
        try {
            
       
        const storedUser = localStorage.getItem("@RNHubLumma:usuario");
        const storedToken = localStorage.getItem( "@RNHubLumma:token");

     
        if(storedUser && storedToken){

            api.setarHeaderDefault({token: storedToken});
            return JSON.parse( storedUser);
        }
        return null;
    } catch (error) {
        alert(error.message)    
    }
    }

    async function logar(login, senha, tipo){
 
        const response = await api.logar({login,senha, tipo});
        if( response == null) throw new Error("Login e/ou Senha inválidos");
        console.log(response)
        if (response?.Id >0){
            response.HashValidacao = "HUB123456789";

            const obj = {id: response.Id, email: response.Email, nome: response.Nome
                , tipo, placa: response.Placa, renavam: response.Renavam, codGestor: response.CodGestor
                , ultimoLogin: response.LastLogin, acesso: response.Acesso ?? response.acesso, gestorMaster: response.GestorMaster };
                usuarioDispatch({usuario: obj});
            api.setarHeaderDefault({token: response.HashValidacao});
                

            localStorage.setItem("@RNHubLumma:usuario", JSON.stringify(obj));
            localStorage.setItem("@RNHubLumma:token", response.HashValidacao);

              
        }else   {
            throw new Error("Usuário não cadastrado");
        }
         
       

     }

     async function atualizarUsuario({usuario}){
       await usuarioDispatch({usuario});
       localStorage.setItem("@RNHubLumma:usuario", JSON.stringify(usuario));
     }

     function sair(){
        // const response = await auth.Logar();
        //Se necessário gravar que o usuário saiu chamar a API
        localStorage.removeItem("@RNHubLumma:usuario")
        localStorage.removeItem("@RNHubLumma:token")

        setExibirSair(false);
        usuarioDispatch({usuario: null});



     }

     function deslogar(){
        
        setExibirSair(true);
     }

  return (
    <DadosGlobalAtualizarContext.Provider value={{objetoDispatch,atualizarUsuario,usuarioDispatch, logar, sair,deslogar}}>
      <DadosGlobalContext.Provider value={{objeto, usuarioState, logado: !!usuario, usuario, carregando}}>
      {children}
     
      </DadosGlobalContext.Provider>
    </DadosGlobalAtualizarContext.Provider>
  );
}



export function useDadosGlobal() {
  const context = React.useContext(DadosGlobalContext)
  if (context === undefined) {
    throw new Error('useDadosGlobal must be used within a DadosGlobalProvider')
  }
  return context
}

export function useDadosGlobalDispatch() {
  const context = React.useContext(DadosGlobalAtualizarContext)
  if (context === undefined) {
    throw new Error('useDadosGlobalDispatch must be used within a DadosGlobalProvider')
  }
  return context
}

