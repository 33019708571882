  
import styled from 'styled-components';

export const Container = styled.div`
background: red;
height: 0%;

  > div + div {
    margin-top: 8px;
  }
`;