import React from 'react';
import { DropzoneArea } from "material-ui-dropzone";
import { Field } from "formik";


const DropZone = ({name, texto, arquivosAceitos, Icon, QtdArquivos, onChange}) => {

    return(
        <Field name={name}>
{({ field, form, meta }) => (
    <div style={{display:'flex', flexDirection:'column', width:'100%'}} >

        <DropzoneArea
        {...field}
        dropzoneText={texto}
        getFileAddedMessage= {(fileName)=> `Arquivo ${fileName} foi adicionado com sucesso`}
        getFileRemovedMessage= {(fileName)=> `Arquivo ${fileName} foi removido com sucesso`}
        acceptedFiles={arquivosAceitos}
        showPreviews={false}
        showPreviewsInDropzone={true}
        showFileNames={true}
        filesLimit={QtdArquivos}
        Icon = {Icon}
        onChange={(files) => {
            onChange(files)
        }}
        maxFileSize={2097152} // Limitado à 2MB
      />
        
      {meta.error && <div style={{color:'red'}}>{meta.error}</div>}
      </div>

      )}
      </Field>
    )
};

export default DropZone;