import React from "react";

import {
  Container,
  IconeFundo,
  IconeTitulo,
  DivTitulo,
  Titulo,
  SubTitulo,
  IconeVoltar,
  MenuHamburguer,
} from "./style";

const TelaCabecalho = ({ titulo, subTitulo, icone, sair, onSair }) => {
  const clickSair = (e) => {
    if (onSair) onSair(e);
  };
  return (
    <Container style={{ justifyContent: "space-between" }} >
      <div
        style={{ width: "90%", display: "flex", justifyContent: "flex-start" }}
      >
        <IconeFundo>
          <IconeTitulo className={icone} />
        </IconeFundo>
        <DivTitulo>
          <Titulo>{titulo}</Titulo>
          <SubTitulo>{subTitulo}</SubTitulo>
        </DivTitulo>
      </div>
      <div
        style={{ width: "auto", display: "flex", justifyContent: "flex-end" }}
      >
        {sair && (
          <IconeVoltar
            onClick={(e) => {
              clickSair(e);
            }}
          >
            <IconeTitulo className={"pe-7s-back"} voltar={true} />
          </IconeVoltar>
        )}
      </div>
    </Container>
  );
};

export default TelaCabecalho;
