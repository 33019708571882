import * as Yup from 'yup';
// import * as valid from '../utils/validacoes'
import * as utils from '../utils'

export default Yup.object().shape({


    senha:Yup.string().required('Campo obrigatório'),
    confirmarSenha: Yup
    .string()
    .required('Campo obrigatório')
    .test('Confirmar senha', 'As senhas não correspondem ', function(value) {
      return this.parent.senha === value;
    }),
    

  
  })