import React, { useLayoutEffect, useCallback, useState } from "react";

import Helmet from "react-helmet";
import { useLocation, useHistory } from "react-router-dom";

import Tela from "../../layout/tela";
import * as Tema from "../../layout/tela/style";

import doneSVG from "../../../image/done.svg";

export default function Finalizacao() {
  const history = useHistory();
  const location = useLocation();
  const dataUrl = location.state;
  const [data, setData] = useState(null);

  const Termo = useCallback(
    (values) => {
      window.scrollTo(0, 0);

      history.push("/indicacaoTermo", { values });
    },
    [history]
  );

  useLayoutEffect(() => {
    if (!dataUrl) {
      Termo();
      return;
    }
    async function funcao() {
      const dados = dataUrl.data;

      await setData(dados);
    }
    funcao();
  }, [dataUrl, Termo]);

  return (
    <Tela titulo={"Indicação"} subTitulo={"Sua indicação foi realizada"}>
      <Tema.Card>
        <div style={{ display: "flex", padding: 10, color: "#70899b" }}>
          <div style={{ width: "60%" }}>
            <img
              src={doneSVG}
              alt="Obrigado por realizar a Indicação"
              style={{ padding: "10px", width: "100%" }}
            />
          </div>
          <div>
            <Tema.CardCabecalhoTitulo style={{ textTransform: "uppercase" }}>
              Obrigado
            </Tema.CardCabecalhoTitulo>
            <Tema.CardCabecalhoSubTitulo>
              Sua indicação foi realizada com sucesso, neste momento será
              enviada para um de nossos analistas para realizarem a validação.
            </Tema.CardCabecalhoSubTitulo>
            <div style={{ display: "flex", gap: 10, padding: 10 }}>
              <span style={{ textTransform: "uppercase", fontWeight: "bold" }}>
                Seu número de protocolo:
              </span>
              <span>{data?.protocolo}</span>
            </div>
          </div>
        </div>
      </Tema.Card>
    </Tela>
  );
}
