import React, { useEffect, useCallback, useState } from "react";
import Helmet from "react-helmet";
import { useHistory } from "react-router-dom";

import Tela from "../../layout/tela";

import * as api from "../../../API/consulta";
import * as utils from "../../../utils";
import { useDadosGlobal } from "../../context";

import * as Padrao from "../../../style/config";
import * as Tema from "../../layout/tela/style";
import Input from "../../layout/tela/campos/input";
import Select from "../../layout/tela/campos/select";
import { Carregando } from "../../loading";

import whats from "../../../image/whats.jpg";

const Contato = ({ semCabecalho }) => {
  const history = useHistory();
  const { usuario } = useDadosGlobal();
  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [placa, setPlaca] = useState("");
  const [tipoMotivo, setTipoMotivo] = useState("");
  const [descMotivo, setDescMotivo] = useState("");
  const [propsAlerta, setPropsAlerta] = useState(utils.propsAlerta);

  const validarEnvio = () => {
    if (nome == "") {
      alert("Informe o Nome Completo");
      return;
    }
    if (email == "") {
      alert("Informe o Email");
      return;
    }
    if (placa == "") {
      alert("Informe a Placa");
      return;
    }
    if (tipoMotivo == "") {
      alert("Informe o Motivo do Contato");
      return;
    }

    if (utils.validaEmail(email)) {
      alert("Digite um Email válido");
      return;
    }
    return true;
  };

  const enviarWhats = () => {
    window.open(
      "https://api.whatsapp.com/send?phone=5511942209561&text=Ol%C3%A1%20preciso%20da%20ajuda%20do%20Suporte",
      "_blank"
    );
  };

  const enviarEmail = async () => {
    try {
      if (!validarEnvio()) return;

      const ret = await api.enviarEmail({
        nome,
        email,
        placa,
        tipoMotivo,
        descMotivo,
      });

      alert(
        "Contato enviado com sucesso. Aguardar retorno do suporte por email. Protocolo: " +
          ret?.Protocolo
      );
      setNome("");
      setEmail("");
      setPlaca("");
      setTipoMotivo("");
      setDescMotivo("");
    } catch (e) {
      setPropsAlerta(
        utils.montaAlerta({
          titulo: e.message,
          origem: e.origem,
          tAnimacao: e.tipoAlerta,
        })
      );
    }
  };

  return (
    <Tela
      titulo={"Contato"}
      icone={"pe-7s-headphones"}
      subTitulo={""}
      semCabecalho={semCabecalho}
    >
      <Tema.CardContato>
        <Tema.CardFormContato>
          <Tema.CardForm style={{ minHeight: "150px", padding: 10 }}>
            <div
              style={{
                display: "flex",
                alignSelf: "self-start",
                alignItems: "center",
                gap: "20px",
              }}
            >
              <Tema.Icone
                className="pe-7s-mail"
                style={{ fontSize: "45px", color: "var(--color-cliente)" }}
              />
              <Padrao.Titulo>Contato via e-mail</Padrao.Titulo>
            </div>
            <div
              style={{ padding: 5, display: "flex", gap: 10, flexFlow: "wrap" }}
            >
              <div style={{ width: "100%" }}>
                <Input
                  label={"Nome Completo"}
                  type={"text"}
                  soLeitura={false}
                  onBlur={(e) => {
                    setNome(e.target.value);
                  }}
                  valor={nome}
                />
              </div>
              <div style={{ width: "100%" }}>
                <Input
                  label={"Email"}
                  type={"email"}
                  soLeitura={false}
                  onBlur={(e) => {
                    setEmail(e.target.value);
                  }}
                  valor={email}
                />
              </div>
            </div>

            <div
              style={{ padding: 5, display: "flex", gap: 10, flexFlow: "wrap" }}
            >
              <Input
                label={"Placa"}
                type={"text"}
                soLeitura={false}
                onBlur={(e) => {
                  setPlaca(e.target.value);
                }}
                valor={placa}
              />
              <div style={{ width: "100%" }}>
                <Select
                  label={"Motivo do Contato"}
                  type={"text"}
                  soLeitura={false}
                  onBlur={(e) => {
                    setTipoMotivo(e.target.value);
                  }}
                  valor={tipoMotivo}
                  source={[
                    { valor: "", descricao: "" },
                    {
                      valor: "Dúvidas ou esclarecimentos",
                      descricao: "Dúvidas ou esclarecimentos",
                    },
                    {
                      valor: "Problemas técnicos",
                      descricao: "Problemas técnicos",
                    },
                    { valor: "Outros", descricao: "Outros" },
                  ]}
                />
              </div>

              <div style={{ width: "100%" }}>
                <Input
                  label={"Descrição Motivo"}
                  type={"text"}
                  soLeitura={false}
                  onBlur={(e) => {
                    setDescMotivo(e.target.value);
                  }}
                  valor={descMotivo}
                />
              </div>
            </div>
          </Tema.CardForm>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: 10,
            }}
          >
            <Padrao.Botao
              size={"medium"}
              principal={true}
              onClick={async () => {
                await enviarEmail();
              }}

              //   startIcon={<Tema.Icone className="pe-7s-mail" />}
            >
              Enviar
            </Padrao.Botao>
          </div>
        </Tema.CardFormContato>

        <Tema.CardFormContatoWhatsapp>
          <div style={{}}>
            <img
              src={whats}
              onClick={() => {
                enviarWhats();
              }}
              alt="Contato via WhatsApp"
              style={{ width: "65%", cursor: "pointer" }}
            />
          </div>
        </Tema.CardFormContatoWhatsapp>
      </Tema.CardContato>

      <Carregando
        tipo={propsAlerta.tipoAnimacao}
        exibir={propsAlerta.exibir}
        userClose={propsAlerta.usuarioFechar}
        loop={true}
        titulo={propsAlerta.titulo}
        origem={propsAlerta.origem}
        setExibir={() => {
          setPropsAlerta(utils.semAlerta);
        }}
        // styleFundo={{width:'90%', height:'90%'}}
        // styleAnimacao={{width:'70%'}}
      />
    </Tela>
  );
};

export default Contato;
