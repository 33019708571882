import React, { useLayoutEffect, useCallback, useState } from "react";
import Helmet from "react-helmet";
import { useLocation, useHistory } from "react-router-dom";

import Tela from "../../layout/tela";
import * as Tema from "../../layout/tela/style";
import * as Padrao from "../../../style/config";
import * as utils from "../../../utils";
import {
  retornaDownloadBoletos,
  retornaDownloadComprovanteBoletos,
} from "../../../API/consulta";

import Dado from "../../layout/tela/infoGraficos/dado";
import Input from "../../layout/tela/campos/input";
import RadioButton from "../../layout/tela/campos/inputRadio";
import TabPanel from "../../layout/tabPanel";
import Panel from "../../layout/tabPanel/panel";
import { Carregando } from "../../loading";

import * as api from "../../../API/consulta";

const Boleto = () => {
  const corInfoGrafico = "#8a5859";
  const history = useHistory();
  const location = useLocation();
  const dataUrl = location.state;
  const [data, setData] = useState(null);
  const [propsAlerta, setPropsAlerta] = useState(utils.propsAlerta);

  const voltar = useCallback(() => {
    window.scrollTo(0, 0);

    history.push("/primeiroEmplacamento", {});
  }, [history]);

  const download = () => {
    try {
      const ret = retornaDownloadBoletos({
        CodFlowExecute: data?.CodFlowExecute,
        ait: data?.Ait,
      });

      window.open(ret);
    } catch (e) {
      setPropsAlerta(
        utils.montaAlerta({
          titulo: e.message,
          origem: e.origem,
          tAnimacao: e.tipoAlerta,
        })
      );
    }
  };

  const downloadComprovante = () => {
    try {
      const ret = retornaDownloadComprovanteBoletos({
        CodFlowExecute: data?.CodFlowExecute,
        ait: data?.Ait,
      });
      console.log("RetornoComprovante" + ret);
      window.open(ret);
    } catch (e) {
      setPropsAlerta(
        utils.montaAlerta({
          titulo: e.message,
          origem: e.origem,
          tAnimacao: e.tipoAlerta,
        })
      );
    }
  };

  const downloadCRLV = async ({ placa }) => {
    try {
      // Setando um "Loading" para que o usuário aguarde.
      setPropsAlerta(
        utils.montaAlerta({
          titulo: "Buscando CRLV. Por favor aguarde",
          origem: "Primeiro emplacamento - CRLV",
          tAnimacao: utils.animacoes.carregando,
        })
      );

      // Fellipe - 20/12/2023 => Chamando o método para recuperar o CRLV do PRC043 - Conforme especificação "LummaHub – CRLV Digital" aprovada.
      const ret = await api.anexarCRLV({
        placa: placa,
      });

      // Removendo o alerta.
      setPropsAlerta(utils.semAlerta);
    } catch (e) {
      setPropsAlerta(
        utils.montaAlerta({
          titulo: e.message,
          origem: e.origem,
          tAnimacao: e.tipoAlerta,
        })
      );
    }
  };

  useLayoutEffect(() => {
    if (!dataUrl) {
      voltar();
      return;
    }
    async function funcao() {
      setData(dataUrl.data);
    }
    funcao();
  }, [dataUrl, voltar]);

  return (
    <Tela
      titulo={"Primeiro Emplacamento"}
      subTitulo={"Detalhamento do Veículos"}
      icone={"pe-7s-car"}
      sair={true}
      onSair={(e) => voltar()}
    >
      <TabPanel defaultPanel={"1"}>
        <Panel titulo={"Info Gráfico"} key={"1"}>
          <Tema.CardFormInfoGrafico>
            <Tema.CardForm>
              <Tema.CardCabecalho style={{ backgroundColor: corInfoGrafico }}>
                <Tema.CardCabecalhoFundoImagem />
                <Tema.CardCabecalhoDivTitulo>
                  <Tema.CardCabecalhoTitulo>
                    {" "}
                    Dados do Veículo
                  </Tema.CardCabecalhoTitulo>
                  <Tema.CardCabecalhoSubTitulo>
                    Abaixo dados do veículo
                  </Tema.CardCabecalhoSubTitulo>
                </Tema.CardCabecalhoDivTitulo>
              </Tema.CardCabecalho>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  color: "#70899b",
                  flexWrap: "wrap",
                }}
              >
                <Dado
                  icone={"pe-7s-car"}
                  titulo={"Placa"}
                  valor={data?.Placa}
                />
                <Dado
                  icone={"pe-7s-car"}
                  titulo={"Chassi"}
                  valor={data?.Chassi}
                />
                <Tema.LineBreak />
                <Dado
                  icone={"pe-7s-car"}
                  titulo={"Renavam"}
                  valor={data?.Renavam}
                />
                <Dado
                  icone={"pe-7s-user"}
                  titulo={"Código Gestor"}
                  valor={data?.CodGestor}
                  minWidth={"122px"}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  color: "#70899b",
                  flexWrap: "wrap",
                }}
              >
                <Dado
                  icone={"pe-7s-comment"}
                  titulo={"Status"}
                  valor={data?.StatusProcess}
                />
                <Tema.LineBreak />
                <Dado
                  icone={"pe-7s-news-paper"}
                  titulo={"CRLV"}
                  valor={data?.Placa ? "Clique aqui!" : ""}
                  onClick={() => {
                    downloadCRLV({ placa: data?.Placa });
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  color: "#70899b",
                  justifyContent: "center",
                }}
              >
                <Dado
                  icone={"pe-7s-close-circle"}
                  titulo={"Pendência"}
                  valor={data?.Pendencia == 1 ? "SIM" : "NÃO"}
                  minWidth={"122px"}
                />
                <Dado
                  icone={"pe-7s-close-circle"}
                  titulo={"Pendência Final de placa"}
                  valor={data?.PendenciaFinalPlaca == 1 ? "SIM" : "NÃO"}
                  minWidth={"122px"}
                />
                <Dado
                  icone={"pe-7s-close-circle"}
                  titulo={"Pendência Kit"}
                  valor={data?.PendenciaKit == 1 ? "SIM" : "NÃO"}
                  minWidth={"122px"}
                />
                <Dado
                  icone={"pe-7s-close-circle"}
                  titulo={"Pendência BIN"}
                  valor={data?.PendenciaBin == 1 ? "SIM" : "NÃO"}
                  minWidth={"122px"}
                />
              </div>
            </Tema.CardForm>
            {/* <Tema.CardForm>
              <Tema.CardCabecalho style={{ backgroundColor: corInfoGrafico }}>
                <Tema.CardCabecalhoFundoImagem />
                <Tema.CardCabecalhoDivTitulo>
                  <Tema.CardCabecalhoTitulo>
                    Documentos do Boleto
                  </Tema.CardCabecalhoTitulo>
                  <Tema.CardCabecalhoSubTitulo>
                    Abaixo faça o download dos documentos disponíveis
                  </Tema.CardCabecalhoSubTitulo>
                </Tema.CardCabecalhoDivTitulo>
              </Tema.CardCabecalho>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  color: "#70899b",
                }}
              ></div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  color: "#70899b",
                }}
              >
                <Dado
                  colunaUnica={true}
                  icone={"pe-7s-cloud-download"}
                  titulo={"Download"}
                  valor={"BOLETO + COMPROVANTE"}
                  onClick={() => {
                    download();
                  }}
                />
              </div>
            </Tema.CardForm> */}
          </Tema.CardFormInfoGrafico>
        </Panel>
      </TabPanel>

      <Carregando
        tipo={propsAlerta.tipoAnimacao}
        exibir={propsAlerta.exibir}
        userClose={propsAlerta.usuarioFechar}
        loop={true}
        titulo={propsAlerta.titulo}
        origem={propsAlerta.origem}
        setExibir={() => {
          setPropsAlerta(utils.semAlerta);
        }}
        // styleFundo={{width:'90%', height:'90%'}}
        // styleAnimacao={{width:'70%'}}
      />
    </Tela>
  );
};

export default Boleto;
