import * as Yup from 'yup';
// import * as valid from '../utils/validacoes'
import * as utils from '../utils'



export default Yup.object().shape({


    
    DadosCondutor: Yup.object().shape({
        nomeCondutor: Yup.string().required('Campo Obrigatório'),
        emailCondutor:Yup.string().test('isEmail', 'Email Inválido', (value) => utils.validaEmail(value) == "")
          .required('Campo obrigatório'),
        tipoCnh: Yup.string().required('Campo Obrigatório'),
        numeroCnh: Yup.string().required('Campo Obrigatório'),
        categoriaCnh: Yup.string().required('Campo Obrigatório'),
        
        dtNascimento: Yup.date().required('Campo Obrigatório')
          .min( new Date(utils.yearMin, 0, 1).toISOString().split('T')[0] , "Digite uma data valida")
          .max( new Date(utils.yearMax, 0, 1).toISOString().split('T')[0] , "Digite uma data valida"),

        dtPrimeiraHabilitacao: Yup.date().required('Campo Obrigatório')
          .min( new Date(utils.yearMin, 0, 1).toISOString().split('T')[0] , "Digite uma data valida")
          .max( new Date(utils.yearMax, 0, 1).toISOString().split('T')[0] , "Digite uma data valida"),

        dtVencimentoCNH: Yup.date().required('Campo Obrigatório')
          // .min( new Date(utils.yearMin, 0, 1).toISOString().split('T')[0] , "Digite uma data valida")
          .min( "1922-01-01" , "Digite uma data valida")
          // .min( new Date(new Date().getFullYear()-100, 0, 1).toISOString().split('T')[0].toString() , "Digite uma data valida")
          .max( new Date(utils.yearMax, 0, 1).toISOString().split('T')[0] , "Digite uma data valida"),

          cpf: Yup.string().test('isCPF', 'CPF Inválido', (value) => utils.validaCPF(value) == "")
          .required('Campo obrigatório'),
          
          endereco: Yup.string().required('Campo Obrigatório'),
          numeroEndereco: Yup.string().required('Campo Obrigatório'),
          complementoEndereco: Yup.string().optional(),
          bairroEndereco: Yup.string().required('Campo Obrigatório'),
          ufEndereco: Yup.string().required('Campo Obrigatório'),
          cidadeEndereco: Yup.string().required('Campo Obrigatório'),
          grauParentesco: Yup.string().optional(),
          ufCNH: Yup.string().required('Campo Obrigatório'),
          numeroRG: Yup.string().required('Campo Obrigatório'),
          ufRG: Yup.string().required('Campo Obrigatório'),
          CEP: Yup.string().required('Campo Obrigatório'),

         
    })
    , Uploads : Yup.object().shape({
        Termo: Yup.array().length(1,'Faça o Upload do Termo Assinado'),
        CNH: Yup.array().length(1,'Faça o Upload da CNH'),

    })

  
  })