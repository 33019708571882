import axios from "axios";
import * as utils from "../utils";

//#region ... Ambiente ..

const base = `${window.location.origin}/`;
const urlProducao = process.env.REACT_APP_URL_PRODUCAO;
const urlHomolog = process.env.REACT_APP_URL_HOMOLOG;
const urlDesenv = process.env.REACT_APP_URL_DESENV;

let urlAPI = "https://api.finesreport.com.br/";
if (base.includes("localhost")) {
  urlAPI = urlDesenv;
} else if (base.includes("homolog")) {
  urlAPI = urlHomolog;
} else {
  urlAPI = urlProducao;
}

const api = axios.create({
  baseURL: urlAPI,
});

export const retornaAmbiente = () => {
  let ret = "";

  if (urlAPI == urlProducao) ret = "produção";
  if (urlAPI == urlHomolog) ret = "homologação";
  if (urlAPI == urlDesenv) ret = "desenvolvimento";
  console.log(ret);
  return ret;
};

export const setarHeaderDefault = ({ token }) => {
  try {
    api.defaults.headers["Authorization"] = `Bearer ${token}`;
  } catch (e) {
    throw utils.trataErro(e, "setarHeaderDefault");
  }
};

export const retornaURL = () => {
  return urlAPI;
};

//#endregion

//#region ... Login ...

export const loginCondutor = async ({ usuario, senha }) => {
  try {
    if (!usuario || !senha) {
      throw new Error("Usuario ou Senha não informados");
    }

    const retorno = await api.post(`/driver/login`, {
      Placa: usuario,
      Senha: senha,
    });
    return retorno.data;
  } catch (e) {
    const erro = utils.trataErro(e, "loginCondutor");

    if (erro.message.toLowerCase().indexOf("invalidos") > -1)
      erro.codigo = utils.animacoes.aviso;

    throw erro;
  }
};

export const loginGestor = async ({ usuario, senha }) => {
  try {
    if (!usuario || !senha) {
      throw new Error("Usuario ou Senha não informados");
    }

    const retorno = await api.post(`/manager/login`, {
      Codigo: usuario,
      Senha: senha,
    });
    return retorno.data;
  } catch (e) {
    const erro = utils.trataErro(e, "loginGestor");

    if (erro.message.toLowerCase().indexOf("invalidos") > -1)
      erro.codigo = utils.animacoes.aviso;

    throw erro;
  }
};

export const modificarSenhaCondutor = async ({ chassi }) => {
  try {
    if (!chassi) {
      throw new Error("Chassi não informado");
    }

    const retorno = await api.post(`/driver/modificarsenha`, {
      Chassi: chassi,
    });
    return retorno.data;
  } catch (e) {
    const erro = utils.trataErro(e, "modificarSenhaCondutor");

    if (erro.message.toLowerCase().indexOf("invalidos") > -1)
      erro.codigo = utils.animacoes.aviso;

    throw erro;
  }
};

export const modificarSenhaGestor = async ({ codigo }) => {
  try {
    if (!codigo) {
      throw new Error("Código não informado");
    }

    const retorno = await api.post(`/driver/modificarsenha/gestor`, {
      CodGestor: codigo,
    });
    return retorno.data;
  } catch (e) {
    const erro = utils.trataErro(e, "modificarSenhaGestor");

    if (erro.message.toLowerCase().indexOf("invalidos") > -1)
      erro.codigo = utils.animacoes.aviso;

    throw erro;
  }
};

export const cancelarSenhaPrimeiroAcesso = async ({ idUsuario }) => {
  try {
    if (!idUsuario) {
      throw new Error("Informe todos os parâmetros: Id do usuário");
    }

    const retorno = await api.post(`/driver/updatecancelar`, {
      Id: idUsuario,
    });
    return retorno.data;
  } catch (e) {
    throw utils.trataErro(e, "cancelarSenhaPrimeiroAcesso");
  }
};

export const alterarSenhaPrimeiroAcesso = async ({ idUsuario, senha }) => {
  try {
    if (!idUsuario || !senha) {
      throw new Error("Informe todos os parâmetros: Id do usuário e a senha");
    }

    const retorno = await api.post(`/driver/updatefirst`, {
      Id: idUsuario,
      Senha: senha,
    });
    return retorno.data;
  } catch (e) {
    throw utils.trataErro(e, "alterarSenhaPrimeiroAcesso");
  }
};

export const cancelarSenhaPrimeiroAcessoGestor = async ({ idUsuario }) => {
  try {
    if (!idUsuario) {
      throw new Error("Informe todos os parâmetros: Id do usuário");
    }

    const retorno = await api.post(`/manager/updatecancelar`, {
      Id: idUsuario,
    });
    return retorno.data;
  } catch (e) {
    throw utils.trataErro(e, "cancelarSenhaPrimeiroAcessoGestor");
  }
};

export const alterarSenhaPrimeiroAcessoGestor = async ({
  idUsuario,
  senha,
}) => {
  try {
    if (!idUsuario || !senha) {
      throw new Error("Informe todos os parâmetros: Id do usuário e a senha");
    }

    const retorno = await api.post(`/manager/updatefirstgr`, {
      Id: idUsuario,
      Senha: senha,
    });
    return retorno.data;
  } catch (e) {
    throw utils.trataErro(e, "alterarSenhaPrimeiroAcessoGestor");
  }
};

//#endregion

//#region ... API Google ...

export const retornaGoogleKey = () => {
  // let ret = "AIzaSyB-AruGMKpqJt7f6Fvwz2ccsxchFuUtw1I";
  // ret = "AIzaSyATRu5L6Z3vXmhpJiJ7487c1wBPLipM4ro";
  // ret = "AIzaSyAzqEyvAuFgu-mtFFBNv2l_uge04-UNsaw";
  let ret = "AIzaSyDtwkVo1aGyABgodkk2dvuwld7_PK1w2XE";
  return ret;
};

export const retornaLocalizacao = () => {
  let ret = true;

  return ret;
};

//#endregion

//#region ... Notificação ...

export const RetornaNotificacoes = async ({
  placa,
  codGestor,
  gestorMaster,
  dtInicio,
  dtFim,
  tipoDataFiltro,
  ait,
}) => {
  try {
    if (!placa && !codGestor && !dtInicio && !dtFim) {
      throw new Error("Informe todos os parâmetros: placa e código Gestor");
    }
    if (!placa) placa = "null";
    if (!codGestor) codGestor = "null";
    if (!ait) ait = "null";

    const retorno = await api.get(
      `/fines/all/${placa}/${codGestor}/${tipoDataFiltro}/${dtInicio}/${dtFim}/${gestorMaster}/${ait}`
    );
    return retorno.data;
  } catch (e) {
    throw utils.trataErro(e, "RetornaNotificacoes");
  }
};

export const filtraNotificacao = async ({ id }) => {
  try {
    if (!id) {
      throw new Error("Informe todos os parâmetros: id");
    }

    const retorno = await api.get(`/fines/novo/all/id/${id}`);
    return retorno.data;
  } catch (e) {
    throw utils.trataErro(e, "filtraNotificacao");
  }
};

export const filtraNotificacaoAIT = async ({ ait }) => {
  try {
    if (!ait) {
      throw new Error("Informe todos os parâmetros: ait");
    }

    const retorno = await api.get(`/fines/novo/all/ait/${ait}`);
    return retorno.data;
  } catch (e) {
    throw utils.trataErro(e, "filtraNotificacao");
  }
};

export const retornaDownloadNotificacao = ({ CodFlowExecute, ait }) => {
  try {
    if (!CodFlowExecute || !ait) {
      throw new Error("Informe todos os parâmetros: Id e Ait");
    }

    const retorno = `${urlAPI}/fines/download/${CodFlowExecute}/${ait}`;
    return retorno;
  } catch (e) {
    throw utils.trataErro(e, "retornaDownloadNotificacao");
  }
};

export const retornaTimelineNotificacao = async ({ codFlowExecute }) => {
  try {
    if (!codFlowExecute) {
      throw new Error("Informe todos os parâmetros: codFlowExecute");
    }

    const retorno = await api.get(
      `/service/fines/timeLine/${codFlowExecute.codFlowExecute}`
    );
    return retorno.data;
  } catch (e) {
    throw utils.trataErro(e, "retornaTimelineNotificacao");
  }
};

//#endregion

//#region ...Boleto ...

export const RetornaBoletos = async ({
  placa,
  codGestor,
  gestorMaster,
  dtInicio,
  dtFim,
  tipoDataFiltro,
  ait,
}) => {
  try {
    if (!placa && !codGestor && !dtInicio && !dtFim) {
      throw new Error(
        "Informe todos os parâmetros: placa, código Gestor, data inicio e data fim"
      );
    }
    if (!placa) placa = "null";
    if (!codGestor) codGestor = "null";
    if (!dtInicio) dtInicio = "null";
    if (!dtFim) dtFim = "null";
    if (!ait) ait = "null";

    const retorno = await api.get(
      `/boletos/all/${placa}/${codGestor}/${tipoDataFiltro}/${dtInicio}/${dtFim}/${gestorMaster}/${ait}`
    );
    return retorno.data;
    // return [];
  } catch (e) {
    throw utils.trataErro(e, "RetornaBoletos");
  }
};

export const filtraBoleto = async ({ id }) => {
  try {
    if (!id) {
      throw new Error("Informe todos os parâmetros: id");
    }

    const retorno = await api.get(`/boletos/all/id/${id}`);
    return retorno.data;
  } catch (e) {
    throw utils.trataErro(e, "filtraBoleto");
  }
};

export const retornaDownloadBoletos = ({ CodFlowExecute, ait }) => {
  try {
    if (!CodFlowExecute || !ait) {
      throw new Error("Informe todos os parâmetros: Id e Ait");
    }

    const retorno = `${urlAPI}/boletos/downloadBC/${CodFlowExecute}/${ait}`;
    return retorno;
  } catch (e) {
    throw utils.trataErro(e, "retornaDownloadBoletos");
  }
};

export const retornaDownloadComprovanteBoletos = ({ CodFlowExecute, ait }) => {
  try {
    if (!CodFlowExecute || !ait) {
      throw new Error("Informe todos os parâmetros: Id e Ait");
    }

    const retorno = `${urlAPI}/boletos/downloadComprovante/${CodFlowExecute}/${ait}`;
    return retorno;
  } catch (e) {
    throw utils.trataErro(e, "retornaDownloadBoletos");
  }
};

export const downloadBoletos = async ({ idMulta, ait }) => {
  try {
    if (!idMulta || !ait) {
      throw new Error("Informe todos os parâmetros: Id e Ait");
    }

    const retorno = await api.get(`/boletos/downloadBC/${idMulta}/${ait}`);
    return retorno.data;
  } catch (e) {
    throw utils.trataErro(e, "downloadBoletos");
  }
};

//#endregion

//#region ... 1º Emplacamento - Frotas ...

export const retornaEmplacamento = async ({
  placa,
  codGestor,
  gestorMaster,
  dtInicio,
  dtFim,
}) => {
  try {
    if (!placa && !codGestor && !dtInicio && !dtFim) {
      throw new Error(
        "Informe todos os parâmetros: placa, código Gestor, data inicio e data fim"
      );
    }
    if (!placa) placa = "null";
    if (!codGestor) codGestor = "null";

    const retorno = await api.get(
      `/emplacamento/all/${placa}/${codGestor}/${dtInicio}/${dtFim}/${gestorMaster}`
    );

    return retorno.data;
  } catch (e) {
    throw utils.trataErro(e, "retornaEmplacamento");
  }
};

export const filtraPrimeiroEmplacamento = async ({ id }) => {
  try {
    if (!id) {
      throw new Error("Informe todos os parâmetros: id");
    }

    const retorno = await api.get(`/firstRegister/all/id/${id}`);
    return retorno.data;
  } catch (e) {
    throw utils.trataErro(e, "filtraBoleto");
  }
};

//#endregion

//#region ... Licenciamento ...

export const retornaLicenciamentos = async ({
  placa,
  codGestor,
  gestorMaster,
}) => {
  try {
    if (!placa) placa = "null";
    if (!codGestor) codGestor = "null";

    const retorno = await api.get(
      `/licensing/all/${placa}/${codGestor}/${gestorMaster}`
    );

    return retorno.data;
  } catch (e) {
    throw utils.trataErro(e, "retornaLicenciamentos");
  }
};

export const retornaDownloadLicenciamento = ({ placa }) => {
  try {
    if (!placa) {
      throw new Error("Informe todos os parâmetros: placa");
    }

    const retorno = `${urlAPI}/licensing/download/${placa}`;
    return retorno;
  } catch (e) {
    throw utils.trataErro(e, "retornaDownloadLicenciamento");
  }
};

export const anexarCRLV = async ({ placa }) => {
  try {
    if (!placa) {
      throw new Error("Informe todos os parâmetros: placa");
    }
    if (!placa) placa = "null";

    const retorno = await api.get(`/service/fines/anexar-crlv/${placa}`);

    const jsonReturn = JSON.parse(retorno.data);
    if (jsonReturn.code == 301) {
      return jsonReturn;
    }
    return jsonReturn.pdf;
  } catch (e) {
    throw utils.trataErro(e, "anexarCRLV");
  }
};

export const anexarCRLVLicenciamento = async ({ codFlowExecute }) => {
  try {
    if (!codFlowExecute) {
      throw new Error("Informe todos os parâmetros: codFlowExecute");
    }
    if (!codFlowExecute) codFlowExecute = "null";

    const retorno = await api.get(
      `/service/Licensing/AnexarCRLVLicenciamento/${codFlowExecute}`
    );

    const jsonReturn = JSON.parse(retorno.data);
    if (jsonReturn.code == 301) {
      return jsonReturn;
    }
    return jsonReturn.pdf;
  } catch (e) {
    throw utils.trataErro(e, "anexarCRLVLicenciamento");
  }
};

export const anexarCRLVGestor = async ({ codGestor }) => {
  try {
    if (!codGestor) {
      throw new Error("Informe todos os parâmetros: placa");
    }
    if (!codGestor) codGestor = "null";

    const retorno = await api.get(
      `/service/fines/anexar-crlv/Gestor/${codGestor}`
    );

    const jsonReturn = JSON.parse(retorno.data);
    if (jsonReturn.code == 301) {
      return jsonReturn;
    }
    return jsonReturn.pdf;
  } catch (e) {
    throw utils.trataErro(e, "anexarCRLV");
  }
};

export const retornaTimelineLicenciamento = async ({ codFlowExecute }) => {
  try {
    if (!codFlowExecute) {
      throw new Error("Informe todos os parâmetros: codFlowExecute");
    }

    const retorno = await api.get(
      `/service/Licensing/timeLine/${codFlowExecute.codFlowExecute}`
    );
    return retorno.data;
  } catch (e) {
    throw utils.trataErro(e, "retornaTimelineLicenciamento");
  }
};

export const downloadPlanilha = async ({ codGestor }) => {
  try {
    if (!codGestor) {
      throw new Error("Informe todos os parâmetros: codGestor");
    }
    if (!codGestor) codGestor = "null";

    const retorno = await api.get(
      `/service/fines/downloadPlanilha/${codGestor}`
    );

    const jsonReturn = JSON.parse(retorno.data);
    if (jsonReturn.code == 301) {
      return jsonReturn;
    }
    return jsonReturn.file;
  } catch (e) {
    throw utils.trataErro(e, "downloadPlanilha");
  }
};

export const downloadCrlvVigente = async ({
  placa,
  codGestor,
  gestorMaster,
}) => {
  try {
    {
      console.log("Teste4=>" + gestorMaster);
    }

    if (!placa) placa = "null";
    if (!codGestor) codGestor = "null";

    {
      console.log(`Teste5: ${placa}/${codGestor}/${gestorMaster}`);
    }

    const retorno = await api.get(
      `/service/fines/downloadCrlvVigente/${placa}/${codGestor}/${gestorMaster}`
    );

    const jsonReturn = JSON.parse(retorno.data);
    if (jsonReturn.code == 301) {
      return jsonReturn;
    }
    return jsonReturn.file;
  } catch (e) {
    throw utils.trataErro(e, "downloadCrlvVigente");
  }
};

export const filtraLicenciamento = async ({ id }) => {
  try {
    if (!id) {
      throw new Error("Informe todos os parâmetros: id");
    }

    const retorno = await api.get(`/licensing/all/id/${id}`);
    return retorno.data;
  } catch (e) {
    throw utils.trataErro(e, "filtraLicenciamento");
  }
};

//#endregion

//#region ... CRLV Digital ...

export const retornaVeiculos = async ({ placa, codGestor, gestorMaster }) => {
  try {
    if (!placa) placa = "null";
    if (!codGestor) codGestor = "null";

    const retorno = await api.get(
      `/driver/all/${placa}/${codGestor}/${gestorMaster}`
    );

    return retorno.data;
  } catch (e) {
    throw utils.trataErro(e, "retornaVeiculos");
  }
};

//#endregion

//#region ... Contato ...

export const enviarEmail = async ({
  nome,
  email,
  placa,
  tipoMotivo,
  descMotivo,
}) => {
  try {
    if (!nome || !email || !placa || !tipoMotivo) {
      throw new Error(
        "Informe todos os parâmetros: Nome, Email, Placa, Tipo Motivo"
      );
    }
    const data = {
      NomeCompleto: nome,
      Email: email,
      Placa: placa,
      Motivo: tipoMotivo,
      DescricaoMotivo: descMotivo,
    };

    const retorno = await api.post(`/driver/enviarcontato`, data);
    return retorno.data;
  } catch (e) {
    throw utils.trataErro(e, "enviarEmail");
  }
};

//#endregion

//#region ... Indicação ...

export const ValidarIndicacao = async ({ idNotificacao }) => {
  try {
    if (!idNotificacao) {
      throw new Error("Informe todos os parâmetros: Id Notificação");
    }

    const retorno = await api.get(`/Indicacao/validar/${idNotificacao}`);
    return retorno.data;
  } catch (e) {
    throw utils.trataErro(e, "Validarindicacao");
  }
};

export const OptouPorNaoIndicar = async ({ ait }) => {
  try {
    if (!ait) {
      throw new Error("Informe todos os parâmetros: ait");
    }
    if (!ait) ait = "null";

    const retorno = await api.post(`/Indicacao/NaoInformarCondutor/${ait}`);
    return retorno.data;
  } catch (e) {
    throw utils.trataErro(e, "OptouPorNaoIndicar");
  }
};

export const GerarPdfTermo = async ({
  locatario,
  dataInfracao,
  horaInfracao,
  nomeCondutor,
  email,
  vencimentoCnh,
  marca,
  modelo,
  placa,
  numeroRegistroInterno,
  numeroCnh,
  ait,
  tipoCnh,
  categoriaCnh,
}) => {
  try {
    // Pegar informações do front e chamar a API para retorno do ID
    const data = {
      locatario: locatario,
      data: dataInfracao,
      HoraInfracao: horaInfracao,
      nomeCondutor: nomeCondutor,
      email: email,
      vencimentoCNH: vencimentoCnh,
      marca: marca,
      modelo: modelo,
      placa: placa,
      numeroRegistroInterno: numeroRegistroInterno,
      numeroCnh: numeroCnh,
      ait: ait,
      ipoCnh: tipoCnh,
      categoriaCNH: categoriaCnh,
    };

    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

    const ret = await api.post(`/Indicacao/PDF`, data);

    const retorno = `${urlAPI}/Indicacao/DownlaoadPDFTermo/${ret.data}`;
    return retorno;
  } catch (e) {
    throw utils.trataErro(e, "GerarPdfTermo");
  }
};

//Montar o post para envioar o termo, depois fazer o mesmo para enviar a termo
export const UploadIndicacaoTermo = async ({ ait, file }) => {
  try {
    if (!ait || !file) {
      throw new Error("Informe todos os parâmetros: AIT e File");
    }

    let formData = new FormData();
    formData.append("file", file, `Termo.${file.name.split(".").pop()}`);

    const retorno = await api.post(
      `/Indicacao/uploadImage/Termo/${ait}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return retorno.data;
  } catch (e) {
    throw utils.trataErro(e, "UploadIndicacaoTermo");
  }
};

//Montar o post para envioar o termo, depois fazer o mesmo para enviar a CNH
export const UploadIndicacaoCnh = async ({ ait, file }) => {
  try {
    if (!ait || !file) {
      throw new Error("Informe todos os parâmetros: AIT e File");
    }

    let formData = new FormData();
    formData.append("file", file, `Cnh.${file.name.split(".").pop()}`);

    const retorno = await api.post(
      `/Indicacao/uploadImage/CNH/${ait}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return retorno.data;
  } catch (e) {
    throw utils.trataErro(e, "UploadIndicacaoCnh");
  }
};

export const SolicitarIndicacao = async ({
  ait,
  bairroEndereco,
  categoriaCnh,
  cidadeEndereco,
  complementoEndereco,
  cpf,
  dataLimiteIndicacao,
  dtPrimeiraHabilitacao,
  dtNascimento,
  dtVencimentoCNH,
  emailCondutor,
  endereco,
  nomeCondutor,
  numeroCnh,
  numeroEndereco,
  tipoCnh,
  ufEndereco,
  grauParentesco,
  rg,
  ufRG,
  ufCNH,
  cep,
}) => {
  try {
    const data = {
      ait: ait,
      bairro: bairroEndereco,
      categoriaCNH: categoriaCnh,
      cidade: cidadeEndereco,
      complemento: complementoEndereco,
      cpf: cpf,
      dataLimiteIndicacao: dataLimiteIndicacao,
      data1CNH: dtPrimeiraHabilitacao,
      dataNascimento: dtNascimento,
      dataVencimento: dtVencimentoCNH,
      email: emailCondutor,
      logradouro: endereco,
      nome: nomeCondutor,
      nroCNH: numeroCnh,
      nroResidencial: numeroEndereco,
      tipoCNH: tipoCnh,
      uf: ufEndereco,
      grauParentesco: grauParentesco,
      ufCNH: ufCNH,
      ufRG: ufRG,
      cep: cep,
      rg: rg,
    };

    const retorno = await api.post(`/Indicacao/solicitarIndicacao`, data);
    return retorno.data;
  } catch (e) {
    throw utils.trataErro(e, "SolicitarIndicacao");
  }
};

//#endregion

//#region ... Dashboard ...

export const retornaDashboard = async ({
  dataIni,
  dataFim,
  placa,
  codGestor,
  gestorMaster,
  tipoDataFiltro,
  ait,
}) => {
  try {
    if (!gestorMaster && !placa && !codGestor) {
      throw new Error("Informe todos os parâmetros: placa e código Gestor");
    }
    if (!tipoDataFiltro) {
      throw new Error("Informe todos os parâmetros: Período de consulta");
    }
    const data = {
      DataInicialStr: dataIni,
      DataFinalStr: dataFim,
      CodigoGestor: codGestor,
      Placa: placa,
      GestorMaster: gestorMaster,
      TipoDataFiltro: tipoDataFiltro,
      Ait: ait,
    };

    const retorno = await api.post(`/dashboards/data/all`, data);
    return retorno.data;
    // return [];
  } catch (e) {
    throw utils.trataErro(e, "retornaDashboard");
  }
};

export const retornaDetNotificacaoRecebida = async ({
  dataIni,
  dataFim,
  placa,
  codGestor,
  gestorMaster,
  tipoDataFiltro,
  ait,
}) => {
  try {
    if (!gestorMaster && !placa && !codGestor) {
      throw new Error("Informe todos os parâmetros: placa e código Gestor");
    }

    const data = {
      DataInicialStr: dataIni,
      DataFinalStr: dataFim,
      CodigoGestor: codGestor,
      GestorMaster: gestorMaster,
      Placa: placa,
      TipoDataFiltro: tipoDataFiltro,
      Ait: ait,
    };
    const retorno = await api.post(
      `/dashboards/data/notificacaoRecebida`,
      data
    );
    return retorno.data;
  } catch (e) {
    throw utils.trataErro(e, "retornaDetNotificacaoRecebida");
  }
};

export const retornaDetNotificacaoRecebidaBoletoComum = async ({
  dataIni,
  dataFim,
  placa,
  codGestor,
  gestorMaster,
  tipoDataFiltro,
  ait,
}) => {
  try {
    if (!gestorMaster && !placa && !codGestor) {
      throw new Error("Informe todos os parâmetros: placa e código Gestor");
    }

    const data = {
      DataInicialStr: dataIni,
      DataFinalStr: dataFim,
      CodigoGestor: codGestor,
      GestorMaster: gestorMaster,
      Placa: placa,
      TipoDataFiltro: tipoDataFiltro,
      Ait: ait,
    };
    const retorno = await api.post(
      `/dashboards/data/notificacaoRecebidaBoletoComum`,
      data
    );
    return retorno.data;
  } catch (e) {
    throw utils.trataErro(e, "retornaDetNotificacaoRecebidaBoletoComum");
  }
};

export const retornaDetNotificacaoRecebidaBoletoNic = async ({
  dataIni,
  dataFim,
  placa,
  codGestor,
  gestorMaster,
  tipoDataFiltro,
  ait,
}) => {
  try {
    if (!gestorMaster && !placa && !codGestor) {
      throw new Error("Informe todos os parâmetros: placa e código Gestor");
    }

    const data = {
      DataInicialStr: dataIni,
      DataFinalStr: dataFim,
      CodigoGestor: codGestor,
      GestorMaster: gestorMaster,
      Placa: placa,
      TipoDataFiltro: tipoDataFiltro,
      Ait: ait,
    };
    const retorno = await api.post(
      `/dashboards/data/notificacaoRecebidaBoletoNic`,
      data
    );
    return retorno.data;
  } catch (e) {
    throw utils.trataErro(e, "retornaDetNotificacaoRecebidaBoletoNic");
  }
};

export const retornaDetBoletoSemNotificacao = async ({
  dataIni,
  dataFim,
  placa,
  codGestor,
  gestorMaster,
  tipoDataFiltro,
  ait,
}) => {
  try {
    if (!gestorMaster && !placa && !codGestor) {
      throw new Error("Informe todos os parâmetros: placa e código Gestor");
    }

    const data = {
      DataInicialStr: dataIni,
      DataFinalStr: dataFim,
      CodigoGestor: codGestor,
      GestorMaster: gestorMaster,
      Placa: placa,
      TipoDataFiltro: tipoDataFiltro,
      Ait: ait,
    };
    const retorno = await api.post(
      `/dashboards/data/boletoSemNotificacao`,
      data
    );
    return retorno.data;
  } catch (e) {
    throw utils.trataErro(e, "retornaDetBoletoSemNotificacao");
  }
};

export const retornaDetNicSemNotificacao = async ({
  dataIni,
  dataFim,
  placa,
  codGestor,
  gestorMaster,
  tipoDataFiltro,
  ait,
}) => {
  try {
    if (!gestorMaster && !placa && !codGestor) {
      throw new Error("Informe todos os parâmetros: placa e código Gestor");
    }

    const data = {
      DataInicialStr: dataIni,
      DataFinalStr: dataFim,
      CodigoGestor: codGestor,
      GestorMaster: gestorMaster,
      Placa: placa,
      TipoDataFiltro: tipoDataFiltro,
      Ait: ait,
    };
    const retorno = await api.post(`/dashboards/data/nicSemNotificacao`, data);
    return retorno.data;
  } catch (e) {
    throw utils.trataErro(e, "retornaDetNicSemNotificacao");
  }
};

export const retornaDetBoletoRecebido = async ({
  dataIni,
  dataFim,
  placa,
  codGestor,
  gestorMaster,
  tipoDataFiltro,
  ait,
}) => {
  try {
    if (!gestorMaster && !placa && !codGestor) {
      throw new Error("Informe todos os parâmetros: placa e código Gestor");
    }

    const data = {
      DataInicialStr: dataIni,
      DataFinalStr: dataFim,
      CodigoGestor: codGestor,
      GestorMaster: gestorMaster,
      Placa: placa,
      TipoDataFiltro: tipoDataFiltro,
      Ait: ait,
    };
    const retorno = await api.post(`/dashboards/data/boletoRecebido`, data);
    return retorno.data;
  } catch (e) {
    throw utils.trataErro(e, "retornaDetBoletoRecebido");
  }
};

export const retornaDetNic = async ({
  dataIni,
  dataFim,
  placa,
  codGestor,
  gestorMaster,
  tipoDataFiltro,
  ait,
}) => {
  try {
    if (!gestorMaster && !placa && !codGestor) {
      throw new Error("Informe todos os parâmetros: placa e código Gestor");
    }

    const data = {
      DataInicialStr: dataIni,
      DataFinalStr: dataFim,
      CodigoGestor: codGestor,
      GestorMaster: gestorMaster,
      Placa: placa,
      TipoDataFiltro: tipoDataFiltro,
      Ait: ait,
    };
    const retorno = await api.post(`/dashboards/data/boletoNIC`, data);
    return retorno.data;
  } catch (e) {
    throw utils.trataErro(e, "retornaDetNic");
  }
};

export const retornaDetPrazoIndicacao = async ({
  dataIni,
  dataFim,
  placa,
  codGestor,
  gestorMaster,
  dentroPrazo,
  tipoDataFiltro,
  ait,
}) => {
  try {
    if (!gestorMaster && !placa && !codGestor) {
      throw new Error("Informe todos os parâmetros: placa e código Gestor");
    }

    const data = {
      DataInicialStr: dataIni,
      DataFinalStr: dataFim,
      CodigoGestor: codGestor,
      GestorMaster: gestorMaster,
      Placa: placa,
      DentroPrazo: dentroPrazo,
      TipoDataFiltro: tipoDataFiltro,
      Ait: ait,
    };
    const retorno = await api.post(`/dashboards/data/prazoIndicacao`, data);
    return retorno.data;
  } catch (e) {
    throw utils.trataErro(e, "retornaDetPrazoIndicacao");
  }
};

export const retornaDetFezIndicacao = async ({
  dataIni,
  dataFim,
  placa,
  codGestor,
  gestorMaster,
  fezIndicacao,
  tipoDataFiltro,
  ait,
}) => {
  try {
    if (!gestorMaster && !placa && !codGestor) {
      throw new Error("Informe todos os parâmetros: placa e código Gestor");
    }

    const data = {
      DataInicialStr: dataIni,
      DataFinalStr: dataFim,
      CodigoGestor: codGestor,
      GestorMaster: gestorMaster,
      Placa: placa,
      FezIndicacao: fezIndicacao,
      TipoDataFiltro: tipoDataFiltro,
      Ait: ait,
    };
    const retorno = await api.post(`/dashboards/data/fezIndicacao`, data);
    return retorno.data;
  } catch (e) {
    throw utils.trataErro(e, "retornaDetFezIndicacao");
  }
};

export const retornaDetTopPlaca = async ({
  dataIni,
  dataFim,
  placa,
  codGestor,
  gestorMaster,
  tipoDataFiltro,
  ait,
}) => {
  try {
    if (!gestorMaster && !placa && !codGestor) {
      throw new Error("Informe todos os parâmetros: placa e código Gestor");
    }

    const data = {
      DataInicialStr: dataIni,
      DataFinalStr: dataFim,
      CodigoGestor: codGestor,
      GestorMaster: gestorMaster,
      Placa: placa,
      TipoDataFiltro: tipoDataFiltro,
      Ait: ait,
    };
    const retorno = await api.post(`/dashboards/data/topPlaca`, data);
    return retorno.data;
  } catch (e) {
    throw utils.trataErro(e, "retornaDetTopPlaca");
  }
};

export const retornaDetTopLocatario = async ({
  dataIni,
  dataFim,
  placa,
  codGestor,
  gestorMaster,
  tipoDataFiltro,
  ait,
}) => {
  try {
    if (!gestorMaster && !placa && !codGestor) {
      throw new Error("Informe todos os parâmetros: placa e código Gestor");
    }

    const data = {
      DataInicialStr: dataIni,
      DataFinalStr: dataFim,
      CodigoGestor: codGestor,
      GestorMaster: gestorMaster,
      Placa: placa,
      TipoDataFiltro: tipoDataFiltro,
      Ait: ait,
    };
    const retorno = await api.post(`/dashboards/data/topLocatario`, data);
    return retorno.data;
  } catch (e) {
    throw utils.trataErro(e, "retornaDetTopLocatario");
  }
};

export const retornaDetRackingMotivos = async ({
  dataIni,
  dataFim,
  placa,
  codGestor,
  gestorMaster,
  infracao,
  tipoDataFiltro,
  ait,
}) => {
  try {
    if (!gestorMaster && !placa && !codGestor) {
      throw new Error("Informe todos os parâmetros: placa e código Gestor");
    }

    const data = {
      DataInicialStr: dataIni,
      DataFinalStr: dataFim,
      CodigoGestor: codGestor,
      GestorMaster: gestorMaster,
      Placa: placa,
      Infracao: infracao,
      TipoDataFiltro: tipoDataFiltro,
      Ait: ait,
    };
    const retorno = await api.post(`/dashboards/data/rackingInfracao`, data);
    return retorno.data;
  } catch (e) {
    throw utils.trataErro(e, "retornaDetRackingMotivos");
  }
};

export const retornaDetTopOrgao = async ({
  dataIni,
  dataFim,
  placa,
  codGestor,
  gestorMaster,
  nomeOrgao,
  tipoDataFiltro,
  ait,
}) => {
  try {
    if (!gestorMaster && !placa && !codGestor) {
      throw new Error("Informe todos os parâmetros: placa e código Gestor");
    }

    const data = {
      DataInicialStr: dataIni,
      DataFinalStr: dataFim,
      CodigoGestor: codGestor,
      GestorMaster: gestorMaster,
      Placa: placa,
      NomeOrgao: nomeOrgao,
      TipoDataFiltro: tipoDataFiltro,
      Ait: ait,
    };
    const retorno = await api.post(`/dashboards/data/topOrgao`, data);
    return retorno.data;
  } catch (e) {
    throw utils.trataErro(e, "retornaDetTopOrgao");
  }
};

export const retornaDetNotificacaoPorEstado = async ({
  dataIni,
  dataFim,
  placa,
  codGestor,
  gestorMaster,
  estado,
  tipoDataFiltro,
  ait,
}) => {
  try {
    if (!gestorMaster && !placa && !codGestor) {
      throw new Error("Informe todos os parâmetros: placa e código Gestor");
    }

    const data = {
      DataInicialStr: dataIni,
      DataFinalStr: dataFim,
      CodigoGestor: codGestor,
      GestorMaster: gestorMaster,
      Placa: placa,
      Estado: estado,
      TipoDataFiltro: tipoDataFiltro,
      Ait: ait,
    };
    const retorno = await api.post(
      `/dashboards/data/notificacaoPorEstado`,
      data
    );
    return retorno.data;
  } catch (e) {
    throw utils.trataErro(e, "retornaDetNotificacaoPorEstado");
  }
};

export const retornaDetPorNatureza = async ({
  dataIni,
  dataFim,
  placa,
  codGestor,
  gestorMaster,
  natureza,
  tipoDataFiltro,
  ait,
}) => {
  try {
    if (!gestorMaster && !placa && !codGestor) {
      throw new Error("Informe todos os parâmetros: placa e código Gestor");
    }

    const data = {
      DataInicialStr: dataIni,
      DataFinalStr: dataFim,
      CodigoGestor: codGestor,
      GestorMaster: gestorMaster,
      Placa: placa,
      Natureza: natureza,
      TipoDataFiltro: tipoDataFiltro,
      Ait: ait,
    };
    const retorno = await api.post(`/dashboards/data/porNatureza`, data);
    return retorno.data;
  } catch (e) {
    throw utils.trataErro(e, "retornaDetPorNatureza");
  }
};

export const retornaDetBoletoPagEmDia = async ({
  dataIni,
  dataFim,
  placa,
  codGestor,
  gestorMaster,
  antesVencimento,
  tipoDataFiltro,
  ait,
}) => {
  try {
    if (!gestorMaster && !placa && !codGestor) {
      throw new Error("Informe todos os parâmetros: placa e código Gestor");
    }

    const data = {
      DataInicialStr: dataIni,
      DataFinalStr: dataFim,
      CodigoGestor: codGestor,
      GestorMaster: gestorMaster,
      Placa: placa,
      AntesVencimento: antesVencimento,
      TipoDataFiltro: tipoDataFiltro,
      Ait: ait,
    };
    const retorno = await api.post(
      `/dashboards/data/boletoPagamentoEmDia`,
      data
    );
    return retorno.data;
  } catch (e) {
    throw utils.trataErro(e, "retornaDetBoletoPagEmDia");
  }
};

export const retornaDetLicenciamentoConcluido = async ({
  placa,
  codGestor,
  gestorMaster,
  tipo,
}) => {
  try {
    if (!gestorMaster && !placa && !codGestor) {
      throw new Error("Informe todos os parâmetros: placa e código Gestor");
    }

    const data = {
      CodigoGestor: codGestor,
      GestorMaster: gestorMaster,
      Placa: placa,
      Tipo: tipo,
    };
    const retorno = await api.post(
      `/dashboards/data/LicenciamentoConcluido`,
      data
    );
    return retorno.data;
  } catch (e) {
    throw utils.trataErro(e, "retornaDetLicenciamentoConcluido");
  }
};

export const retornaDetLicenciamentoStatus = async ({
  placa,
  codGestor,
  gestorMaster,
  tipo,
}) => {
  try {
    if (!gestorMaster && !placa && !codGestor) {
      throw new Error("Informe todos os parâmetros: placa e código Gestor");
    }

    const data = {
      CodigoGestor: codGestor,
      GestorMaster: gestorMaster,
      Placa: placa,
      Tipo: tipo,
    };
    const retorno = await api.post(
      `/dashboards/data/LicenciamentoStatus`,
      data
    );
    return retorno.data;
  } catch (e) {
    throw utils.trataErro(e, "retornaDetLicenciamentoStatus");
  }
};

export const retornaDetPrimeiroEmplacamentoTotal = async ({
  dataIni,
  dataFim,
  placa,
  codGestor,
  gestorMaster,
}) => {
  try {
    if (!gestorMaster && !placa && !codGestor) {
      throw new Error("Informe todos os parâmetros: placa e código Gestor");
    }

    const data = {
      DataInicialStr: dataIni,
      DataFinalStr: dataFim,
      CodigoGestor: codGestor,
      GestorMaster: gestorMaster,
      Placa: placa,
    };
    const retorno = await api.post(
      `/dashboards/data/primeiroEmplacamentoTotal`,
      data
    );
    return retorno.data;
  } catch (e) {
    throw utils.trataErro(e, "retornaDetPrimeiroEmplacamentoTotal");
  }
};

export const retornaDetPrimeiroEmplacamentoPendencia = async ({
  dataIni,
  dataFim,
  placa,
  codGestor,
  gestorMaster,
}) => {
  try {
    if (!gestorMaster && !placa && !codGestor) {
      throw new Error("Informe todos os parâmetros: placa e código Gestor");
    }

    const data = {
      DataInicialStr: dataIni,
      DataFinalStr: dataFim,
      CodigoGestor: codGestor,
      GestorMaster: gestorMaster,
      Placa: placa,
    };
    const retorno = await api.post(
      `/dashboards/data/primeiroEmplacamentoPendencia`,
      data
    );
    return retorno.data;
  } catch (e) {
    throw utils.trataErro(e, "retornaDetPrimeiroEmplacamentoPendencia");
  }
};

export const retornaDetPrimeiroEmplacamentoEmAndamento = async ({
  dataIni,
  dataFim,
  placa,
  codGestor,
  gestorMaster,
}) => {
  try {
    if (!gestorMaster && !placa && !codGestor) {
      throw new Error("Informe todos os parâmetros: placa e código Gestor");
    }

    const data = {
      DataInicialStr: dataIni,
      DataFinalStr: dataFim,
      CodigoGestor: codGestor,
      GestorMaster: gestorMaster,
      Placa: placa,
    };
    const retorno = await api.post(
      `/dashboards/data/primeiroEmplacamentoEmAndamento`,
      data
    );
    return retorno.data;
  } catch (e) {
    throw utils.trataErro(e, "retornaDetPrimeiroEmplacamentoEmAndamento");
  }
};

export const retornaDetPrimeiroEmplacamentoConcluido = async ({
  dataIni,
  dataFim,
  placa,
  codGestor,
  gestorMaster,
}) => {
  try {
    if (!gestorMaster && !placa && !codGestor) {
      throw new Error("Informe todos os parâmetros: placa e código Gestor");
    }

    const data = {
      DataInicialStr: dataIni,
      DataFinalStr: dataFim,
      CodigoGestor: codGestor,
      GestorMaster: gestorMaster,
      Placa: placa,
    };
    const retorno = await api.post(
      `/dashboards/data/primeiroEmplacamentoConcluido`,
      data
    );
    return retorno.data;
  } catch (e) {
    throw utils.trataErro(e, "retornaDetPrimeiroEmplacamentoConcluido");
  }
};

// #############################
// Boletos #####################
// #############################
export const retornaDetBoletoPorEstado = async ({
  dataIni,
  dataFim,
  placa,
  codGestor,
  gestorMaster,
  estado,
  tipoDataFiltro,
  ait,
}) => {
  try {
    if (!gestorMaster && !placa && !codGestor) {
      throw new Error("Informe todos os parâmetros: placa e código Gestor");
    }

    const data = {
      DataInicialStr: dataIni,
      DataFinalStr: dataFim,
      CodigoGestor: codGestor,
      GestorMaster: gestorMaster,
      Placa: placa,
      Estado: estado,
      TipoDataFiltro: tipoDataFiltro,
      Ait: ait,
    };
    const retorno = await api.post(`/dashboards/data/boletoPorEstado`, data);
    return retorno.data;
  } catch (e) {
    throw utils.trataErro(e, "retornaDetBoletoPorEstado");
  }
};

export const retornaDetBoletoTopOrgao = async ({
  dataIni,
  dataFim,
  placa,
  codGestor,
  gestorMaster,
  nomeOrgao,
  tipoDataFiltro,
  ait,
}) => {
  try {
    if (!gestorMaster && !placa && !codGestor) {
      throw new Error("Informe todos os parâmetros: placa e código Gestor");
    }

    const data = {
      DataInicialStr: dataIni,
      DataFinalStr: dataFim,
      CodigoGestor: codGestor,
      GestorMaster: gestorMaster,
      Placa: placa,
      NomeOrgao: nomeOrgao,
      TipoDataFiltro: tipoDataFiltro,
      Ait: ait,
    };
    const retorno = await api.post(`/dashboards/data/boletoTopOrgao`, data);
    return retorno.data;
  } catch (e) {
    throw utils.trataErro(e, "retornaDetBoletoTopOrgao");
  }
};

export const retornaDetBoletoRankingMotivos = async ({
  dataIni,
  dataFim,
  placa,
  codGestor,
  gestorMaster,
  infracao,
  tipoDataFiltro,
  ait,
}) => {
  try {
    if (!gestorMaster && !placa && !codGestor) {
      throw new Error("Informe todos os parâmetros: placa e código Gestor");
    }

    const data = {
      DataInicialStr: dataIni,
      DataFinalStr: dataFim,
      CodigoGestor: codGestor,
      GestorMaster: gestorMaster,
      Placa: placa,
      Infracao: infracao,
      TipoDataFiltro: tipoDataFiltro,
      Ait: ait,
    };
    const retorno = await api.post(
      `/dashboards/data/boletoRankingInfracao`,
      data
    );
    return retorno.data;
  } catch (e) {
    throw utils.trataErro(e, "retornaDetBoletoRankingMotivos");
  }
};

export const retornaDetBoletoTopPlaca = async ({
  dataIni,
  dataFim,
  placa,
  codGestor,
  gestorMaster,
  tipoDataFiltro,
  ait,
}) => {
  try {
    if (!gestorMaster && !placa && !codGestor) {
      throw new Error("Informe todos os parâmetros: placa e código Gestor");
    }

    const data = {
      DataInicialStr: dataIni,
      DataFinalStr: dataFim,
      CodigoGestor: codGestor,
      GestorMaster: gestorMaster,
      Placa: placa,
      TipoDataFiltro: tipoDataFiltro,
      Ait: ait,
    };
    const retorno = await api.post(`/dashboards/data/boletoTopPlaca`, data);
    return retorno.data;
  } catch (e) {
    throw utils.trataErro(e, "retornaDetBoletoTopPlaca");
  }
};

//#endregion
