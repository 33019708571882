import React from 'react';
import styled from 'styled-components';

import { useDadosGlobal } from "../../context";

import * as Tema from "../style";


// const Ul = styled.ul`
//   list-style: none;
//   display: flex;
//   flex-flow: row nowrap;

//   li {
//     padding: 18px 10px;
//   }

//   @media (max-width: 768px) {
//     flex-flow: column nowrap;
//     background-color: var(--color-primary);
//     position: fixed;
//     transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(-100%)'};
//     top: 0;
//     left: 0;
//     height: 100vh;
//     width: 300px;
//     padding-top: 3.5rem;
//     transition: transform 0.3s ease-in-out;
//     z-index: 2;

//     li {
//       color: #fff;
//     }
//   }
// `;

const Ul = styled.ul`
  background-color: var(--color-primary);
  list-style: none;
  display: flex;
  flex-flow: column nowrap;
  position: fixed;
  transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(-100%)'};
  top: 0;
  left: 0;
  height: 100vh;
  width: 300px;
  padding-top: 3.5rem;
  transition: transform 0.3s ease-in-out;
  z-index: 2;

  li {
    padding: 18px 10px;
    color: var(--color-secundary);
  }
`;

const RightNav = ({ open, closeMobileMenu }) => {
  const [idMenu, setIdMenu] = React.useState(0);
  const { usuario } = useDadosGlobal();
  const selecionar = (id) => {
    if (id === idMenu) id = 0;
    setIdMenu(id);
  };
  return (
    <Ul open={open}>
      <Tema.Container>
      <Tema.TituloMenu>
        {"Dashboard"} 
      </Tema.TituloMenu>
      <Tema.MenuDiv>
        <Tema.MenuContainer>
          <Tema.MenuLista>
            <Tema.ItemLink to="/home" onClick={() => (closeMobileMenu ())}>
              <Tema.MenuLink
                id={1}
                onClick={() => {
                  selecionar(1);
                }}
              >
                <Tema.MenuIcone className="pe-7s-display1" />
                {"Home"}
                {/* <Tema.MenuIcone className={`pe-7s-angle-down esquerda ${idMenu ===1 && 'visible'}`} /> */}
              </Tema.MenuLink>
            </Tema.ItemLink>
            {/* <Tema.MenuListaOpcoes className={idMenu ===1 && 'visible'}>
                </Tema.MenuListaOpcoes> */}
          </Tema.MenuLista>
        </Tema.MenuContainer>
      </Tema.MenuDiv>
      
      
      {/* Menu Primeiro emplacamento  */}
      <div
        style={{
          display: usuario.gestorMaster ? "" : "none",
        }}
      >
        <Tema.TituloMenu>
        {"Primeiro Emplacamento"} 
        </Tema.TituloMenu>
        <Tema.MenuDiv>
          <Tema.MenuContainer>
            <Tema.MenuLista>
              <Tema.ItemLink to="/primeiroEmplacamento" onClick={() => (closeMobileMenu ())}>
                <Tema.MenuLink
                  id={4}
                  onClick={() => {
                    selecionar(4);
                  }}
                >
                  <Tema.MenuIcone className="pe-7s-display1" />
                  {"Primeiro Emplacamento"}
                </Tema.MenuLink>
              </Tema.ItemLink>
            </Tema.MenuLista>
          </Tema.MenuContainer>
        </Tema.MenuDiv>
      </div>
      
      
      {/* Menu Meu veículo  */}
      <Tema.TituloMenu>
        {usuario?.tipo == 1 ? "Condutor" : "Gestor"}
      </Tema.TituloMenu>
      <Tema.MenuDiv>
        <Tema.MenuContainer>
          <Tema.MenuLista>
            <Tema.MenuLink
              id={3}
              onClick={() => {
                selecionar(3);
              }}
            >
              <Tema.MenuIcone className="pe-7s-car" />
              {"Meu veículo"}
              {(
                <Tema.MenuIcone
                  className={`pe-7s-angle-down esquerda ${
                    idMenu === 3 && "visible"
                  }`}
                />
              )}
            </Tema.MenuLink>
            <Tema.MenuListaOpcoes
              className={
                idMenu === 3 && "visible"
              }
            >
              <Tema.ItemLink to="/notificacao" onClick={() => (closeMobileMenu ())}>
                <Tema.MenuItem>Notificações</Tema.MenuItem>
              </Tema.ItemLink>
              <Tema.ItemLink to="/boleto" onClick={() => (closeMobileMenu ())}>
                <Tema.MenuItem>Boletos</Tema.MenuItem>
              </Tema.ItemLink>
              <Tema.ItemLink to="/licenciamento" onClick={() => (closeMobileMenu ())}>
                <Tema.MenuItem>Licenciamento</Tema.MenuItem>
              </Tema.ItemLink>
              
              {/* Fellipe - 06/06/2023 => Vamos inserir uma "Tela" do CRLV Digital apenas para condutor */}
              <Tema.ItemLink  
                style={{ display: usuario?.tipo == 1 ? "" : "none" }}
                to="/crlvDigital" 
                onClick={() => (closeMobileMenu ())}>
                <Tema.MenuItem>CRLV Digital</Tema.MenuItem>
              </Tema.ItemLink>
              
            </Tema.MenuListaOpcoes>
          </Tema.MenuLista>
        </Tema.MenuContainer>
      </Tema.MenuDiv>
      
      
    </Tema.Container>
    </Ul>
  )
}

export default RightNav
