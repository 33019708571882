import {retornaGoogleKey} from '../../../API'
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
const apiKEY = retornaGoogleKey();



function Mapa({localizacao, zoom, width, height}) {
    if (!width) width = '400px'
    if (!height) height = '400px'
    if (!zoom) zoom = 15
  return (
    <LoadScript
      googleMapsApiKey={apiKEY}
    >
      <GoogleMap
        mapContainerStyle={{width, height}}
        center={localizacao}
        zoom={zoom}
      >
        { /* Child components, such as markers, info windows, etc. */ }
        <Marker
          // onLoad={onLoad}
          position={localizacao}
        />
      </GoogleMap>
    </LoadScript>
  )
}

export default Mapa;
