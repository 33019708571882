import React, { useEffect, useCallback, useState } from "react";

import Tela from "../../layout/tela";
import * as Padrao from "../../../style/config";
import * as Tema from "../../layout/tela/style";
import { Carregando } from "../../loading";

import * as utils from "../../../utils";

import * as api from "../../../API/consulta";

import Lottie from "react-lottie";
import animationData from "../../../assets/animated/CrlvDigital.json";

import { useDadosGlobal } from "../../context";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const abrirlink = (url) => window.open(url, "_blank");

const DownloadCRLV = ({}) => {
  const { usuario } = useDadosGlobal();
  const [carregando, setCarregando] = useState(true);
  const [propsAlerta, setPropsAlerta] = useState(utils.propsAlerta);

  useEffect(() => {
    async function carregar() {
      try {
      } catch (e) {
        setPropsAlerta(
          utils.montaAlerta({
            titulo: e.message,
            origem: e.origem,
            tAnimacao: e.tipoAlerta,
          })
        );
      }
    }
    carregar();
  }, []);

  // ### Métodos

  // ## Download CRLV Digital
  const downloadCRLV = async ({ placa }) => {
    try {
      const obj = utils.montaAlerta({
        titulo: "Pesquisando o seu CRLV",
        tAnimacao: utils.animacoes.carregando,
      });
      setPropsAlerta(obj);

      const ret = await api.anexarCRLV({
        placa: placa,
      });

      setPropsAlerta(utils.semAlerta);
    } catch (e) {
      setPropsAlerta(
        utils.montaAlerta({
          titulo: e.message,
          origem: e.origem,
          tAnimacao: e.tipoAlerta,
        })
      );
    }
    setCarregando(false);
  };

  // ### Tela
  return (
    <Tela
      titulo={"CRLV Digital"}
      icone={"pe-7s-car"}
      subTitulo={`Faça o Download do seu CRLV`}
      menu={true}
    >
      <React.Fragment>
        <Tema.CardForm>
          <Tema.CardFormTermo>
            <div
              style={{ textAlign: "center", width: "48%", alignSelf: "center" }}
            >
              <h1 style={{ textTransform: "uppercase", fontSize: "medium" }}>
                CRLV Digital
              </h1>
              <div>
                <Lottie
                  options={defaultOptions}
                  height={"100%"}
                  width={"100%"}
                />
              </div>
            </div>
            {console.log("Placa: " + usuario?.placa)}
            <div style={{ margin: "auto auto" }}>
              <h3>Clique no botão abaixo para fazer o download do seu CRLV</h3>
              {/* <p>
                Não esqueça de verificar todos so dados e assinar para nos
                enviar
              </p> */}
              <div
                style={{
                  display: "flex",
                  flexFlow: "row-reverse",
                  marginTop: "20px",
                }}
              >
                <Padrao.Botao
                  size="small"
                  principal={true}
                  onClick={async () => {
                    await downloadCRLV({ placa: usuario?.placa });
                  }}
                >
                  Download
                </Padrao.Botao>
              </div>
            </div>
          </Tema.CardFormTermo>
        </Tema.CardForm>
      </React.Fragment>
      <Carregando
        tipo={propsAlerta.tipoAnimacao}
        exibir={propsAlerta.exibir}
        userClose={propsAlerta.usuarioFechar}
        loop={true}
        titulo={propsAlerta.titulo}
        origem={propsAlerta.origem}
        setExibir={() => {
          setPropsAlerta(utils.semAlerta);
        }}
        // styleFundo={{width:'90%', height:'90%'}}
        // styleAnimacao={{width:'70%'}}
      />
    </Tela>
  );
};

export default DownloadCRLV;
