import React, { useState, useCallback } from 'react';

import Chart, { Props } from "react-apexcharts";


const Grafico = ({ style, data, totalQtd, Labels, onClick }) => {

  const props: Props = {}

  props.options = {

    chart: {
      events: {
        dataPointMouseEnter: function (e) {
          if (onClick) {
            try {
              e.path[0].style.cursor = "pointer";
            } catch {

            }

          }
        },
        dataPointSelection: async function (e, chart, option) {
          if (onClick) {
            return await onClick(option)
          }

        }
      },
      type: 'radialBar',
    },

    plotOptions: {
      radialBar: {
        dataLabels: {
          name: {
            fontSize: '17px',
          },
          value: {
            fontSize: '16px',
          },
          total: {
            show: true,
            label: 'Total',
            formatter: function (w) {
              // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
              return totalQtd
            }
          }
        }
      }
    },
    labels: Labels,
  };

  return (
    <React.Fragment>
      <div style={style}>
        <Chart
          options={props.options}
          series={data}
          type="radialBar"
        />
      </div>
    </React.Fragment>
  );
}

export default Grafico;

