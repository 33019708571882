import React from "react";
import Helmet from "react-helmet";

import Cabecalho from "../tela/cabecalho";
import * as Tema from "./style";

const Tela = ({
  icone,
  titulo,
  subTitulo,
  sair,
  onSair,
  children,
  semCabecalho,
}) => {
  return (
    <React.Fragment>
      <Tema.Container>
        {semCabecalho ? null : (
          <Cabecalho
            icone={icone}
            titulo={titulo}
            subTitulo={subTitulo}
            sair={sair}
            onSair={onSair}
          />
        )}
        {children}
      </Tema.Container>
    </React.Fragment>
  );
};

export default Tela;
