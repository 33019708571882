
import  * as Padrao from '../../../../style/config'
import { Field } from "formik";
import MenuItem from "@material-ui/core/MenuItem";


const Select =({label, name, labelAtivo, value, onChange, desabilitado, placeHolder, minWidth,widthAuto, itens})=>{

    return(
        <Field name={name}>
{({ field, form, meta }) => (
        <Padrao.Input
        {...field}
        widthAuto={widthAuto}
        select
        label={label}
        size="small"
        variant="outlined"
        value={value}
        minWidth={minWidth}
        onChange={onChange}
        disabled={desabilitado}
        placeholder={placeHolder}
        InputLabelProps={{
            shrink: labelAtivo,
          }}
          helperText={meta.error}
          error={meta.error}
      >
          {itens.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.descricao}
                  </MenuItem>
                ))}
          </Padrao.Input>
      )}
        </Field>
    )
};

export default Select