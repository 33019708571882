import styled from "styled-components";
import ButtonMaterial from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import { Modal } from "@material-ui/core";

const media = {
  mobile: "@media(max-width: 900px)",
  desktop: "@media (min-width: 1180px)",
};

export const Padrao = { CorPrimaria: "", CoSecundaria: "#F27405" };

// export const ItemMenu = styled.a`
//     color: #b8b7ad;
//     text-decoration: none;
//     &:hover, &:focus {
//         color: ${Padrao.CoSecundaria};
//       }
// `

export const ItemMenu = styled.a`
  color: #b8b7ad;
  text-decoration: none;
  &:hover,
  &:focus {
    color: ${Padrao.CoSecundaria};
  }
`;

export const Icone = styled.i`
  text-align: center;
  width: 34px;
  height: 34px;
  line-height: 34px;
  left: 5px;
  font-size: xx-large;
  transition: color 300ms;
  opacity: 0.8;
  color: #fff;
`;

export const styleBotao = {
  background: "var(--color-primary)",
  color: "white",
  fontfamily: "Roboto",
  fontweight: 400,
  width: "150px",
  height: "45px",
  borderradius: "10px",
  cursor: "pointer",
  transition: "transform 0.3s ease",
};

export const styleBotaoPequeno = {
  background: "var(--color-primary)",
  color: "white",
  fontfamily: "Roboto",
  fontweight: 400,
  width: "130px",
  height: "40px",
  borderradius: "10px",
  cursor: "pointer",
  transition: "transform 0.3s ease",
};
export const Botao = styled(ButtonMaterial)`
  && {
    background-color: var(--color-primary);
    background-color: ${(props) =>
      props.principal ? "var(--color-primary)" : "var(--color-secundary)"};
    color: ${(props) =>
      props.principal ? "var(--color-secundary)" : "var(--color-primary)"};
    /* color: white; */
    font-family: Roboto;
    font-size: 10px;
    font-weight: 400;
    width: auto;
    min-height: 35px;
    border-radius: 0.25rem;
    cursor: pointer;
    transition: transform 0.3s ease;
  }

  &&:hover {
    opacity: 0.8;
    background-color: ${(props) =>
      props.principal ? "var(--color-primary)" : "var(--color-secundary)"};
    color: ${(props) =>
      props.principal ? "var(--color-secundary)" : "var(--color-primary)"};
  }
`;

export const BotaoIcone = styled(IconButton)`
  && {
    color: var(--color-primary);
  }

  &&:hover {
    opacity: 0.8;
    color: var(--color-primary);
  }
`;

export const Titulo = styled.span`
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
  color: var(--color-cliente);
`;

export const Navegacao = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
  padding: 10px;
  ${media.mobile} {
    padding: 5px;
  }
`;

export const Input = styled(TextField)`
  && .MuiInputLabel-outlined.MuiInputLabel-shrink {
    width: max-content;
  }
  &&.MuiTextField-root {
    flex: 1;
    ${(props) => props.widthAuto && `flex: none`}
  }
  && .MuiOutlinedInput-root {
    height: 36.4px;
    ${(props) => props.minWidth && `min-width: ${props.minWidth}`};
    ${(props) =>
      props.width &&
      `width: ${props.width};
    ${media.mobile} {
    width: 100%;
    }
    `};
  }
  && .MuiInputBase-input {
    color: var(--color-primary) !important;
    font-family: var(--font-padrao) !important;
    font-size: 13px;
  }

  && label.Mui-focused {
    color: var(--color-primary);
    border-color: var(--color-primary);
  }

  && .MuiInputBase-input:after {
    border-color: var(--color-primary);
  }

  && .Mui-focused fieldset {
    border-color: var(--color-primary);
  }
`;

export const ModalHub = styled(Modal)`
  & .MuiBackdrop-root {
    background-color: #000000c4;
  }
`;
