import styled from "styled-components";

const media = {
  mobile: "@media(max-width: 900px)",
  desktop: "@media (min-width: 1180px)",
};

export const Container = styled.div`
  background: transparent;
  width: 100%;
  height: auto;

  @media (min-width: 1180px) {
    background: transparent;
    width: 100%;
    height: auto;
    /* .icon: ; */
  }
`;

export const Card = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  box-shadow: 0 0.46875rem 2.1875rem rgba(0, 0, 0, 0.03),
    0 0.9375rem 1.40625rem rgba(0, 0, 0, 0.03),
    0 0.25rem 0.53125rem rgba(0, 0, 0, 0.05),
    0 0.125rem 0.1875rem rgba(0, 0, 0, 0.03);
  transition: all 0.2s;
`;
export const CardForm = styled(Card)`
  width: 98%;
  height: auto;
  padding: 10px;
  ${media.mobile} {
    width: 100%;
    margin: auto auto;
  }
`;

export const CardFormNotificacaoDetalheMultiLine = styled.div`
  display: flex;
  gap: 1%;
  flex-flow: wrap;
  flex-direction: row;
  flex-wrap: nowrap;

  ${media.mobile} {
    width: 100%;
  }
`;

export const DivBotaoNaoIndicar = styled.div`
  padding: 15px 10px 0px 10px;
  ${media.mobile} {
    padding: 15px 10px 0px 0px;
  }
`;

export const CardFormIndicacao = styled.div`
  display: flex;
  column-gap: 2%;
  flex-wrap: wrap;
  padding: 0px 10px 10px 10px;
  ${media.mobile} {
    padding: 0px;
  }
`;

export const CardFormIndicacaoDetalhe = styled(Card)`
  min-height: 150px;
  width: 49%;
  margin-top: 20px;
  padding: 10px;
  ${media.mobile} {
    width: 99%;
    margin-top: 20px;
  }
`;

export const CardFormUpload = styled.div`
  padding: 5px;
  display: flex;
  gap: 10px;
  width: 100%;
  ${media.mobile} {
    padding: 5px;
    display: flex;
    gap: 10px;
    width: 100%;
    flex-direction: column;
  }
`;

export const CardFormTermo = styled.div`
  display: flex;
  ${media.mobile} {
    display: flex;
    flex-direction: column;
  }
`;

export const CardFormHome = styled.div`
  padding: 5px;
  display: flex;
  gap: 10px;
  flex-flow: wrap;
  align-items: flex-end;
  ${media.mobile} {
    padding: 5px;
    gap: 10px;
    flex-flow: wrap;
    display: contents;
  }
`;

export const FormTitulo = styled.div`
  margin-bottom: 10px;
`;

export const FormLinha = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;
export const FormColuna = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-wrap: wrap;
  flex: 1 1 25%;
  gap: 10px;

  ${media.mobile} {
    flex: 1 1 100%;
  }
  ${media.desktop} {
    ${(props) => props.auto && `flex: none`}
  }
`;
export const CardCabecalho = styled.div`
  margin: -1px -1px 0;
  padding: 1.5rem 0.5rem;
  position: relative;
  color: #fff;
  z-index: 1;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
`;
export const CardCabecalhoFundoImagem = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 8;
  opacity: 0.25;
  filter: grayscale(80%);
  background-position: center;
  background-size: cover;
  background-image: url("https://www.instacarro.com/uploads/2018/09/c86554a7-tudo-o-que-voc%C3%AA-precisa-saber-sobre-multas-de-tr%C3%A2nsito.jpg");
`;
export const CardCabecalhoDivTitulo = styled.div`
  flex-direction: column;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  display: flex;
  align-content: center;
  align-items: center;
  text-align: left;
`;

export const CardCabecalhoTitulo = styled.h5`
  font-weight: 500;
  font-size: 2vw;
  margin: 0;
  line-height: 1.2;
  ${(props) => props.color && `color:${props.color};`};

  ${media.mobile} {
    font-size: 4.3vw;
  }
`;

export const CardCabecalhoSubTitulo = styled.h6`
  margin: 5px 0 0;
  opacity: 0.8;
  font-size: ${(props) => (props.fontSize ? props.fontSize : `0.8rem`)};
  font-weight: 400;
  line-height: 1.2;
  ${(props) => props.onClick && `&:hover{cursor: pointer;}`}

  ${media.mobile} {
    font-size: 3vw;
  }
`;

export const CardDados = styled.div`
  height: ${(props) => (props.height ? props.height : "120px")};
  width: 24.9%;
  display: flex;
  flex-direction: column;
  border: 1px solid #dee2e6;
  align-items: center;
  justify-content: center;
  flex: 1;
  /* min-width: 122px; */
  ${media.mobile} {
    width: 50%;
  }
`;

export const Icone = styled.i`
  margin: auto;
  ${(props) => props.color && `color:${props.color};`};
  font-size: 2.7rem;
`;

export const CardContato = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  ${media.mobile} {
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 52%;
  }
`;

export const CardFormContato = styled.div`
  width: 50%;
  ${media.mobile} {
    width: 100%;
  }
`;

export const CardFormContatoWhatsapp = styled.div`
  min-height: 150px;
  height: 417px;
  width: 50%;
  justify-content: center;
  text-align: center;
  padding: 10px;
  ${media.mobile} {
    width: 50%;
    justify-content: center;
    text-align: center;
    padding: 10px;
    align-self: center;
    margin-top: 5%;
  }
`;

export const LineBreak = styled.div`
  display: none;
  ${media.mobile} {
    width: 100%;
    display: flex;
  }
`;

export const ColumnBreak = styled.div`
  display: none;
  ${media.mobile} {
    flex-basis: 100%;
    width: 0;
  }
`;

export const CardFormLoginContato = styled.div`
  width: ${(props) => (props.abrir = 3 ? "70%" : "inherit")};
  outline: none;
  padding: ${(props) => (props.abrir = 3 ? 10 : 0)};
  background: white;
  border-radius: 10;

  ${media.mobile} {
    width: ${(props) => (props.abrir = 3 ? "85%" : "inherit")};
    padding: ${(props) => (props.abrir = 3 ? 10 : 0)};
    background: white;
    height: 70%;
  }
`;

export const CardFormLoginSenha = styled.div`
  width: 90%;
  justify-content: space-evenly;
  ${media.mobile} {
    width: 100%;
    display: flex;
  }
`;

export const CardFormInfoGrafico = styled.div`
  display: flex;
  gap: 20px;
  justify-content: center;
  ${media.mobile} {
    flex-flow: column;
  }
`;

export const DivLinhaTotais = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  padding-top: 10px;
  gap: 10px;
`;

export const DivTotaisFundo = styled.div`
  background-image: ${(props) => props.corFundo};
  min-width: 230px;
  max-width: 17%;
  position: relative;
  display: flex;
  flex-direction: column;
  word-wrap: break-word;
  background-color: transparent;
  background-clip: border-box;
  border: 0 solid rgba(0, 0, 0, 0.125);
  border-radius: 0.35rem;
  box-shadow: -8px 8px 14px 0 rgb(25 42 70 / 11%);
  height: 160px;
  filter: drop-shadow(0 20px 8px rgba(0, 0, 0, 0.3));
`;
export const DivTotaisBody = styled.div`
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.5rem;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
`;
export const DivTotaisMedia = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 1rem 0;
  padding-bottom: 0.375rem !important;
`;
export const DivTotaisMediaBody = styled.div`
  color: #fff !important;
  text-align: left;
  flex: 1;
`;
export const DivTotaisTitulo = styled.h3`
  font-size: 1.7rem !important;
  line-height: 2rem;
  margin-bottom: 0 !important;
  font-family: Montserrat, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif;
  font-weight: 500;
  margin-top: 0;
`;

export const DivTotaisSubTitulo = styled.span``;

export const CardDetalhes = styled.div`
  text-align: center;
  padding: 0.3rem;
  position: relative;
  transition: all 0.2s;
  flex: 1;
  &:hover {
    z-index: 15;
    transform: scale(1.15);
    box-shadow: 0 0.46875rem 4.1875rem rgb(8 10 37 / 5%),
      0 0.9375rem 2.40625rem rgb(8 10 37 / 5%),
      0 0.25rem 1.3125rem rgb(8 10 37 / 6%),
      0 0.125rem 1.1875rem rgb(8 10 37 / 6%);
    cursor: pointer;
  }
`;
export const CardDetalhesIcone = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  border-radius: 50%;
  width: 54px;
  height: 54px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
`;
export const CardDetalhesIconeFundo = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0.3;
  background: ${(props) => props.corFundo};
`;
export const CardDetalhesTitulo = styled.div`
  font-weight: 700;
  font-size: 2.5rem;
  display: block;
  line-height: 1;
  margin: 1rem auto;
`;
export const CardDetalhesSubTitulo = styled.div`
  margin: -0.5rem 0 0;
  display: block;
  opacity: 0.6;
`;
