import React from 'react';

import { ClapSpinner} from "react-spinners-kit";
import * as Tema from './style'
function Spinner({carregando, size}) {
    if (!size) size=47
    
  return (
      <Tema.DivSpinner>
        <ClapSpinner size={size}  frontColor="var(--color-primary)" backColor="var(--color-secundary)" loading={carregando} />

      </Tema.DivSpinner>

  );
}

export default Spinner;
