import React from 'react';

import {ChartitGraphLineProps, } from 'react-chartist';

// import pluginLegend from "chartist-plugin-legend";

import  './style.css'

// import  '../style/plugin.scss'

import * as Tema from './style'

const Grafico =({name, data, corLabel,labels, colors, height, backGround,showArea, showLinhaH, onClick})=>{
    
 const [update, setUpdate] = React.useState(false);
    
      var dataGraf =  {
          labels: labels,
          series: data
        };   

        
      let op :ChartitGraphLineProps={
        data: dataGraf,
        type: 'Line',
        options:{
            // high: 3,
            low: 0,
            fullWidth: true,
            showArea:showArea,
            showLine:true,
            height:height,
            width:'100%',
            chartPadding:{
left:0,
right:50,
bottom:20,
            },
classNames:{
  series: name + '-series'
},
            // As this is axis specific we need to tell Chartist to use whole numbers only on the concerned axis
            axisY: {
              onlyInteger: true,
              // offset: 20,
              showGrid:showLinhaH,
              showLabel:false



            },
            axisX:{
                showGrid:false,



            },
            // showPoint: 
           
          },

        
        
    }
    return (
     

      // document.querySelector('.ct-line').getTotalLength()
     
         
          <Tema.Grafico data={op.data} options={op.options} type={op.type} 
          colors={colors} idGradient={'Linegradient'} lineWidth={2}
          backGround={backGround} name={name} corLabel={corLabel}
          cursorClick = "ct-point"
     listener={{
       draw:e=>{
         
        if(e.type === 'point') {
          
          if (onClick){
                
            e.element._node.onclick = event => onClick(e);
            

          }}},
       created: e=>{
         setUpdate(true)
        //  setTamLinha( e.axisX.stepLength * e.axisX.ticks.length)
        if (showArea){

        var defs = e.svg.elem('defs');
        defs.elem('linearGradient', {
          id: 'Linegradient',
          x1: 0, y1: 1, x2: 0, y2: 0
        }).elem('stop', {
          offset: 0.5,
          // 'stop-opacity': 0.2,
          'stop-color': colors.slice(0,1)
        }).parent().elem('stop', {
          offset: 0.4,
          // 'stop-opacity': 1,
          'stop-color': '#fff'
        })

      }
    }
     }}
          />
      
      )
};

export default Grafico