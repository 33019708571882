import React, { useEffect } from "react";
import { useCountUp } from "react-countup";

const CountUp = ({ end, valor, sepadorDecimal, caractermilhar, prefix, casasDecimais }) => {
  if (!prefix) prefix = null;

  const { countUp, start, pauseResume, reset, update } = useCountUp({
    start: 0,
    end: end,
    // delay: 1000,
    duration: 4,
    separator:caractermilhar,
    decimals: casasDecimais,
    decimal: sepadorDecimal,
    prefix: prefix,
    // onReset: () => console.log('Resetted!'),
    // onUpdate: () => console.log('Updated!'),
    // onPauseResume: () => console.log('Paused or resumed!'),
    // onStart: ({ pauseResume }) => console.log(pauseResume),
    // onEnd: ({ pauseResume }) => console.log(pauseResume),
  });

  useEffect(() => {
    update(valor);
  }, [valor]);

  return <span>{countUp}</span>;
};

export default CountUp;
